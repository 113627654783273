import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'

type Props = {
  data: {
    referenceNumber: string
    LetterheadNumber: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Balance Certificate Verification Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Reference Number</b>
              </Col>
              <Col md={6}>
                <p>{data.referenceNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Letterhead Number</b>
              </Col>
              <Col md={6}>
                <p>{data.LetterheadNumber}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
