import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getInternshipApplicationsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getInternshipApplications, params)
    yield put(actions.getInternshipApplicationsSuccess(response?.data?.data))
    yield put(actions.getInternshipApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getInternshipApplicationsFinish())
  }
}

//Get file
function* getInternshipApplicationFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getInternshipApplicationFile, fileName)
    const data: any = response?.data
    yield put(actions.getInternshipApplicationFileSuccess(data))
    yield put(actions.getInternshipApplicationFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getInternshipApplicationFileError())
  }
}

function* updateInternshipApplicationsSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateInternshipApplications,
      body,
      action.payload?.id
    )
    yield put(actions.updateInternshipApplicationsSuccess(response.data?.data))
    yield put(actions.updateInternshipApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateInternshipApplicationsFinish())
  }
}

function* deleteInternshipApplicationsSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteInternshipApplications, body)
    yield put(actions.deleteInternshipApplicationsSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteInternshipApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteInternshipApplicationsFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_INTERNSHIP_APPLICATIONS_START, getInternshipApplicationsSaga)
  yield takeLatest(
    actionTypes.UPDATE_INTERNSHIP_APPLICATIONS_START,
    updateInternshipApplicationsSaga
  )
  yield takeLatest(
    actionTypes.GET_INTERNSHIP_APPLICATIONS_FILE_START,
    getInternshipApplicationFileSaga
  )
  yield takeLatest(
    actionTypes.DELETE_INTERNSHIP_APPLICATIONS_START,
    deleteInternshipApplicationsSaga
  )
}
