export const actionTypes: any = {
  // Get service category
  GET_SERVICE_CATEGORY_DATA_START: 'GET_SERVICE_CATEGORY_DATA_START',
  GET_SERVICE_CATEGORY_DATA_SUCCESS: 'GET_SERVICE_CATEGORY_DATA_SUCCESS',
  GET_SERVICE_CATEGORY_DATA_FINISH: 'GET_SERVICE_CATEGORY_DATA_FINISH',

  // add service category
  ADD_SERVICE_CATEGORY_DATA_START: 'ADD_SERVICE_CATEGORY_DATA_START',
  ADD_SERVICE_CATEGORY_DATA_SUCCESS: 'ADD_SERVICE_CATEGORY_DATA_SUCCESS',
  ADD_SERVICE_CATEGORY_DATA_FINISH: 'ADD_SERVICE_CATEGORY_DATA_FINISH',
  RESET_SERVICE_CATEGORY_DATA: 'RESET_SERVICE_CATEGORY_DATA',

  // update service category
  UPDATE_SERVICE_CATEGORY_DATA_START: 'UPDATE_SERVICE_CATEGORY_DATA_START',
  UPDATE_SERVICE_CATEGORY_DATA_SUCCESS: 'UPDATE_SERVICE_CATEGORY_DATA_SUCCESS',
  UPDATE_SERVICE_CATEGORY_DATA_FINISH: 'UPDATE_SERVICE_CATEGORY_DATA_FINISH',

  // delete service category
  DELETE_SERVICE_CATEGORY_DATA_START: 'DELETE_SERVICE_CATEGORY_DATA_START',
  DELETE_SERVICE_CATEGORY_DATA_SUCCESS: 'DELETE_SERVICE_CATEGORY_DATA_SUCCESS',
  DELETE_SERVICE_CATEGORY_DATA_FINISH: 'DELETE_SERVICE_CATEGORY_DATA_FINISH',

  // Enable service category
  ENABLE_SERVICE_CATEGORY_DATA_REQUEST: 'ENABLE_SERVICE_CATEGORY_DATA_REQUEST',
  ENABLE_SERVICE_CATEGORY_DATA_SUCCESS: 'ENABLE_SERVICE_CATEGORY_DATA_SUCCESS',
  ENABLE_SERVICE_CATEGORY_DATA_FINISH: 'ENABLE_SERVICE_CATEGORY_DATA_FINISH',

  // Disable service category
  DISABLE_SERVICE_CATEGORY_DATA_REQUEST: 'DISABLE_SERVICE_CATEGORY_DATA_REQUEST',
  DISABLE_SERVICE_CATEGORY_DATA_SUCCESS: 'DISABLE_SERVICE_CATEGORY_DATA_SUCCESS',
  DISABLE_SERVICE_CATEGORY_DATA_FINISH: 'DISABLE_SERVICE_CATEGORY_DATA_FINISH',

  // Enable service category
  SINGLE_ENABLE_SERVICE_CATEGORY_DATA_REQUEST: 'SINGLE_ENABLE_SERVICE_CATEGORY_DATA_REQUEST',
  SINGLE_ENABLE_SERVICE_CATEGORY_DATA_SUCCESS: 'SINGLE_ENABLE_SERVICE_CATEGORY_DATA_SUCCESS',
  SINGLE_ENABLE_SERVICE_CATEGORY_DATA_FINISH: 'SINGLE_ENABLE_SERVICE_CATEGORY_DATA_FINISH',

  // Disable service category
  SINGLE_DISABLE_SERVICE_CATEGORY_DATA_REQUEST: 'SINGLE_DISABLE_SERVICE_CATEGORY_DATA_REQUEST',
  SINGLE_DISABLE_SERVICE_CATEGORY_DATA_SUCCESS: 'SINGLE_DISABLE_SERVICE_CATEGORY_DATA_SUCCESS',
  SINGLE_DISABLE_SERVICE_CATEGORY_DATA_FINISH: 'SINGLE_DISABLE_SERVICE_CATEGORY_DATA_FINISH',

  // Get service category
  GET_SERVICE_CATEGORY_LIST_START: 'GET_SERVICE_CATEGORY_LIST_START',
  GET_SERVICE_CATEGORY_LIST_SUCCESS: 'GET_SERVICE_CATEGORY_LIST_SUCCESS',
  GET_SERVICE_CATEGORY_LIST_FINISH: 'GET_SERVICE_CATEGORY_LIST_FINISH',

  // sort
  SORT_SERVICE_CATEGORY_START: 'SORT_SERVICE_CATEGORY_START',
  SORT_SERVICE_CATEGORY_SUCCESS: 'SORT_SERVICE_CATEGORY_SUCCESS',
  SORT_SERVICE_CATEGORY_FINISH: 'SORT_SERVICE_CATEGORY_FINISH',
  SORT_SERVICE_CATEGORY_RESET: 'SORT_SERVICE_CATEGORY_RESET',
}
