import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const NOTIFICATION = `${API_URL}/news`
export const PUSHNOTIFICATIONTEMPLATE = `${API_URL}/push-notification`

export const service = {
  addPushNotificationTemplate: (data: any) => {
    return axios.post(PUSHNOTIFICATIONTEMPLATE, data)
  },

  getPushNotificationTemplate: (params: ParamsModel) => {
    return axios.get(PUSHNOTIFICATIONTEMPLATE, {params})
  },

  updatePushNotificationTemplate: (body: any, id: string) => {
    return axios.patch(`${PUSHNOTIFICATIONTEMPLATE}/${id}`, body)
  },

  deletePushNotificationTemplate: (data: DeleteModel) => {
    return axios.delete(PUSHNOTIFICATIONTEMPLATE, {data})
  },

  getNews: (params: ParamsModel) => {
    return axios.get(NOTIFICATION, {params})
  },

  addNews: (data: any) => {
    return axios.post(NOTIFICATION, data)
  },

  updateNews: (body: any, id: string) => {
    return axios.patch(`${NOTIFICATION}/${id}`, body)
  },

  deleteNews: (data: DeleteModel) => {
    return axios.delete(NOTIFICATION, {data})
  },
  singleActivateNews: (id: any) => {
    return axios.patch(`${NOTIFICATION}/enable`, id)
  },

  singleDeactivateNews: (id: any) => {
    return axios.patch(`${NOTIFICATION}/disable`, id)
  },
}
