import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {LayoutModel, SortLayoutModel} from '../Model/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const LAYOUT_API = `${API_URL}/home-page-component`

export const service = {
  getLayout: (params: ParamsModel) => {
    return axios.get(LAYOUT_API, {params})
  },
  getAllLayout: () => {
    return axios.get(`${LAYOUT_API}/list`)
  },

  addLayout: (data: any) => {
    return axios.post(LAYOUT_API, data)
  },

  updateLayout: (body: LayoutModel, id: string) => {
    return axios.put(`${LAYOUT_API}/${id}`, body)
  },

  deleteLayout: (data: {id: string}) => {
    return axios.delete(`${LAYOUT_API}/bulk-delete`, {data})
  },

  enableLayout: (data: Array<string>) => {
    const selectedLayout = {
      layoutId: data,
    }
    return axios.patch(`${LAYOUT_API}/enable`, selectedLayout)
  },

  disableLayout: (data: Array<string>) => {
    const selectedLayout = {
      layoutId: data,
    }
    return axios.patch(`${LAYOUT_API}/disable`, selectedLayout)
  },

  singleEnableLayout: (data: Array<string>) => {
    const selectedLayout = {
      layoutId: [data],
    }
    return axios.patch(`${LAYOUT_API}/enable`, selectedLayout)
  },

  singleDisableLayout: (data: Array<string>) => {
    const selectedLayout = {
      layoutId: [data],
    }
    return axios.patch(`${LAYOUT_API}/disable`, selectedLayout)
  },
  sortLayout: (body: SortLayoutModel) => {
    return axios.patch(`${LAYOUT_API}/sort`, body)
  },
}
