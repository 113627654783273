export const actionTypes: any = {
  // get
  GET_ALL_MERCHANT_SUB_CATEGORY_START: 'GET_ALL_MERCHANT_SUB_CATEGORY_START',
  GET_ALL_MERCHANT_SUB_CATEGORY_SUCCESS: 'GET_ALL_MERCHANT_SUB_CATEGORY_SUCCESS',
  GET_ALL_MERCHANT_SUB_CATEGORY_FINISH: 'GET_ALL_MERCHANT_SUB_CATEGORY_FINISH',

  // extended hours
  GET_MERCHANT_SUB_CATEGORY_START: 'GET_MERCHANT_SUB_CATEGORY_START',
  GET_MERCHANT_SUB_CATEGORY_SUCCESS: 'GET_MERCHANT_SUB_CATEGORY_SUCCESS',
  GET_MERCHANT_SUB_CATEGORY_FINISH: 'GET_MERCHANT_SUB_CATEGORY_FINISH',

  // add branch
  ADD_MERCHANT_SUB_CATEGORY_START: 'ADD_MERCHANT_SUB_CATEGORY_START',
  ADD_MERCHANT_SUB_CATEGORY_SUCCESS: 'ADD_MERCHANT_SUB_CATEGORY_SUCCESS',
  ADD_MERCHANT_SUB_CATEGORY_FINISH: 'ADD_MERCHANT_SUB_CATEGORY_FINISH',
  RESET_MERCHANT_SUB_CATEGORY: 'RESET_MERCHANT_SUB_CATEGORY',

  // update MERCHANT_SUB_CATEGORY
  UPDATE_MERCHANT_SUB_CATEGORY_START: 'UPDATE_MERCHANT_SUB_CATEGORY_START',
  UPDATE_MERCHANT_SUB_CATEGORY_SUCCESS: 'UPDATE_MERCHANT_SUB_CATEGORY_SUCCESS',
  UPDATE_MERCHANT_SUB_CATEGORY_FINISH: 'UPDATE_MERCHANT_SUB_CATEGORY_FINISH',

  // delete MERCHANT_SUB_CATEGORY
  DELETE_MERCHANT_SUB_CATEGORY_START: 'DELETE_MERCHANT_SUB_CATEGORY_START',
  DELETE_MERCHANT_SUB_CATEGORY_SUCCESS: 'DELETE_MERCHANT_SUB_CATEGORY_SUCCESS',
  DELETE_MERCHANT_SUB_CATEGORY_FINISH: 'DELETE_MERCHANT_SUB_CATEGORY_FINISH',

  // Enable MERCHANT_SUB_CATEGORY
  ENABLE_MERCHANT_SUB_CATEGORY_REQUEST: 'ENABLE_MERCHANT_SUB_CATEGORY_REQUEST',
  ENABLE_MERCHANT_SUB_CATEGORY_SUCCESS: 'ENABLE_MERCHANT_SUB_CATEGORY_SUCCESS',
  ENABLE_MERCHANT_SUB_CATEGORY_FINISH: 'ENABLE_MERCHANT_SUB_CATEGORY_FINISH',

  // Disable MERCHANT_SUB_CATEGORY
  DISABLE_MERCHANT_SUB_CATEGORY_REQUEST: 'DISABLE_MERCHANT_SUB_CATEGORY_REQUEST',
  DISABLE_MERCHANT_SUB_CATEGORY_SUCCESS: 'DISABLE_MERCHANT_SUB_CATEGORY_SUCCESS',
  DISABLE_MERCHANT_SUB_CATEGORY_FINISH: 'DISABLE_MERCHANT_SUB_CATEGORY_FINISH',

  // Enable MERCHANT_SUB_CATEGORY
  SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_REQUEST: 'SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_REQUEST',
  SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_SUCCESS: 'SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_SUCCESS',
  SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_FINISH: 'SINGLE_ENABLE_MERCHANT_SUB_CATEGORY_FINISH',

  // Disable MERCHANT_SUB_CATEGORY
  SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_REQUEST: 'SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_REQUEST',
  SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_SUCCESS: 'SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_SUCCESS',
  SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_FINISH: 'SINGLE_DISABLE_MERCHANT_SUB_CATEGORY_FINISH',

  // sort
  SORT_MERCHANT_SUB_CATEGORY_START: 'SORT_MERCHANT_SUB_CATEGORY_START',
  SORT_MERCHANT_SUB_CATEGORY_SUCCESS: 'SORT_MERCHANT_SUB_CATEGORY_SUCCESS',
  SORT_MERCHANT_SUB_CATEGORY_FINISH: 'SORT_MERCHANT_SUB_CATEGORY_FINISH',
  SORT_MERCHANT_SUB_CATEGORY_RESET: 'SORT_MERCHANT_SUB_CATEGORY_RESET',
}
