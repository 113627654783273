import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {NotificationModel} from '../Model'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getNotificationSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getNotification, params)
    yield put(actions.getNotificationSuccess(response?.data?.data))
    yield put(actions.getNotificationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getNotificationFinish())
  }
}

function* addNotificationSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addNotification, body)
    yield put(actions.addNotificationSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addNotificationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addNotificationFinish())
  }
}

function* updateNotificationSaga(action: ActionModel) {
  try {
    const body: NotificationModel = action.payload
    const response: ResponseModel = yield call(service.updateNotification, body, action.payload?.id)
    yield put(actions.updateNotificationSuccess(response.data?.data))
    yield put(actions.updateNotificationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateNotificationFinish())
  }
}

function* deleteNotificationSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteNotification, body)
    yield put(actions.deleteNotificationSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteNotificationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteNotificationFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_NOTIFICATION_START, getNotificationSaga)
  yield takeLatest(actionTypes.ADD_NOTIFICATION_START, addNotificationSaga)
  yield takeLatest(actionTypes.UPDATE_NOTIFICATION_START, updateNotificationSaga)
  yield takeLatest(actionTypes.DELETE_NOTIFICATION_START, deleteNotificationSaga)
}
