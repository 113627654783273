import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'

type Props = {
  data: {
    id: string
    full_name: string
    email: string
    mobile_number: string
    message: string
    address: string
    district: string
    country: string
    agentType: string
    remittance: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Agent Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Full Name</b>
              </Col>
              <Col md={6}>
                <p>{data.full_name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Agent Type</b>
              </Col>
              <Col md={6}>
                <p>{data.agentType}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email</b>
              </Col>
              <Col md={6}>
                <p>{data.email}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Mobile Number</b>
              </Col>
              <Col md={6}>
                <p>{data.mobile_number}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Message</b>
              </Col>
              <Col md={6}>
                <p>{data.message}</p>
              </Col>
            </Row>
            {data.district && (
              <Row>
                <Col md={6}>
                  <b>District</b>
                </Col>
                <Col md={6}>
                  <p>{data.district}</p>
                </Col>
              </Row>
            )}
            {data.country && (
              <Row>
                <Col md={6}>
                  <b>Country</b>
                </Col>
                <Col md={6}>
                  <p>{data.country}</p>
                </Col>
              </Row>
            )}
            {data.address && (
              <Row>
                <Col md={6}>
                  <b>Address</b>
                </Col>
                <Col md={6}>
                  <p>{data.address}</p>
                </Col>
              </Row>
            )}
            {data.remittance && (
              <Row>
                <Col md={6}>
                  <b>Remittance</b>
                </Col>
                <Col md={6}>
                  <p>{data.remittance}</p>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
