export const actionTypes: any = {
  GET_LOAN_SUB_CATEGORY_START: 'GET_LOAN_SUB_CATEGORY_START',
  GET_LOAN_SUB_CATEGORY_SUCCESS: 'GET_LOAN_SUB_CATEGORY_SUCCESS',
  GET_LOAN_SUB_CATEGORY_FINISH: 'GET_LOAN_SUB_CATEGORY_FINISH',

  ADD_LOAN_SUB_CATEGORY_START: 'ADD_LOAN_SUB_CATEGORY_START',
  ADD_LOAN_SUB_CATEGORY_SUCCESS: 'ADD_LOAN_SUB_CATEGORY_SUCCESS',
  ADD_LOAN_SUB_CATEGORY_FINISH: 'ADD_LOAN_SUB_CATEGORY_FINISH',
  ADD_LOAN_SUB_CATEGORY_RESET: 'ADD_LOAN_SUB_CATEGORY_RESET',

  UPDATE_LOAN_SUB_CATEGORY_START: 'UPDATE_LOAN_SUB_CATEGORY_START',
  UPDATE_LOAN_SUB_CATEGORY_SUCCESS: 'UPDATE_LOAN_SUB_CATEGORY_SUCCESS',
  UPDATE_LOAN_SUB_CATEGORY_FINISH: 'UPDATE_LOAN_SUB_CATEGORY_FINISH',
  UPDATE_LOAN_SUB_CATEGORY_RESET: 'UPDATE_LOAN_SUB_CATEGORY_RESET',

  DELETE_LOAN_SUB_CATEGORY_START: 'DELETE_LOAN_SUB_CATEGORY_START',
  DELETE_LOAN_SUB_CATEGORY_SUCCESS: 'DELETE_LOAN_SUB_CATEGORY_SUCCESS',
  DELETE_LOAN_SUB_CATEGORY_FINISH: 'DELETE_LOAN_SUB_CATEGORY_FINISH',

  SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_FINISH: 'SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_FINISH',

  SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_FINISH',

  SORT_LOAN_SUB_CATEGORY_START: 'SORT_LOAN_SUB_CATEGORY_START',
  SORT_LOAN_SUB_CATEGORY_SUCCESS: 'SORT_LOAN_SUB_CATEGORY_SUCCESS',
  SORT_LOAN_SUB_CATEGORY_FINISH: 'SORT_LOAN_SUB_CATEGORY_FINISH',
  SORT_LOAN_SUB_CATEGORY_RESET: 'SORT_LOAN_SUB_CATEGORY_RESET',
}
