export const actionTypes: any = {
  // get sub category
  GET_INTERNSHIP_APPLICATIONS_START: 'GET_INTERNSHIP_APPLICATIONS_START',
  GET_INTERNSHIP_APPLICATIONS_SUCCESS: 'GET_INTERNSHIP_APPLICATIONS_SUCCESS',
  GET_INTERNSHIP_APPLICATIONS_FINISH: 'GET_INTERNSHIP_APPLICATIONS_FINISH',

  // get File csv xlsx
  GET_INTERNSHIP_APPLICATIONS_FILE_START: 'GET_INTERNSHIP_APPLICATIONS_FILE_START',
  GET_INTERNSHIP_APPLICATIONS_FILE_SUCCESS: 'GET_INTERNSHIP_APPLICATIONS_FILE_SUCCESS',
  GET_INTERNSHIP_APPLICATIONS_FILE_FINISH: 'GET_INTERNSHIP_APPLICATIONS_FILE_FINISH',

  // ADD sub category
  ADD_INTERNSHIP_APPLICATIONS_START: 'ADD_INTERNSHIP_APPLICATIONS_START',
  ADD_INTERNSHIP_APPLICATIONS_SUCCESS: 'ADD_INTERNSHIP_APPLICATIONS_SUCCESS',
  ADD_INTERNSHIP_APPLICATIONS_FINISH: 'ADD_INTERNSHIP_APPLICATIONS_FINISH',
  ADD_INTERNSHIP_APPLICATIONS_RESET: 'ADD_INTERNSHIP_APPLICATIONS_RESET',

  // Update sub category
  UPDATE_INTERNSHIP_APPLICATIONS_START: 'UPDATE_INTERNSHIP_APPLICATIONS_START',
  UPDATE_INTERNSHIP_APPLICATIONS_SUCCESS: 'UPDATE_INTERNSHIP_APPLICATIONS_SUCCESS',
  UPDATE_INTERNSHIP_APPLICATIONS_FINISH: 'UPDATE_INTERNSHIP_APPLICATIONS_FINISH',
  UPDATE_INTERNSHIP_APPLICATIONS_RESET: 'UPDATE_INTERNSHIP_APPLICATIONS_RESET',

  // delete sub category
  DELETE_INTERNSHIP_APPLICATIONS_START: 'DELETE_INTERNSHIP_APPLICATIONS_START',
  DELETE_INTERNSHIP_APPLICATIONS_SUCCESS: 'DELETE_INTERNSHIP_APPLICATIONS_SUCCESS',
  DELETE_INTERNSHIP_APPLICATIONS_FINISH: 'DELETE_INTERNSHIP_APPLICATIONS_FINISH',

  // Enable INTERNSHIP_APPLICATIONS
  ENABLE_INTERNSHIP_APPLICATIONS_REQUEST: 'ENABLE_INTERNSHIP_APPLICATIONS_REQUEST',
  ENABLE_INTERNSHIP_APPLICATIONS_SUCCESS: 'ENABLE_INTERNSHIP_APPLICATIONS_SUCCESS',
  ENABLE_INTERNSHIP_APPLICATIONS_FINISH: 'ENABLE_INTERNSHIP_APPLICATIONS_FINISH',

  // Disable INTERNSHIP_APPLICATIONS
  DISABLE_INTERNSHIP_APPLICATIONS_REQUEST: 'DISABLE_INTERNSHIP_APPLICATIONS_REQUEST',
  DISABLE_INTERNSHIP_APPLICATIONS_SUCCESS: 'DISABLE_INTERNSHIP_APPLICATIONS_SUCCESS',
  DISABLE_INTERNSHIP_APPLICATIONS_FINISH: 'DISABLE_INTERNSHIP_APPLICATIONS_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_REQUEST:
    'SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_REQUEST',
  SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_SUCCESS:
    'SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_SUCCESS',
  SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_FINISH: 'SINGLE_ACTIVATE_INTERNSHIP_APPLICATIONS_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_REQUEST:
    'SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_REQUEST',
  SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_SUCCESS:
    'SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_SUCCESS',
  SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_FINISH:
    'SINGLE_DEACTIVATE_INTERNSHIP_APPLICATIONS_FINISH',
}
