import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ApiManagerDetailModel} from '../Model'

function* getApiManagerDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getApiManagerDetail, params)
    yield put(actions.getApiManagerDetailSuccess(response?.data?.data))
    yield put(actions.getApiManagerDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getApiManagerDetailFinish())
  }
}

function* addApiManagerDetailSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addApiManagerDetail, body)
    yield put(actions.addApiManagerDetailSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addApiManagerDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addApiManagerDetailFinish())
  }
}

// function* enableCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const selectedUsers: any = action.payload?.data
//     const response: ResponseModel = yield call(service.enableCardMenuDetail, selectedUsers)
//     yield put(actions.enableCardMenuDetailSuccess(response?.data))
//     yield put(actions.enableCardMenuDetailFinish())
//   } catch (err: any) {
//     if (err.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
//     }
//     yield put(actions.enableCardMenuDetailFinish())
//   }
// }

// function* disableCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const selectedUsers: any = action.payload?.data
//     const response: ResponseModel = yield call(service.disableCardMenuDetail, selectedUsers)
//     yield put(actions.disableCardMenuDetailSuccess(response?.data))
//     yield put(actions.disableCardMenuDetailFinish())
//   } catch (err: any) {
//     if (err.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
//     }
//     yield put(actions.disableCardMenuDetailFinish())
//   }
// }

// function* singleEnableCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const selectedUsers: any = action.payload?.data
//     const response: ResponseModel = yield call(service.singleEnableCardMenuDetail, selectedUsers)
//     yield put(actions.singleEnableCardMenuDetailSuccess(response?.data))
//     yield put(actions.singleEnableCardMenuDetailFinish())
//   } catch (err: any) {
//     if (err.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
//     }
//     yield put(actions.singleEnableCardMenuDetailFinish())
//   }
// }

// function* singleDisableCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const selectedUsers: any = action.payload?.data
//     const response: ResponseModel = yield call(service.singleDisableCardMenuDetail, selectedUsers)
//     yield put(actions.singleDisableCardMenuDetailSuccess(response?.data))
//     yield put(actions.singleDisableCardMenuDetailFinish())
//   } catch (err: any) {
//     if (err.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
//     }
//     yield put(actions.singleDisableCardMenuDetailFinish())
//   }
// }

function* updateApiManagerDetailSaga(action: ActionModel) {
  try {
    const body: ApiManagerDetailModel = action.payload
    const response: ResponseModel = yield call(
      service.updateApiManagerDetail,
      body,
      action.payload?.id
    )
    yield put(actions.updateApiManagerDetailSuccess(response.data?.data))
    yield put(actions.updateApiManagerDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateApiManagerDetailFinish())
  }
}

// function* deleteCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const body: any = action.payload
//     const response: ResponseModel = yield call(service.deleteCardMenuDetail, body)

//     yield put(actions.deleteCardMenuDetailSuccess(response.data?.data))
//     yield put(actions.deleteCardMenuDetailFinish())
//   } catch (err: any) {
//     if (err.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
//     }
//     yield put(actions.deleteCardMenuDetailFinish())
//   }
// }

// //Get CardMenuDetail saga
// function* getAllCardMenuDetailSaga(action: ActionModel) {
//   try {
//     const response: ResponseModel = yield call(service.getAllCardMenuDetail)
//     const data: any = response?.data
//     yield put(actions.getAllCardMenuDetailSuccess(data))
//   } catch (error: any) {
//     if (error.response) {
//       yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
//     }
//     yield put(actions.getAllCardMenuDetailError())
//   }
// }

export function* saga() {
  yield takeLatest(actionTypes.GET_API_MANAGER_START, getApiManagerDetailSaga)

  /*   yield takeLatest(actionTypes.GET_CARD_MENU_START, getAllCardMenuDetailSaga) */
  yield takeLatest(actionTypes.ADD_API_MANAGER_START, addApiManagerDetailSaga)
  yield takeLatest(actionTypes.UPDATE_API_MANAGER_START, updateApiManagerDetailSaga)
  // yield takeLatest(actionTypes.DELETE_API_MANAGER_START, deleteCardMenuDetailSaga)
  // yield takeLatest(actionTypes.ENABLE_API_MANAGER_REQUEST, enableCardMenuDetailSaga)
  // yield takeLatest(actionTypes.DISABLE_API_MANAGER_REQUEST, disableCardMenuDetailSaga)
  // yield takeLatest(actionTypes.SINGLE_ENABLE_API_MANAGER_REQUEST, singleEnableCardMenuDetailSaga)
  // yield takeLatest(actionTypes.SINGLE_DISABLE_API_MANAGER_REQUEST, singleDisableCardMenuDetailSaga)
  // yield takeLatest(actionTypes.GET_ALL_API_MANAGER_START, getAllCardMenuDetailSaga)
}
