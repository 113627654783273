import {Action} from 'redux'
import {LayoutModel} from '../Model/Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialLayoutState: ILayoutState = {
  data: {
    layout: [],
    meta: [],
  },
  sortLayoutData: [],
  layoutList: {layout: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface ILayoutState {
  data?: {
    layout?: LayoutModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortLayoutData?: ILayoutState[]
  layoutList?: {layout: LayoutModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ILayoutState = initialLayoutState,
  action: ActionWithPayload<ILayoutState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_LAYOUT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        layoutList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_LAYOUT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET Layout DATA
    case actionTypes.GET_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_LAYOUT_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_LAYOUT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Layout
    case actionTypes.ADD_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_LAYOUT_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_LAYOUT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_LAYOUT: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Layout
    case actionTypes.UPDATE_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_LAYOUT_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_LAYOUT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_LAYOUT_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_LAYOUT_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_LAYOUT_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_LAYOUT_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_LAYOUT_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_LAYOUT_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_LAYOUT_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_LAYOUT_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_LAYOUT_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_LAYOUT_SUCCESS: {
      const changedData: any = action.payload?.data?.layout
      let newData = state?.data?.layout?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, layout: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_LAYOUT_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_LAYOUT_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_LAYOUT_SUCCESS: {
      const changedData: any = action.payload?.data?.layout
      let newData = state?.data?.layout?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, layout: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_LAYOUT_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_LAYOUT_SUCCESS: {
      return {
        ...state,
        sortLayoutData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_LAYOUT_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_LAYOUT_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortLayoutData: [],
      }
    }

    default:
      return state
  }
}
