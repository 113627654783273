import React, {createContext, ReactNode, useEffect, useState} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {ChildrenComponentProps} from 'src/types/globalTypes'

export const ActiveMenuContex = createContext({})

const MasterLayout: React.FC<ChildrenComponentProps> = ({children}) => {
  const location = useLocation()
  const [currentActiveMenu, setCurrentActiveMenu] = useState('')

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ActiveMenuContex.Provider value={{currentActiveMenu, setCurrentActiveMenu}}>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <ScrollTop />
      </ActiveMenuContex.Provider>
    </PageDataProvider>
  )
}

export {MasterLayout}
