export const actionTypes: any = {
  GET_SPECIFIC_CARD_MANAGER_START: 'GET_SPECIFIC_CARD_MANAGER_START',
  GET_SPECIFIC_CARD_MANAGER_SUCCESS: 'GET_SPECIFIC_CARD_MANAGER_SUCCESS',
  GET_SPECIFIC_CARD_MANAGER_FINISH: 'GET_SPECIFIC_CARD_MANAGER_FINISH',

  GET_CARD_MANAGER_START: 'GET_CARD_MANAGER_START',
  GET_CARD_MANAGER_SUCCESS: 'GET_CARD_MANAGER_SUCCESS',
  GET_CARD_MANAGER_FINISH: 'GET_CARD_MANAGER_FINISH',

  ADD_CARD_MANAGER_START: 'ADD_CARD_MANAGER_START',
  ADD_CARD_MANAGER_SUCCESS: 'ADD_CARD_MANAGER_SUCCESS',
  ADD_CARD_MANAGER_FINISH: 'ADD_CARD_MANAGER_FINISH',
  ADD_CARD_MANAGER_RESET: 'ADD_CARD_MANAGER_RESET',

  UPDATE_CARD_MANAGER_START: 'UPDATE_CARD_MANAGER_START',
  UPDATE_CARD_MANAGER_SUCCESS: 'UPDATE_CARD_MANAGER_SUCCESS',
  UPDATE_CARD_MANAGER_FINISH: 'UPDATE_CARD_MANAGER_FINISH',
  UPDATE_CARD_MANAGER_RESET: 'UPDATE_CARD_MANAGER_RESET',

  DELETE_CARD_MANAGER_START: 'DELETE_CARD_MANAGER_START',
  DELETE_CARD_MANAGER_SUCCESS: 'DELETE_CARD_MANAGER_SUCCESS',
  DELETE_CARD_MANAGER_FINISH: 'DELETE_CARD_MANAGER_FINISH',

  SINGLE_ACTIVATE_CARD_MANAGER_REQUEST: 'SINGLE_ACTIVATE_CARD_MANAGER_REQUEST',
  SINGLE_ACTIVATE_CARD_MANAGER_SUCCESS: 'SINGLE_ACTIVATE_CARD_MANAGER_SUCCESS',
  SINGLE_ACTIVATE_CARD_MANAGER_FINISH: 'SINGLE_ACTIVATE_CARD_MANAGER_FINISH',

  SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST: 'SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST',
  SINGLE_DEACTIVATE_CARD_MANAGER_SUCCESS: 'SINGLE_DEACTIVATE_CARD_MANAGER_SUCCESS',
  SINGLE_DEACTIVATE_CARD_MANAGER_FINISH: 'SINGLE_DEACTIVATE_CARD_MANAGER_FINISH',

  SORT_CARD_MANAGER_START: 'SORT_CARD_MANAGER_START',
  SORT_CARD_MANAGER_SUCCESS: 'SORT_CARD_MANAGER_SUCCESS',
  SORT_CARD_MANAGER_FINISH: 'SORT_CARD_MANAGER_FINISH',
  SORT_CARD_MANAGER_RESET: 'SORT_CARD_MANAGER_RESET',
}
