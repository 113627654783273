import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getEnquirySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getEnquiry, params)
    yield put(actions.getEnquirySuccess(response?.data?.data))
    yield put(actions.getEnquiryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getEnquiryFinish())
  }
}

function* deleteEnquirySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteEnquiry, body)
    yield put(actions.deleteEnquirySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteEnquiryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteEnquiryFinish())
  }
}

//Get branchless file
function* exportEnquiryFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(service.exportEnquiryFile, fileName, params)
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.DELETE_ENQUIRY_START, deleteEnquirySaga)
  yield takeLatest(actionTypes.GET_ENQUIRY_START, getEnquirySaga)
  yield takeLatest(actionTypes.EXPORT_ENQUIRY_FILE_START, exportEnquiryFileSaga)
}
