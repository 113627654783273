import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
// branch
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as pushNotificationTemplate from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

let FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('title is required'),
  body: Yup.string().required('template body is required'),
})

const AddPushNotificationTemplate = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()

  const [imageEng, setImageEng] = useState('')
  const [messageImage, setMessageImage] = useState('')

  const {loading, success} = useSelector((state: any) => state.pushNotificationTemplate)

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Push Notification Template added successfully')
        : toast.success('Push Notification Template edited successfully')
      dispatch(pushNotificationTemplate?.actions.getPushNotificationTemplate(params))
      dispatch(pushNotificationTemplate?.actions?.addPushNotificationTemplateReset())
      handleClose()
    }
  }, [success])

  const hideImageField = checkBackendSetting('hideImageField')
  return (
    <div className='modal-container' data-cy='add-news-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Push Notification Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                body: '',
                image: '',
                message_data: {
                  type: '',
                  redirect_to: '',
                  image: '',
                },
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    pushNotificationTemplate.actions.updatePushNotificationTemplate(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(pushNotificationTemplate.actions.addPushNotificationTemplate(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['title', 'body', 'image', 'message_data']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='add-news-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Notification title'
                            label='Notification title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-news-title-text-field'
                          />
                        </div>
                        {!hideImageField && (
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Image'
                              name='image'
                              setFieldValue={setFieldValue}
                              setImageUrl={setImageEng}
                              value={values?.image}
                              data-cy='add-news-image-field'
                            />

                            {!isEmpty(values?.image) && (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setImageEng('')
                                        setFieldValue('image', '')
                                      }}
                                      data-cy='thumbnail-remove-button'
                                    >
                                      Delete
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={`${imageBaseUrl}/${values?.image}`}
                                      alt=''
                                      data-cy='news-image-preview'
                                    />
                                  </div>
                                </li>
                              </>
                            )}
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Notification Body'
                            label='Notification body'
                            name='body'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-news-title-text-field'
                            as='textarea'
                            rows={4}
                          />
                        </div>
                        {/* <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Body of template'
                            name='body'
                            initialValue={!isEmpty(editSelectedData) ? editSelectedData?.body : ''}
                            handleChange={handleChange}
                            data-cy='add-news-description-text-area'
                          />
                        </div> */}
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Notification title'
                            label='Message Type'
                            name='message_data.type'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={false}
                            data-cy='add-news-title-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Redirect link'
                            label='Redirect Link'
                            name='message_data.redirect_to'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={false}
                            data-cy='add-news-title-text-field'
                          />
                        </div>

                        {!hideImageField && (
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Message Image'
                              name='message_data.image'
                              setFieldValue={setFieldValue}
                              setImageUrl={setMessageImage}
                              value={values?.message_data?.image}
                              data-cy='add-push-notification-template-image-field'
                            />

                            {!isEmpty(values?.message_data?.image) && (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setMessageImage('')
                                        setFieldValue('message_data.image', '')
                                      }}
                                      data-cy='thumbnail-remove-button'
                                    >
                                      Delete
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={`${imageBaseUrl}/${values?.message_data?.image}`}
                                      alt=''
                                      data-cy='push-notification-template-image-preview'
                                    />
                                  </div>
                                </li>
                              </>
                            )}
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddPushNotificationTemplate
