import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as Yup from 'yup'
import * as dynamicCompRedux from '../index'
// import {actions as dynamicCompRedux} from '../redux/action'
import * as cms from 'src/app/modules/cms'

import {IMenuState} from 'src/app/modules/cms/components/menu'
import {MenuOptionModal} from 'src/app/modules/cms/components/menu/Model'
import axios from 'axios'
import Select from 'react-select'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

interface IServicePartnerState {
  data?: {
    servicePartner?: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  countryList?: {servicePartnerCountryList?: any[]}
  currencyList?: any[]
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  importLoading?: boolean
  importSuccess?: boolean
  countryLoading?: boolean
  countrySuccess?: boolean
  currencyLoading?: boolean
  currencySuccess?: boolean
  loading?: boolean
  success?: boolean
}

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const AddDynamicComponent = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [dynamicRes, setDynamicRes] = useState<any>({})
  const [componentOptions, setComponentOptions] = useState([])
  const [layoutOptions, setlayoutOptions] = useState([])
  const [updatedSelectedEdit, setUpdatedSelectedEdit] = useState<any>({})

  const [selectedComponent, setSelectedComponent] = useState<string>('')
  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)
  const {loading, success} = useSelector((state: any) => state.dynamicComponent)

  const getLayoutList = async () => {
    let res = await axios.get(`${API_URL}/home-page-component`)
    setDynamicRes(res?.data?.data)
  }

  useEffect(() => {
    dispatch(cms.menu.actions.getTopMenu())
    getLayoutList()
  }, [])

  useEffect(() => {
    if (dynamicRes?.components) {
      let compOptions = dynamicRes?.components?.map((items) => ({
        label: items.title,
        value: items.id,
        systemName: items.title,
      }))
      setComponentOptions(compOptions)
    }

    // working in this
    if (!isEmpty(editSelectedData) && dynamicRes?.components?.length > 0) {
      let udpatedComponent = editSelectedData.components.map((item) => {
        let updatedFilterOptions = filteredLayoutOptions(item.component_id)
        let filteredPreviewImg = updatedFilterOptions.filter(
          (option) => option.value === item.layout_id
        )[0]?.image

        return {
          id: item?.id,
          component_id: item?.component_id,
          layout_id: item.layout_id,
          layoutOptions: updatedFilterOptions,
          prevewImg: filteredPreviewImg,
        }
      })
      editSelectedData = {...editSelectedData, components: udpatedComponent}
      setUpdatedSelectedEdit(editSelectedData)
    }
  }, [dynamicRes])

  const filteredLayoutOptions = (selectedCompValue) => {
    let layout = dynamicRes?.components
      ?.filter((item) => item?.id === selectedCompValue)[0]
      ?.layouts?.layouts?.map((items) => ({
        label: items.title,
        value: items.id,
        systemName: items.title,
        image: items.image,
      }))

    return layout
  }

  useEffect(() => {
    if (selectedComponent) {
      let layout = dynamicRes?.components
        ?.filter((item) => item?.id === selectedComponent)[0]
        ?.layouts?.layouts?.map((items) => ({
          label: items.title,
          value: items.id,
          systemName: items.title,
          image: items.image,
        }))

      setlayoutOptions(layout)
    }
  }, [selectedComponent])

  const topMenuOptions = topMenu?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  // useEffect(() => {
  //   if (!isEmpty(editSelectedData) && dynamicRes?.components?.length > 0) {
  //     let udpatedComponent = editSelectedData.components.map((item) => {
  //       console.info('item value here=============', item)
  //       let updatedFilterOptions = filteredLayoutOptions(item.component_id)
  //       return {
  //         id: item?.id,
  //         component_id: item?.component_id,
  //         layout_id: item.layout_id,
  //         layoutOptions: updatedFilterOptions,
  //       }
  //     })
  //     console.info('udpated component here-----------------', udpatedComponent)
  //     editSelectedData = {...editSelectedData, components: udpatedComponent}
  //     console.info('udpated eid seleccted data here-----------------', editSelectedData)
  //   }
  // }, [editSelectedData])

  useEffect(() => {
    if (success) {
      dispatch(dynamicCompRedux?.actions.getDynamicComponent(params))
      isEmpty(editSelectedData)
        ? toast.success('Dynamic Component added successfully')
        : toast.success('Dynamic Component edited successfully')
      dispatch(dynamicCompRedux?.actions?.resetDynamicComponent())
      handleClose()
    }
  }, [success])

  const FORM_VALIDATION = Yup.object().shape({
    menu_id: Yup.string().required('Required field'),
    components: Yup.array()
      .of(
        Yup.object().shape({
          component_id: Yup.string().required('Required field'),
          layout_id: Yup.string().required('Required field'),
        })
      )
      .min(1, 'At least one property is required'),
  })

  const CustomFormSelect = ({name, options, value, onChange, disabled = false}) => (
    <Select
      name={name}
      options={options}
      isDisabled={disabled}
      value={options?.find((option) => option?.value === value)}
      onChange={onChange}
      isClearable
    />
  )

  return (
    <div className='modal-container' data-cy='search-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Dynamic Components</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                menu_id: '',
                components: [
                  {
                    component_id: '',
                    layout_id: '',
                    layoutOptions: [],
                    prevewImg: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                let filteredComponent = values?.components.map(
                  ({layoutOptions, prevewImg, ...rest}) => rest
                )
                values = {...values, components: filteredComponent}

                const formData = {
                  ...values,
                  components: values?.components?.map((item: any) => ({
                    ...item,
                    ...(item?.id && {id: !isEmpty(editSelectedData) ? item.id : null}),
                    id: !isEmpty(editSelectedData) ? item?.id : null,
                    // show_in_listing: item?.show_in_listing === 'true' ? true : false,
                  })),
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    dynamicCompRedux.actions.updateDynamicComponent(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(dynamicCompRedux.actions.addDynamicComponent(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(updatedSelectedEdit)) {
                    const fields = ['id', 'menu_id', 'components']
                    fields.forEach((field) =>
                      setFieldValue(field, updatedSelectedEdit[field], false)
                    )
                    setFieldValue('menu_id', updatedSelectedEdit?.menu_id, false)
                  }
                }, [updatedSelectedEdit])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='search-category-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Top Menu'
                            name='menu_id'
                            // disabled={
                            //   !isEmpty(editSelectedData) || values?.province_id ? false : true
                            // }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={topMenuOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='components'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Dynamic Components</h5>
                              {values?.components && values?.components.length > 0
                                ? values?.components?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <label
                                          className={`mb-1 fw-bolder fs-6 ${
                                            'Select component' || 'col-lg-4'
                                          }`}
                                        >
                                          <span className='required'>Select Component</span>
                                        </label>
                                        <CustomFormSelect
                                          name={`components[${index}].componentId`}
                                          options={componentOptions}
                                          value={values?.components[index]?.component_id}
                                          onChange={(option) => {
                                            // Update the selected componentId
                                            setFieldValue(
                                              `components[${index}].component_id`,
                                              option.value
                                            )
                                            // Clear the layout_id when component changes
                                            setFieldValue(`components[${index}].layout_id`, '')
                                            // Update layout options based on selected component

                                            const updatedFilterOptions = filteredLayoutOptions(
                                              option.value
                                            )
                                            setFieldValue(
                                              `components[${index}].layoutOptions`,
                                              updatedFilterOptions || []
                                            )
                                          }}
                                        />
                                      </div>
                                      <>
                                        {!isEmpty(values?.components[index]?.component_id)
                                          ? setSelectedComponent(
                                              values?.components[index].component_id
                                            )
                                          : ''}
                                      </>
                                      <div className='col-md-6 col-xs-12 '>
                                        <label
                                          className={`mb-1 fw-bolder fs-6 ${
                                            'Select Layout' || 'col-lg-4'
                                          }`}
                                        >
                                          <span className='required'>Select Layout</span>
                                        </label>
                                        <CustomFormSelect
                                          name={`components[${index}].layout_id`}
                                          options={values?.components[index].layoutOptions} // Use individual layoutOptions for this component
                                          value={values?.components[index]?.layout_id}
                                          disabled={
                                            values?.components[index]?.component_id ? false : true
                                          }
                                          onChange={(option) => {
                                            setFieldValue(
                                              `components[${index}].layout_id`,
                                              option.value
                                            )
                                            setFieldValue(
                                              `components[${index}].prevewImg`,
                                              option.image || []
                                            )
                                          }}
                                        />
                                      </div>
                                      {values?.components[index]?.prevewImg && (
                                        <div className='col-md-6 col-xs-12 mt-6'>
                                          <label
                                            className={`mb-1 fw-bolder fs-6 ${
                                              'Layout Preview' || 'col-lg-4'
                                            }`}
                                          >
                                            <span className='required'>Layout Preview</span>
                                          </label>
                                          <img
                                            className='thumbImage w-100 h-100'
                                            src={`${imageBaseUrl}/${values?.components[index]?.prevewImg}`}
                                            alt='image'
                                          />
                                        </div>
                                      )}
                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}

                              <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                <button
                                  className='p-2 ps-5 pe-5 btn btn-primary'
                                  type='button'
                                  onClick={() =>
                                    arrayHelpers.push({
                                      component_id: '',
                                      layout_id: '',
                                      layoutOptions: [],
                                    })
                                  }
                                  data-cy='modal-field-add'
                                >
                                  + Add More
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddDynamicComponent
