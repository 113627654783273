export const actionTypes: any = {
  // get sub category
  GET_GENERAL_APPLICATIONS_LIST_START: 'GET_GENERAL_APPLICATIONS_LIST_START',
  GET_GENERAL_APPLICATIONS_LIST_SUCCESS: 'GET_GENERAL_APPLICATIONS_LIST_SUCCESS',
  GET_GENERAL_APPLICATIONS_LIST_FINISH: 'GET_GENERAL_APPLICATIONS_LIST_FINISH',

  // get File csv xlsx
  GET_GENERAL_APPLICATIONS_LIST_FILE_START: 'GET_GENERAL_APPLICATIONS_LIST_FILE_START',
  GET_GENERAL_APPLICATIONS_LIST_FILE_SUCCESS: 'GET_GENERAL_APPLICATIONS_LIST_FILE_SUCCESS',
  GET_GENERAL_APPLICATIONS_LIST_FILE_FINISH: 'GET_GENERAL_APPLICATIONS_LIST_FILE_FINISH',

  // ADD sub category
  ADD_GENERAL_APPLICATIONS_LIST_START: 'ADD_GENERAL_APPLICATIONS_LIST_START',
  ADD_GENERAL_APPLICATIONS_LIST_SUCCESS: 'ADD_GENERAL_APPLICATIONS_LIST_SUCCESS',
  ADD_GENERAL_APPLICATIONS_LIST_FINISH: 'ADD_GENERAL_APPLICATIONS_LIST_FINISH',
  ADD_GENERAL_APPLICATIONS_LIST_RESET: 'ADD_GENERAL_APPLICATIONS_LIST_RESET',

  // Update sub category
  UPDATE_GENERAL_APPLICATIONS_LIST_START: 'UPDATE_GENERAL_APPLICATIONS_LIST_START',
  UPDATE_GENERAL_APPLICATIONS_LIST_SUCCESS: 'UPDATE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  UPDATE_GENERAL_APPLICATIONS_LIST_FINISH: 'UPDATE_GENERAL_APPLICATIONS_LIST_FINISH',
  UPDATE_GENERAL_APPLICATIONS_LIST_RESET: 'UPDATE_GENERAL_APPLICATIONS_LIST_RESET',

  // delete sub category
  DELETE_GENERAL_APPLICATIONS_LIST_START: 'DELETE_GENERAL_APPLICATIONS_LIST_START',
  DELETE_GENERAL_APPLICATIONS_LIST_SUCCESS: 'DELETE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  DELETE_GENERAL_APPLICATIONS_LIST_FINISH: 'DELETE_GENERAL_APPLICATIONS_LIST_FINISH',

  // Enable GENERAL_APPLICATIONS
  ENABLE_GENERAL_APPLICATIONS_LIST_REQUEST: 'ENABLE_GENERAL_APPLICATIONS_LIST_REQUEST',
  ENABLE_GENERAL_APPLICATIONS_LIST_SUCCESS: 'ENABLE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  ENABLE_GENERAL_APPLICATIONS_LIST_FINISH: 'ENABLE_GENERAL_APPLICATIONS_LIST_FINISH',

  // Disable GENERAL_APPLICATIONS
  DISABLE_GENERAL_APPLICATIONS_LIST_REQUEST: 'DISABLE_GENERAL_APPLICATIONS_LIST_REQUEST',
  DISABLE_GENERAL_APPLICATIONS_LIST_SUCCESS: 'DISABLE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  DISABLE_GENERAL_APPLICATIONS_LIST_FINISH: 'DISABLE_GENERAL_APPLICATIONS_LIST_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_REQUEST:
    'SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_REQUEST',
  SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_SUCCESS:
    'SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_FINISH:
    'SINGLE_ACTIVATE_GENERAL_APPLICATIONS_LIST_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_REQUEST:
    'SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_REQUEST',
  SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_SUCCESS:
    'SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_SUCCESS',
  SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_FINISH:
    'SINGLE_DEACTIVATE_GENERAL_APPLICATIONS_LIST_FINISH',
}
