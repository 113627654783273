import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialApplyLoanState: IApplyLoanState = {
  data: {
    goodPaymentVerification: [],
    meta: {},
  },
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IApplyLoanState {
  data?: {
    goodPaymentVerification?: {[key: string]: number | string}[]
    meta?: {[key: string]: number}
  }
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IApplyLoanState = initialApplyLoanState,
  action: ActionWithPayload<IApplyLoanState>
) => {
  switch (action.type) {
    case actionTypes.GET_GOOD_PAYMENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_GOOD_PAYMENT_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_GOOD_PAYMENT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_APPLY_LOAN: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.DELETE_GOOD_PAYMENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_GOOD_PAYMENT_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_GOOD_PAYMENT_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    default:
      return state
  }
}
