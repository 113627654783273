import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAllBenefitMasterSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllBenefitMaster, params)
    yield put(actions.getAllBenefitMasterSuccess(response?.data?.data))
    yield put(actions.getAllBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllBenefitMasterFinish())
  }
}

function* getSpecificBenefitMasterSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificBenefitMaster, params)
    yield put(actions.getSpecificBenefitMasterSuccess(response?.data?.data))
    yield put(actions.getSpecificBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificBenefitMasterFinish())
  }
}

function* getBenefitMasterSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBenefitMaster, params)
    yield put(actions.getBenefitMasterSuccess(response?.data?.data))
    yield put(actions.getBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBenefitMasterFinish())
  }
}

function* addBenefitMasterSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBenefitMaster, body)
    yield put(actions.addBenefitMasterSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBenefitMasterFinish())
  }
}

function* updateBenefitMasterSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateBenefitMaster,
      body,
      action.payload?.id
    )
    yield put(actions.updateBenefitMasterSuccess(response.data?.data))
    yield put(actions.updateBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBenefitMasterFinish())
  }
}

function* deleteBenefitMasterSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBenefitMaster, body)
    yield put(actions.deleteBenefitMasterSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteBenefitMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBenefitMasterFinish())
  }
}

function* singleActivateBenefitMaster(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateBenefitMaster,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_FINISH})
  }
}

function* singleDeactivateBenefitMaster(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateBenefitMaster,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_FINISH})
  }
}

function* sortBenefitMaster(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialBenefitMaster, body)

    yield put({
      type: actionTypes.SORT_BENEFIT_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_BENEFIT_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BENEFIT_MASTER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_BENEFIT_MASTER_START, getAllBenefitMasterSaga)
  yield takeLatest(actionTypes.GET_SPECIFIC_BENEFIT_MASTER_START, getSpecificBenefitMasterSaga)
  yield takeLatest(actionTypes.GET_BENEFIT_MASTER_START, getBenefitMasterSaga)
  yield takeLatest(actionTypes.ADD_BENEFIT_MASTER_START, addBenefitMasterSaga)
  yield takeLatest(actionTypes.UPDATE_BENEFIT_MASTER_START, updateBenefitMasterSaga)
  yield takeLatest(actionTypes.DELETE_BENEFIT_MASTER_START, deleteBenefitMasterSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_REQUEST, singleActivateBenefitMaster)
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_REQUEST,
    singleDeactivateBenefitMaster
  )
  yield takeLatest(actionTypes.SORT_BENEFIT_MASTER_START, sortBenefitMaster)
}
