import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'

type Props = {
  data: {
    fullName: string
    mobileNumber: string
    emailAddress: string
  }
  handleClose: () => void
}
const ViewPortfolioDetails = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Portfolio Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Personal Details</h5>
            <Row>
              <Col md={6}>
                <p>Full Name</p>
              </Col>
              <Col md={6}>
                <p>{data.fullName}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>Mobile Number</p>
              </Col>
              <Col md={6}>
                <p>{data.mobileNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p>Email Address</p>
              </Col>
              <Col md={6}>
                <p>{data.emailAddress}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewPortfolioDetails
