import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel, OptionModel} from '../Model'
import {actionTypes} from './constants'
// import {INewsState} from './reducer'
// import {NewsModel} from '../Model/NewsModel'
export const actions = {
  // create push notification schedule

  // create NOTIFICATION
  addPushNotificationSchedule: (data: any) => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_SCHEDULE_START,
    payload: data,
  }),
  addPushNotificationScheduleSuccess: (task: any) => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_SCHEDULE_SUCCESS,
    payload: task,
  }),
  addPushNotificationScheduleFinish: () => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_SCHEDULE_FINISH,
  }),
  addPushNotificationScheduleReset: () => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_SCHEDULE_RESET,
  }),

  // get push notificaiton schedule
  getPushNotificationSchedule: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_SCHEDULE_START,
    payload: params,
  }),

  getPushNotificationScheduleSuccess: (data: any) => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_SCHEDULE_SUCCESS,
    payload: data,
  }),
  getPushNotificationScheduleFinish: () => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_SCHEDULE_FINISH,
  }),
  getPushNotificationScheduleError: (error: unknown) => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_SCHEDULE_FINISH,
    payload: {error},
  }),

  // update push notification schedule

  updatePushNotificationSchedule: (data: any, id: string) => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_SCHEDULE_START,
    payload: {data, id},
  }),
  updatePushNotificationScheduleSuccess: (task: any) => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS,
    payload: task,
  }),
  updatePushNotificationScheduleFinish: () => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_SCHEDULE_FINISH,
  }),
  updatePushNotificationScheduleReset: () => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_SCHEDULE_RESET,
  }),

  // DELETE NOTIFICATION
  deletePushNotificationSchedule: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_SCHEDULE_START,
    payload: {idArray: data},
  }),
  deletePushNotificationScheduleSuccess: (data: any) => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS,
    payload: data,
  }),
  deletePushNotificationScheduleFinish: () => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_SCHEDULE_FINISH,
  }),

  // GET NOTIFICATION
  getNews: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_NOTIFICATION_START,
    payload: params,
  }),

  getNewsSuccess: (data: any) => ({
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload: data,
  }),
  getNewsFinish: () => ({
    type: actionTypes.GET_NOTIFICATION_FINISH,
  }),
  getNewsError: (error: unknown) => ({
    type: actionTypes.GET_NOTIFICATION_FINISH,
    payload: {error},
  }),

  // create NOTIFICATION
  addNews: (data: any) => ({
    type: actionTypes.ADD_NOTIFICATION_START,
    payload: data,
  }),
  addNewsSuccess: (task: any) => ({
    type: actionTypes.ADD_NOTIFICATION_SUCCESS,
    payload: task,
  }),
  addNewsFinish: () => ({
    type: actionTypes.ADD_NOTIFICATION_FINISH,
  }),
  addNewsReset: () => ({
    type: actionTypes.ADD_NOTIFICATION_RESET,
  }),

  // update NOTIFICATION

  updateNews: (data: any, id: string) => ({
    type: actionTypes.UPDATE_NOTIFICATION_START,
    payload: {data, id},
  }),
  updateNewsSuccess: (task: any) => ({
    type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
    payload: task,
  }),
  updateNewsFinish: () => ({
    type: actionTypes.UPDATE_NOTIFICATION_FINISH,
  }),
  updateNewsReset: () => ({
    type: actionTypes.UPDATE_NOTIFICATION_RESET,
  }),

  // DELETE NOTIFICATION
  deleteNews: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_NOTIFICATION_START,
    payload: {newsId: data},
  }),
  deleteNewsSuccess: (data: any) => ({
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  }),
  deleteNewsFinish: () => ({
    type: actionTypes.DELETE_NOTIFICATION_FINISH,
  }),
  // Active NOTIFICATION
  activateNews: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_NOTIFICATION_REQUEST,
    payload: {newsId: id},
  }),
  //  deactive NOTIFICATION
  deactivateNews: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_NOTIFICATION_REQUEST,
    payload: {newsId: id},
  }),
  // single active NOTIFICATION
  singleActivateNews: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_NOTIFICATION_REQUEST,
    payload: {newsId: [id]},
  }),
  // single deactive NOTIFICATION
  singleDeactivateNews: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_NOTIFICATION_REQUEST,
    payload: {newsId: [id]},
  }),
}
