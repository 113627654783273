export const actionTypes: any = {
  // get
  GET_ALL_PARTNERS_START: 'GET_ALL_PARTNERS_START',
  GET_ALL_PARTNERS_SUCCESS: 'GET_ALL_PARTNERS_SUCCESS',
  GET_ALL_PARTNERS_FINISH: 'GET_ALL_PARTNERS_FINISH',

  // extended hours
  GET_PARTNERS_START: 'GET_PARTNERS_START',
  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_FINISH: 'GET_PARTNERS_FINISH',

  // add branch
  ADD_PARTNERS_START: 'ADD_PARTNERS_START',
  ADD_PARTNERS_SUCCESS: 'ADD_PARTNERS_SUCCESS',
  ADD_PARTNERS_FINISH: 'ADD_PARTNERS_FINISH',
  RESET_PARTNERS: 'RESET_PARTNERS',

  // update PARTNERS
  UPDATE_PARTNERS_START: 'UPDATE_PARTNERS_START',
  UPDATE_PARTNERS_SUCCESS: 'UPDATE_PARTNERS_SUCCESS',
  UPDATE_PARTNERS_FINISH: 'UPDATE_PARTNERS_FINISH',

  // delete PARTNERS
  DELETE_PARTNERS_START: 'DELETE_PARTNERS_START',
  DELETE_PARTNERS_SUCCESS: 'DELETE_PARTNERS_SUCCESS',
  DELETE_PARTNERS_FINISH: 'DELETE_PARTNERS_FINISH',

  // Enable PARTNERS
  ENABLE_PARTNERS_REQUEST: 'ENABLE_PARTNERS_REQUEST',
  ENABLE_PARTNERS_SUCCESS: 'ENABLE_PARTNERS_SUCCESS',
  ENABLE_PARTNERS_FINISH: 'ENABLE_PARTNERS_FINISH',

  // Disable PARTNERS
  DISABLE_PARTNERS_REQUEST: 'DISABLE_PARTNERS_REQUEST',
  DISABLE_PARTNERS_SUCCESS: 'DISABLE_PARTNERS_SUCCESS',
  DISABLE_PARTNERS_FINISH: 'DISABLE_PARTNERS_FINISH',

  // Enable PARTNERS
  SINGLE_ENABLE_PARTNERS_REQUEST: 'SINGLE_ENABLE_PARTNERS_REQUEST',
  SINGLE_ENABLE_PARTNERS_SUCCESS: 'SINGLE_ENABLE_PARTNERS_SUCCESS',
  SINGLE_ENABLE_PARTNERS_FINISH: 'SINGLE_ENABLE_PARTNERS_FINISH',

  // Disable PARTNERS
  SINGLE_DISABLE_PARTNERS_REQUEST: 'SINGLE_DISABLE_PARTNERS_REQUEST',
  SINGLE_DISABLE_PARTNERS_SUCCESS: 'SINGLE_DISABLE_PARTNERS_SUCCESS',
  SINGLE_DISABLE_PARTNERS_FINISH: 'SINGLE_DISABLE_PARTNERS_FINISH',

  // sort
  SORT_PARTNERS_START: 'SORT_PARTNERS_START',
  SORT_PARTNERS_SUCCESS: 'SORT_PARTNERS_SUCCESS',
  SORT_PARTNERS_FINISH: 'SORT_PARTNERS_FINISH',
  SORT_PARTNERS_RESET: 'SORT_PARTNERS_RESET',
}
