import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {FeaturedModel, SortFeaturedActionModel, SortFeaturedModel} from '../Model'

function* getFeaturedSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getFeatured, params)
    yield put(actions.getFeaturedSuccess(response?.data?.data))
    yield put(actions.getFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getFeaturedFinish())
  }
}

function* addFeaturedSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addFeatured, body)
    yield put(actions.addFeaturedSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addFeaturedFinish())
  }
}

function* enableFeaturedSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableFeatured, selectedUsers)
    yield put(actions.enableFeaturedSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableFeaturedFinish())
  }
}

function* disableFeaturedSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableFeatured, selectedUsers)
    yield put(actions.disableFeaturedSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableFeaturedFinish())
  }
}

function* singleEnableFeaturedSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableFeatured, selectedUsers)
    yield put(actions.singleEnableFeaturedSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableFeaturedFinish())
  }
}

function* singleDisableFeaturedSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableFeatured, selectedUsers)
    yield put(actions.singleDisableFeaturedSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableFeaturedFinish())
  }
}

function* updateFeaturedSaga(action: ActionModel) {
  try {
    const body: FeaturedModel = action.payload
    const response: ResponseModel = yield call(service.updateFeatured, body, action.payload?.id)
    yield put(actions.updateFeaturedSuccess(response.data?.data))
    yield put(actions.updateFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateFeaturedFinish())
  }
}

function* deleteFeaturedSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteFeatured, body)

    yield put(actions.deleteFeaturedSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteFeaturedFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteFeaturedFinish())
  }
}

//Get Featured saga
function* getAllFeaturedSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAllFeatured)
    const data: any = response?.data
    yield put(actions.getAllFeaturedSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAllFeaturedError())
  }
}

function* sortFeatured(action: SortFeaturedActionModel) {
  try {
    const body: SortFeaturedModel = action.payload
    const response: ResponseModel = yield call(service.sortFeatured, body)

    yield put({
      type: actionTypes.SORT_FEATURED_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_FEATURED_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_FEATURED_FINISH})
  }
}

function* getFeaturedFileTypeSaga(action: ActionModel) {
  try {
    yield delay(500)
    const response: ResponseModel = yield call(service.getFeaturedFileType)
    yield put(actions.getFeaturedFileTypeSuccess(response?.data?.data))
    yield put(actions.getFeaturedFileTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getFeaturedFileTypeFinish())
  }
}

function* getFeaturedImageTypeSaga(action: ActionModel) {
  try {
    yield delay(500)
    const response: ResponseModel = yield call(service.getFeaturedImageType)
    yield put(actions.getFeaturedImageTypeSuccess(response?.data?.data))
    yield put(actions.getFeaturedImageTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getFeaturedImageTypeFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_FEATURED_START, getFeaturedSaga)

  /*   yield takeLatest(actionTypes.GET_FEATURED_START, getAllFeaturedSaga) */
  yield takeLatest(actionTypes.ADD_FEATURED_START, addFeaturedSaga)
  yield takeLatest(actionTypes.UPDATE_FEATURED_START, updateFeaturedSaga)
  yield takeLatest(actionTypes.DELETE_FEATURED_START, deleteFeaturedSaga)
  yield takeLatest(actionTypes.ENABLE_FEATURED_REQUEST, enableFeaturedSaga)
  yield takeLatest(actionTypes.DISABLE_FEATURED_REQUEST, disableFeaturedSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_FEATURED_REQUEST, singleEnableFeaturedSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_FEATURED_REQUEST, singleDisableFeaturedSaga)
  yield takeLatest(actionTypes.GET_ALL_FEATURED_START, getAllFeaturedSaga)
  yield takeLatest(actionTypes.SORT_FEATURED_START, sortFeatured)
  yield takeLatest(actionTypes.GET_FEATURED_FILE_TYPE_START, getFeaturedFileTypeSaga)
  yield takeLatest(actionTypes.GET_FEATURED_IMAGE_TYPE_START, getFeaturedImageTypeSaga)
}
