import {ParamsModel} from 'src/app/modules/common/Model'
import {
  AuctionImageTypeListModel,
  AuctionModel,
  AuctionTypeModel,
  DynamicComponentModel,
  PropertyTypeListModel,
  SortAuctionModel,
} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  //add  dynamic component
  addDynamicComponent: (data: DynamicComponentModel | any) => ({
    type: actionTypes.ADD_DYNAMIC_COMPONENT_START,
    payload: data,
  }),
  addDynamicComponentSuccess: (task: any) => ({
    type: actionTypes.ADD_DYNAMIC_COMPONENT_SUCCESS,
    payload: task,
  }),
  addDynamicComponentFinish: () => ({
    type: actionTypes.ADD_DYNAMIC_COMPONENT_FINISH,
  }),
  resetDynamicComponent: () => ({
    type: actionTypes.RESET_DYNAMIC_COMPONENT,
  }),

  // add abroad representatives

  addAbroadRepresentatives: (data: DynamicComponentModel | any) => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_START,
    payload: data,
  }),
  addAbroadRepresentativesSuccess: (task: any) => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_SUCCESS,
    payload: task,
  }),
  addAbroadRepresentativesFinish: () => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_FINISH,
  }),

  // get abroad representatives DATA
  getDynamicComponent: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_DYNAMIC_COMPONENT_START,
    payload: params,
  }),
  getDynamicComponentSuccess: (data: DynamicComponentModel | any) => ({
    type: actionTypes.GET_DYNAMIC_COMPONENT_SUCCESS,
    payload: data,
  }),
  getDynamicComponentFinish: () => ({
    type: actionTypes.GET_DYNAMIC_COMPONENT_FINISH,
  }),

  //Update abroad representatives DATA
  updateDynamicComponent: (data: DynamicComponentModel | any, id: string) => ({
    type: actionTypes.UPDATE_DYNAMIC_COMPONENT_START,
    payload: data,
    id,
  }),

  updateDynamicComponentSuccess: (data: DynamicComponentModel | any) => ({
    type: actionTypes.UPDATE_DYNAMIC_COMPONENT_SUCCESS,
    payload: data,
  }),

  updateDynamicComponentFinish: () => ({
    type: actionTypes.UPDATE_DYNAMIC_COMPONENT_FINISH,
  }),

  // delete dynamic component DATA
  deleteDynamicComponent: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_DYNAMIC_COMPONENT_START,
    payload: {layoutId: data},
  }),
  deleteDynamicComponentSuccess: (data: any) => ({
    type: actionTypes.DELETE_DYNAMIC_COMPONENT_SUCCESS,
    payload: data,
  }),
  deleteDynamicComponentFinish: () => ({
    type: actionTypes.DELETE_DYNAMIC_COMPONENT_FINISH,
  }),
}
