import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as servicePartnerRedux from '..'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddServicePartner = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const [imageFile, setImageFile] = useState('')

  const {loading, success, countryList, currencyList} = useSelector(
    (state: any) => state.servicePartner
  )

  const statusOptions = [
    {label: 'Active', value: '1'},
    {label: 'Inactive', value: '0'},
  ]

  useEffect(() => {
    dispatch(servicePartnerRedux.actions.getServicePartner(params))
    dispatch(setting.actions.getSettingType())
  }, [params])

  useEffect(() => {
    if (success) {
      dispatch(servicePartnerRedux?.actions.getServicePartner(params))
      isEmpty(editSelectedData)
        ? toast.success('Service Partner added successfully')
        : toast.success('Service Partner edited successfully')
      dispatch(servicePartnerRedux?.actions.resetServicePartner())
      handleClose()
    }
  }, [success])

  const currencyListOptions = currencyList?.servicePartnerRateCategory?.map((currency: any) => {
    return {
      label: currency?.name,
      value: currency?.name,
    }
  })

  const servicePartnerCountryNameRequired = checkBackendSetting('servicePartnerCountryNameRequired')
  const phoneNumberValidation = checkBackendSetting('phoneNumberValidation')

  let FORM_VALIDATION = Yup.object().shape({
    slug: Yup.string().required('Slug is required'),
    title: Yup.string().required('Title is required'),
    countryCode: Yup.string().when('servicePartnerCountryNameRequired ', {
      is: (value: any) => !value && servicePartnerCountryNameRequired,
      then: Yup.string().required('Country Name is required'),
      otherwise: Yup.string(),
    }),
    img: Yup.string().required('Image  is required'),
    status: Yup.string().required('Status  is required'),
  })

  if (phoneNumberValidation) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      phoneNo: Yup.string()
        .matches(
          /^[a-zA-Z0-9.:+\-\/\\, ]+$/,
          'Must contain only alphanumerical characters and the symbols (- + : , /.)'
        )
        .nullable(),
    })
  } else {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      phoneNo: Yup.string()
        .matches(/^[0-9 0-9]*$/, 'Please provide valid phone number')
        .max(10, 'Phone number should not exceed 10 digits')
        .nullable(),
    })
  }

  return (
    <div className='modal-container' data-cy='modal'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Service Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                slug: editSelectedData?.slug ?? '',
                countryCode: editSelectedData?.countryCode ?? '',
                title: editSelectedData?.title ?? '',
                titleNp: editSelectedData?.titleNp ?? '',
                img: editSelectedData?.img ?? '',
                currencyCode: editSelectedData?.currencyCode ?? '',
                currencyName: editSelectedData?.currencyName ?? '',
                website: editSelectedData?.website ?? '',
                phoneNo: editSelectedData?.phoneNo ?? '',
                address: editSelectedData?.address ?? '',
                serviceCharge: editSelectedData?.serviceCharge ?? '',
                companyName: editSelectedData?.companyName ?? '',
                status: editSelectedData?.status ?? '1',
                order: editSelectedData?.order ?? '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    servicePartnerRedux.actions.updateServicePartner(values, editSelectedData?.id)
                  )
                } else {
                  dispatch(servicePartnerRedux.actions.addServicePartner(values))
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleBlur,
                errors,
                values,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    setFieldValue(
                      'status',
                      editSelectedData?.status || editSelectedData?.status === '1' ? '1' : '0',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title'
                            label='Title'
                            name='title'
                            onChange={(e: any) => {
                              handleChange(e)
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : setFieldValue(
                                    'slug',
                                    e.target.value.replace(/\s/g, '-').toLowerCase()
                                  )
                            }}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='TitleNp'
                            label='TitleNp'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            disabled
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Country Name'
                            label='Country Name'
                            name='countryCode'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              countryList?.servicePartnerCountryList?.map((country: any) => {
                                return {
                                  label: country?.title,
                                  value: country?.countryCode,
                                }
                              }) ?? []
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                            required={servicePartnerCountryNameRequired}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Company Address'
                            label='Company Address'
                            name='address'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='website'
                            label='Website'
                            name='website'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <label className='mb-1 fw-bolder fs-6  col-lg-4'>
                            {/*<span className='required'>Currency Name</span>*/}
                            <span>Currency Name</span>
                          </label>
                          <Select
                            placeholder='Currency Name'
                            name='currencyName'
                            onChange={(e: any) => {
                              setFieldValue('currencyName', e.value)
                              const code = currencyList?.servicePartnerRateCategory?.filter(
                                (itm: any) => itm.name === e.value
                              )
                              setFieldValue('currencyCode', code[0]?.code)
                            }}
                            options={currencyListOptions ?? []}
                            value={
                              values?.currencyName
                                ? currencyListOptions?.find(
                                    (obj: any) => obj?.value === values?.currencyName
                                  )
                                : null
                            }
                          />
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name={'currencyName'}
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Currency code'
                            label='Currency Code'
                            name='currencyCode'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Phone Number'
                            label='Phone Number'
                            name='phoneNo'
                            onChange={(e: any) => {
                              setFieldValue('phoneNo', e.target.value.toString())
                            }}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='number'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Service Charge'
                            label='Service Charge'
                            name='serviceCharge'
                            onChange={(e: any) => {
                              setFieldValue('serviceCharge', e.target.value.toString())
                            }}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='number'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Order'
                            label='Order'
                            name='order'
                            onChange={(e: any) => {
                              setFieldValue('order', e.target.value.toString())
                            }}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image'
                            name='img'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageFile}
                            value={values?.img}
                            required
                          />
                          {!isEmpty(values?.img) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageFile('')
                                      setFieldValue('img', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.img}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddServicePartner
