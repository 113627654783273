export const actionTypes: any = {
  // get
  GET_DEPARTMENT_START: 'GET_DEPARTMENT_START',
  GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
  GET_DEPARTMENT_FINISH: 'GET_DEPARTMENT_FINISH',
  // get
  GET_DEPARTMENT_DROPDOWN_START: 'GET_DEPARTMENT_DROPDOWN_START',
  GET_DEPARTMENT_DROPDOWN_SUCCESS: 'GET_DEPARTMENT_DROPDOWN_SUCCESS',
  GET_DEPARTMENT_DROPDOWN_FINISH: 'GET_DEPARTMENT_DROPDOWN_FINISH',
  // add
  ADD_DEPARTMENT_START: 'ADD_DEPARTMENT_START',
  ADD_DEPARTMENT_SUCCESS: 'ADD_DEPARTMENT_SUCCESS',
  ADD_DEPARTMENT_FINISH: 'ADD_DEPARTMENT_FINISH',
  ADD_DEPARTMENT_FAILURE: 'ADD_DEPARTMENT_FAILURE',
  ADD_DEPARTMENT_RESET: 'ADD_DEPARTMENT_RESET',
  // update
  UPDATE_DEPARTMENT_START: 'UPDATE_DEPARTMENT_START',
  UPDATE_DEPARTMENT_SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_FINISH: 'UPDATE_DEPARTMENT_FINISH',
  UPDATE_DEPARTMENT_RESET: 'UPDATE_DEPARTMENT_RESET',

  // delete
  DELETE_DEPARTMENT_START: 'DELETE_DEPARTMENT_START',
  DELETE_DEPARTMENT_SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_FINISH: 'DELETE_DEPARTMENT_FINISH',

  // bulk/single active department
  SINGLE_ACTIVATE_DEPARTMENT_REQUEST: 'SINGLE_ACTIVATE_DEPARTMENT_REQUEST',
  SINGLE_ACTIVATE_DEPARTMENT_SUCCESS: 'SINGLE_ACTIVATE_DEPARTMENT_SUCCESS',
  SINGLE_ACTIVATE_DEPARTMENT_FINISH: 'SINGLE_ACTIVATE_DEPARTMENT_FINISH',

  // bulk/single deactive department
  SINGLE_DEACTIVATE_DEPARTMENT_REQUEST: 'SINGLE_DEACTIVATE_DEPARTMENT_REQUEST',
  SINGLE_DEACTIVATE_DEPARTMENT_SUCCESS: 'SINGLE_DEACTIVATE_DEPARTMENT_SUCCESS',
  SINGLE_DEACTIVATE_DEPARTMENT_FINISH: 'SINGLE_DEACTIVATE_DEPARTMENT_FINISH',
}
