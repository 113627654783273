import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import * as bankDetail from 'src/app/modules/aboutBank/components'
import * as collegeDetail from 'src/app/modules/aboutCollege/components'
import * as auth from 'src/app/modules/auth'
import * as cardMenu from 'src/app/modules/cardMenu/redux'
import * as functionalArea from 'src/app/modules/careerSettings/components/FunctionalArera/redux'
import * as position from 'src/app/modules/careerSettings/components/Position/redux'
import * as Qualification from 'src/app/modules/careerSettings/components/Qualification/redux'
import * as generalApplication from 'src/app/modules/careers/components/generalApplication/redux'
import * as internshipApplication from 'src/app/modules/careers/components/internshipAppliedList/redux'
import * as nicGeneralApplication from 'src/app/modules/careers/components/nicGeneralApplication/redux'
import * as nicInternshipApplication from 'src/app/modules/careers/components/nicInternshipAppliedList/redux'
import * as vacancy from 'src/app/modules/careers/components/vacancy/redux'
import * as vacancyApplicant from 'src/app/modules/careers/components/vacancyApplicantReport/redux'
import * as cms from 'src/app/modules/cms'
import * as aboutbankdetail from 'src/app/modules/cms/components/aboutBankDetail/redux'
import * as applyMembership from 'src/app/modules/cms/components/applyforMembership/redux'
import * as banner from 'src/app/modules/cms/components/banner/redux'
import * as banneroffer from 'src/app/modules/cms/components/bannerOffer/redux'
import * as customerTestimonials from 'src/app/modules/cms/components/customer-testimonials/redux'
import * as emailTemplate from 'src/app/modules/cms/components/emailTemplate/redux'
import * as faqIconType from 'src/app/modules/cms/components/faqCategory/faqIconType/redux'
import * as faqCategory from 'src/app/modules/cms/components/faqCategory/redux'
import * as faqManager from 'src/app/modules/cms/components/faqManager/redux'
import * as featured from 'src/app/modules/cms/components/featuredImage/redux'
import * as gallery from 'src/app/modules/cms/components/gallery/redux'
import * as roadBlockPopup from 'src/app/modules/cms/components/roadblockPopup/redux'
import * as textPopup from 'src/app/modules/cms/components/textPopup/redux'
import * as rolePermission from 'src/app/modules/common'
import * as mediaManager from 'src/app/modules/common/components/fileManager'
import * as csrCategory from 'src/app/modules/csr/components/category'
import * as csr from 'src/app/modules/csr/components/csr'
import {globalErrorReducer} from 'src/app/modules/errors/redux/reducer'
import * as eventManager from 'src/app/modules/events'
import * as feedbackForm from 'src/app/modules/feedbacks'
import * as financialDocument from 'src/app/modules/financialDocument'
import * as formsDownload from 'src/app/modules/formsAndDownload'
import * as grievanceHandling from 'src/app/modules/grievance'
import * as leadForm from 'src/app/modules/leadForm'
import * as membership from 'src/app/modules/membership'
import * as merchant from 'src/app/modules/merchantAndOffers'
import * as network from 'src/app/modules/network'
import * as newsAndNotices from 'src/app/modules/newsAndNotices'
import * as pushNotification from 'src/app/modules/pushNotification'

import * as products from 'src/app/modules/products'
import * as profile from 'src/app/modules/profileComponent'
import * as baseRate from 'src/app/modules/rates/components/baseRate'
import * as forexRate from 'src/app/modules/rates/components/forexRate'
import * as goldRate from 'src/app/modules/rates/components/goldRate'
import * as interestRate from 'src/app/modules/rates/components/interestRate'
import * as updateNotes from 'src/app/modules/rates/components/updateNotes'
import * as forexRateCategory from 'src/app/modules/rates/forexRateCategory/redux'
import * as goldRateCategory from 'src/app/modules/rates/goldRateCategory/redux'
import * as applyLoan from 'src/app/modules/reports/components/applyLoan/redux'
import * as municipality from 'src/app/modules/reports/components/municipality/components/redux'
import * as portfolio from 'src/app/modules/reports/components/portfolio/redux'
import * as services from 'src/app/modules/services'
import * as serviceCategoryOne from 'src/app/modules/services/components/serviceCategory/redux'
import * as serviceTag from 'src/app/modules/services/components/serviceTag/redux'
import * as siteModule from 'src/app/modules/siteSettings'
import * as share from 'src/app/modules/stock/components/share/redux'
import * as userManagement from 'src/app/modules/userMgmt'
import * as departments from 'src/app/modules/userMgmt/components/departments'
import * as userRole from 'src/app/modules/userMgmt/components/userRoles'

import * as appointmentType from 'src/app/modules/appointments/components/applyAppointment/redux'
import * as appointmentStatus from 'src/app/modules/appointments/components/appointmentRequest/appointmentStatus/redux'
import * as appointmentRequest from 'src/app/modules/appointments/components/appointmentRequest/redux'
import * as appointmentReason from 'src/app/modules/appointments/components/reasonForAppointment/redux'
import * as benefitCalculatorListManager from 'src/app/modules/benefitCalculatorListManager/redux'
import {certificateCategory, certificateManager} from 'src/app/modules/certificate'
import * as galleryCategory from 'src/app/modules/cms/components/gallery/galleryCategory/redux'
import * as gallerytype from 'src/app/modules/cms/components/gallery/gallerytype/redux'
import * as review from 'src/app/modules/cms/components/review/redux'
import * as reviewStatus from 'src/app/modules/cms/components/review/reviewstatus/redux'
import * as successStories from 'src/app/modules/cms/components/successStories'
import * as customerLoginManager from 'src/app/modules/customerLoginManager/redux'
import * as disputeClaimCardType from 'src/app/modules/disputeClaim/components/disputeClaimCardType/redux'
import * as disputeClaimManager from 'src/app/modules/disputeClaim/components/disputeClaimManager/redux'
import * as disputeClaimOption from 'src/app/modules/disputeClaim/components/disputeClaimOption/redux'
import * as loanEligibilityPurpose from 'src/app/modules/loanEligibilityChecker/components/loanPurpose/index'
import * as loanEligibilitySubCategory from 'src/app/modules/loanEligibilityChecker/components/loanSubCategory/index'
import * as loanEligibilityTenure from 'src/app/modules/loanEligibilityChecker/components/loanTenure/index'
import * as extensionCounter from 'src/app/modules/network/components/extensionCounter/redux'
import {notifications} from 'src/app/modules/notification'
import {partners} from 'src/app/modules/partnerComponent'
import {payoutLocation} from 'src/app/modules/payoutLocation'
import * as bankGuaranteeApplicationManager from 'src/app/modules/reports/components/bankGuaranteeApplications/redux'
import {subscribeManager, subscribeType} from 'src/app/modules/subscribe'
import * as tutorialCategory from 'src/app/modules/tutorial/components/categorySettings/redux'
import * as tutorialManager from 'src/app/modules/tutorial/components/tutorialManager/redux'
import * as dashboard from 'src/app/pages/dashboard/GoogleAnalyticsRedux'
// import * as certificateCategory from 'src/app/modules/certificate/components/certificateCategor'
// import * as certificateManager from 'src/app/modules/certificate/components/certificateManager'
import * as awardManager from 'src/app/modules/achievementsAndAwards/redux/index'
import * as apiManager from 'src/app/modules/apiManager/redux'
import * as benefitCalculatorManager from 'src/app/modules/benefitCalculator/components/benefitManager/index'
import * as benefitCalculatorMasterDataSettings from 'src/app/modules/benefitCalculator/components/benefitMaster/index'
import * as benefitCalculatorCategorySettings from 'src/app/modules/benefitCalculator/components/categorySettings/index'
import * as benefitCalculatorSubCategorySettings from 'src/app/modules/benefitCalculator/components/subCategorySettings/index'
import * as cookieManager from 'src/app/modules/cookie/redux/index'
import * as historyManager from 'src/app/modules/history/index'
import * as enquiryManager from 'src/app/modules/inquiry/components/enquiryManager/index'
import * as enquiryType from 'src/app/modules/inquiry/components/enquiryType/index'
import * as introductoryManager from 'src/app/modules/introductory/redux/index'
import * as experienceManager from 'src/app/modules/network/components/experience/redux/index'
import * as onlineApplicationCategory from 'src/app/modules/onlineApplication/applicationCategory/redux'
import * as onlineApplicationManager from 'src/app/modules/onlineApplication/applicationManager/redux'

import * as cardComparisonManager from 'src/app/modules/cardComparison/components/cardComparisonManager/index'
import * as cardComparisonMasterDataSettings from 'src/app/modules/cardComparison/components/cardComparisonMaster/index'
import * as cardComparisonCategorySettings from 'src/app/modules/cardComparison/components/categorySettings/index'

import * as menuButtonManager from 'src/app/modules/cms/components/menuButtonManager/index'

import * as programCategorySettings from 'src/app/modules/programs/programCategory/index'

import * as agentManager from 'src/app/modules/agentManager/redux'
import * as programManager from 'src/app/modules/programs/programManager/index'

import * as agency from 'src/app/modules/network/components/agent/index'
import * as exchangeRate from 'src/app/modules/servicePartner/components/exchangeRate/index'
import * as servicePartnerManagement from 'src/app/modules/servicePartner/components/servicePartnerManagement/index'

import * as tisaForexRate from 'src/app/modules/rates/components/tisaForexRate'

import * as tisaForexRateCategory from 'src/app/modules/rates/tisaForexRateCategory/redux'
import * as algoliaSearch from 'src/app/modules/searchManager/algoliaSearch'
import * as searchCategory from 'src/app/modules/searchManager/searchCategory'

import * as auctionEnquiry from 'src/app/modules/auction/auctionEnquiry'
import * as auction from 'src/app/modules/auction/auctionManger'
import * as auctionType from 'src/app/modules/auction/auctionType'
import * as propertyType from 'src/app/modules/auction/propertyType'

import * as activityLogs from 'src/app/modules/activityLogs/logs'

import * as balanceCertificateVerificationApplicationManager from 'src/app/modules/reports/components/balanceCertificateVerification/redux'

import * as goodPaymentVerificationApplicationManager from 'src/app/modules/reports/components/goodPaymentVerification/redux'

import * as helpDesk from 'src/app/modules/helpDesk/helpDeskReport/redux'
import {helpDeskReport} from 'src/app/modules/helpDesk/helpDeskReport'
import * as AbroadRepresentativeManager from 'src/app/modules/abroadRepresentative/abroadRepresentativeManager'

import * as layout from 'src/app/modules/personalBaseHomepage/layoutManager'
import * as DynamicComponentManager from 'src/app/modules/personalBaseHomepage/dynamicManager'

export const rootReducer = combineReducers({
  history: historyManager.reducer,
  agent: agentManager.reducer,
  experienceManager: experienceManager.reducer,
  enquiryManager: enquiryManager.reducer,
  enquiryType: enquiryType.reducer,
  cookie: cookieManager.reducer,
  introductory: introductoryManager.reducer,
  award: awardManager.reducer,
  benefitManager: benefitCalculatorManager.reducer,
  benefitCategory: benefitCalculatorCategorySettings.reducer,
  benefitSubCategory: benefitCalculatorSubCategorySettings.reducer,
  benefitMaster: benefitCalculatorMasterDataSettings.reducer,
  loanPurpose: loanEligibilityPurpose.reducer,
  loanSubCategory: loanEligibilitySubCategory.reducer,
  loanTenure: loanEligibilityTenure.reducer,
  customerLoginManager: customerLoginManager.reducer,
  benefitCalculatorListManager: benefitCalculatorListManager.reducer,
  disputes: disputeClaimManager.reducer,
  disputeCard: disputeClaimCardType.reducer,
  disputeOption: disputeClaimOption.reducer,
  certificateCategory: certificateCategory.reducer,
  bankGuaranteeApplications: bankGuaranteeApplicationManager.reducer,
  certificateManager: certificateManager.reducer,
  tutorialCategory: tutorialCategory.reducer,
  subscribeType: subscribeType.reducer,
  subscribeManager: subscribeManager.reducer,
  tutorial: tutorialManager.reducer,
  auth: auth.reducer,
  globalError: globalErrorReducer,
  smtp: siteModule.smtp.reducer,
  key: siteModule.key.reducer,
  groupSetting: siteModule.groupSetting.reducer,
  settingType: siteModule.settings.reducer,
  userManagement: userManagement.reducer,
  module: siteModule.module.reducer,
  permission: rolePermission.reducer,
  place: rolePermission.placeReducer,
  categoryType: rolePermission.categoryTypeReducer,
  customerTestimonialMediaType: rolePermission.mediaTypeReducer,
  role: userManagement.roles.reducer,
  userRoles: userRole.reducer,
  departments: departments.reducer,
  profile: profile.reducer,
  menuManager: cms.menu.reducer,
  content: cms.content.reducer,
  categories: cms.categories.reducer,
  vacancy: vacancy.reducer,
  vacancyApplicant: vacancyApplicant.reducer,
  applicantStatus: vacancyApplicant.reducer1,
  menuButton: menuButtonManager.reducer,
  agency: agency.reducer,

  //Rate
  goldRate: goldRate.reducer,
  goldRateFile: goldRate.reducer1,
  goldRateCategory: goldRateCategory.reducer,
  forexRate: forexRate.reducer,
  forexRateFile: forexRate.reducer1,
  forexRateCategory: forexRateCategory.reducer,
  interestRate: interestRate.reducer,
  baseRate: baseRate.reducer,
  updateNotes: updateNotes.reducer,
  //Branch
  branch: network.branch.reducer,
  branchless: network.branchless.reducer,
  manager: network.branchManager.reducer,
  atm: network.atm.reducer,
  exportBranchlessBankingFile: network.branchless.reducer1,
  operationIncharge: network.operationIncharge.reducer,
  exportOperationIncharge: network.operationIncharge.reducer1,
  extensionCounter: extensionCounter.reducer,

  //Stock
  share: share.reducer,

  //CMS
  apiManager: apiManager.reducer,
  banner: banner.reducer,
  bannerOffer: banneroffer.reducer,
  bankDetail: bankDetail.reducer,
  collegeDetail: collegeDetail.reducer,

  aboutbankdetail: aboutbankdetail.reducer,
  cardMenu: cardMenu.reducer,
  emailTemplate: emailTemplate.reducer,
  textPopup: textPopup.reducer,
  roadBlockPopup: roadBlockPopup.reducer,
  faqCategory: faqCategory.reducer,
  faqManager: faqManager.reducer,
  gallery: gallery.reducer,
  gallerytype: gallerytype.reducer,
  galleryCategory: galleryCategory.reducer,

  // featured image
  featured: featured.reducer,

  review: review.reducer,
  reviewStatus: reviewStatus.reducer,
  //general application
  generalApplication: generalApplication.reducer,
  nicGeneralApplication: nicGeneralApplication.reducer,
  //internship application
  internshipApplication: internshipApplication.reducer,
  nicInternshipApplication: nicInternshipApplication.reducer,

  customerTestimonials: customerTestimonials.reducer,
  subCategory: newsAndNotices.subCategory.reducer,
  news: newsAndNotices.news.reducer,
  pushNotificationTemplate: pushNotification.pushNotificationTemplate.reducer,
  pushNotificationSchedule: pushNotification.pushNotificationManager.reducer,
  notices: newsAndNotices.notices.reducer,
  events: eventManager.reducer,

  //Membership
  memberType: membership.memberType.reducer,
  memberSubType: membership.memberSubType.reducer,
  member: membership.member.reducer,
  mediaManager: mediaManager.reducer,
  faqIconType: faqIconType.reducer,

  //Service
  serviceCategoryOne: serviceCategoryOne.reducer,
  serviceTag: serviceTag.reducer,
  //Grievance Handling
  serviceCategory: grievanceHandling.serviceCategory.reducer,
  serviceType: grievanceHandling.serviceType.reducer,
  serviceSubType: grievanceHandling.serviceSubType.reducer,
  grievance: grievanceHandling.grievance.reducer,

  //Financial Document Handling
  documentCategory: financialDocument.documentCategory.reducer,
  documentSubCategory: financialDocument.documentSubCategory.reducer,
  manageDocument: financialDocument.manageDocument.reducer,

  //Merchant and Offers
  merchantSubCategory: merchant.merchantSubCategory.reducer,
  merchantManager: merchant.merchantManager.reducer,
  offers: merchant.offers.reducer,

  //Products
  productCategory: products.productCategory.reducer,
  productSubCategory: products.productSubCategory.reducer,
  productTag: products.productTag.reducer,
  productComparisonCategory: products.productComparisonCategory.reducer,
  productComparisonSubCategory: products.productComparisonSubCategory.reducer,
  productManager: products.productManager.reducer,
  productMidMenu: products.productMidMenuManager.reducer,

  //mediaManager: mediaManager.reducer,
  csr: csr.reducer,
  csrCategory: csrCategory.reducer,
  successStory: successStories.reducer,
  //Loan
  applyLoan: applyLoan.reducer,
  serviceManager: services.serviceManager.reducer,
  municipality: municipality.reducer,

  //Forms and Download
  formsAndDownload: formsDownload.formAndDownload.reducer,
  formsAndDownloadCategory: formsDownload.formAndDownload.reducer1,

  // qualification
  Qualification: Qualification.reducer,
  // position: position.reducer,
  position: position.reducer,

  functionalArea: functionalArea.reducer,

  //Contact Person
  contactPerson: siteModule.contactPerson.reducer,

  //Contact Person
  applyMembership: applyMembership.reducer,

  //Appointment
  appointmentType: appointmentType.reducer,
  appointmentReason: appointmentReason.reducer,
  appointmentRequest: appointmentRequest.reducer,
  appointmentStatus: appointmentStatus.reducer,

  //LeadForm
  leadFormState: leadForm.leadFormData.reducer,

  //feedbackForm
  feedbackFormState: feedbackForm.feedbackData.reducer,

  //Homepage Setting
  homepageSetting: siteModule.homepageSetting.reducer,

  //About Bank

  // bankDetail: bankDetail.reducer,

  //Notification
  notification: notifications.reducer,
  //partners
  partners: partners.reducer,
  //payoutLocation
  payoutLocation: payoutLocation.reducer,
  //Dashboard

  devices: dashboard.reducer,
  country: dashboard.reducer1,
  unique: dashboard.reducer2,
  portfolio: portfolio.reducer,
  onlineApplicationCategory: onlineApplicationCategory.reducer,
  onlineApplicationManager: onlineApplicationManager.reducer,

  //card Comparison
  cardCategory: cardComparisonCategorySettings.reducer,
  cardMaster: cardComparisonMasterDataSettings.reducer,
  cardManager: cardComparisonManager.reducer,

  //program module
  programCategory: programCategorySettings.reducer,
  program: programManager.reducer,

  //service partner
  servicePartner: servicePartnerManagement.reducer,
  servicePartnerFile: servicePartnerManagement.reducer1,
  exchangeRate: exchangeRate.reducer,
  exchangeRateFile: exchangeRate.reducer1,

  // tisa foreRate
  tisaForexRate: tisaForexRate.reducer,
  tisaForexRateFile: tisaForexRate.reducer1,
  tisaForexRateCategory: tisaForexRateCategory.reducer,
  //search manager reducer
  searchCategory: searchCategory.reducer,
  algoliaSearch: algoliaSearch.reducer,
  // Auction Modal
  auctionType: auctionType.reducer,
  propertyType: propertyType.reducer,
  auction: auction.reducer,
  auctionEnquiry: auctionEnquiry.reducer,

  // activity logs
  activityLogs: activityLogs.reducer,

  balanceCertificateVerificationApplications:
    balanceCertificateVerificationApplicationManager.reducer,
  goodPaymentVerificationApplications: goodPaymentVerificationApplicationManager.reducer,
  helpDeskApplications: helpDesk.reducer,
  abroadRepresentative: AbroadRepresentativeManager.reducer,
  layout: layout.reducer,
  dynamicComponent: DynamicComponentManager.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    agency.saga(),
    historyManager.saga(),
    experienceManager.saga(),
    cookieManager.saga(),
    introductoryManager.saga(),
    awardManager.saga(),
    benefitCalculatorManager.saga(),
    benefitCalculatorCategorySettings.saga(),
    benefitCalculatorSubCategorySettings.saga(),
    benefitCalculatorMasterDataSettings.saga(),
    loanEligibilityPurpose.saga(),
    loanEligibilitySubCategory.saga(),
    enquiryType.saga(),
    enquiryManager.saga(),
    loanEligibilityTenure.saga(),
    disputeClaimCardType.saga(),
    disputeClaimOption.saga(),
    disputeClaimManager.saga(),
    customerLoginManager.saga(),
    agentManager.saga(),
    benefitCalculatorListManager.saga(),
    bankGuaranteeApplicationManager.saga(),
    tutorialCategory.saga(),
    certificateCategory.saga(),
    certificateManager.saga(),
    subscribeManager.saga(),
    subscribeType.saga(),
    tutorialManager.saga(),
    auth.saga(),
    siteModule.smtp.saga(),
    siteModule.key.saga(),
    siteModule.settings.saga(),
    siteModule.groupSetting.saga(),
    siteModule.module.saga(),
    userRole.saga(),
    departments.saga(),
    rolePermission.saga(),
    userManagement.saga(),
    userManagement.roles.saga(),
    profile.saga(),
    cms.menu.saga(),
    cms.content.saga(),
    cms.categories.saga(),
    reviewStatus.saga(),
    menuButtonManager.saga(),
    //Rate
    goldRate.saga(),
    goldRateCategory.saga(),
    forexRate.saga(),
    forexRateCategory.saga(),
    interestRate.saga(),
    baseRate.saga(),
    updateNotes.saga(),
    //Network
    network.branch.saga(),
    network.atm.saga(),
    network.branchless.saga(),
    network.operationIncharge.saga(),
    network.branchManager.saga(),
    banner.saga(),
    apiManager.saga(),
    banneroffer.saga(),
    aboutbankdetail.saga(),
    customerTestimonials.saga(),
    newsAndNotices.subCategory.saga(),
    newsAndNotices.news.saga(),
    newsAndNotices.notices.saga(),
    pushNotification.pushNotificationTemplate.saga(),
    pushNotification.pushNotificationManager.saga(),
    eventManager.saga(),
    textPopup.saga(),
    roadBlockPopup.saga(),
    extensionCounter.saga(),

    //Stock
    share.saga(),
    //Membership
    membership.memberType.saga(),
    membership.memberSubType.saga(),
    membership.member.saga(),
    mediaManager.saga(),
    faqCategory.saga(),
    faqManager.saga(),
    faqIconType.saga(),

    //Grievance Handling
    grievanceHandling.serviceCategory.saga(),
    grievanceHandling.serviceType.saga(),
    grievanceHandling.serviceSubType.saga(),
    grievanceHandling.grievance.saga(),
    csrCategory.saga(),
    csr.saga(),
    successStories.saga(),
    //Financial Document Handling
    financialDocument.documentCategory.saga(),
    financialDocument.documentSubCategory.saga(),
    financialDocument.manageDocument.saga(),

    //Merchant and Offers
    merchant.merchantSubCategory.saga(),
    merchant.merchantManager.saga(),
    merchant.offers.saga(),
    // Products
    products.productCategory.saga(),
    products.productSubCategory.saga(),
    products.productTag.saga(),
    products.productComparisonCategory.saga(),
    products.productComparisonSubCategory.saga(),
    products.productManager.saga(),
    products.productMidMenuManager.saga(),
    //
    gallery.saga(),
    gallerytype.saga(),
    galleryCategory.saga(),

    // featured image
    featured.saga(),
    review.saga(),
    //Service
    serviceCategoryOne.saga(),
    serviceTag.saga(),
    //Loan
    applyLoan.saga(),
    emailTemplate.saga(),
    services.serviceManager.saga(),
    municipality.saga(),
    formsDownload.formAndDownload.saga(),

    Qualification.saga(),
    position.saga(),
    functionalArea.saga(),
    //Contact Person
    siteModule.contactPerson.saga(),
    //vacancy
    vacancy.saga(),
    generalApplication.saga(),
    internshipApplication.saga(),
    nicInternshipApplication.saga(),
    vacancyApplicant.saga(),
    nicGeneralApplication.saga(),
    //appointment
    appointmentType.saga(),
    appointmentReason.saga(),
    appointmentRequest.saga(),
    appointmentStatus.saga(),
    applyMembership.saga(),

    //LeadForm
    leadForm.leadFormData.saga(),

    //feedForm
    feedbackForm.feedbackData.saga(),

    //Homepage Setting
    siteModule.homepageSetting.saga(),

    //Bank Detail
    bankDetail.saga(),
    collegeDetail.saga(),
    // Card Comparism
    cardMenu.saga(),

    //Notification
    notifications.saga(),

    //partners
    partners.saga(),

    //payoutLocation
    payoutLocation.saga(),

    //dashboard
    dashboard.saga(),

    // portfolio
    portfolio.saga(),

    // onlineApplication
    onlineApplicationCategory.saga(),
    onlineApplicationManager.saga(),

    // cardComparison
    cardComparisonCategorySettings.saga(),
    cardComparisonMasterDataSettings.saga(),
    cardComparisonManager.saga(),
    // program Module
    programCategorySettings.saga(),
    programManager.saga(),

    //servicePartner Module
    servicePartnerManagement.saga(),
    exchangeRate.saga(),

    // tisa forex service
    tisaForexRate.saga(),
    tisaForexRateCategory.saga(),
    // search manager
    searchCategory.saga(),
    algoliaSearch.saga(),
    // Auction
    auctionType.saga(),
    propertyType.saga(),
    auction.saga(),
    auctionEnquiry.saga(),

    // Activity Logs
    activityLogs.saga(),

    balanceCertificateVerificationApplicationManager.saga(),
    goodPaymentVerificationApplicationManager.saga(),
    helpDesk.saga(),
    AbroadRepresentativeManager.saga(),
    layout.saga(),
    DynamicComponentManager.saga(),
  ])
}
