import {ErrorMessage, Form, FieldArray, Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import Modal from 'rsuite/Modal'
import {useEffect, useState} from 'react'
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
//manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import * as appointmentReasonRedux from 'src/app/modules/appointments/components/reasonForAppointment/redux'
import * as appointmentTypeRedux from 'src/app/modules/appointments/components/applyAppointment/redux'
import {IAppointmentTypeState} from 'src/app/modules/appointments/components/applyAppointment/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as branch from '../../../../network/index'

import {IBranchState} from 'src/app/modules/network/components/branch'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {bankName} from 'src/cms/helpers/constants'

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  appointmentTypeId: Yup.array().of(Yup.string()).min(1, 'Appointment Type is required'),
  status: Yup.string().required('Status is required'),
})

const FORM_N_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  status: Yup.string().required('Status is required'),
})

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddAppointmentReason = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.appointmentReason)
  const appointmentTypeData = useSelector((state: any) => state.appointmentType)
  const branchData: IBranchState = useSelector((state: any) => state.branch)

  const [applicationName, setApplicationName] = useState('')

  useEffect(() => {
    dispatch(appointmentTypeRedux.actions.getAppointmentType())
    dispatch(branch.branch.actions.getBranchData())
  }, [])

  const appointmentTypeOptions = appointmentTypeData?.data?.appointmentType.map((item: any) => ({
    label: item.title,
    value: item.id,
  }))

  const branchOptions = (branchData?.data?.branch || []).map((branchitem: any) => ({
    label: branchitem.title,
    value: branchitem.id,
  }))

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const settingType: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'meetingType')
    .map((item: any) => item)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setApplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  useEffect(() => {
    if (success) {
      dispatch(appointmentReasonRedux?.actions.getAppointmentReason(params))
      isEmpty(editSelectedData)
        ? toast.success('Appointment Reason added successfully')
        : toast.success('Appointment Reason edited successfully')
      dispatch(appointmentReasonRedux?.actions.createAppointmentReasonReset())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const mettingSectionOption = [
    {label: 'True', value: 'True'},
    {label: 'False', value: 'False'},
  ]

  const hideLeadFormOption = [
    {label: 'True', value: 'True'},
    {label: 'False', value: 'False'},
  ]

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Reason for Appointment </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                appointmentTypeId: [],
                hasMeetSection: 'True',
                hideLeadForm: 'False',
                redirectLink: '',
                branches: [],
                status: 'Active',
              }}
              validationSchema={
                bankName === applicationName && settingType?.[0]?.value === '1'
                  ? FORM_N_VALIDATION
                  : FORM_VALIDATION
              }
              /*  hasMeetSection === 'True' ?
              Yup.object().shape({...FORM_VALIDATION,
                branches: Yup.string().required('Branch is required'),
              }) : Yup.object().shape(FORM_VALIDATION) */

              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  hasMeetSection: values?.hasMeetSection === 'True' ? true : false,
                  hideLeadForm: values?.hideLeadForm === 'True' ? true : false,
                  branches: !isEmpty(values?.branches)
                    ? values?.branches?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  appointmentTypeId: !isEmpty(values?.appointmentTypeId)
                    ? values?.appointmentTypeId?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    appointmentReasonRedux.actions.updateAppointmentReason(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(appointmentReasonRedux.actions.CreateAppointmentReason(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['title', 'branches', 'appointmentTypeId', 'redirectLink']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    ),
                      setFieldValue(
                        'hasMeetSection',
                        editSelectedData?.hasMeetSection === true ? 'True' : 'False',
                        false
                      ),
                      setFieldValue(
                        'hideLeadForm',
                        editSelectedData?.hideLeadForm === true ? 'True' : 'False',
                        false
                      )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      {/* <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            type='text'
                           
                            placeholder='Appointment Type'
                            label='Appointment Type'
                            name='appointmentTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={appointmentTypeOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div> */}

                      <div className='row justify-content-between'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Title'
                            name='title'
                            label='Title'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select a Appointment type'
                            label='Appointment Type'
                            name='appointmentTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={appointmentTypeOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                            multiple={true}
                          />
                        </div>

                        {/* </div> */}
                        {bankName === applicationName && settingType?.[0]?.value === '1' ? null : (
                          <>
                            <div className='col-md-6 col-xs-12'>
                              <label className='form-label fw-bolder text-dark fs-6 required'>
                                Show How would like to meet section
                              </label>

                              <div className='d-flex ms-5'>
                                {mettingSectionOption?.map(
                                  (status: {label: string; value: string}) => (
                                    <FormRadio
                                      containerClassName=''
                                      label='Show How would like to meet section '
                                      name='hasMeetSection'
                                      type='radio'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      checkBoxText={status?.label}
                                      value={status?.value}
                                    />
                                  )
                                )}
                              </div>

                              <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                                <ErrorMessage
                                  name='hasMeetSection'
                                  component='div'
                                  className='field-error-message'
                                />
                              </div>
                            </div>
                            {values.hasMeetSection === 'False' ? (
                              <>
                                <div className='col-md-6 col-xs-12'>
                                  <label className='form-label fw-bolder text-dark fs-6 required'>
                                    Hide Lead Form ?
                                  </label>

                                  <div className='d-flex ms-5'>
                                    {hideLeadFormOption?.map(
                                      (status: {label: string; value: string}) => (
                                        <FormRadio
                                          containerClassName=''
                                          label='Hide Lead Form ? '
                                          name='hideLeadForm'
                                          type='radio'
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          checkBoxText={status?.label}
                                          value={status?.value}
                                          required={true}
                                        />
                                      )
                                    )}
                                  </div>
                                  <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                                    <ErrorMessage
                                      name='hideLeadForm'
                                      component='div'
                                      className='field-error-message'
                                    />
                                  </div>
                                </div>
                                <div className='col-md-6 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    placeholder='Please select  a branch'
                                    label='Branches'
                                    name='branches'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={branchOptions}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    required
                                    multiple={true}
                                  />
                                </div>
                              </>
                            ) : null}

                            {values.hideLeadForm === 'True' ? (
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Redirect Link'
                                  name='redirectLink'
                                  label='Redirect Link'
                                  containerClassName=''
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={false}
                                />
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>

                      <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>

                      <div className='d-flex ms-5'>
                        {statusOptions?.map((status: {label: string; value: string}) => (
                          <FormRadio
                            containerClassName=''
                            label='Select Status'
                            name='status'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checkBoxText={status?.label}
                            value={status?.value}
                            required={true}
                          />
                        ))}
                      </div>
                      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='field-error-message'
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAppointmentReason
