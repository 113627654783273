export const actionTypes: any = {
  // get appointment_reason
  GET_APPOINTMENT_REASON_START: 'GET_APPOINTMENT_REASON_START',
  GET_APPOINTMENT_REASON_SUCCESS: 'GET_APPOINTMENT_REASON_SUCCESS',
  GET_APPOINTMENT_REASON_FINISH: 'GET_APPOINTMENT_REASON_FINISH',

  // get appointment_reason
  GET_ALL_APPOINTMENT_REASON_START: 'GET_ALL_APPOINTMENT_REASON_START',
  GET_ALL_APPOINTMENT_REASON_SUCCESS: 'GET_ALL_APPOINTMENT_REASON_SUCCESS',
  GET_ALL_APPOINTMENT_REASON_FINISH: 'GET_ALL_APPOINTMENT_REASON_FINISH',

  //Add appointment_reason
  ADD_APPOINTMENT_REASON_REQUEST: 'ADD_APPOINTMENT_REASON_REQUEST',
  ADD_APPOINTMENT_REASON_SUCCESS: 'ADD_APPOINTMENT_REASON_SUCCESS',
  ADD_APPOINTMENT_REASON_FINISH: 'ADD_APPOINTMENT_REASON_FINISH',
  ADD_APPOINTMENT_REASON_RESET: 'ADD_APPOINTMENT_REASON_RESET',

  // update appointment_reason
  UPDATE_APPOINTMENT_REASON_START: 'UPDATE_APPOINTMENT_REASON_START',
  UPDATE_APPOINTMENT_REASON_SUCCESS: 'UPDATE_APPOINTMENT_REASON_SUCCESS',
  UPDATE_APPOINTMENT_REASON_FINISH: 'UPDATE_APPOINTMENT_REASON_FINISH',
  UPDATE_APPOINTMENT_REASON_RESET: 'UPDATE_APPOINTMENT_REASON_RESET',

  //Bulk Activate appointment_reason
  ACTIVATE_APPOINTMENT_REASON_REQUEST: 'ACTIVATE_APPOINTMENT_REASON_REQUEST',
  ACTIVATE_APPOINTMENT_REASON_SUCCESS: 'ACTIVATE_APPOINTMENT_REASON_SUCCESS',
  ACTIVATE_APPOINTMENT_REASON_FINISH: 'ACTIVATE_APPOINTMENT_REASON_FINISH',

  //Bulk Deactivate appointment_reason
  DEACTIVATE_APPOINTMENT_REASON_REQUEST: 'DEACTIVATE_APPOINTMENT_REASON_REQUEST',
  DEACTIVATE_APPOINTMENT_REASON_SUCCESS: 'DEACTIVATE_APPOINTMENT_REASON_SUCCESS',
  DEACTIVATE_APPOINTMENT_REASON_FINISH: 'DEACTIVATE_APPOINTMENT_REASON_FINISH',

  //Single Activate appointment_reason
  SINGLE_ACTIVATE_APPOINTMENT_REASON_REQUEST: 'SINGLE_ACTIVATE_APPOINTMENT_REASON_REQUEST',
  SINGLE_ACTIVATE_APPOINTMENT_REASON_SUCCESS: 'SINGLE_ACTIVATE_APPOINTMENT_REASON_SUCCESS',
  SINGLE_ACTIVATE_APPOINTMENT_REASON_FINISH: 'SINGLE_ACTIVATE_APPOINTMENT_REASON_FINISH',

  //Single Deactivate appointment_reason
  SINGLE_DEACTIVATE_APPOINTMENT_REASON_REQUEST: 'SINGLE_DEACTIVATE_APPOINTMENT_REASON_REQUEST',
  SINGLE_DEACTIVATE_APPOINTMENT_REASON_SUCCESS: 'SINGLE_DEACTIVATE_APPOINTMENT_REASON_SUCCESS',
  SINGLE_DEACTIVATE_APPOINTMENT_REASON_FINISH: 'SINGLE_DEACTIVATE_APPOINTMENT_REASON_FINISH',

  // delete appointment_reason
  DELETE_APPOINTMENT_REASON_START: 'DELETE_APPOINTMENT_REASON_START',
  DELETE_APPOINTMENT_REASON_SUCCESS: 'DELETE_APPOINTMENT_REASON_SUCCESS',
  DELETE_APPOINTMENT_REASON_FINISH: 'DELETE_APPOINTMENT_REASON_FINISH',
}
