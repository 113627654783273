import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import * as Yup from 'yup'
import * as auctionRedux from '../index'
import * as servicePartnerRedux from 'src/app/modules/servicePartner/components/servicePartnerManagement'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

interface IServicePartnerState {
  data?: {
    servicePartner?: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  countryList?: {servicePartnerCountryList?: any[]}
  currencyList?: any[]
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  importLoading?: boolean
  importSuccess?: boolean
  countryLoading?: boolean
  countrySuccess?: boolean
  currencyLoading?: boolean
  currencySuccess?: boolean
  loading?: boolean
  success?: boolean
}

const AddAbroadRepresentative = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()
  const [provinceValue, setProvinceValue] = useState('')
  const [locationCategoryValue, setLocationCategoryValue] = useState('')
  const [countryOptions, setCountryOptions] = useState([])
  const [attachfile, setAttachfile] = useState('')
  const [attachedFile, setAttachedFile] = useState('')

  const servicePartnerData: IServicePartnerState = useSelector((state: any) => state.servicePartner)

  const {loading, success} = useSelector((state: any) => state.abroadRepresentative)

  useMemo(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(servicePartnerRedux.actions.servicePartnerCountryList())
  }, [])

  useMemo(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (!isEmpty(servicePartnerData?.countryList?.servicePartnerCountryList)) {
      let countryList = servicePartnerData?.countryList?.servicePartnerCountryList?.map((item) => ({
        label: item?.title,
        value: item?.id,
      }))
      setCountryOptions(countryList)
    }
  }, [servicePartnerData])

  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
      setLocationCategoryValue(editSelectedData?.addressCategory?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (success) {
      dispatch(auctionRedux?.actions.getAbroadRepresentatives(params))
      isEmpty(editSelectedData)
        ? toast.success('Abroad representatives added successfully')
        : toast.success('Abroad representatives edited successfully')
      dispatch(auctionRedux?.actions?.resetAbroadRepresentatives())
      handleClose()
    }
  }, [success])

  const disableNBADropdown = checkBackendSetting('disableNBADropdown')
  const showAuctionDescription = checkBackendSetting('showAuctionDescription')
  const showAuctionAttachment = checkBackendSetting('showAuctionAttachment')
  const auctionImageNotRequired = checkBackendSetting('auctionImageNotRequired')
  const auctionPropertyNotRequired = checkBackendSetting('auctionPropertyNotRequired')

  const FORM_VALIDATION = Yup.object().shape({
    country_id: Yup.string().required('Required field'),
    representatives: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required field'),
        name_np: Yup.string().required('Required field'),
        designation: Yup.string().required('Required field'),
        designation_np: Yup.string().required('Required field'),
        email: Yup.string()
          .email('Invalid Email. Eg: example@xyz.com')
          .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please provide valid email')
          .required('Required field'),
        number: Yup.string().required('Required field'),
      })
    ),
    // .min(1, 'At least one property is required'),
  })

  return (
    <div className='modal-container' data-cy='search-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Abroad Representative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                country_id: '',
                description: '',
                description_np: '',
                image: '',
                representatives: [
                  {
                    name: '',
                    name_np: '',
                    number: '',
                    email: '',
                    designation: '',
                    designation_np: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  representatives: values?.representatives?.map(
                    (item: {[key: string]: string}) => ({
                      ...item,
                      ...(item?.id && {id: !isEmpty(editSelectedData) ? item.id : null}),
                      // id: !isEmpty(editSelectedData) ? item?.id : null,
                      // show_in_listing: item?.show_in_listing === 'true' ? true : false,
                    })
                  ),
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    auctionRedux.actions.updateAbroadRepresentatives(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(auctionRedux.actions.addAbroadRepresentatives(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'country_id',
                      'description',
                      'description_np',
                      'image',
                      'representatives',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('country_id', editSelectedData?.country_id, false)
                  }
                }, [])

                // useEffect(() => {
                //   if (values.province_id && values.province_id !== editSelectedData.province_id) {
                //     setFieldValue('province_id', '')
                //   }
                // }, [values.address_category_id, editSelectedData])

                // useEffect(() => {
                //   if (values.district_id && values.district_id !== editSelectedData.district_id) {
                //     setFieldValue('district_id', '')
                //   }
                // }, [values.province_id, editSelectedData])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='search-category-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Country'
                            name='country_id'
                            // disabled={
                            //   !isEmpty(editSelectedData) || values?.province_id ? false : true
                            // }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={countryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description'
                            name='description'
                            height={350}
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                            data-cy='add-notice-description-np-text-area'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description (NP)'
                            name='description_np'
                            height={350}
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description_np : ''
                            }
                            handleChange={handleChange}
                            data-cy='add-notice-description-np-text-area'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image Upload'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.image}
                            data-cy='add-news-file-field'
                          />
                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='news-remove-image-button'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.image.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.image}`
                                    }
                                    alt=''
                                    data-cy='news-file-preview'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='representatives'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Abroad Representatives</h5>
                              {values?.representatives && values?.representatives.length > 0
                                ? values?.representatives?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Enter name'
                                          name={`representatives[${index}].name`}
                                          label='Name'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Enter name np'
                                          name={`representatives[${index}].name_np`}
                                          label='Name (NP)'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='string'
                                          placeholder='Enter contact number'
                                          name={`representatives[${index}].number`}
                                          label='Contact number'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='email'
                                          placeholder='Enter email address'
                                          name={`representatives[${index}].email`}
                                          label='Email'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Enter designation'
                                          name={`representatives[${index}].designation`}
                                          label='Designation'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Enter designation in nepali'
                                          name={`representatives[${index}].designation_np`}
                                          label='Designation (NP)'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={true}
                                          touched={touched}
                                        />
                                      </div>

                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}

                              <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                <button
                                  className='p-2 ps-5 pe-5 btn btn-primary'
                                  type='button'
                                  onClick={() => arrayHelpers.push({})}
                                  data-cy='modal-field-add'
                                >
                                  + Add More
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAbroadRepresentative
