import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getDynamicComponentSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getDynamicComponent, params)
    yield put(actions.getDynamicComponentSuccess(response?.data?.data))
    yield put(actions.getDynamicComponentFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getDynamicComponentFinish())
  }
}

function* addDynamicComponentSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addDynamicComponent, body)
    yield put(actions.addDynamicComponentSuccess(response.data?.data))
    yield put(actions.addDynamicComponentFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.addDynamicComponentFinish())
  }
}

function* updateDynamicComponentSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(
      service.updateDynamicComponent,
      body,
      action.payload?.id
    )
    yield put(actions.updateDynamicComponentSuccess(response.data?.data))
    yield put(actions.updateDynamicComponentFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.updateDynamicComponentFinish())
  }
}

function* deleteDynamicComponentSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteDynamicComponent, body)
    yield put(actions.deleteDynamicComponentSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteDynamicComponentFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.deleteDynamicComponentFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_DYNAMIC_COMPONENT_START, getDynamicComponentSaga)
  yield takeLatest(actionTypes.ADD_DYNAMIC_COMPONENT_START, addDynamicComponentSaga)
  yield takeLatest(actionTypes.UPDATE_DYNAMIC_COMPONENT_START, updateDynamicComponentSaga)
  yield takeLatest(actionTypes.DELETE_DYNAMIC_COMPONENT_START, deleteDynamicComponentSaga)
}
