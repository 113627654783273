import {Action} from 'redux'
import {OptionModel} from '../Model'
import {PushNotificationTemplateModel} from '../Model/NewsModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialNewsState: IPushNotificationTemplateState = {
  data: {
    push_notification_messages: [],
    meta: {},
  },
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface IPushNotificationTemplateState {
  data?: {
    push_notification_messages?: PushNotificationTemplateModel[]
    meta?: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IPushNotificationTemplateState = initialNewsState,
  action: ActionWithPayload<IPushNotificationTemplateState>
) => {
  switch (action.type) {
    //GET NOTIFICATION DATA
    case actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // update push notification template
    case actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // case actionTypes.GET_NOTIFICATION_SUCCESS: {
    //   return {...state, data: action.payload, loading: false}
    // }

    // case actionTypes.GET_NOTIFICATION_FINISH: {
    //   const error = action.payload
    //   return {...state, error, loading: false}
    // }

    //Add push notification template

    case actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.ADD_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_NOTIFICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_NOTIFICATION_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update news
    case actionTypes.UPDATE_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_NOTIFICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Singer activate branchless banking
    //Activate Users
    case actionTypes.SINGLE_ACTIVATE_NOTIFICATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_NOTIFICATION_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_NOTIFICATION_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_NOTIFICATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_NOTIFICATION_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_NOTIFICATION_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    default:
      return state
  }
}
