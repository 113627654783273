import {isEmpty} from 'lodash'
import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'

type Props = {
  data: {
    mobileNumber: string
    emailAddress: string
    amount: string
    guaranteeReferenceNumber: string
    letterheadNumber?: string
    currency?: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Bank Guarantee Application Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Bank Guarantee Reference Number</b>
              </Col>
              <Col md={6}>
                <p>{data.guaranteeReferenceNumber}</p>
              </Col>
            </Row>
            {!isEmpty(data?.letterheadNumber) && (
              <Row>
                <Col md={6}>
                  <b>Letterhead Number</b>
                </Col>
                <Col md={6}>
                  <p>{data.letterheadNumber}</p>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6}>
                <b>Mobile Number</b>
              </Col>
              <Col md={6}>
                <p>{data.mobileNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email Address</b>
              </Col>
              <Col md={6}>
                <p>{data.emailAddress}</p>
              </Col>
            </Row>
            {!isEmpty(data?.currency) && (
              <Row>
                <Col md={6}>
                  <b>Currency</b>
                </Col>
                <Col md={6}>
                  <p>{data?.currency}</p>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6}>
                <b>Amount</b>
              </Col>
              <Col md={6}>
                <p>{data.amount}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
