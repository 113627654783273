import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import TrashIcon from '@rsuite/icons/Trash'

import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {SortType} from 'rsuite-table/lib/@types/common'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import axios from 'axios'
import * as goodPaymentVerification from '../../goodPaymentVerification/redux'
import {FiEye} from 'react-icons/fi'
import ViewDetailsModal from './ViewDetailsModal'
import AddUpdateNotes from './AddUpdateNotes'
import {IUpdateNotesState} from 'src/app/modules/rates/components/updateNotes'
import * as updateNotesRedux from 'src/app/modules/rates/components/updateNotes'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const GoodPaymentVerification = () => {
  const dispatch = useDispatch()

  //for update notes
  const [editSelectedData, setEditCheckedData] = useState<any>({})
  const [showAddEditNotesModal, setShowAddEditNotesModal] = useState(false)
  const [excerptId, setExcerptId]: any = useState('')

  const goodPaymentVerificationApplicationData = useSelector(
    (state: any) => state.goodPaymentVerificationApplications
  )
  const {loading, success, deleteSuccess} = useSelector(
    (state: any) => state.goodPaymentVerificationApplications
  )

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  //for update notes
  const updateNotes: IUpdateNotesState = useSelector((state: any) => state.updateNotes)

  useEffect(() => {
    const updateNoteList = updateNotes?.data?.excerptRateCategory?.filter(
      (item: any) => item.name === 'Bank Guarantee Application'
    )
    updateNoteList?.length > 0 && setExcerptId(updateNoteList[0]?.id)
  }, [updateNotes])

  useEffect(() => {
    dispatch(updateNotesRedux?.actions.getUpdateNotes())
  }, [])

  const downloadBankGuaranteeExcel = () => {
    const GET_APPLICATION_FILE = `${API_URL}/bank-guarantee/export?search=${params.search}`
    axios(GET_APPLICATION_FILE, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `GoodPaymentVerification.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              icon={<FiEye />}
              data-cy='view-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let loan = [rowData.id]
                setCheckedValues(loan)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      flexGrow: 1,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'Cheque Number',
      dataKey: 'chequeNumber',
      flexGrow: 1,
      cell: <Cell dataKey='chequeNumber' />,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Application deleted successfully')
    }

    handleChecked([])
    dispatch(goodPaymentVerification?.actions.getApplications(params))
  }, [deleteSuccess, success, params])

  const data = goodPaymentVerificationApplicationData?.data?.goodPaymentVerification
    ? goodPaymentVerificationApplicationData?.data?.goodPaymentVerification?.map(
        (item: any, i: number) => ({
          sn: (params?.page - 1) * params?.limit + (i + 1),
          id: item.id,
          accountNumber: item.accountNumber,
          chequeNumber: item.chequeNumber,
        })
      )
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleDelete = () => {
    let applicationIds = checkedValues?.map((value) => ({id: value}))
    dispatch(goodPaymentVerification?.actions.deleteApplications(applicationIds))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(goodPaymentVerification?.actions.getApplications(params))
  }

  const handleAddNotice = () => {
    setEditCheckedData({})
    setShowAddEditNotesModal(true)
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Good for Payment Verification'
          addShow={false}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          toggleMultipleShow={false}
          exportButtonName='Export CSV'
          handleExport={downloadBankGuaranteeExcel}
          addNoteShow={true}
          handleAddNotice={handleAddNotice}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={goodPaymentVerificationApplicationData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showAddEditNotesModal && (
          <AddUpdateNotes
            open={showAddEditNotesModal}
            excerptId={excerptId}
            handleClose={() => setShowAddEditNotesModal(false)}
            editSelectedData={editSelectedData}
          />
        )}

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}
        <>
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
    </>
  )
}

export default GoodPaymentVerification
