import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {OptionModel} from '../Model'
import {AgencyModel} from '../Model/AgencyModel'
import {ParamsModel} from 'src/app/modules/common/Model'
import {IAgencyState} from './reducer'

function* getAgencyCategory() {
  try {
    const response: ResponseModel = yield call(service.getAgencyCategory)
    yield put(actions.getAgencyCategorySuccess(response?.data?.data))
    yield put(actions.getAgencyCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAgencyCategoryFinish())
  }
}

function* getExtendedHoursSaga() {
  try {
    const response: ResponseModel = yield call(service.getExtendedHours)
    yield put(actions.getExtendedHourSuccess(response?.data?.data))
    yield put(actions.getExtendedHourFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getExtendedHourFinish())
  }
}

function* getAgencyListDataSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAgencyListData, params)
    yield put(actions.getAgencyListDataSuccess(response?.data?.data))
    yield put(actions.getAgencyListDataFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAgencyListDataFinish())
  }
}

function* getAgencyDataSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAgencyData, params)
    yield put(actions.getAgencyDataSuccess(response?.data?.data))
    yield put(actions.getAgencyDataFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAgencyDataFinish())
  }
}

function* getAllAgencyDataSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllAgencyData)
    yield put(actions.getAllAgencyListSuccess(response?.data?.data))
    yield put(actions.getAllAgencyListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAgencyDataFinish())
  }
}

function* addAgencySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAgency, body)
    yield put(actions.addAgencySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addAgencyFinish())
  }
}

function* enableAgencySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableAgency, selectedUsers)
    yield put(actions.enableAgencySuccess(response?.data))
    yield delay(100)
    yield put(actions.enableAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableAgencyFinish())
  }
}

function* disableAgencySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableAgency, selectedUsers)
    yield put(actions.disableAgencySuccess(response?.data))
    yield delay(100)
    yield put(actions.disableAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableAgencyFinish())
  }
}

function* singleEnableAgencySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableAgency, selectedUsers)
    yield put(actions.singleEnableAgencySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableAgencyFinish())
  }
}

function* singleDisableAgencySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableAgency, selectedUsers)
    yield put(actions.singleDisableAgencySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableAgencyFinish())
  }
}

function* importAgencySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importAgency, body)
    yield put(actions.importAgencySuccess(response.data?.data))
    yield put(actions.importAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importAgencyFinish())
  }
}

function* updateAgencySaga(action: ActionModel) {
  try {
    const body: AgencyModel = action.payload
    const response: ResponseModel = yield call(service.updateAgency, body, action.payload?.id)
    yield put(actions.updateAgencySuccess(response.data?.data))
    yield put(actions.updateAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateAgencyFinish())
  }
}

function* deleteAgencySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAgency, body)
    yield put(actions.deleteAgencySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteAgencyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteAgencyFinish())
  }
}

//Get branchless file
function* exportFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    yield delay(500)
    const data: any = action.payload.params
    const response: ResponseModel = yield call(service.exportFile, fileName, data)
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    yield put(actions.exportFileError())
  }
}

function* exportTemplateFileSaga() {
  try {
    const response: ResponseModel = yield call(service.exportTemplateFile)
    const responseData: any = response?.data
    yield put(actions.exportTemplateFileSuccess(responseData))
    yield put(actions.exportTemplateFileFinish())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportTemplateFileFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_AGENCY_CATEGORY_START, getAgencyCategory)
  yield takeLatest(actionTypes.GET_EXTENDED_HOURS_START, getExtendedHoursSaga)
  yield takeLatest(actionTypes.GET_AGENCY_DATA_START, getAgencyDataSaga)
  yield takeLatest(actionTypes.GET_AGENCY_LIST_DATA_START, getAgencyListDataSaga)
  yield takeLatest(actionTypes.GET_ALL_AGENCY_DATA_START, getAllAgencyDataSaga)
  yield takeLatest(actionTypes.ADD_AGENCY_START, addAgencySaga)
  yield takeLatest(actionTypes.UPDATE_AGENCY_START, updateAgencySaga)
  yield takeLatest(actionTypes.DELETE_AGENCY_START, deleteAgencySaga)
  yield takeLatest(actionTypes.ENABLE_AGENCY_REQUEST, enableAgencySaga)
  yield takeLatest(actionTypes.DISABLE_AGENCY_REQUEST, disableAgencySaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_AGENCY_REQUEST, singleEnableAgencySaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_AGENCY_REQUEST, singleDisableAgencySaga)
  yield takeLatest(actionTypes.EXPORT_AGENCY_FILE_START, exportFileSaga)
  yield takeLatest(actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_START, exportTemplateFileSaga)
  yield takeLatest(actionTypes.IMPORT_AGENCY_START, importAgencySaga)
}
