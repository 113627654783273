export const actionTypes: any = {
  // get sub category
  GET_INTERNSHIP_LISTS_START: 'GET_INTERNSHIP_LISTS_START',
  GET_INTERNSHIP_LISTS_SUCCESS: 'GET_INTERNSHIP_LISTS_SUCCESS',
  GET_INTERNSHIP_LISTS_FINISH: 'GET_INTERNSHIP_LISTS_FINISH',

  // get File csv xlsx
  GET_INTERNSHIP_LISTS_FILE_START: 'GET_INTERNSHIP_LISTS_FILE_START',
  GET_INTERNSHIP_LISTS_FILE_SUCCESS: 'GET_INTERNSHIP_LISTS_FILE_SUCCESS',
  GET_INTERNSHIP_LISTS_FILE_FINISH: 'GET_INTERNSHIP_LISTS_FILE_FINISH',

  // ADD sub category
  ADD_INTERNSHIP_LISTS_START: 'ADD_INTERNSHIP_LISTS_START',
  ADD_INTERNSHIP_LISTS_SUCCESS: 'ADD_INTERNSHIP_LISTS_SUCCESS',
  ADD_INTERNSHIP_LISTS_FINISH: 'ADD_INTERNSHIP_LISTS_FINISH',
  ADD_INTERNSHIP_LISTS_RESET: 'ADD_INTERNSHIP_LISTS_RESET',

  // Update sub category
  UPDATE_INTERNSHIP_LISTS_START: 'UPDATE_INTERNSHIP_LISTS_START',
  UPDATE_INTERNSHIP_LISTS_SUCCESS: 'UPDATE_INTERNSHIP_LISTS_SUCCESS',
  UPDATE_INTERNSHIP_LISTS_FINISH: 'UPDATE_INTERNSHIP_LISTS_FINISH',
  UPDATE_INTERNSHIP_LISTS_RESET: 'UPDATE_INTERNSHIP_LISTS_RESET',

  // delete sub category
  DELETE_INTERNSHIP_LISTS_START: 'DELETE_INTERNSHIP_LISTS_START',
  DELETE_INTERNSHIP_LISTS_SUCCESS: 'DELETE_INTERNSHIP_LISTS_SUCCESS',
  DELETE_INTERNSHIP_LISTS_FINISH: 'DELETE_INTERNSHIP_LISTS_FINISH',

  // Enable INTERNSHIP_LISTS
  ENABLE_INTERNSHIP_LISTS_REQUEST: 'ENABLE_INTERNSHIP_LISTS_REQUEST',
  ENABLE_INTERNSHIP_LISTS_SUCCESS: 'ENABLE_INTERNSHIP_LISTS_SUCCESS',
  ENABLE_INTERNSHIP_LISTS_FINISH: 'ENABLE_INTERNSHIP_LISTS_FINISH',

  // Disable INTERNSHIP_LISTS
  DISABLE_INTERNSHIP_LISTS_REQUEST: 'DISABLE_INTERNSHIP_LISTS_REQUEST',
  DISABLE_INTERNSHIP_LISTS_SUCCESS: 'DISABLE_INTERNSHIP_LISTS_SUCCESS',
  DISABLE_INTERNSHIP_LISTS_FINISH: 'DISABLE_INTERNSHIP_LISTS_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_INTERNSHIP_LISTS_REQUEST: 'SINGLE_ACTIVATE_INTERNSHIP_LISTS_REQUEST',
  SINGLE_ACTIVATE_INTERNSHIP_LISTS_SUCCESS: 'SINGLE_ACTIVATE_INTERNSHIP_LISTS_SUCCESS',
  SINGLE_ACTIVATE_INTERNSHIP_LISTS_FINISH: 'SINGLE_ACTIVATE_INTERNSHIP_LISTS_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_INTERNSHIP_LISTS_REQUEST: 'SINGLE_DEACTIVATE_INTERNSHIP_LISTS_REQUEST',
  SINGLE_DEACTIVATE_INTERNSHIP_LISTS_SUCCESS: 'SINGLE_DEACTIVATE_INTERNSHIP_LISTS_SUCCESS',
  SINGLE_DEACTIVATE_INTERNSHIP_LISTS_FINISH: 'SINGLE_DEACTIVATE_INTERNSHIP_LISTS_FINISH',
}
