export const actionTypes: any = {
  GET_HOMEPAGE_SECTION_START: 'GET_HOMEPAGE_SECTION_START',
  GET_HOMEPAGE_SECTION_SUCCESS: 'GET_HOMEPAGE_SECTION_SUCCESS',
  GET_HOMEPAGE_SECTION_FINISH: 'GET_HOMEPAGE_SECTION_FINISH',

  GET_HOMEPAGE_LAYOUT_START: 'GET_HOMEPAGE_LAYOUT_START',
  GET_HOMEPAGE_LAYOUT_SUCCESS: 'GET_HOMEPAGE_LAYOUT_SUCCESS',
  GET_HOMEPAGE_LAYOUT_FINISH: 'GET_HOMEPAGE_LAYOUT_FINISH',

  GET_HOMEPAGE_SETTING_START: 'GET_HOMEPAGE_SETTING_START',
  GET_HOMEPAGE_SETTING_SUCCESS: 'GET_HOMEPAGE_SETTING_SUCCESS',
  GET_HOMEPAGE_SETTING_FINISH: 'GET_HOMEPAGE_SETTING_FINISH',

  ADD_HOMEPAGE_SETTING_START: 'ADD_HOMEPAGE_SETTING_START',
  ADD_HOMEPAGE_SETTING_SUCCESS: 'ADD_HOMEPAGE_SETTING_SUCCESS',
  ADD_HOMEPAGE_SETTING_FINISH: 'ADD_HOMEPAGE_SETTING_FINISH',
  ADD_HOMEPAGE_SETTING_RESET: 'ADD_HOMEPAGE_SETTING_RESET',

  UPDATE_HOMEPAGE_SETTING_START: 'UPDATE_HOMEPAGE_SETTING_START',
  UPDATE_HOMEPAGE_SETTING_SUCCESS: 'UPDATE_HOMEPAGE_SETTING_SUCCESS',
  UPDATE_HOMEPAGE_SETTING_FINISH: ' UPDATE_HOMEPAGE_SETTING_FINISH',
  UPDATE_HOMEPAGE_SETTING_RESET: 'UPDATE_HOMEPAGE_SETTING_RESET',
  // single active sub category
  SINGLE_ACTIVATE_HOMEPAGE_SETTING_REQUEST: 'SINGLE_ACTIVATE_HOMEPAGE_SETTING_REQUEST',
  SINGLE_ACTIVATE_HOMEPAGE_SETTING_SUCCESS: 'SINGLE_ACTIVATE_HOMEPAGE_SETTING_SUCCESS',
  SINGLE_ACTIVATE_HOMEPAGE_SETTING_FINISH: 'SINGLE_ACTIVATE_HOMEPAGE_SETTING_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_HOMEPAGE_SETTING_REQUEST: 'SINGLE_DEACTIVATE_HOMEPAGE_SETTING_REQUEST',
  SINGLE_DEACTIVATE_HOMEPAGE_SETTING_SUCCESS: 'SINGLE_DEACTIVATE_HOMEPAGE_SETTING_SUCCESS',
  SINGLE_DEACTIVATE_HOMEPAGE_SETTING_FINISH: 'SINGLE_DEACTIVATE_HOMEPAGE_SETTING_FINISH',

  // sort
  SORT_HOMEPAGE_SETTING_START: 'SORT_HOMEPAGE_SETTING_START',
  SORT_HOMEPAGE_SETTING_SUCCESS: 'SORT_HOMEPAGE_SETTING_SUCCESS',
  SORT_HOMEPAGE_SETTING_FINISH: 'SORT_HOMEPAGE_SETTING_FINISH',
  SORT_HOMEPAGE_SETTING_RESET: 'SORT_HOMEPAGE_SETTING_RESET',

  // delete
  DELETE_HOMEPAGE_SETTING_START: 'DELETE_HOMEPAGE_SETTING_START',
  DELETE_HOMEPAGE_SETTING_SUCCESS: 'DELETE_HOMEPAGE_SETTING_SUCCESS',
  DELETE_HOMEPAGE_SETTING_FINISH: 'DELETE_HOMEPAGE_SETTING_FINISH',
}
