import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import {FiEye} from 'react-icons/fi'
// includes
import {isEmpty} from 'lodash'
import {useParams} from 'react-router-dom'
import Pagination from 'rsuite/Pagination'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as auctionEnquiryRedux from '../index'
import {IconButton, Tooltip, Whisper} from 'rsuite'
import ViewAuctionEnquiryDetailsModal from './viewAuctionEnquiryDetailsModal'

const AuctionEnquiryComponent = () => {
  const dispatch = useDispatch()
  const parameter: any = useParams()
  const {data, loading} = useSelector((state: any) => state.auctionEnquiry)
  const [params, setParams] = useState<any>({
    auction_id: parameter?.id,
    page: 1,
    limit: 10,
  })
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedRowViewId, setSelectedRowViewId] = useState('')

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              setShowDetailsModal(true)
              setSelectedRowViewId(rowData?.id)
            }}
            data-cy='view-button'
            icon={<FiEye />}
          />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Full Name',
      dataKey: 'fullname',
      flexGrow: 1,
      width: 200,
      cell: <Cell dataKey='fullname' />,
      sortable: true,
    },
    {
      label: 'Email',
      dataKey: 'email',
      width: 200,
      cell: <Cell dataKey='email' />,
      sortable: false,
    },
    {
      label: 'Mobile',
      dataKey: 'mobileNumber',
      cell: <Cell dataKey='mobileNumber' />,
      sortable: false,
    },
    {
      label: 'Address',
      dataKey: 'address',
      width: 100,
      cell: <Cell dataKey='address' />,
      sortable: false,
    },
    {
      label: 'Submitted Date',
      dataKey: 'submitted_date',
      width: 100,
      cell: <Cell dataKey='submitted_date' />,
      sortable: false,
    },
    {
      label: 'Message',
      dataKey: 'message',
      width: 200,
      cell: <Cell dataKey='message' />,
      sortable: false,
    },
    {
      label: 'Action',
      width: 60,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(auctionEnquiryRedux.actions.getAuctionEnquiry(params))
  }, [params, parameter?.id])

  const auctionEnquiryData: any = data?.auctionEnquiries
    ? data?.auctionEnquiries?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        auction: item?.auction,
        fullname: item?.fullname,
        email: item?.email,
        mobileNumber: item?.mobileNumber,
        address: item?.address,
        message: item?.message,
        submitted_date: item?.submitted_date,
        submitted_time: item?.submitted_time,
      }))
    : []

  const handleRefresh = () => {
    dispatch(auctionEnquiryRedux.actions.getAuctionEnquiry(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: data?.meta?.total})
    dispatch(auctionEnquiryRedux.actions.getAuctionEnquiry(params))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded' data-cy='search-category-shadow-comp'>
        <DesignComponent
          moduleName={`Auction Enquiry${
            !isEmpty(auctionEnquiryData) ? ` - ${auctionEnquiryData?.[0]?.auction?.name}` : ''
          } `}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          exportShow={false}
          importShow={false}
          data={auctionEnquiryData}
          sortShow={false}
          addShow={false}
          deleteShow={false}
          toggleMultipleShow={false}
          sortButtonName='Sort'
          handleGetAllData={handleGetAllData}
          loading={loading}
          data-cy='search-category-design-comp'
        />

        <div className='datatable' data-cy='search-category-datatable'>
          <RSuiteTable
            showCheckbox={false}
            columns={columns}
            data={auctionEnquiryData}
            showLoading={loading}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='search-category-pagination'
          />
        </div>

        {showDetailsModal && (
          <ViewAuctionEnquiryDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.auctionEnquiries?.filter((res: any) => res.id === selectedRowViewId)[0]}
          />
        )}
      </div>
    </div>
  )
}

export default AuctionEnquiryComponent
