import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {AbroadRepresentativesModel, AuctionModel, SortAuctionModel} from '../Model/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const AUCTION_API = `${API_URL}/auction`
export const ABROAD_REPRESENTATIVES_API = `${API_URL}/abroad-representative`

export const service = {
  addAbroadRepresentatives: (data: any) => {
    return axios.post(ABROAD_REPRESENTATIVES_API, data)
  },
  getAbroadRepresentatives: (params: ParamsModel) => {
    return axios.get(ABROAD_REPRESENTATIVES_API, {params})
  },
  updateAbroadRepresentatives: (body: AbroadRepresentativesModel, id: string) => {
    return axios.put(`${ABROAD_REPRESENTATIVES_API}/${id}`, body)
  },

  deleteAbroadRepresentatives: (data: {id: string}) => {
    return axios.delete(`${ABROAD_REPRESENTATIVES_API}/bulk-delete`, {data})
  },

  getAuction: (params: ParamsModel) => {
    return axios.get(AUCTION_API, {params})
  },
  addAuction: (data: any) => {
    return axios.post(AUCTION_API, data)
  },

  updateAuction: (body: AuctionModel, id: string) => {
    return axios.put(`${AUCTION_API}/${id}`, body)
  },

  deleteAuction: (data: {id: string}) => {
    return axios.delete(`${AUCTION_API}/bulk-delete`, {data})
  },

  enableAuction: (data: Array<string>) => {
    const selectedAuction = {
      auctionId: data,
    }
    return axios.patch(`${AUCTION_API}/enable`, selectedAuction)
  },

  disableAuction: (data: Array<string>) => {
    const selectedAuction = {
      auctionId: data,
    }
    return axios.patch(`${AUCTION_API}/disable`, selectedAuction)
  },

  singleEnableAuction: (data: Array<string>) => {
    const selectedAuction = {
      auctionId: [data],
    }
    return axios.patch(`${AUCTION_API}/enable`, selectedAuction)
  },

  singleDisableAuction: (data: Array<string>) => {
    const selectedAuction = {
      auctionId: [data],
    }
    return axios.patch(`${AUCTION_API}/disable`, selectedAuction)
  },
  sortAuction: (body: SortAuctionModel) => {
    return axios.patch(`${AUCTION_API}/sort`, body)
  },
  getAuctionTypeList: () => {
    return axios.get(`${AUCTION_API}/auction-type-list`)
  },
  getPropertyTypeList: () => {
    return axios.get(`${AUCTION_API}/property-type-list`)
  },
  getAuctionImageTypeList: () => {
    return axios.get(`${AUCTION_API}/auction-imagestype-list`)
  },
}
