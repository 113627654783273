export const actionTypes: any = {
  // get
  GET_ALL_PRODUCT_SUB_CATEGORY_START: 'GET_ALL_PRODUCT_SUB_CATEGORY_START',
  GET_ALL_PRODUCT_SUB_CATEGORY_SUCCESS: 'GET_ALL_PRODUCT_SUB_CATEGORY_SUCCESS',
  GET_ALL_PRODUCT_SUB_CATEGORY_FINISH: 'GET_ALL_PRODUCT_SUB_CATEGORY_FINISH',

  // extended hours
  GET_PRODUCT_SUB_CATEGORY_START: 'GET_PRODUCT_SUB_CATEGORY_START',
  GET_PRODUCT_SUB_CATEGORY_SUCCESS: 'GET_PRODUCT_SUB_CATEGORY_SUCCESS',
  GET_PRODUCT_SUB_CATEGORY_FINISH: 'GET_PRODUCT_SUB_CATEGORY_FINISH',

  // add branch
  ADD_PRODUCT_SUB_CATEGORY_START: 'ADD_PRODUCT_SUB_CATEGORY_START',
  ADD_PRODUCT_SUB_CATEGORY_SUCCESS: 'ADD_PRODUCT_SUB_CATEGORY_SUCCESS',
  ADD_PRODUCT_SUB_CATEGORY_FINISH: 'ADD_PRODUCT_SUB_CATEGORY_FINISH',
  RESET_PRODUCT_SUB_CATEGORY: 'RESET_PRODUCT_SUB_CATEGORY',

  // update PRODUCT_SUB_CATEGORY
  UPDATE_PRODUCT_SUB_CATEGORY_START: 'UPDATE_PRODUCT_SUB_CATEGORY_START',
  UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS: 'UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS',
  UPDATE_PRODUCT_SUB_CATEGORY_FINISH: 'UPDATE_PRODUCT_SUB_CATEGORY_FINISH',

  // delete PRODUCT_SUB_CATEGORY
  DELETE_PRODUCT_SUB_CATEGORY_START: 'DELETE_PRODUCT_SUB_CATEGORY_START',
  DELETE_PRODUCT_SUB_CATEGORY_SUCCESS: 'DELETE_PRODUCT_SUB_CATEGORY_SUCCESS',
  DELETE_PRODUCT_SUB_CATEGORY_FINISH: 'DELETE_PRODUCT_SUB_CATEGORY_FINISH',

  // Enable PRODUCT_SUB_CATEGORY
  ENABLE_PRODUCT_SUB_CATEGORY_REQUEST: 'ENABLE_PRODUCT_SUB_CATEGORY_REQUEST',
  ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS: 'ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS',
  ENABLE_PRODUCT_SUB_CATEGORY_FINISH: 'ENABLE_PRODUCT_SUB_CATEGORY_FINISH',

  // Disable PRODUCT_SUB_CATEGORY
  DISABLE_PRODUCT_SUB_CATEGORY_REQUEST: 'DISABLE_PRODUCT_SUB_CATEGORY_REQUEST',
  DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS: 'DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS',
  DISABLE_PRODUCT_SUB_CATEGORY_FINISH: 'DISABLE_PRODUCT_SUB_CATEGORY_FINISH',

  // Enable PRODUCT_SUB_CATEGORY
  SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_REQUEST: 'SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_REQUEST',
  SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS: 'SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS',
  SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_FINISH: 'SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_FINISH',

  // Disable PRODUCT_SUB_CATEGORY
  SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_REQUEST: 'SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_REQUEST',
  SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS: 'SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS',
  SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_FINISH: 'SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_FINISH',

  // Sort ProductSubSort
  SORT_PRODUCT_SUB_CATEGORY_START: 'SORT_PRODUCT_SUB_CATEGORY_START',
  SORT_PRODUCT_SUB_CATEGORY_SUCCESS: 'SORT_PRODUCT_SUB_CATEGORY_SUCCESS',
  SORT_PRODUCT_SUB_CATEGORY_FINISH: 'SORT_PRODUCT_SUB_CATEGORY_FINISH',
  SORT_PRODUCT_SUB_CATEGORY_RESET: 'SORT_PRODUCT_SUB_CATEGORY_RESET',
}
