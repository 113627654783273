export const actionTypes: any = {
  // get Update Notes
  GET_UPDATE_NOTES_START: 'GET_UPDATE_NOTES_START',
  GET_UPDATE_NOTES_SUCCESS: 'GET_UPDATE_NOTES_SUCCESS',
  GET_UPDATE_NOTES_FINISH: 'GET_UPDATE_NOTES_FINISH',

  // get Update Notes
  GET_UPDATE_NOTES_WITH_DATA_START: 'GET_UPDATE_NOTES_WITH_DATA_START',
  GET_UPDATE_NOTES_WITH_DATA_SUCCESS: 'GET_UPDATE_NOTES_WITH_DATA_SUCCESS',
  GET_UPDATE_NOTES_WITH_DATA_FINISH: 'GET_UPDATE_NOTES_WITH_DATA_FINISH',

  //Add Update Notes
  ADD_UPDATE_NOTES_REQUEST: 'ADD_UPDATE_NOTES_REQUEST',
  ADD_UPDATE_NOTES_SUCCESS: 'ADD_UPDATE_NOTES_SUCCESS',
  ADD_UPDATE_NOTES_FINISH: 'ADD_UPDATE_NOTES_FINISH',
  ADD_UPDATE_NOTES_RESET: 'ADD_UPDATE_NOTES_RESET',

  // update Update Notes
  UPDATE_UPDATE_NOTES_START: 'UPDATE_UPDATE_NOTES_START',
  UPDATE_UPDATE_NOTES_SUCCESS: 'UPDATE_UPDATE_NOTES_SUCCESS',
  UPDATE_UPDATE_NOTES_FINISH: 'UPDATE_UPDATE_NOTES_FINISH',
  UPDATE_UPDATE_NOTES_RESET: 'UPDATE_UPDATE_NOTES_RESET',
}
