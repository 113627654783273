export const actionTypes: any = {
  // Get service Tag
  GET_SERVICE_TAG_DATA_START: 'GET_SERVICE_TAG_DATA_START',
  GET_SERVICE_TAG_DATA_SUCCESS: 'GET_SERVICE_TAG_DATA_SUCCESS',
  GET_SERVICE_TAG_DATA_FINISH: 'GET_SERVICE_TAG_DATA_FINISH',

  // add service Tag
  ADD_SERVICE_TAG_DATA_START: 'ADD_SERVICE_TAG_DATA_START',
  ADD_SERVICE_TAG_DATA_SUCCESS: 'ADD_SERVICE_TAG_DATA_SUCCESS',
  ADD_SERVICE_TAG_DATA_FINISH: 'ADD_SERVICE_TAG_DATA_FINISH',
  RESET_SERVICE_TAG_DATA: 'RESET_SERVICE_TAG_DATA',

  // update service Tag
  UPDATE_SERVICE_TAG_DATA_START: 'UPDATE_SERVICE_TAG_DATA_START',
  UPDATE_SERVICE_TAG_DATA_SUCCESS: 'UPDATE_SERVICE_TAG_DATA_SUCCESS',
  UPDATE_SERVICE_TAG_DATA_FINISH: 'UPDATE_SERVICE_TAG_DATA_FINISH',

  // delete service Tag
  DELETE_SERVICE_TAG_DATA_START: 'DELETE_SERVICE_TAG_DATA_START',
  DELETE_SERVICE_TAG_DATA_SUCCESS: 'DELETE_SERVICE_TAG_DATA_SUCCESS',
  DELETE_SERVICE_TAG_DATA_FINISH: 'DELETE_SERVICE_TAG_DATA_FINISH',

  // Enable service Tag
  ENABLE_SERVICE_TAG_DATA_REQUEST: 'ENABLE_SERVICE_TAG_DATA_REQUEST',
  ENABLE_SERVICE_TAG_DATA_SUCCESS: 'ENABLE_SERVICE_TAG_DATA_SUCCESS',
  ENABLE_SERVICE_TAG_DATA_FINISH: 'ENABLE_SERVICE_TAG_DATA_FINISH',

  // Disable service Tag
  DISABLE_SERVICE_TAG_DATA_REQUEST: 'DISABLE_SERVICE_TAG_DATA_REQUEST',
  DISABLE_SERVICE_TAG_DATA_SUCCESS: 'DISABLE_SERVICE_TAG_DATA_SUCCESS',
  DISABLE_SERVICE_TAG_DATA_FINISH: 'DISABLE_SERVICE_TAG_DATA_FINISH',

  // Enable service Tag
  SINGLE_ENABLE_SERVICE_TAG_DATA_REQUEST: 'SINGLE_ENABLE_SERVICE_TAG_DATA_REQUEST',
  SINGLE_ENABLE_SERVICE_TAG_DATA_SUCCESS: 'SINGLE_ENABLE_SERVICE_TAG_DATA_SUCCESS',
  SINGLE_ENABLE_SERVICE_TAG_DATA_FINISH: 'SINGLE_ENABLE_SERVICE_TAG_DATA_FINISH',

  // Disable service Tag
  SINGLE_DISABLE_SERVICE_TAG_DATA_REQUEST: 'SINGLE_DISABLE_SERVICE_TAG_DATA_REQUEST',
  SINGLE_DISABLE_SERVICE_TAG_DATA_SUCCESS: 'SINGLE_DISABLE_SERVICE_TAG_DATA_SUCCESS',
  SINGLE_DISABLE_SERVICE_TAG_DATA_FINISH: 'SINGLE_DISABLE_SERVICE_TAG_DATA_FINISH',

  // Get service Tag List
  GET_SERVICE_TAG_LIST_START: 'GET_SERVICE_TAG_LIST_START',
  GET_SERVICE_TAG_LIST_SUCCESS: 'GET_SERVICE_TAG_LIST_SUCCESS',
  GET_SERVICE_TAG_LIST_FINISH: 'GET_SERVICE_TAG_LIST_FINISH',

  // sort
  SORT_SERVICE_TAG_START: 'SORT_SERVICE_TAG_START',
  SORT_SERVICE_TAG_SUCCESS: 'SORT_SERVICE_TAG_SUCCESS',
  SORT_SERVICE_TAG_FINISH: 'SORT_SERVICE_TAG_FINISH',
  SORT_SERVICE_TAG_RESET: 'SORT_SERVICE_TAG_RESET',
}
