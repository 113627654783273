export const actionTypes: any = {
  // get branchless banking
  GET_OPERATION_INCHARGE_START: 'GET_OPERATION_INCHARGE_START',
  GET_OPERATION_INCHARGE_SUCCESS: 'GET_OPERATION_INCHARGE_SUCCESS',
  GET_OPERATION_INCHARGE_FINISH: 'GET_OPERATION_INCHARGE_FINISH',

  // ADD branchless banking
  ADD_OPERATION_INCHARGE_START: 'ADD_OPERATION_INCHARGE_START',
  ADD_OPERATION_INCHARGE_SUCCESS: 'ADD_OPERATION_INCHARGE_SUCCESS',
  ADD_OPERATION_INCHARGE_FINISH: 'ADD_OPERATION_INCHARGE_FINISH',
  ADD_OPERATION_INCHARGE_RESET: 'ADD_OPERATION_INCHARGE_RESET',

  // Update branchless banking
  UPDATE_OPERATION_INCHARGE_START: 'UPDATE_OPERATION_INCHARGE_START',
  UPDATE_OPERATION_INCHARGE_SUCCESS: 'UPDATE_OPERATION_INCHARGE_SUCCESS',
  UPDATE_OPERATION_INCHARGE_FINISH: 'UPDATE_OPERATION_INCHARGE_FINISH',
  UPDATE_OPERATION_INCHARGE_RESET: 'UPDATE_OPERATION_INCHARGE_RESET',

  // delete branchless banking
  DELETE_OPERATION_INCHARGE_START: 'DELETE_OPERATION_INCHARGE_START',
  DELETE_OPERATION_INCHARGE_SUCCESS: 'DELETE_OPERATION_INCHARGE_SUCCESS',
  DELETE_OPERATION_INCHARGE_FINISH: 'DELETE_OPERATION_INCHARGE_FINISH',

  // single active branchless banking
  SINGLE_ACTIVATE_OPERATION_INCHARGE_REQUEST: 'SINGLE_ACTIVATE_OPERATION_INCHARGE_REQUEST',
  SINGLE_ACTIVATE_OPERATION_INCHARGE_SUCCESS: 'SINGLE_ACTIVATE_OPERATION_INCHARGE_SUCCESS',
  SINGLE_ACTIVATE_OPERATION_INCHARGE_FINISH: 'SINGLE_ACTIVATE_OPERATION_INCHARGE_FINISH',

  // single deactive branchless banking
  SINGLE_DEACTIVATE_OPERATION_INCHARGE_REQUEST: 'SINGLE_DEACTIVATE_OPERATION_INCHARGE_REQUEST',
  SINGLE_DEACTIVATE_OPERATION_INCHARGE_SUCCESS: 'SINGLE_DEACTIVATE_OPERATION_INCHARGE_SUCCESS',
  SINGLE_DEACTIVATE_OPERATION_INCHARGE_FINISH: 'SINGLE_DEACTIVATE_OPERATION_INCHARGE_FINISH',

  // get File csv xlsx
  EXPORT_FILE_START: 'EXPORT_FILE_START',
  EXPORT_FILE_SUCCESS: 'EXPORT_FILE_SUCCESS',
  EXPORT_FILE_FINISH: 'EXPORT_FILE_FINISH',

  // Import branch
  IMPORT_OPERATION_INCHARGE_START: 'IMPORT_OPERATION_INCHARGE_START',
  IMPORT_OPERATION_INCHARGE_SUCCESS: 'IMPORT_OPERATION_INCHARGE_SUCCESS',
  IMPORT_OPERATION_INCHARGE_FINISH: 'IMPORT_OPERATION_INCHARGE_FINISH',

  EXPORT_OPERATION_TEMPLATE_FILE_START: 'EXPORT_OPERATION_TEMPLATE_FILE_START',
  EXPORT_OPERATION_TEMPLATE_FILE_SUCCESS: 'EXPORT_OPERATION_TEMPLATE_FILE_SUCCESS',
  EXPORT_OPERATION_TEMPLATE_FILE_FINISH: 'EXPORT_OPERATION_TEMPLATE_FILE_FINISH',
}
