import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getDisputesSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getDisputes, params)
    yield put(actions.getDisputesSuccess(response?.data?.data))
    yield put(actions.getDisputesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getDisputesFinish())
  }
}

function* deleteDisputesSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteDisputes, body)
    yield put(actions.deleteDisputesSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.deleteDisputesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteDisputesFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_DISPUTES_START, getDisputesSaga)
  yield takeLatest(actionTypes.DELETE_DISPUTES_START, deleteDisputesSaga)
}
