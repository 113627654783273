export const actionTypes: any = {
  GET_GOOD_PAYMENT_START: 'GET_GOOD_PAYMENT_START',
  GET_GOOD_PAYMENT_SUCCESS: 'GET_GOOD_PAYMENT_SUCCESS',
  GET_GOOD_PAYMENT_FINISH: 'GET_GOOD_PAYMENT_FINISH',
  RESET_APPLY_LOAN: 'RESET_APPLY_LOAN',

  DELETE_GOOD_PAYMENT_START: 'DELETE_GOOD_PAYMENT_START',
  DELETE_GOOD_PAYMENT_SUCCESS: 'DELETE_GOOD_PAYMENT_SUCCESS',
  DELETE_GOOD_PAYMENT_FINISH: 'DELETE_GOOD_PAYMENT_FINISH',
}
