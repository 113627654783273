import {ParamsModel} from 'src/app/modules/common/Model'
import {AuctionEnquiryModel} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  // get AuctionEnquiry DATA
  getAuctionEnquiry: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_AUCTION_ENQUIRY_START,
    payload: params,
  }),
  getAuctionEnquirySuccess: (data: AuctionEnquiryModel | any) => ({
    type: actionTypes.GET_AUCTION_ENQUIRY_SUCCESS,
    payload: data,
  }),
  getAuctionEnquiryFinish: () => ({
    type: actionTypes.GET_AUCTION_ENQUIRY_FINISH,
  }),
}
