import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
// branch
import moment from 'moment'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as news from '../index'
import getBackendSettingValue from 'src/app/modules/common/getBackendSetting'
import * as messageTempalte from '../../pushNotificationTemplate/index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

let FORM_VALIDATION = Yup.object().shape({
  message_id: Yup.string().required('Message template is required'),
})

const AddPushNotificationManager = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()

  const {data} = useSelector((state: any) => state.pushNotificationTemplate)
  const [messageDataValue, setMessageDataValue] = useState('')

  const {loading, success} = useSelector((state: any) => state.pushNotificationSchedule)

  const messageTemplateOptions = data?.push_notification_messages?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    dispatch(messageTempalte?.actions.getPushNotificationTemplate())
  }, [])

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  const sendNowOptions = [
    {label: 'True', value: 'Active'},
    {label: 'False', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Push Notification Manager added successfully')
        : toast.success('Push Notification Manager edited successfully')
      dispatch(news?.actions.getPushNotificationSchedule(params))
      dispatch(news?.actions?.addPushNotificationScheduleReset())
      handleClose()
    }
  }, [success])

  FORM_VALIDATION = FORM_VALIDATION.shape({
    send_now: Yup.string().required('Send now is required'),
  })

  return (
    <div className='modal-container' data-cy='add-news-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Push Notification Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                message_id: '',
                send_now: '',
                scheduled_time: '',
                customers: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  send_now: values?.send_now === 'Active' ? true : false,
                  scheduled_time: values?.scheduled_time
                    ? moment(values?.scheduled_time).format('YYYY-MM-DD HH:mm')
                    : null,
                  customers: 'all',
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    news.actions.updatePushNotificationSchedule(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(news.actions.addPushNotificationSchedule(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['message_id', 'scheduled_time', 'end_time', 'customers']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'send_now',
                      editSelectedData?.send_now === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='add-news-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select message template'
                            label='Message Template'
                            name='message_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={messageTemplateOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-push-notification-manager-message-template-drop-down-field'
                          />
                        </div>
                        <>
                          {!isEmpty(values?.message_id)
                            ? setMessageDataValue(values?.message_id)
                            : ''}
                        </>
                      </div>
                      <div className='row'>
                        <>
                          <div className='col-md-3 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              // dateFormat='hh:mm aa'
                              showTimeSelect={true}
                              showTimeSelectOnly={false}
                              name='scheduled_time'
                              label='Scheduled Time'
                              placeholderText='--:-- --'
                              setFieldValue={setFieldValue}
                              value={values.scheduled_time || false}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                            />
                          </div>
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <label className={`form-label fw-bolder text-dark fs-6 ${'required'}`}>
                            Send Now
                          </label>

                          <div className='d-flex ms-5'>
                            {sendNowOptions?.map((send: {label: string; value: string}) => (
                              <FormRadio
                                key={send?.value}
                                containerClassName=''
                                label='Select Send Option'
                                name='send_now'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={send?.label}
                                value={send?.value}
                                data-cy='news-send-now-radio-button'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='send_now'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddPushNotificationManager
