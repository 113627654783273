import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const HelpDesk_URL = `${API_URL}/help-desk-enquiries`

export const service = {
  getHelpDesk: (params: ParamsModel) => {
    return axios.get(HelpDesk_URL, {params})
  },

  updateHelpDesk: (body: any, id: string) => {
    return axios.patch(`${HelpDesk_URL}/${id}`, body)
  },
}
