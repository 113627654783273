import {ParamsModel} from 'src/app/modules/common/Model'
import {
  AbroadRepresentativesModel,
  AuctionImageTypeListModel,
  AuctionModel,
  AuctionTypeModel,
  PropertyTypeListModel,
  SortAuctionModel,
} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  // add abroad representatives

  addAbroadRepresentatives: (data: AbroadRepresentativesModel | any) => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_START,
    payload: data,
  }),
  addAbroadRepresentativesSuccess: (task: any) => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_SUCCESS,
    payload: task,
  }),
  addAbroadRepresentativesFinish: () => ({
    type: actionTypes.ADD_ABROAD_REPRESENTATIVES_FINISH,
  }),
  resetAbroadRepresentatives: () => ({
    type: actionTypes.RESET_ABROAD_REPRESENTATIVES,
  }),

  // get abroad representatives DATA
  getAbroadRepresentatives: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ABROAD_REPRESENTATIVES_START,
    payload: params,
  }),
  getAbroadRepresentativesSuccess: (data: AbroadRepresentativesModel | any) => ({
    type: actionTypes.GET_ABROAD_REPRESENTATIVES_SUCCESS,
    payload: data,
  }),
  getAbroadRepresentativesFinish: () => ({
    type: actionTypes.GET_ABROAD_REPRESENTATIVES_FINISH,
  }),

  //Update abroad representatives DATA
  updateAbroadRepresentatives: (data: AbroadRepresentativesModel | any, id: string) => ({
    type: actionTypes.UPDATE_ABROAD_REPRESENTATIVES_START,
    payload: data,
    id,
  }),

  updateAbroadRepresentativesSuccess: (data: AbroadRepresentativesModel | any) => ({
    type: actionTypes.UPDATE_ABROAD_REPRESENTATIVES_SUCCESS,
    payload: data,
  }),

  updateAbroadRepresentativesFinish: () => ({
    type: actionTypes.UPDATE_ABROAD_REPRESENTATIVES_FINISH,
  }),

  // delete abroad representatives DATA
  deleteAbroadRepresentatives: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_ABROAD_REPRESENTATIVES_START,
    payload: {abroadRepresentativeId: data},
  }),
  deleteAbroadRepresentativesSuccess: (data: any) => ({
    type: actionTypes.DELETE_ABROAD_REPRESENTATIVES_SUCCESS,
    payload: data,
  }),
  deleteAbroadRepresentativesFinish: () => ({
    type: actionTypes.DELETE_ABROAD_REPRESENTATIVES_FINISH,
  }),
}
