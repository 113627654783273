import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'

import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiEye} from 'react-icons/fi'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as experienceManager from '../index'
import AddExperience from './AddExperience'
import ViewDetailsModal from './ViewDetailsModal'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const ExperienceManager = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  const [openSortModal, setOpenSortModal] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  //for view details modal
  const [selectedExperienceManagerId, setSelectedExperienceManagerId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const experienceManagerData: any = useSelector((state: any) => state.experienceManager)

  const {
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    sortExperienceManagerData,
    importSuccess,
    success,
    loading,
  } = experienceManagerData

  useEffect(() => {
    dispatch(experienceManager.actions.getExperienceManager(params))
  }, [params])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const handleToggleAction = (data: {[key: string]: string}) => {
    const formData = {id: data?.id}
    data?.status
      ? dispatch(experienceManager.actions.singleDeactivateExperienceManager(formData))
      : dispatch(experienceManager.actions.singleActivateExperienceManager(formData))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <CheckPermissions type='Edit'>
            <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
              <IconButton
                appearance='subtle'
                onClick={() => {
                  setShowDetailsModal(true)
                  setSelectedExperienceManagerId(rowData?.id)
                }}
                icon={<FiEye />}
                data-cy='view-button'
              />
            </Whisper>
          </CheckPermissions>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'SN',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name(E.N)',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Branch',
      dataKey: 'branchName',
      flexGrow: 1,
      cell: <Cell dataKey='branchName' />,
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
      sortable: true,
    },
    {
      label: 'Hide In Website',
      dataKey: 'hideInWebsite',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.hideInWebsite ? (
              <Tag color='red' size='sm'>
                Yes
              </Tag>
            ) : (
              <Tag color='green' size='sm'>
                No
              </Tag>
            )
          }
        </Cell>
      ),
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (experienceManagerData?.deleteSuccess) {
      toast.success('Experience Manager deleted successfully')
      dispatch(experienceManager?.actions.getExperienceManager(params))
      setCheckedValues([])
    }
  }, [experienceManagerData])

  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Experience Manager activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success('Experience Manager deactivated successfully')
    }
    handleChecked([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Experience Manager activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Experience Manager deactivated successfully')
    }
    if (importSuccess) {
      toast.success('Experience Manager imported successfully')
    }

    handleChecked([])
    dispatch(experienceManager?.actions.getExperienceManager(params))
  }, [activateSuccess, deactivateSuccess, importSuccess])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const activateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(experienceManager.actions.activateExperienceManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(experienceManager.actions.deactivateExperienceManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleChecked = (values: any) => {
    setCheckedValues(values)
  }

  const data = experienceManagerData?.data?.manager
    ? experienceManagerData?.data?.manager?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        branch: item?.branchId,
        branchName: item?.branchName,
        name: item?.name,
        nameNp: item?.nameNp,
        email: item?.email,
        telephone: item?.telephone,
        managerPhone: item?.managerPhone,
        hideInWebsite: item?.hideInWebsite,
        status: item?.status,
      }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({id: value}))
    dispatch(experienceManager?.actions?.deleteExperienceManager(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(experienceManager?.actions.getExperienceManager(params))
  }

  const handleSubmitSort = (data: Array<any>) => {
    let body = {
      experienceManagerId: data?.map((item) => ({id: item?.keyId})),
    }
    dispatch(experienceManager?.actions.sortExperienceManager(body))
  }

  useEffect(() => {
    if (!isEmpty(sortExperienceManagerData) && success) {
      toast.success('Experience Manager sorted successfully')
      dispatch(experienceManager?.actions.sortExperienceManagerReset())
      setParams({...params, limit: 10})
      dispatch(experienceManager?.actions.getExperienceManager(params))
      setOpenSortModal(false)
    }
  }, [sortExperienceManagerData, success])

  const downloadExperienceManagerData = () => {
    if (!isEmpty(checkedValues)) {
      const GET_APPLICATION_FILE = `${API_URL}/experience-manager/selectExport`
      const checkedData = checkedValues.map((d: string) => ({
        id: d,
      }))
      let payload = {
        experienceManagerId: [...checkedData],
      }
      axios(GET_APPLICATION_FILE, {
        data: {...payload},
        method: 'POST',
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `ExperienceManager.csv`)
        document.body.appendChild(link)
        link.click()
        toast.success('Download Success')
      })
    } else {
      const GET_APPLICATION_FILE = `${API_URL}/experience-manager/export`
      axios(GET_APPLICATION_FILE, {
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `ExperienceManager.csv`)
        document.body.appendChild(link)
        link.click()
        toast.success('Download Success')
      })
    }
  }

  const importExperienceManagerData = (event: any) => {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(experienceManager?.actions?.importExperienceManager(formData))
      event.target.value = null
    }
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Experience Manager'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={true}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          sortShow={true}
          data={data}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleRefresh}
          exportButtonName='Export CSV'
          handleExport={downloadExperienceManagerData}
          handleImport={importExperienceManagerData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={loading}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={experienceManagerData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {showDetailsModal && (
            <ViewDetailsModal
              handleClose={() => setShowDetailsModal(false)}
              data={data?.filter((d: any) => d.id === selectedExperienceManagerId)[0]}
            />
          )}

          {open && (
            <AddExperience
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>
        <>
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => modalSubmit()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
    </>
  )
}

export default ExperienceManager
