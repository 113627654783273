import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getTutorialSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getTutorial, params)
    yield put(actions.getTutorialSuccess(response?.data?.data))
    yield put(actions.getTutorialFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getTutorialFinish())
  }
}

function* addTutorialSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addTutorial, body)
    yield put(actions.addTutorialSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addTutorialFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addTutorialFinish())
  }
}

function* updateTutorialSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateTutorial, body, action.payload?.id)
    yield put(actions.updateTutorialSuccess(response.data?.data))
    yield put(actions.updateTutorialFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateTutorialFinish())
  }
}

function* deleteTutorialSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteTutorial, body)
    yield put(actions.deleteTutorialSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteTutorialFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteTutorialFinish())
  }
}

function* singleActivateTutorial(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateTutorial,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_FINISH})
  }
}

function* singleDeactivateTutorial(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateTutorial,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_FINISH})
  }
}

function* sortTutorial(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorial, body)

    yield put({
      type: actionTypes.SORT_TUTORIAL_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_TUTORIAL_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_TUTORIAL_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_TUTORIAL_START, getTutorialSaga)
  yield takeLatest(actionTypes.ADD_TUTORIAL_START, addTutorialSaga)
  yield takeLatest(actionTypes.UPDATE_TUTORIAL_START, updateTutorialSaga)
  yield takeLatest(actionTypes.DELETE_TUTORIAL_START, deleteTutorialSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_TUTORIAL_REQUEST, singleActivateTutorial)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_TUTORIAL_REQUEST, singleDeactivateTutorial)
  yield takeLatest(actionTypes.SORT_TUTORIAL_START, sortTutorial)
}
