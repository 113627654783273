import {useSelector} from 'react-redux'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'

const getBackendSettingValue = (checkData: string) => {
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)
  let response = settings?.data?.setting?.filter((d: any) => d?.name === `${checkData}`)[0]?.value
  return response
}

export default getBackendSettingValue
