export const actionTypes: any = {
  // get sub category
  GET_SPECIFIC_SUB_CATEGORY_START: 'GET_SPECIFIC_SUB_CATEGORY_START',
  GET_SPECIFIC_SUB_CATEGORY_SUCCESS: 'GET_SPECIFIC_SUB_CATEGORY_SUCCESS',
  GET_SPECIFIC_SUB_CATEGORY_FINISH: 'GET_SPECIFIC_SUB_CATEGORY_FINISH',
  // get sub category
  GET_SUB_CATEGORY_START: 'GET_SUB_CATEGORY_START',
  GET_SUB_CATEGORY_SUCCESS: 'GET_SUB_CATEGORY_SUCCESS',
  GET_SUB_CATEGORY_FINISH: 'GET_SUB_CATEGORY_FINISH',

  // ADD sub category
  ADD_SUB_CATEGORY_START: 'ADD_SUB_CATEGORY_START',
  ADD_SUB_CATEGORY_SUCCESS: 'ADD_SUB_CATEGORY_SUCCESS',
  ADD_SUB_CATEGORY_FINISH: 'ADD_SUB_CATEGORY_FINISH',
  ADD_SUB_CATEGORY_RESET: 'ADD_SUB_CATEGORY_RESET',

  // Update sub category
  UPDATE_SUB_CATEGORY_START: 'UPDATE_SUB_CATEGORY_START',
  UPDATE_SUB_CATEGORY_SUCCESS: 'UPDATE_SUB_CATEGORY_SUCCESS',
  UPDATE_SUB_CATEGORY_FINISH: 'UPDATE_SUB_CATEGORY_FINISH',
  UPDATE_SUB_CATEGORY_RESET: 'UPDATE_SUB_CATEGORY_RESET',

  // delete sub category
  DELETE_SUB_CATEGORY_START: 'DELETE_SUB_CATEGORY_START',
  DELETE_SUB_CATEGORY_SUCCESS: 'DELETE_SUB_CATEGORY_SUCCESS',
  DELETE_SUB_CATEGORY_FINISH: 'DELETE_SUB_CATEGORY_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_SUB_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_SUB_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_SUB_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_SUB_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_SUB_CATEGORY_FINISH: 'SINGLE_ACTIVATE_SUB_CATEGORY_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_SUB_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_SUB_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_SUB_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_SUB_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_SUB_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_SUB_CATEGORY_FINISH',

  // sort
  SORT_NEWS_SUB_CATEGORY_START: 'SORT_NEWS_SUB_CATEGORY_START',
  SORT_NEWS_SUB_CATEGORY_SUCCESS: 'SORT_NEWS_SUB_CATEGORY_SUCCESS',
  SORT_NEWS_SUB_CATEGORY_FINISH: 'SORT_NEWS_SUB_CATEGORY_FINISH',
  SORT_NEWS_SUB_CATEGORY_RESET: 'SORT_NEWS_SUB_CATEGORY_RESET',
}
