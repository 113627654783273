export const actionTypes: any = {
  // get FUNCTIONALAREA
  GET_FUNCTIONALAREA_START: 'GET_FUNCTIONALAREA_START',
  GET_FUNCTIONALAREA_SUCCESS: 'GET_FUNCTIONALAREA_SUCCESS',
  GET_FUNCTIONALAREA_FINISH: 'GET_FUNCTIONALAREA_FINISH',

  // ADD FUNCTIONALAREA
  ADD_FUNCTIONALAREA_START: 'ADD_FUNCTIONALAREA_START',
  ADD_FUNCTIONALAREA_SUCCESS: 'ADD_FUNCTIONALAREA_SUCCESS',
  ADD_FUNCTIONALAREA_FINISH: 'ADD_FUNCTIONALAREA_FINISH',
  ADD_FUNCTIONALAREA_RESET: 'ADD_FUNCTIONALAREA_RESET',

  // Update FUNCTIONALAREA
  UPDATE_FUNCTIONALAREA_START: 'UPDATE_FUNCTIONALAREA_START',
  UPDATE_FUNCTIONALAREA_SUCCESS: 'UPDATE_FUNCTIONALAREA_SUCCESS',
  UPDATE_FUNCTIONALAREA_FINISH: 'UPDATE_FUNCTIONALAREA_FINISH',
  UPDATE_FUNCTIONALAREA_RESET: 'UPDATE_FUNCTIONALAREA_RESET',

  // delete FUNCTIONALAREA
  DELETE_FUNCTIONALAREA_START: 'DELETE_FUNCTIONALAREA_START',
  DELETE_FUNCTIONALAREA_SUCCESS: 'DELETE_FUNCTIONALAREA_SUCCESS',
  DELETE_FUNCTIONALAREA_FINISH: 'DELETE_FUNCTIONALAREA_FINISH',

  // single FUNCTIONALAREA
  SINGLE_ACTIVATE_FUNCTIONALAREA_REQUEST: 'SINGLE_ACTIVATE_FUNCTIONALAREA_REQUEST',
  SINGLE_ACTIVATE_FUNCTIONALAREA_SUCCESS: 'SINGLE_ACTIVATE_FUNCTIONALAREA_SUCCESS',
  SINGLE_ACTIVATE_FUNCTIONALAREA_FINISH: 'SINGLE_ACTIVATE_FUNCTIONALAREA_FINISH',

  // single deactive FUNCTIONALAREA
  SINGLE_DEACTIVATE_FUNCTIONALAREA_REQUEST: 'SINGLE_DEACTIVATE_FUNCTIONALAREA_REQUEST',
  SINGLE_DEACTIVATE_FUNCTIONALAREA_SUCCESS: 'SINGLE_DEACTIVATE_FUNCTIONALAREA_SUCCESS',
  SINGLE_DEACTIVATE_FUNCTIONALAREA_FINISH: 'SINGLE_DEACTIVATE_FUNCTIONALAREA_FINISH',

  // Enable Gallery
  ENABLE_FUNCTIONALAREA_REQUEST: 'ENABLE_FUNCTIONALAREA_REQUEST',
  ENABLE_FUNCTIONALAREA_SUCCESS: 'ENABLE_FUNCTIONALAREA_SUCCESS',
  ENABLE_FUNCTIONALAREA_FINISH: 'ENABLE_FUNCTIONALAREA_FINISH',

  // Disable Gallery
  DISABLE_FUNCTIONALAREA_REQUEST: 'DISABLE_FUNCTIONALAREA_REQUEST',
  DISABLE_FUNCTIONALAREA_SUCCESS: 'DISABLE_FUNCTIONALAREA_SUCCESS',
  DISABLE_FUNCTIONALAREA_FINISH: 'DISABLE_FUNCTIONALAREA_FINISH',

  // get GALLERYs
  GET_ALL_FUNCTIONALAREA_START: 'GET_ALL_FUNCTIONALAREA_START',
  GET_ALL_FUNCTIONALAREA_SUCCESS: 'GET_ALL_FUNCTIONALAREA_SUCCESS',
  GET_ALL_FUNCTIONALAREA_FINISH: 'GET_ALL_FUNCTIONALAREA_FINISH',

  // sort
  SORT_FUNCTIONALAREA_START: 'SORT_FUNCTIONALAREA_START',
  SORT_FUNCTIONALAREA_SUCCESS: 'SORT_FUNCTIONALAREA_SUCCESS',
  SORT_FUNCTIONALAREA_FINISH: 'SORT_FUNCTIONALAREA_FINISH',
  SORT_FUNCTIONALAREA_RESET: 'SORT_FUNCTIONALAREA_RESET',
}
