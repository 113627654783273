export const actionTypes: any = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FINISH: 'LOGIN_FINISH',

  FIRST_LOGIN_REQUEST: 'FIRST_LOGIN_REQUEST',
  FIRST_LOGIN_SUCCESS: 'FIRST_LOGIN_SUCCESS',
  FIRST_LOGIN_FINISH: 'FIRST_LOGIN_FINISH',

  SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FINISH: 'SET_PASSWORD_FINISH',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FINISH: 'RESET_PASSWORD_FINISH',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FINISH: 'FORGOT_PASSWORD_FINISH',

  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FINISH: 'RESEND_OTP_FINISH',

  OTP_REQUEST: 'OTP_REQUEST',
  OTP_SUCCESS: 'OTP_SUCCESS',
  OTP_FINISH: 'OTP_FINISH',

  Logout: '[Logout] Action',

  USER_REQUESTED: 'USER_REQUESTED',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',

  GET_DB_MODULE_REQUEST: 'GET_DB_MODULE_REQUEST',
  GET_DB_MODULE_SUCCESS: 'GET_DB_MODULE_SUCCESS',
  GET_DB_MODULE_FINISH: 'GET_DB_MODULE_FINISH',

  //Get Guest token
  GET_GUEST_TOKEN_START: 'GET_GUEST_TOKEN_START',
  GET_GUEST_TOKEN_SUCCESS: 'GET_GUEST_TOKEN_SUCCESS',
  GET_GUEST_TOKEN_FINISH: 'GET_GUEST_TOKEN_FINISH',
}
