export const actionTypes: any = {
  // get Base Rate
  GET_BASE_RATE_START: 'GET_BASE_RATE_START',
  GET_BASE_RATE_SUCCESS: 'GET_BASE_RATE_SUCCESS',
  GET_BASE_RATE_FINISH: 'GET_BASE_RATE_FINISH',

  // add Base Rate
  ADD_BASE_RATE_START: 'ADD_BASE_RATE_START',
  ADD_BASE_RATE_SUCCESS: 'ADD_BASE_RATE_SUCCESS',
  ADD_BASE_RATE_FINISH: 'ADD_BASE_RATE_FINISH',
  RESET_BASE_RATE_TYPE: 'RESET_BASE_RATE_TYPE',

  // update Base Rate
  UPDATE_BASE_RATE_START: 'UPDATE_BASE_RATE_START',
  UPDATE_BASE_RATE_SUCCESS: 'UPDATE_BASE_RATE_SUCCESS',
  UPDATE_BASE_RATE_FINISH: 'UPDATE_INTEREST_RATE_FINISH',

  // delete Base Rate
  DELETE_BASE_RATE_START: 'DELETE_BASE_RATE_START',
  DELETE_BASE_RATE_SUCCESS: 'DELETE_BASE_RATE_SUCCESS',
  DELETE_BASE_RATE_FINISH: 'DELETE_BASE_RATE_FINISH',

  // Enable Base Rate
  ENABLE_BASE_RATE_REQUEST: 'ENABLE_BASE_RATE_REQUEST',
  ENABLE_BASE_RATE_SUCCESS: 'ENABLE_BASE_RATE_SUCCESS',
  ENABLE_BASE_RATE_FINISH: 'ENABLE_BASE_RATE_FINISH',

  // Disable Base Rate
  DISABLE_BASE_RATE_REQUEST: 'DISABLE_BASE_RATE_REQUEST',
  DISABLE_BASE_RATE_SUCCESS: 'DISABLE_BASE_RATE_SUCCESS',
  DISABLE_BASE_RATE_FINISH: 'DISABLE_BASE_RATE_FINISH',

  // Enable Base Rate
  SINGLE_ENABLE_BASE_RATE_REQUEST: 'SINGLE_ENABLE_BASE_RATE_REQUEST',
  SINGLE_ENABLE_BASE_RATE_SUCCESS: 'SINGLE_ENABLE_BASE_RATE_SUCCESS',
  SINGLE_ENABLE_BASE_RATE_FINISH: 'SINGLE_ENABLE_BASE_RATE_FINISH',

  // Disable Base Rate
  SINGLE_DISABLE_BASE_RATE_REQUEST: 'SINGLE_DISABLE_BASE_RATE_REQUEST',
  SINGLE_DISABLE_BASE_RATE_SUCCESS: 'SINGLE_DISABLE_BASE_RATE_SUCCESS',
  SINGLE_DISABLE_BASE_RATE_FINISH: 'SINGLE_DISABLE_BASE_RATE_FINISH',

  // get Base rate loan File csv xlsx
  GET_BASE_RATE_FILE_START: 'GET_BASE_RATE_FILE_START',
  GET_BASE_RATE_FILE_SUCCESS: 'GET_BASE_RATE_FILE_SUCCESS',
  GET_BASE_RATE_FILE_FINISH: 'GET_BASE_RATE_FILE_FINISH',

  // get interest rate  file csv xlsx
  GET_INTEREST_SPREAD_FILE_START: 'GET_INTEREST_SPREAD_FILE_START',
  GET_INTEREST_SPREAD_FILE_SUCCESS: 'GET_INTEREST_SPREAD_FILE_SUCCESS',
  GET_INTEREST_SPREAD_FILE_FINISH: 'GET_INTEREST_SPREAD_FILE_FINISH',
}
