import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getDisputeCardTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getDisputeType, params)
    yield put(actions.getDisputeTypeSuccess(response?.data?.data))
    yield put(actions.getDisputeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getDisputeTypeFinish())
  }
}

function* getDisputeCardSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getDisputeCard, params)
    yield put(actions.getDisputeCardSuccess(response?.data?.data))
    yield put(actions.getDisputeCardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getDisputeCardFinish())
  }
}

function* addDisputeCardSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addDisputeCard, body)
    yield put(actions.addDisputeCardSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addDisputeCardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addDisputeCardFinish())
  }
}

function* updateDisputeCardSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateDisputeCard, body, action.payload?.id)
    yield put(actions.updateDisputeCardSuccess(response.data?.data))
    yield put(actions.updateDisputeCardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateDisputeCardFinish())
  }
}

function* deleteDisputeCardSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteDisputeCard, body)
    yield put(actions.deleteDisputeCardSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteDisputeCardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteDisputeCardFinish())
  }
}

function* singleActivateDisputeCard(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateDisputeCard,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_FINISH})
  }
}

function* singleDeactivateDisputeCard(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateDisputeCard,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_FINISH})
  }
}

function* sortDisputeCard(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortDisputeCard, body)

    yield put({
      type: actionTypes.SORT_DISPUTE_CARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_DISPUTE_CARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_DISPUTE_CARD_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_DISPUTE_TYPE_START, getDisputeCardTypeSaga)
  yield takeLatest(actionTypes.GET_DISPUTE_CARD_START, getDisputeCardSaga)
  yield takeLatest(actionTypes.ADD_DISPUTE_CARD_START, addDisputeCardSaga)
  yield takeLatest(actionTypes.UPDATE_DISPUTE_CARD_START, updateDisputeCardSaga)
  yield takeLatest(actionTypes.DELETE_DISPUTE_CARD_START, deleteDisputeCardSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_REQUEST, singleActivateDisputeCard)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_REQUEST, singleDeactivateDisputeCard)
  yield takeLatest(actionTypes.SORT_DISPUTE_CARD_START, sortDisputeCard)
}
