import { ErrorMessage, Form, Formik } from "formik";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { StateParamsModel } from "src/app/modules/common/Model";
import * as Yup from "yup";
import * as propertyTypeRedux from "../index";
import Modal from "rsuite/Modal";
import FormRadio from "src/cms/helpers/components/forms/FormRadio";
import FormTextBox from "src/cms/helpers/components/forms/FormTextBox";

type Props = {
  open: boolean;
  params?: StateParamsModel;
  handleClose: () => void;
  actionType: string;
  editSelectedData?: any;
};

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  status: Yup.string().required("Status is required"),
});

const AddPropertyType = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state: any) => state.propertyType);

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  useEffect(() => {
    if (success) {
      dispatch(propertyTypeRedux?.actions.getPropertyType(params));
      isEmpty(editSelectedData)
        ? toast.success("Property Type added successfully")
        : toast.success("Property Type edited successfully");
      dispatch(propertyTypeRedux?.actions?.resetPropertyType());
      handleClose();
    }
  }, [success]);

  return (
    <div className="modal-container" data-cy="search-category-container">
      <Modal
        open={open}
        onClose={handleClose}
        className="w-75"
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
        data-cy="modal"
      >
        <Modal.Header>
          <Modal.Title data-cy="modal-title">
            {actionType} Property Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: "",
                status: "",
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  status: values?.status === "Active" ? true : false,
                };

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    propertyTypeRedux.actions.updatePropertyType(
                      formData,
                      editSelectedData?.id
                    )
                  );
                } else {
                  dispatch(propertyTypeRedux.actions.addPropertyType(formData));
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                errors,
                values,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ["id", "name", "name_np"];
                    fields.forEach((field) =>
                      setFieldValue(field, editSelectedData[field], false)
                    );
                    setFieldValue(
                      "status",
                      editSelectedData?.status === true ? "Active" : "Inactive",
                      false
                    );
                  }
                }, []);

                return (
                  <Form>
                    <div
                      className="card-body border-top pt-5"
                      data-cy="search-category-body"
                    >
                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <FormTextBox
                            type="text"
                            labelClassName="col-md-12"
                            containerClassName="col-md-12"
                            placeholder="Enter Name"
                            label="Name (EN)"
                            name="name"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy="search-category-name-text-fields"
                          />
                        </div>

                        <div className="col-md-6 col-xs-12">
                          <FormTextBox
                            type="text"
                            labelClassName="col-md-12"
                            containerClassName="col-md-12"
                            placeholder="Name (NP)"
                            label="Name (NP)"
                            name="name_np"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy="search-category-name-np-text-field"
                          />
                        </div>

                        <div className="col-md-6 col-xs-12">
                          <label className="form-label fw-bolder text-dark fs-6 required">
                            Status
                          </label>

                          <div className="d-flex ms-5">
                            {statusOptions?.map(
                              (status: { label: string; value: string }) => (
                                <FormRadio
                                  containerClassName=""
                                  label="Select Status"
                                  name="status"
                                  type="radio"
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                  data-cy="search-category-status-radio"
                                />
                              )
                            )}
                          </div>
                          <div className="fv-plugins-message-container text-danger fw-bolder small text-end mt-1">
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="field-error-message"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end px-9 ">
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary btn-sm ms-3"
                        data-cy="modal-submit"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-secondary btn-sm ms-3"
                        data-cy="modal-cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AddPropertyType;
