import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getInternshipListsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getInternshipLists, params)
    yield put(actions.getInternshipListsSuccess(response?.data?.data))
    yield put(actions.getInternshipListsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getInternshipListsFinish())
  }
}

//Get file
function* getInternshipApplicationFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getInternshipApplicationFile, fileName)
    const data: any = response?.data
    yield put(actions.getInternshipApplicationFileSuccess(data))
    yield put(actions.getInternshipApplicationFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getInternshipApplicationFileError())
  }
}

function* updateInternshipListsSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateInternshipLists,
      body,
      action.payload?.id
    )
    yield put(actions.updateInternshipListsSuccess(response.data?.data))
    yield put(actions.updateInternshipListsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateInternshipListsFinish())
  }
}

function* deleteInternshipListsSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteInternshipLists, body)
    yield put(actions.deleteInternshipListsSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteInternshipListsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteInternshipListsFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_INTERNSHIP_LISTS_START, getInternshipListsSaga)
  yield takeLatest(actionTypes.UPDATE_INTERNSHIP_LISTS_START, updateInternshipListsSaga)
  yield takeLatest(actionTypes.GET_INTERNSHIP_LISTS_FILE_START, getInternshipApplicationFileSaga)
  yield takeLatest(actionTypes.DELETE_INTERNSHIP_LISTS_START, deleteInternshipListsSaga)
}
