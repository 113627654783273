import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getDisputeOptionSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getDisputeOption, params)
    yield put(actions.getDisputeOptionSuccess(response?.data?.data))
    yield put(actions.getDisputeOptionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getDisputeOptionFinish())
  }
}

function* addDisputeOptionSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addDisputeOption, body)
    yield put(actions.addDisputeOptionSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addDisputeOptionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addDisputeOptionFinish())
  }
}

function* updateDisputeOptionSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateDisputeOption,
      body,
      action.payload?.id
    )
    yield put(actions.updateDisputeOptionSuccess(response.data?.data))
    yield put(actions.updateDisputeOptionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateDisputeOptionFinish())
  }
}

function* deleteDisputeOptionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteDisputeOption, body)
    yield put(actions.deleteDisputeOptionSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteDisputeOptionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteDisputeOptionFinish())
  }
}

function* singleActivateDisputeOption(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateDisputeOption,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_FINISH})
  }
}

function* singleDeactivateDisputeOption(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateDisputeOption,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_FINISH})
  }
}

function* sortDisputeOption(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortDisputeOption, body)

    yield put({
      type: actionTypes.SORT_DISPUTE_OPTION_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_DISPUTE_OPTION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_DISPUTE_OPTION_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_DISPUTE_OPTION_START, getDisputeOptionSaga)
  yield takeLatest(actionTypes.ADD_DISPUTE_OPTION_START, addDisputeOptionSaga)
  yield takeLatest(actionTypes.UPDATE_DISPUTE_OPTION_START, updateDisputeOptionSaga)
  yield takeLatest(actionTypes.DELETE_DISPUTE_OPTION_START, deleteDisputeOptionSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST, singleActivateDisputeOption)
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST,
    singleDeactivateDisputeOption
  )
  yield takeLatest(actionTypes.SORT_DISPUTE_OPTION_START, sortDisputeOption)
}
