export const actionTypes: any = {
  // get
  GET_ALL_PAYOUT_LOCATION_START: 'GET_ALL_PAYOUT_LOCATION_START',
  GET_ALL_PAYOUT_LOCATION_SUCCESS: 'GET_ALL_PAYOUT_LOCATION_SUCCESS',
  GET_ALL_PAYOUT_LOCATION_FINISH: 'GET_ALL_PAYOUT_LOCATION_FINISH',

  // extended hours
  GET_PAYOUT_LOCATION_START: 'GET_PAYOUT_LOCATION_START',
  GET_PAYOUT_LOCATION_SUCCESS: 'GET_PAYOUT_LOCATION_SUCCESS',
  GET_PAYOUT_LOCATION_FINISH: 'GET_PAYOUT_LOCATION_FINISH',

  // add PAYOUT LOCATION
  ADD_PAYOUT_LOCATION_START: 'ADD_PAYOUT_LOCATION_START',
  ADD_PAYOUT_LOCATION_SUCCESS: 'ADD_PAYOUT_LOCATION_SUCCESS',
  ADD_PAYOUT_LOCATION_FINISH: 'ADD_PAYOUT_LOCATION_FINISH',
  RESET_PAYOUT_LOCATION: 'RESET_PAYOUT_LOCATION',

  // update PAYOUT_LOCATION
  UPDATE_PAYOUT_LOCATION_START: 'UPDATE_PAYOUT_LOCATION_START',
  UPDATE_PAYOUT_LOCATION_SUCCESS: 'UPDATE_PAYOUT_LOCATION_SUCCESS',
  UPDATE_PAYOUT_LOCATION_FINISH: 'UPDATE_PAYOUT_LOCATION_FINISH',

  // delete PAYOUT_LOCATION
  DELETE_PAYOUT_LOCATION_START: 'DELETE_PAYOUT_LOCATION_START',
  DELETE_PAYOUT_LOCATION_SUCCESS: 'DELETE_PAYOUT_LOCATION_SUCCESS',
  DELETE_PAYOUT_LOCATION_FINISH: 'DELETE_PAYOUT_LOCATION_FINISH',

  // Enable PAYOUT_LOCATION
  ENABLE_PAYOUT_LOCATION_REQUEST: 'ENABLE_PAYOUT_LOCATION_REQUEST',
  ENABLE_PAYOUT_LOCATION_SUCCESS: 'ENABLE_PAYOUT_LOCATION_SUCCESS',
  ENABLE_PAYOUT_LOCATION_FINISH: 'ENABLE_PAYOUT_LOCATION_FINISH',

  // Disable PAYOUT_LOCATION
  DISABLE_PAYOUT_LOCATION_REQUEST: 'DISABLE_PAYOUT_LOCATION_REQUEST',
  DISABLE_PAYOUT_LOCATION_SUCCESS: 'DISABLE_PAYOUT_LOCATION_SUCCESS',
  DISABLE_PAYOUT_LOCATION_FINISH: 'DISABLE_PAYOUT_LOCATION_FINISH',

  // Enable PAYOUT_LOCATION
  SINGLE_ENABLE_PAYOUT_LOCATION_REQUEST: 'SINGLE_ENABLE_PAYOUT_LOCATION_REQUEST',
  SINGLE_ENABLE_PAYOUT_LOCATION_SUCCESS: 'SINGLE_ENABLE_PAYOUT_LOCATION_SUCCESS',
  SINGLE_ENABLE_PAYOUT_LOCATION_FINISH: 'SINGLE_ENABLE_PAYOUT_LOCATION_FINISH',

  // Disable PAYOUT_LOCATION
  SINGLE_DISABLE_PAYOUT_LOCATION_REQUEST: 'SINGLE_DISABLE_PAYOUT_LOCATION_REQUEST',
  SINGLE_DISABLE_PAYOUT_LOCATION_SUCCESS: 'SINGLE_DISABLE_PAYOUT_LOCATION_SUCCESS',
  SINGLE_DISABLE_PAYOUT_LOCATION_FINISH: 'SINGLE_DISABLE_PAYOUT_LOCATION_FINISH',

  // sort
  SORT_PAYOUT_LOCATION_START: 'SORT_PAYOUT_LOCATION_START',
  SORT_PAYOUT_LOCATION_SUCCESS: 'SORT_PAYOUT_LOCATION_SUCCESS',
  SORT_PAYOUT_LOCATION_FINISH: 'SORT_PAYOUT_LOCATION_FINISH',
  SORT_PAYOUT_LOCATION_RESET: 'SORT_PAYOUT_LOCATION_RESET',

  // Import payout location
  IMPORT_PAYOUT_LOCATION_START: 'IMPORT_PAYOUT_LOCATION_START',
  IMPORT_PAYOUT_LOCATION_SUCCESS: 'IMPORT_PAYOUT_LOCATION_SUCCESS',
  IMPORT_PAYOUT_LOCATION_FINISH: 'IMPORT_PAYOUT_LOCATION_FINISH',

  // get File csv xlsx
  EXPORT_PAYOUT_LOCATION_FILE_START: 'EXPORT_PAYOUT_LOCATION_FILE_START',
  EXPORT_PAYOUT_LOCATION_FILE_SUCCESS: 'EXPORT_PAYOUT_LOCATION_FILE_SUCCESS',
  EXPORT_PAYOUT_LOCATION_FILE_FINISH: 'EXPORT_PAYOUT_LOCATION_FILE_FINISH',

  //get CSV template
  EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_START: 'EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_START',
  EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_SUCCESS: 'EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_SUCCESS',
  EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_FINISH: 'EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_FINISH',
}
