export const actionTypes: any = {
  // get
  GET_ENQUIRY_START: 'GET_ENQUIRY_START',
  GET_ENQUIRY_SUCCESS: 'GET_ENQUIRY_SUCCESS',
  GET_ENQUIRY_FINISH: 'GET_ENQUIRY_FINISH',

  // delete SUBSCRIBE
  DELETE_ENQUIRY_START: 'DELETE_ENQUIRY_START',
  DELETE_ENQUIRY_SUCCESS: 'DELETE_ENQUIRY_SUCCESS',
  DELETE_ENQUIRY_FINISH: 'DELETE_ENQUIRY_FINISH',

  // get File csv xlsx
  EXPORT_ENQUIRY_FILE_START: 'EXPORT_ENQUIRY_FILE_START',
  EXPORT_ENQUIRY_FILE_SUCCESS: 'EXPORT_ENQUIRY_FILE_SUCCESS',
  EXPORT_ENQUIRY_FILE_FINISH: 'EXPORT_ENQUIRY_FILE_FINISH',
}
