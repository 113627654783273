import {FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent} from 'react'
import TreePicker from 'rsuite/TreePicker'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {FormOptionModal} from '../Model'
import {MenuModal} from '../Model/MenuModal'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<any>
  touched: any
  editSelectedData?: MenuModal
  iconTypeOptions?: FormOptionModal[]
  linkTypeOptions?: FormOptionModal[]
  menuStatusOptions?: FormOptionModal[]
  menuTypeOptions?: FormOptionModal[]
  menuOptions?: any
  topMenuOptions?: FormOptionModal[]
  serviceDataOptions?: FormOptionModal[]
  productDataOptions?: FormOptionModal[]
  programDataOptions?: FormOptionModal[]
  contentCategoryOptions?: FormOptionModal[]
  visibilityStatusOptions?: FormOptionModal[]
  contentDataOptions?: FormOptionModal[]
  setSelectedContentCat?: any
  values: {[key: string]: string} | any
  setFieldValue?: any
  breadcrumbOptions: FormOptionModal[]
  requiredTopMenu?: boolean
}

export function BasicMenuInfo({
  handleChange,
  errors,
  touched,
  iconTypeOptions,
  topMenuOptions,
  linkTypeOptions,
  menuStatusOptions,
  menuOptions,
  menuTypeOptions,
  editSelectedData,
  contentDataOptions,
  setFieldValue,
  productDataOptions,
  contentCategoryOptions,
  serviceDataOptions,
  setSelectedContentCat,
  values,
  visibilityStatusOptions,
  programDataOptions,
  breadcrumbOptions,
  requiredTopMenu,
}: Props) {
  const selectedIconTypeId = iconTypeOptions?.find(
    (item: FormOptionModal) => item.value === values.iconType
  )
  const selectedLinkTypeId = linkTypeOptions?.find(
    (item: FormOptionModal) => item.value === values.menuLinkType
  )
  const multiLanguage = checkMultiLanguage()

  const hideTopMenu = checkBackendSetting('hideTopMenuDropdown')
  const showBreadCrumb = checkBackendSetting('showBreadCrumb')
  const showMenuShortDescription = checkBackendSetting('showMenuShortDescription')
  const showMenuDescription = checkBackendSetting('showMenuDescription')

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              placeholder={multiLanguage ? 'Menu Title' : 'Menu Title (EN)'}
              name='name'
              label={multiLanguage ? 'Menu Title' : 'Menu Title (EN)'}
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              onChange={(e: any) => {
                handleChange(e)
                !isEmpty(editSelectedData)
                  ? editSelectedData?.slug
                  : setFieldValue('slug', e.target.value.replace(/\s/g, '-').toLowerCase())
              }}
              errors={errors}
              touched={touched}
              required={true}
            />
          </div>
          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder='Menu Title (NP)'
                name='nameNp'
                label='Menu Title (NP)'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          )}
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Enter Slug'
              label='Slug'
              name='slug'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <label className='form-label fw-bolder text-dark fs-6'>Parent Menu</label>
            <TreePicker
              size='lg'
              height={50}
              name='parentId'
              className='col-md-12'
              searchable={false}
              defaultExpandAll
              data={menuOptions}
              onSelect={(e: any) => setFieldValue('parentId', e.value)}
              defaultValue={editSelectedData?.parentId}
              onClean={(e: any) => setFieldValue('parentId', e.value)}
              // errors={errors}
            />
          </div>
          {showMenuShortDescription ? (
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <FormTinyMce
                  containerClassName='col-md-12'
                  name='shortDescription'
                  label={'Short Description (EN)'}
                  initialValue={
                    !isEmpty(editSelectedData) ? editSelectedData?.shortDescription : ''
                  }
                  handleChange={handleChange}
                />
              </div>

              <div className='col-md-6 col-sm-6 col-xs-12'>
                <FormTinyMce
                  containerClassName='col-md-12'
                  name='shortDescriptionNp'
                  label='Short Description (NP)'
                  initialValue={
                    !isEmpty(editSelectedData) ? editSelectedData?.shortDescriptionNp : ''
                  }
                  handleChange={handleChange}
                />
              </div>
            </div>
          ) : null}
          {showMenuDescription ? (
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <FormTinyMce
                  containerClassName='col-md-12'
                  name='description'
                  label={'Description (EN)'}
                  initialValue={!isEmpty(editSelectedData) ? editSelectedData?.description : ''}
                  handleChange={handleChange}
                />
              </div>

              <div className='col-md-6 col-sm-6 col-xs-12'>
                <FormTinyMce
                  containerClassName='col-md-12'
                  name='descriptionNp'
                  label='Description (NP)'
                  initialValue={!isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''}
                  handleChange={handleChange}
                />
              </div>
            </div>
          ) : null}

          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Select Icon Type'
                label='Icon Type'
                name='iconType'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={iconTypeOptions}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}
          {selectedIconTypeId?.systemName === 'image' ? (
            <div className='col-md-6 col-xs-12'>
              <FormInputMediaManager
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                label='Menu Image'
                name='menuImage'
                setFieldValue={setFieldValue}
                value={values?.menuImage}
              />

              {!isEmpty(values?.menuImage) ? (
                <>
                  <li className='listing'>
                    <div className='thumbImageBlock'>
                      <button
                        type='button'
                        title='Remove'
                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                        onClick={() => {
                          setFieldValue('menuImage', '')
                        }}
                        data-cy='modal-thumbnail-remove'
                      >
                        Delete
                      </button>

                      <img
                        className='thumbImage w-100 h-100'
                        src={`${imageBaseUrl}/${values?.menuImage}`}
                        alt=''
                      />
                    </div>
                  </li>
                </>
              ) : null}
            </div>
          ) : selectedIconTypeId?.systemName === 'class' ? (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder='Fa-icons Eg: fa-5'
                name='menuIcon'
                label='Icon Class'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          ) : null}
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Select Menu Link Type'
              label='Link Type'
              name='menuLinkType'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={linkTypeOptions}
              values={values}
              setFieldValue={setFieldValue}
              required
            />
          </div>
          {selectedLinkTypeId?.systemName === 'product' ? (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Product'
                name='productId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={productDataOptions}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          ) : selectedLinkTypeId?.systemName === 'content' ? (
            ''
          ) : selectedLinkTypeId?.systemName === 'url' ? (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder='URL'
                name='url'
                label='URL'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          ) : selectedLinkTypeId?.systemName === 'file' ? (
            <div className='col-md-6 col-xs-12'>
              <FormInputMediaManager
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                label='File'
                name='file'
                setFieldValue={setFieldValue}
                value={values?.file}
              />
            </div>
          ) : selectedLinkTypeId?.systemName === 'services' ? (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Service'
                name='serviceId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                options={serviceDataOptions}
              />
            </div>
          ) : selectedLinkTypeId?.systemName === 'program' ? (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Program'
                name='programId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={programDataOptions}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          ) : null}
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Select Menu Type'
              label='Menu Type'
              name='menuType'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={menuTypeOptions}
              values={values}
              setFieldValue={setFieldValue}
              required
            />
          </div>
          {!hideTopMenu && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Select Top Menu'
                label='Top Menu'
                name='topMenuId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                multiple={true}
                options={topMenuOptions}
                values={values}
                setFieldValue={setFieldValue}
                required={requiredTopMenu}
              />
            </div>
          )}
          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormInputMediaManager
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                label='Featured Image'
                name='featuredImage'
                setFieldValue={setFieldValue}
                value={values?.featuredImage}
              />

              {!isEmpty(values?.featuredImage) ? (
                <>
                  <li className='listing'>
                    <div className='thumbImageBlock'>
                      <button
                        type='button'
                        title='Remove'
                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                        onClick={() => {
                          setFieldValue('featuredImage', '')
                        }}
                        data-cy='modal-thumbnail-remover'
                      >
                        Delete
                      </button>

                      <img
                        className='thumbImage w-100 h-100'
                        src={`${imageBaseUrl}/${values?.featuredImage}`}
                        alt=''
                      />
                    </div>
                  </li>
                </>
              ) : null}
            </div>
          )}
          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder={multiLanguage ? 'Featured Image Text' : 'Featured Image Text (EN)'}
                name='featuredImageText'
                label={multiLanguage ? 'Featured Image Text' : 'Featured Image Text (EN)'}
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          )}
          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder='Featured Image Text (NP)'
                name='featuredImageTextNp'
                label='Featured Image Text (NP)'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          )}
          {multiLanguage ? null : (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                type='text'
                placeholder='Featured Image URL'
                name='featuredImageUrl'
                label='Featured Image URL'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          )}
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Hide in Website'
              name='hideInWebsite'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={visibilityStatusOptions}
              values={values}
              required
              setFieldValue={setFieldValue}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Select Status'
              label='Status'
              name='status'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={menuStatusOptions}
              values={values}
              required
              setFieldValue={setFieldValue}
            />
          </div>
          {showBreadCrumb && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show BreadCrumb'
                name='show_breadcrumb_id'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={breadcrumbOptions}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}
          <div className='col-md-6 col-xs-12 mt-5 pt-5'>
            <div>
              <FormCheckbox
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                name='allowInRoute'
                touched={touched}
                errors={errors}
                onChange={handleChange}
                checkBoxText='Allow in Routes'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
