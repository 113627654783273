import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getLoanPurposeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getLoanPurpose, params)
    yield put(actions.getLoanPurposeSuccess(response?.data?.data))
    yield put(actions.getLoanPurposeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getLoanPurposeFinish())
  }
}

function* addLoanPurposeSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addLoanPurpose, body)
    yield put(actions.addLoanPurposeSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addLoanPurposeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addLoanPurposeFinish())
  }
}

function* updateLoanPurposeSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateLoanPurpose, body, action.payload?.id)
    yield put(actions.updateLoanPurposeSuccess(response.data?.data))
    yield put(actions.updateLoanPurposeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateLoanPurposeFinish())
  }
}

function* deleteLoanPurposeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteLoanPurpose, body)
    yield put(actions.deleteLoanPurposeSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteLoanPurposeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteLoanPurposeFinish())
  }
}

function* singleActivateLoanPurpose(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateLoanPurpose,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_FINISH})
  }
}

function* singleDeactivateLoanPurpose(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateLoanPurpose,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_FINISH})
  }
}

function* sortLoanPurpose(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortLoanPurpose, body)

    yield put({
      type: actionTypes.SORT_LOAN_PURPOSE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_LOAN_PURPOSE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_LOAN_PURPOSE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_LOAN_PURPOSE_START, getLoanPurposeSaga)
  yield takeLatest(actionTypes.ADD_LOAN_PURPOSE_START, addLoanPurposeSaga)
  yield takeLatest(actionTypes.UPDATE_LOAN_PURPOSE_START, updateLoanPurposeSaga)
  yield takeLatest(actionTypes.DELETE_LOAN_PURPOSE_START, deleteLoanPurposeSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_REQUEST, singleActivateLoanPurpose)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_REQUEST, singleDeactivateLoanPurpose)
  yield takeLatest(actionTypes.SORT_LOAN_PURPOSE_START, sortLoanPurpose)
}
