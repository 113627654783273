import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get ApplyMembership saga
function* getApplyMembershipSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getApplyMembership, params)
    const data: any = response?.data
    yield put(actions.getApplyMembershipSuccess(data))
  } catch (error: any) {
    yield put(actions.getApplyMembershipError(error))
  }
}

//Create ApplyMembership
function* createApplyMembershipSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addApplyMembership, body)
    yield put({type: actionTypes.ADD_APPLY_MEMBERSHIP_SUCCESS, payload: response.data})
    yield delay(100)
    yield put({type: actionTypes.ADD_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_APPLY_MEMBERSHIP_FINISH})
  }
}

//Update ApplyMembership
function* updateApplyMembership(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateApplyMembership,
      body,
      action.payload?.id
    )
    yield put({type: actionTypes.UPDATE_APPLY_MEMBERSHIP_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_APPLY_MEMBERSHIP_FINISH})
  }
}

//Activate Deactivate ApplyMembership Saga
function* activateApplyMembershipSaga(action: ActionModel) {
  try {
    const selectedApplyMemberships: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.activateApplyMembership,
      selectedApplyMemberships
    )
    yield put({type: actionTypes.ACTIVATE_APPLY_MEMBERSHIP_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.ACTIVATE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_APPLY_MEMBERSHIP_FINISH})
  }
}

function* singleActivateApplyMembershipSaga(action: ActionModel) {
  try {
    const selectedApplyMembership: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleActivateApplyMembership,
      selectedApplyMembership
    )

    yield put({type: actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_FINISH})
  }
}

function* deactivateApplyMembershipSaga(action: ActionModel) {
  try {
    const selectedApplyMembership: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.deactivateApplyMembership,
      selectedApplyMembership
    )

    yield put({type: actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_FINISH})
  }
}

function* singleDeactivateApplyMembershipSaga(action: ActionModel) {
  try {
    const selectedApplyMembership: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDeactivateApplyMembership,
      selectedApplyMembership
    )
    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS,
      payload: response?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_FINISH})
  }
}

function* deleteApplyMembershipSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteApplyMembership, body)
    yield put({type: actionTypes.DELETE_APPLY_MEMBERSHIP_SUCCESS})
    yield delay(0.1)
    yield put({type: actionTypes.DELETE_APPLY_MEMBERSHIP_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_APPLY_MEMBERSHIP_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_APPLY_MEMBERSHIP_START, getApplyMembershipSaga)
  yield takeLatest(actionTypes.ADD_APPLY_MEMBERSHIP_REQUEST, createApplyMembershipSaga)
  yield takeLatest(actionTypes.UPDATE_APPLY_MEMBERSHIP_START, updateApplyMembership)
  yield takeLatest(actionTypes.DELETE_APPLY_MEMBERSHIP_START, deleteApplyMembershipSaga)
  yield takeLatest(actionTypes.ACTIVATE_APPLY_MEMBERSHIP_REQUEST, activateApplyMembershipSaga)
  yield takeLatest(actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_REQUEST, deactivateApplyMembershipSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    singleActivateApplyMembershipSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    singleDeactivateApplyMembershipSaga
  )
}
