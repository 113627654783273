import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get Review saga
function* getReviewSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getReviewAndRating, params)
    const data: any = response?.data
    yield put(actions.getReviewSuccess(data))
  } catch (error: any) {
    yield put(actions.getReviewError(error))
  }
}

//Get Review file
function* exportReviewFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(service.exportReviewFile, fileName, params)
    const responseData: any = response?.data
    yield put(actions.exportReviewFileSuccess(responseData))
    yield delay(100)
    yield put(actions.exportReviewFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportReviewFileError())
  }
}
export function* saga() {
  yield takeLatest(actionTypes.GET_REVIEW_RATING_START, getReviewSaga)
  yield takeLatest(actionTypes.EXPORT_REVIEW_FILE_START, exportReviewFileSaga)
}
