export const actionTypes: any = {
  GET_ALL_CARD_MASTER_START: 'GET_ALL_CARD_MASTER_START',
  GET_ALL_CARD_MASTER_SUCCESS: 'GET_ALL_CARD_MASTER_SUCCESS',
  GET_ALL_CARD_MASTER_FINISH: 'GET_ALL_CARD_MASTER_FINISH',

  GET_SPECIFIC_CARD_MASTER_START: 'GET_SPECIFIC_CARD_MASTER_START',
  GET_SPECIFIC_CARD_MASTER_SUCCESS: 'GET_SPECIFIC_CARD_MASTER_SUCCESS',
  GET_SPECIFIC_CARD_MASTER_FINISH: 'GET_SPECIFIC_CARD_MASTER_FINISH',

  GET_CARD_MASTER_START: 'GET_CARD_MASTER_START',
  GET_CARD_MASTER_SUCCESS: 'GET_CARD_MASTER_SUCCESS',
  GET_CARD_MASTER_FINISH: 'GET_CARD_MASTER_FINISH',

  ADD_CARD_MASTER_START: 'ADD_CARD_MASTER_START',
  ADD_CARD_MASTER_SUCCESS: 'ADD_CARD_MASTER_SUCCESS',
  ADD_CARD_MASTER_FINISH: 'ADD_CARD_MASTER_FINISH',
  ADD_CARD_MASTER_RESET: 'ADD_CARD_MASTER_RESET',

  UPDATE_CARD_MASTER_START: 'UPDATE_CARD_MASTER_START',
  UPDATE_CARD_MASTER_SUCCESS: 'UPDATE_CARD_MASTER_SUCCESS',
  UPDATE_CARD_MASTER_FINISH: 'UPDATE_CARD_MASTER_FINISH',
  UPDATE_CARD_MASTER_RESET: 'UPDATE_CARD_MASTER_RESET',

  DELETE_CARD_MASTER_START: 'DELETE_CARD_MASTER_START',
  DELETE_CARD_MASTER_SUCCESS: 'DELETE_CARD_MASTER_SUCCESS',
  DELETE_CARD_MASTER_FINISH: 'DELETE_CARD_MASTER_FINISH',

  SINGLE_ACTIVATE_CARD_MASTER_REQUEST: 'SINGLE_ACTIVATE_CARD_MASTER_REQUEST',
  SINGLE_ACTIVATE_CARD_MASTER_SUCCESS: 'SINGLE_ACTIVATE_CARD_MASTER_SUCCESS',
  SINGLE_ACTIVATE_CARD_MASTER_FINISH: 'SINGLE_ACTIVATE_CARD_MASTER_FINISH',

  SINGLE_DEACTIVATE_CARD_MASTER_REQUEST: 'SINGLE_DEACTIVATE_CARD_MASTER_REQUEST',
  SINGLE_DEACTIVATE_CARD_MASTER_SUCCESS: 'SINGLE_DEACTIVATE_CARD_MASTER_SUCCESS',
  SINGLE_DEACTIVATE_CARD_MASTER_FINISH: 'SINGLE_DEACTIVATE_CARD_MASTER_FINISH',

  SORT_CARD_MASTER_START: 'SORT_CARD_MASTER_START',
  SORT_CARD_MASTER_SUCCESS: 'SORT_CARD_MASTER_SUCCESS',
  SORT_CARD_MASTER_FINISH: 'SORT_CARD_MASTER_FINISH',
  SORT_CARD_MASTER_RESET: 'SORT_CARD_MASTER_RESET',
}
