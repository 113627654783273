import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as serviceCategoryRedux from '../../serviceCategory/redux'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Must not contain number or symbol'),
  status: Yup.string().required('Status is required'),
})

const AddServiceCategory = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const {loading, success} = useSelector((state: any) => state.serviceCategoryOne)

  useEffect(() => {
    if (success) {
      dispatch(serviceCategoryRedux?.actions.getServiceCategory(params))
      isEmpty(editSelectedData)
        ? toast.success('Category added successfully')
        : toast.success('Category edited successfully')
      dispatch(serviceCategoryRedux?.actions.resetServiceCategory())
      handleClose()
    }
  }, [success])
  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const hideServiceCategoryNp = checkBackendSetting('hideServiceCategoryNp')

  const showServicesCategoryItemsSection = checkBackendSetting('showServicesCategoryItemsSection')
  return (
    <div className='modal-container' data-cy='add-service-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Service Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                name_np: '',
                slug: '',
                status: '',
                items: [
                  {
                    title: '',
                    image: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name.replace(/\s/g, '-').toLowerCase(),
                  items: values?.items?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item?.id : null,
                  })),
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    serviceCategoryRedux.actions.updateServiceCategory(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(serviceCategoryRedux.actions.addServiceCategory(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['id', 'name', 'name_np', 'order', 'slug', 'items']

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='add-service-body'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        {hideServiceCategoryNp ? null : (
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter Name (NP)'
                              label='Name (NP)'
                              name='name_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            required={true}
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.name.replace(/\s/g, '-').toLowerCase()
                            }
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {showServicesCategoryItemsSection && (
                      <div className='row'>
                        <FieldArray
                          name='items'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Items</h5>
                              {values?.items && values?.items.length > 0
                                ? values?.items?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Title'
                                          name={`items[${index}].title`}
                                          label='Title'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormInputMediaManager
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Image'
                                          name={`items[${index}].image`}
                                          setFieldValue={setFieldValue}
                                          value={values?.items[index]?.image}
                                          showImageOnly={true}
                                        />
                                        {!isEmpty(responseData?.image) ? (
                                          <>
                                            <li className='listing'>
                                              <div className='thumbImageBlock'>
                                                <button
                                                  type='button'
                                                  title='Remove'
                                                  className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                  onClick={() => {
                                                    setFieldValue(`items[${index}].image`, '')
                                                  }}
                                                  data-cy='modal-thumbnail-remove'
                                                >
                                                  X
                                                </button>

                                                <img
                                                  className='thumbImage w-100 h-100'
                                                  src={`${imageBaseUrl}/${responseData?.image}`}
                                                  alt='image'
                                                />
                                              </div>
                                            </li>
                                          </>
                                        ) : null}
                                      </div>

                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}
                              {values?.items[values?.items?.length - 1]?.image && (
                                <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                  <button
                                    className='p-2 ps-5 pe-5 btn btn-primary'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        image: '',
                                        title: '',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    )}

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='submit-button'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='cancel-button'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddServiceCategory
