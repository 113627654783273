import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ExchangeRateModel} from '../Model'

function* getExchangeRateSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getExchangeRate, params)
    yield put(actions.getExchangeRateSuccess(response?.data?.data))
    yield put(actions.getExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getExchangeRateFinish())
  }
}

function* addExchangeRateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addExchangeRate, body)
    yield put(actions.addExchangeRateSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addExchangeRateFinish())
  }
}

function* enableExchangeRateSaga(action: ActionModel) {
  try {
    const selectedExchangeRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableExchangeRate, selectedExchangeRate)
    yield put(actions.enableExchangeRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableExchangeRateFinish())
  }
}

function* disableExchangeRateSaga(action: ActionModel) {
  try {
    const selectedExchangeRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableExchangeRate, selectedExchangeRate)
    yield put(actions.disableExchangeRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableExchangeRateFinish())
  }
}

function* singleEnableExchangeRateSaga(action: ActionModel) {
  try {
    const selectedExchangeRate: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleEnableExchangeRate,
      selectedExchangeRate
    )
    yield put(actions.singleEnableExchangeRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableExchangeRateFinish())
  }
}

function* singleDisableExchangeRateSaga(action: ActionModel) {
  try {
    const selectedExchangeRate: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableExchangeRate,
      selectedExchangeRate
    )
    yield put(actions.singleDisableExchangeRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableExchangeRateFinish())
  }
}

function* updateExchangeRateSaga(action: ActionModel) {
  try {
    const body: ExchangeRateModel = action.payload
    const params: any = action
    const response: ResponseModel = yield call(service.updateExchangeRate, body, params.id)

    yield put(actions.updateExchangeRateSuccess(response.data?.data))
    yield put(actions.updateExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateExchangeRateFinish())
  }
}

function* deleteExchangeRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteExchangeRate, body)

    yield put(actions.deleteExchangeRateSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteExchangeRateFinish())
  }
}

function* downloadExchangeRateSampleSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.downloadSample, fileName)
    const data: any = response?.data
    yield put(actions.downloadExchangeRateSampleSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.downloadExchangeRateSampleError(error))
  }
}
function* importExchangeRateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importExchangeRate, body)
    yield put(actions.importExchangeRateSuccess(response?.data))
    yield put(actions.importExchangeRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importExchangeRateFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_EXCHANGE_RATE_START, getExchangeRateSaga)
  yield takeLatest(actionTypes.ADD_EXCHANGE_RATE_START, addExchangeRateSaga)
  yield takeLatest(actionTypes.UPDATE_EXCHANGE_RATE_START, updateExchangeRateSaga)
  yield takeLatest(actionTypes.DELETE_EXCHANGE_RATE_START, deleteExchangeRateSaga)
  yield takeLatest(actionTypes.ENABLE_EXCHANGE_RATE_REQUEST, enableExchangeRateSaga)
  yield takeLatest(actionTypes.DISABLE_EXCHANGE_RATE_REQUEST, disableExchangeRateSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_REQUEST, singleEnableExchangeRateSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_REQUEST, singleDisableExchangeRateSaga)
  yield takeLatest(
    actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST,
    downloadExchangeRateSampleSaga
  )
  yield takeLatest(actionTypes.IMPORT_EXCHANGE_RATE_REQUEST, importExchangeRateSaga)
}
