export const actionTypes: any = {
  // get sub category
  GET_NOTIFICATION_START: 'GET_NOTIFICATION_START',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',

  // ADD PUSH NOTIFICATION TEMPLATE
  ADD_PUSH_NOTIFICATION_TEMPLATE_START: 'ADD_PUSH_NOTIFICATION_TEMPLATE_START',
  ADD_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: 'ADD_PUSH_NOTIFICATION_TEMPLATE_SUCCESS',
  ADD_PUSH_NOTIFICATION_TEMPLATE_FINISH: 'ADD_PUSH_NOTIFICATION_TEMPLATE_FINISH',
  ADD_PUSH_NOTIFICATION_TEMPLATE_RESET: 'ADD_PUSH_NOTIFICATION_TEMPLATE_RESET',

  // GET push notificaiton template
  GET_PUSH_NOTIFICATION_TEMPLATE_START: 'GET_PUSH_NOTIFICATION_TEMPLATE_START',
  GET_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: 'GET_PUSH_NOTIFICATION_TEMPLATE_SUCCESS',
  GET_PUSH_NOTIFICATION_TEMPLATE_FINISH: 'GET_PUSH_NOTIFICATION_TEMPLATE_FINISH',

  //update
  UPDATE_PUSH_NOTIFICATION_TEMPLATE_START: 'UPDATE_PUSH_NOTIFICATION_TEMPLATE_START',
  UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: 'UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS',
  UPDATE_PUSH_NOTIFICATION_TEMPLATE_FINISH: 'UPDATE_PUSH_NOTIFICATION_TEMPLATE_FINISH',

  // delete push notification template
  DELETE_PUSH_NOTIFICATION_TEMPLATE_START: 'DELETE_PUSH_NOTIFICATION_TEMPLATE_START',
  DELETE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS: 'DELETE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS',
  DELETE_PUSH_NOTIFICATION_TEMPLATE_FINISH: 'DELETE_PUSH_NOTIFICATION_TEMPLATE_FINISH',

  // add news
  ADD_NOTIFICATION_START: 'ADD_NOTIFICATION_START',
  ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FINISH: 'ADD_NOTIFICATION_FINISH',
  ADD_NOTIFICATION_RESET: 'ADD_NOTIFICATION_RESET',

  // Update sub category
  UPDATE_NOTIFICATION_START: 'UPDATE_NOTIFICATION_START',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FINISH: 'UPDATE_NOTIFICATION_FINISH',
  UPDATE_NOTIFICATION_RESET: 'UPDATE_NOTIFICATION_RESET',

  // delete sub category
  DELETE_NOTIFICATION_START: 'DELETE_NOTIFICATION_START',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FINISH: 'DELETE_NOTIFICATION_FINISH',
}
