export const actionTypes: any = {
  // get Api Manager List
  GET_API_MANAGER_START: 'GET_API_MANAGER_START',
  GET_API_MANAGER_SUCCESS: 'GET_API_MANAGER_SUCCESS',
  GET_API_MANAGER_FINISH: 'GET_API_MANAGER_FINISH',

  // get Media type
  GET_API_MANAGER_FILE_TYPE_START: 'GET_API_MANAGER_FILE_TYPE_START',
  GET_API_MANAGER_FILE_TYPE_SUCCESS: 'GET_API_MANAGER_FILE_TYPE_SUCCESS',
  GET_API_MANAGER_FILE_TYPE_FINISH: 'GET_API_MANAGER_FILE_TYPE_FINISH',

  // add API list
  ADD_API_MANAGER_START: 'ADD_API_MANAGER_START',
  ADD_API_MANAGER_SUCCESS: 'ADD_API_MANAGER_SUCCESS',
  ADD_API_MANAGER_FINISH: 'ADD_API_MANAGER_FINISH',
  RESET_API_MANAGER_TYPE: 'RESET_API_MANAGER_TYPE',

  // update API list
  UPDATE_API_MANAGER_START: 'UPDATE_API_MANAGER_START',
  UPDATE_API_MANAGER_SUCCESS: 'UPDATE_API_MANAGER_SUCCESS',
  UPDATE_API_MANAGER_FINISH: 'UPDATE_API_MANAGER_FINISH',

  // delete API list
  DELETE_API_MANAGER_START: 'DELETE_API_MANAGER_START',
  DELETE_API_MANAGER_SUCCESS: 'DELETE_API_MANAGER_SUCCESS',
  DELETE_API_MANAGER_FINISH: 'DELETE_API_MANAGER_FINISH',

  // Enable API list
  ENABLE_API_MANAGER_REQUEST: 'ENABLE_API_MANAGER_REQUEST',
  ENABLE_API_MANAGER_SUCCESS: 'ENABLE_API_MANAGER_SUCCESS',
  ENABLE_API_MANAGER_FINISH: 'ENABLE_API_MANAGER_FINISH',

  // Disable API list
  DISABLE_API_MANAGER_REQUEST: 'DISABLE_API_MANAGER_REQUEST',
  DISABLE_API_MANAGER_SUCCESS: 'DISABLE_API_MANAGER_SUCCESS',
  DISABLE_API_MANAGER_FINISH: 'DISABLE_API_MANAGER_FINISH',

  // Enable API list
  SINGLE_ENABLE_API_MANAGER_REQUEST: 'SINGLE_ENABLE_API_MANAGER_REQUEST',
  SINGLE_ENABLE_API_MANAGER_SUCCESS: 'SINGLE_ENABLE_API_MANAGER_SUCCESS',
  SINGLE_ENABLE_API_MANAGER_FINISH: 'SINGLE_ENABLE_API_MANAGER_FINISH',

  // Disable API list
  SINGLE_DISABLE_API_MANAGER_REQUEST: 'SINGLE_DISABLE_API_MANAGER_REQUEST',
  SINGLE_DISABLE_API_MANAGER_SUCCESS: 'SINGLE_DISABLE_API_MANAGER_SUCCESS',
  SINGLE_DISABLE_API_MANAGER_FINISH: 'SINGLE_DISABLE_API_MANAGER_FINISH',

  // get API_MANAGERs
  GET_ALL_API_MANAGER_START: 'GET_ALL_API_MANAGER_START',
  GET_ALL_API_MANAGER_SUCCESS: 'GET_ALL_API_MANAGER_SUCCESS',
  GET_ALL_API_MANAGER_FINISH: 'GET_ALL_API_MANAGER_FINISH',

  // sort
  SORT_API_MANAGER_START: 'SORT_API_MANAGER_START',
  SORT_API_MANAGER_SUCCESS: 'SORT_API_MANAGER_SUCCESS',
  SORT_API_MANAGER_FINISH: 'SORT_API_MANAGER_FINISH',
  SORT_API_MANAGER_RESET: 'SORT_API_MANAGER_RESET',
}
