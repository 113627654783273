import CheckOutlineIcon from '@rsuite/icons/CheckOutline'
import CloseOutlineIcon from '@rsuite/icons/CloseOutline'
import axios from 'axios'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {FiEye} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {IconButton} from 'rsuite'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import * as reviewRedux from 'src/app/modules/cms/components/review/redux'
import * as reviewStatusRedux from 'src/app/modules/cms/components/review/reviewstatus/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IReviewState} from '../redux'
import ViewDetailsModal from './ViewDetailsModal'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const Cell = Table.Cell
const Review = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: 'created_at',
    order: 'desc',
  })

  const [actionType, setActionType] = useState('')
  const [seletectedReviewId, setSelectedReviewId] = useState('')
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ReviewList: IReviewState = useSelector((state: any) => state.review)
  const reviewStatusData: any = useSelector((state: any) => state.reviewStatus)
  useEffect(() => {
    dispatch(reviewRedux.actions.getReview(params))
  }, [params])

  useEffect(() => {
    dispatch(reviewStatusRedux.actions.getReviewStatus())
  }, [])

  //Review Action functions
  const reviewAction = () => {
    setActionType('')
    setSelectedReviewId('')

    let selectedStatusId = reviewStatusData?.data?.reviewStatus?.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id

    axios
      .put(API_URL + `/review/${seletectedReviewId}`, {
        reviewStatusId: selectedStatusId,
      })
      .then((res) => {
        toast.success('Success.')
        const updatedApplicantIndex = data.findIndex((dta: any) => dta.id === seletectedReviewId)
        const updatedData = [...data]
        updatedData[updatedApplicantIndex] = res.data?.data
        dispatch(reviewRedux.actions.getReview(params))
      })
  }

  const {loading} = ReviewList

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              setShowDetailsModal(true)
              setSelectedReviewId(rowData?.id)
            }}
            data-cy='edit-button'
            icon={<FiEye />}
          />
        </Whisper>

        {rowData?.reviewStatus.systemName !== 'approved' && (
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Approve</Tooltip>}>
            <IconButton
              onClick={() => {
                setActionType('approved')
                setSelectedReviewId(rowData?.id)
              }}
              data-cy='approve-button'
              appearance='subtle'
              icon={<CheckOutlineIcon />}
            />
          </Whisper>
        )}

        {rowData?.reviewStatus.systemName !== 'rejected' && (
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Disapprove</Tooltip>}>
            <IconButton
              onClick={() => {
                setActionType('rejected')
                setSelectedReviewId(rowData?.id)
              }}
              data-cy='disapprove-button'
              appearance='subtle'
              icon={<CloseOutlineIcon />}
            />
          </Whisper>
        )}
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Reviewed Page',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) =>
            !isEmpty(rowData?.reviewedData.title) ? rowData?.reviewedData?.title : '-'
          }
        </Cell>
      ),
    },
    {
      label: 'Name',
      flexGrow: 1,
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
      sortable: true,
    },

    {
      label: 'Message',
      dataKey: 'ratingMessage',
      flexGrow: 1,
      cell: <Cell dataKey='ratingMessage' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.reviewStatus.systemName === 'approved' ? (
              <Tag color='green' size='sm'>
                Approved
              </Tag>
            ) : rowData?.reviewStatus.systemName === 'rejected' ? (
              <Tag color='red' size='sm'>
                Rejected
              </Tag>
            ) : (
              <Tag color='green' size='sm'>
                Pending
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleExport = () => {
    dispatch(reviewRedux.actions.exportReviewFile('Review Data', params))
  }

  const handleRefresh = () => {
    dispatch(reviewRedux.actions.getReview(params))
  }

  //Get data from api to map in datatable
  const data = ReviewList?.data?.review
    ? ReviewList?.data?.review?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.name,
        email: item?.email,
        mobileNumber: item?.mobileNumber,
        rating: item?.rating,
        ratingMessage: item?.ratingMessage,
        reviewType: item?.reviewType,
        reviewFor: item?.reveiwFor,
        reviewedData: item?.reviewData,
        reviewStatus: item?.reviewStatus,
      }))
    : []

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Review and Rating'
          params={params}
          setParams={setParams}
          filterData={true}
          handleExport={handleExport}
          deleteShow={false}
          handleRefresh={handleRefresh}
          exportShow={true}
          addShow={false}
          importShow={false}
          data={data}
          toggleMultipleShow={false}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={ReviewList?.loading}
            onChecked={handleCheck}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={ReviewList?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === seletectedReviewId)[0]}
          />
        )}

        <DeleteModal
          title={`${actionType === 'approved' ? 'approve' : 'reject'} this review`}
          handleClick={() => reviewAction()}
          isOpen={actionType ? true : false}
          handleClose={() => setActionType('')}
        />
      </div>
    </div>
  )
}
export default Review
