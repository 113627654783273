export const actionTypes: any = {
  // get Gallery
  GET_ABOUT_BANK_DETAIL_START: 'GET_ABOUT_BANK_DETAIL_START',
  GET_ABOUT_BANK_DETAIL_SUCCESS: 'GET_ABOUT_BANK_DETAIL_SUCCESS',
  GET_ABOUT_BANK_DETAIL_FINISH: 'GET_ABOUT_BANK_DETAIL_FINISH',

  // get Media type
  GET_ABOUT_BANK_DETAIL_FILE_TYPE_START: 'GET_ABOUT_BANK_DETAIL_FILE_TYPE_START',
  GET_ABOUT_BANK_DETAIL_FILE_TYPE_SUCCESS: 'GET_ABOUT_BANK_DETAIL_FILE_TYPE_SUCCESS',
  GET_ABOUT_BANK_DETAIL_FILE_TYPE_FINISH: 'GET_ABOUT_BANK_DETAIL_FILE_TYPE_FINISH',

  // add Gallery
  ADD_ABOUT_BANK_DETAIL_START: 'ADD_ABOUT_BANK_DETAIL_START',
  ADD_ABOUT_BANK_DETAIL_SUCCESS: 'ADD_ABOUT_BANK_DETAIL_SUCCESS',
  ADD_ABOUT_BANK_DETAIL_FINISH: 'ADD_ABOUT_BANK_DETAIL_FINISH',
  RESET_ABOUT_BANK_DETAIL_TYPE: 'RESET_ABOUT_BANK_DETAIL_TYPE',

  // update Gallery
  UPDATE_ABOUT_BANK_DETAIL_START: 'UPDATE_ABOUT_BANK_DETAIL_START',
  UPDATE_ABOUT_BANK_DETAIL_SUCCESS: 'UPDATE_ABOUT_BANK_DETAIL_SUCCESS',
  UPDATE_ABOUT_BANK_DETAIL_FINISH: 'UPDATE_ABOUT_BANK_DETAIL_FINISH',

  // delete Gallery
  DELETE_ABOUT_BANK_DETAIL_START: 'DELETE_ABOUT_BANK_DETAIL_START',
  DELETE_ABOUT_BANK_DETAIL_SUCCESS: 'DELETE_ABOUT_BANK_DETAIL_SUCCESS',
  DELETE_ABOUT_BANK_DETAIL_FINISH: 'DELETE_ABOUT_BANK_DETAIL_FINISH',

  // Enable Gallery
  ENABLE_ABOUT_BANK_DETAIL_REQUEST: 'ENABLE_ABOUT_BANK_DETAIL_REQUEST',
  ENABLE_ABOUT_BANK_DETAIL_SUCCESS: 'ENABLE_ABOUT_BANK_DETAIL_SUCCESS',
  ENABLE_ABOUT_BANK_DETAIL_FINISH: 'ENABLE_ABOUT_BANK_DETAIL_FINISH',

  // Disable Gallery
  DISABLE_ABOUT_BANK_DETAIL_REQUEST: 'DISABLE_ABOUT_BANK_DETAIL_REQUEST',
  DISABLE_ABOUT_BANK_DETAIL_SUCCESS: 'DISABLE_ABOUT_BANK_DETAIL_SUCCESS',
  DISABLE_ABOUT_BANK_DETAIL_FINISH: 'DISABLE_ABOUT_BANK_DETAIL_FINISH',

  // Enable Gallery
  SINGLE_ENABLE_ABOUT_BANK_DETAIL_REQUEST: 'SINGLE_ENABLE_ABOUT_BANK_DETAIL_REQUEST',
  SINGLE_ENABLE_ABOUT_BANK_DETAIL_SUCCESS: 'SINGLE_ENABLE_ABOUT_BANK_DETAIL_SUCCESS',
  SINGLE_ENABLE_ABOUT_BANK_DETAIL_FINISH: 'SINGLE_ENABLE_ABOUT_BANK_DETAIL_FINISH',

  // Disable Gallery
  SINGLE_DISABLE_ABOUT_BANK_DETAIL_REQUEST: 'SINGLE_DISABLE_ABOUT_BANK_DETAIL_REQUEST',
  SINGLE_DISABLE_ABOUT_BANK_DETAIL_SUCCESS: 'SINGLE_DISABLE_ABOUT_BANK_DETAIL_SUCCESS',
  SINGLE_DISABLE_ABOUT_BANK_DETAIL_FINISH: 'SINGLE_DISABLE_ABOUT_BANK_DETAIL_FINISH',

  // get ABOUT_BANK_DETAILs
  GET_ALL_ABOUT_BANK_DETAIL_START: 'GET_ALL_ABOUT_BANK_DETAIL_START',
  GET_ALL_ABOUT_BANK_DETAIL_SUCCESS: 'GET_ALL_ABOUT_BANK_DETAIL_SUCCESS',
  GET_ALL_ABOUT_BANK_DETAIL_FINISH: 'GET_ALL_ABOUT_BANK_DETAIL_FINISH',

  // sort
  SORT_ABOUT_BANK_DETAIL_START: 'SORT_ABOUT_BANK_DETAIL_START',
  SORT_ABOUT_BANK_DETAIL_SUCCESS: 'SORT_ABOUT_BANK_DETAIL_SUCCESS',
  SORT_ABOUT_BANK_DETAIL_FINISH: 'SORT_ABOUT_BANK_DETAIL_FINISH',
  SORT_ABOUT_BANK_DETAIL_RESET: 'SORT_ABOUT_BANK_DETAIL_RESET',
}
