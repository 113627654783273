import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// rsuite
import Table from "rsuite/Table";
import Tag from "rsuite/Tag";
import Toggle from "rsuite/Toggle";
import Tooltip from "rsuite/Tooltip";
import Whisper from "rsuite/Whisper";

// includes
import Edit2 from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { SortType } from "rsuite-table/lib/@types/common";
import Pagination from "rsuite/Pagination";
import IconButton from "rsuite/esm/IconButton";
import { ItemType, StateParamsModel } from "src/app/modules/common/Model";
import CheckPermissions from "src/app/modules/common/checkPermission";
import DeleteModal from "src/app/modules/common/components/deleteModal";
import DesignComponent from "src/app/modules/common/components/designComponent";
import RSuiteTable from "src/cms/helpers/components/rsuiteTable";
import * as propertyTypeRedux from "../index";
import AddPropertyType from "./AddPropertyType";

const PropertyTypeComponent = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState("Add");
  const {
    data,
    loading,
    singleEnableSuccess,
    singleDisableSuccess,
    enableSuccess,
    disableSuccess,
    toggleLoading,
    success,
    deleteSuccess,
    sortPropertyTypeData,
  } = useSelector((state: any) => state.propertyType);
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  });
  const [checkedValues, setCheckedValues] = useState<Array<string>>([]);
  const [editSelectedData, setEditCheckedData] = useState<any>();

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertOpen = () => setAlertOpen(true);
  const handleAlertClose = () => setAlertOpen(false);
  const [openSortModal, setOpenSortModal] = useState(false);

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    });
  };

  const enablePropertyTypeData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }));
      dispatch(propertyTypeRedux.actions.enablePropertyType(formData));
    } else {
      toast.error("No data Selected");
    }
  };

  const disablePropertyTypeData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }));
      dispatch(propertyTypeRedux.actions.disablePropertyType(formData));
    } else {
      toast.error("No data Selected");
    }
  };

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`);
    }
    return (
      <Cell
        {...props}
        className="link-group"
        data-cy="search-category-link-group"
      >
        <CheckPermissions type="Edit">
          <Whisper
            placement="top"
            trigger="hover"
            data-cy="search-category-edit-button"
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <IconButton
              appearance="subtle"
              onClick={() => {
                setEditCheckedData(rowData);
                setActionType("Edit");
                setOpen(true);
              }}
              icon={<Edit2 />}
              data-cy="edit-button"
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type="Delete">
          <Whisper
            placement="top"
            trigger="hover"
            data-cy="search-category-delete-button"
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <IconButton
              appearance="subtle"
              onClick={() => {
                handleAlertOpen();
                let propertyTypeId = [rowData.id];
                setCheckedValues(propertyTypeId);
              }}
              icon={<TrashIcon />}
              data-cy="delete-button"
            />
          </Whisper>
        </CheckPermissions>
        <Whisper
          placement="top"
          trigger="hover"
          data-cy="search-category-status-button"
          speaker={<Tooltip>Status</Tooltip>}
        >
          <Toggle
            size="sm"
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy="toggle-button"
          />
        </Whisper>
      </Cell>
    );
  };

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(
          propertyTypeRedux.actions.singleDisablePropertyType({ id: data.id })
        )
      : dispatch(
          propertyTypeRedux.actions.singleEnablePropertyType({ id: data.id })
        );
  };

  const Cell = Table.Cell;

  const handleClose = () => setOpen(false);

  const columns = [
    {
      label: "S.N.",
      dataKey: "sn",
      width: 60,
      cell: <Cell dataKey="sn" />,
    },
    {
      label: "Name",
      dataKey: "name",
      flexGrow: 1,
      cell: <Cell dataKey="name" />,
      sortable: true,
    },

    {
      label: "Order",
      dataKey: "order",
      height: 85,
      cell: <Cell dataKey="order" />,
      sortable: true,
    },
    {
      label: "Status",
      dataKey: "status",
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color="green" size="sm">
                Active
              </Tag>
            ) : (
              <Tag color="red" size="sm">
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: "Action",
      width: 150,
      align: "center",
      cell: <ActionCell dataKey="id" />,
    },
  ];

  useEffect(() => {
    dispatch(propertyTypeRedux.actions.getPropertyType(params));
  }, [params]);

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values);
  };

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Property Type deleted successfully");
    }
    if (enableSuccess) {
      toast.success("Property Type enabled successfully");
    }
    if (disableSuccess) {
      toast.success("Property Type disabled successfully");
    }
    handleChecked([]);
    dispatch(propertyTypeRedux?.actions.getPropertyType(params));
  }, [deleteSuccess, success, enableSuccess, disableSuccess]);

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success("Property Type enabled successfully");
    }
    if (singleDisableSuccess) {
      toast.success("Property Type disabled successfully");
    }
    handleChecked([]);
  }, [singleDisableSuccess, singleEnableSuccess]);

  const propertyTypeData = data?.propertyType
    ? data?.propertyType?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        name: item.name,
        name_np: item.name_np,
        order: item.order,
        status: item.status,
      }))
    : [];

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType });
  };

  const handleAddModal = () => {
    setEditCheckedData({});
    setOpen(true);
    setActionType("Add");
  };

  const handleDelete = () => {
    let propertyTypeId = checkedValues?.map((value) => ({ id: value }));
    dispatch(propertyTypeRedux?.actions?.deletePropertyType(propertyTypeId));
    handleAlertClose();
  };

  const handleRefresh = () => {
    dispatch(propertyTypeRedux.actions.getPropertyType(params));
  };

  const handleGetAllData = () => {
    setParams({ ...params, limit: data?.meta?.total });
    dispatch(propertyTypeRedux.actions.getPropertyType(params));
  };

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      propertyTypeId: data?.map((item) => ({ id: item?.keyId })),
    };
    dispatch(propertyTypeRedux.actions.sortPropertyType(body));
  };

  useEffect(() => {
    if (!isEmpty(sortPropertyTypeData) && success) {
      toast.success("Property Type sorted successfully");
      dispatch(propertyTypeRedux.actions.sortPropertyTypeReset());
      setParams({ ...params, limit: 10 });
      dispatch(propertyTypeRedux.actions.getPropertyType(params));
      setOpenSortModal(false);
    }
  }, [sortPropertyTypeData, success]);

  return (
    <>
      <div
        className="shadow p-3 bg-white rounded"
        data-cy="search-category-shadow-comp"
      >
        <DesignComponent
          moduleName="Property Type"
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={enablePropertyTypeData}
          disableMultiple={disablePropertyTypeData}
          data={propertyTypeData}
          sortShow={true}
          sortButtonName="Sort"
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
          data-cy="search-category-design-comp"
        />

        <div className="datatable" data-cy="search-category-datatable">
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={propertyTypeData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
            data-cy="search-category-r-suite"
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className="mt-5"
            maxButtons={5}
            size="sm"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
            data-cy="search-category-pagination"
          />
          {open && (
            <AddPropertyType
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>

        {/* Delete Modal */}
        <>
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error("No data selected") && setAlertOpen(false)
            ))}
        </>
      </div>
    </>
  );
};

export default PropertyTypeComponent;
