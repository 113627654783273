export const actionTypes: any = {
  // get Gallery
  GET_GALLERY_START: 'GET_GALLERY_START',
  GET_GALLERY_SUCCESS: 'GET_GALLERY_SUCCESS',
  GET_GALLERY_FINISH: 'GET_GALLERY_FINISH',

  // get Media type
  GET_GALLERY_FILE_TYPE_START: 'GET_GALLERY_FILE_TYPE_START',
  GET_GALLERY_FILE_TYPE_SUCCESS: 'GET_GALLERY_FILE_TYPE_SUCCESS',
  GET_GALLERY_FILE_TYPE_FINISH: 'GET_GALLERY_FILE_TYPE_FINISH',

  // add Gallery
  ADD_GALLERY_START: 'ADD_GALLERY_START',
  ADD_GALLERY_SUCCESS: 'ADD_GALLERY_SUCCESS',
  ADD_GALLERY_FINISH: 'ADD_GALLERY_FINISH',
  RESET_GALLERY_TYPE: 'RESET_GALLERY_TYPE',

  // update Gallery
  UPDATE_GALLERY_START: 'UPDATE_GALLERY_START',
  UPDATE_GALLERY_SUCCESS: 'UPDATE_GALLERY_SUCCESS',
  UPDATE_GALLERY_FINISH: 'UPDATE_GALLERY_FINISH',

  // delete Gallery
  DELETE_GALLERY_START: 'DELETE_GALLERY_START',
  DELETE_GALLERY_SUCCESS: 'DELETE_GALLERY_SUCCESS',
  DELETE_GALLERY_FINISH: 'DELETE_GALLERY_FINISH',

  // Enable Gallery
  ENABLE_GALLERY_REQUEST: 'ENABLE_GALLERY_REQUEST',
  ENABLE_GALLERY_SUCCESS: 'ENABLE_GALLERY_SUCCESS',
  ENABLE_GALLERY_FINISH: 'ENABLE_GALLERY_FINISH',

  // Disable Gallery
  DISABLE_GALLERY_REQUEST: 'DISABLE_GALLERY_REQUEST',
  DISABLE_GALLERY_SUCCESS: 'DISABLE_GALLERY_SUCCESS',
  DISABLE_GALLERY_FINISH: 'DISABLE_GALLERY_FINISH',

  // Enable Gallery
  SINGLE_ENABLE_GALLERY_REQUEST: 'SINGLE_ENABLE_GALLERY_REQUEST',
  SINGLE_ENABLE_GALLERY_SUCCESS: 'SINGLE_ENABLE_GALLERY_SUCCESS',
  SINGLE_ENABLE_GALLERY_FINISH: 'SINGLE_ENABLE_GALLERY_FINISH',

  // Disable Gallery
  SINGLE_DISABLE_GALLERY_REQUEST: 'SINGLE_DISABLE_GALLERY_REQUEST',
  SINGLE_DISABLE_GALLERY_SUCCESS: 'SINGLE_DISABLE_GALLERY_SUCCESS',
  SINGLE_DISABLE_GALLERY_FINISH: 'SINGLE_DISABLE_GALLERY_FINISH',

  // get GALLERYs
  GET_ALL_GALLERY_START: 'GET_ALL_GALLERY_START',
  GET_ALL_GALLERY_SUCCESS: 'GET_ALL_GALLERY_SUCCESS',
  GET_ALL_GALLERY_FINISH: 'GET_ALL_GALLERY_FINISH',

  // sort
  SORT_GALLERY_START: 'SORT_GALLERY_START',
  SORT_GALLERY_SUCCESS: 'SORT_GALLERY_SUCCESS',
  SORT_GALLERY_FINISH: 'SORT_GALLERY_FINISH',
  SORT_GALLERY_RESET: 'SORT_GALLERY_RESET',
}
