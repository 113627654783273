import RemindOutlineIcon from '@rsuite/icons/RemindOutline'
import TrashIcon from '@rsuite/icons/Trash'
import {EmptyObject} from 'chart.js/types/basic'
import {useEffect, useState} from 'react'
import {AiOutlineEdit} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import IconButton from 'rsuite/IconButton'
import Modal from 'rsuite/Modal'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

//Manual import
import {isEmpty} from 'lodash'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import * as shareRedux from 'src/app/modules/stock/components/share/redux'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {StockRateModel} from '../Model'
import {IStockRateState} from '../redux'
import AddShare from './AddShare'

const Cell = Table.Cell
const Share = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<StockRateModel | EmptyObject>({})
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  const [alertOpenFile, setAlertOpenFile] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const handleAlertOpenFile = () => setAlertOpenFile(true)
  const handleAlertCloseFile = () => setAlertOpenFile(false)

  const handleClose1 = () => setOpen1(false)
  const handlOpen = () => setOpen1(true)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const shareData: IStockRateState = useSelector((state: any) => state.share)
  useEffect(() => {
    dispatch(shareRedux.actions.getStockRate(params))
  }, [params])

  const {
    success,
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    deleteSuccess,
    loading,
  } = shareData

  const handleToggleAction = (data: {[key: string]: string}) => {
    const formData = [
      {
        id: data.id,
      },
    ]
    data?.status
      ? dispatch(shareRedux.actions.deactivateStockRate(formData))
      : dispatch(shareRedux.actions.activateStockRates(formData))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen1(true)
              }}
              icon={<AiOutlineEdit />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let shareId = [rowData.id]
                setSelectedData(shareId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      flexGrow: 1,
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Max Price',
      dataKey: 'maxPrice',
      flexGrow: 1,
      cell: <Cell dataKey='maxPrice' />,
    },

    {
      label: 'Min Price',
      dataKey: 'minPrice',
      flexGrow: 1,
      cell: <Cell dataKey='minPrice' />,
    },
    {
      label: 'Closing Price',
      dataKey: 'closingPrice',
      flexGrow: 1,
      cell: <Cell dataKey='closingPrice' />,
    },
    /*  {
      label: 'Turn Over',
      dataKey: 'turnover',
      flexGrow: 1,
      cell: <Cell dataKey='turnover' />,
    }, */
    {
      label: 'Previous Closing',
      dataKey: 'lastDayValue',
      flexGrow: 1,
      cell: <Cell dataKey='lastDayValue' />,
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Stock activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Stock deactivated successfully')
    }
    if (deleteSuccess) {
      toast.success('Stock deleted successfully')
    }
    handleCheck([])
    dispatch(shareRedux?.actions.getStockRate(params))
  }, [activateSuccess, deactivateSuccess, deleteSuccess, success])
  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success(' Stock Activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success(' Stock deactivated successfully')
    }
    handleCheck([])
  }, [singleActivateSuccess, singleDeactivateSuccess, success])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }
  const activateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(shareRedux.actions.activateStockRates(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(shareRedux.actions.deactivateStockRate(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleRefresh = () => {
    dispatch(shareRedux.actions.getStockRate(params))
  }

  //Get data from api to map in datatable
  const data = shareData?.data?.share
    ? shareData?.data?.share?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.name,
        maxPrice: item?.maximum_price,
        minPrice: item?.minimum_price,
        closingPrice: item?.closing_price,
        turnover: item?.turnover,
        lastDayValue: item?.prevClosing,
        status: item?.status,
      }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen1(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let stockSelectedId = selectedData?.map((value) => ({id: value}))
    dispatch(shareRedux?.actions.deleteStockRate(stockSelectedId))
    handleAlertClose()
  }

  function fileImport(event: any) {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(shareRedux.actions.importStockRate(formData))
      event.target.value = null
    }
  }
  const handleTemplateExport = () => {
    dispatch(shareRedux.actions.exportTemplateFile())
  }

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showInterestTitle =
    settings?.data?.setting?.filter((d: any) => d?.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Stock Manager'
          params={params}
          setParams={setParams}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          exportShow={true}
          importShow={true}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          data={data}
          sortShow={false}
          handleExport={handleAlertOpenFile}
          templateButtonName='Download Template for Shares'
          handleTemplateExport={handleTemplateExport}
          templateLinkShow={showInterestTitle ? true : false}
          handleImport={fileImport}
          exportButtonName='Export'
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={shareData?.loading}
            onChecked={handleCheck}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={shareData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open1 && (
          <AddShare
            open={open1}
            handleClose={handleClose1}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}
        <>
          {alertOpen &&
            (!isEmpty(selectedData) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
      {/* File Download */}
      <div className='modal-container'>
        <Modal
          backdrop='static'
          role='alertdialog'
          open={alertOpenFile}
          onClose={handleAlertCloseFile}
          enforceFocus={false}
          size='xs'
          data-cy='modal'
        >
          <Modal.Body>
            <div className='px-4'>
              <div className='d-flex'>
                <RemindOutlineIcon
                  style={{
                    color: '#ffb300',
                    fontSize: 24,
                  }}
                />
                <h4 data-cy='modal-title'>
                  <strong className='ms-3'>Choose file format and download!</strong>
                </h4>
              </div>

              {/* Template download */}
              <div className='row mt-5'>
                <div className='col-lg-6'>
                  <button
                    className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                    onClick={() => {
                      dispatch(shareRedux.actions.getStockRateFile('csv', params))
                      handleAlertCloseFile()
                    }}
                    data-cy='import-file-link'
                  >
                    CSV file
                  </button>
                </div>
                <div className='col-lg-6'>
                  <button
                    className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                    onClick={() => {
                      dispatch(shareRedux.actions.getStockRateFile('xlsx', params))
                      handleAlertCloseFile()
                    }}
                    data-cy='import-files-link'
                  >
                    XLSX file
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleAlertCloseFile} className='btn btn-secondary btn-sm ms-3'>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* End File Download */}
    </div>
  )
}
export default Share
