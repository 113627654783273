export const actionTypes: any = {
  // get
  GET_ANALYTIC_USER_START: 'GET_ANALYTIC_USER_START',
  GET_ANALYTIC_USER_SUCCESS: 'GET_ANALYTIC_USER_SUCCESS',
  GET_ANALYTIC_USER_FINISH: 'GET_ANALYTIC_USER_FINISH',

  GET_COUNTRY_USER_START: 'GET_COUNTRY_USER_START',
  GET_COUNTRY_USER_SUCCESS: 'GET_COUNTRY_USER_SUCCESS',
  GET_COUNTRY_USER_FINISH: 'GET_COUNTRY_USER_FINISH',

  GET_UNIQUE_USER_START: 'GET_UNIQUE_USER_START',
  GET_UNIQUE_USER_SUCCESS: 'GET_UNIQUE_USER_SUCCESS',
  GET_UNIQUE_USER_FINISH: 'GET_UNIQUE_USER_FINISH',
}
