export const actionTypes: any = {
  GET_TUTORIAL_START: 'GET_TUTORIAL_START',
  GET_TUTORIAL_SUCCESS: 'GET_TUTORIAL_SUCCESS',
  GET_TUTORIAL_FINISH: 'GET_TUTORIAL_FINISH',

  ADD_TUTORIAL_START: 'ADD_TUTORIAL_START',
  ADD_TUTORIAL_SUCCESS: 'ADD_TUTORIAL_SUCCESS',
  ADD_TUTORIAL_FINISH: 'ADD_TUTORIAL_FINISH',
  ADD_TUTORIAL_RESET: 'ADD_TUTORIAL_RESET',

  UPDATE_TUTORIAL_START: 'UPDATE_TUTORIAL_START',
  UPDATE_TUTORIAL_SUCCESS: 'UPDATE_TUTORIAL_SUCCESS',
  UPDATE_TUTORIAL_FINISH: 'UPDATE_TUTORIAL_FINISH',
  UPDATE_TUTORIAL_RESET: 'UPDATE_TUTORIAL_RESET',

  DELETE_TUTORIAL_START: 'DELETE_TUTORIAL_START',
  DELETE_TUTORIAL_SUCCESS: 'DELETE_TUTORIAL_SUCCESS',
  DELETE_TUTORIAL_FINISH: 'DELETE_TUTORIAL_FINISH',

  SINGLE_ACTIVATE_TUTORIAL_REQUEST: 'SINGLE_ACTIVATE_TUTORIAL_REQUEST',
  SINGLE_ACTIVATE_TUTORIAL_SUCCESS: 'SINGLE_ACTIVATE_TUTORIAL_SUCCESS',
  SINGLE_ACTIVATE_TUTORIAL_FINISH: 'SINGLE_ACTIVATE_TUTORIAL_FINISH',

  SINGLE_DEACTIVATE_TUTORIAL_REQUEST: 'SINGLE_DEACTIVATE_TUTORIAL_REQUEST',
  SINGLE_DEACTIVATE_TUTORIAL_SUCCESS: 'SINGLE_DEACTIVATE_TUTORIAL_SUCCESS',
  SINGLE_DEACTIVATE_TUTORIAL_FINISH: 'SINGLE_DEACTIVATE_TUTORIAL_FINISH',

  SORT_TUTORIAL_START: 'SORT_TUTORIAL_START',
  SORT_TUTORIAL_SUCCESS: 'SORT_TUTORIAL_SUCCESS',
  SORT_TUTORIAL_FINISH: 'SORT_TUTORIAL_FINISH',
  SORT_TUTORIAL_RESET: 'SORT_TUTORIAL_RESET',
}
