import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getEnquiryTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getEnquiryType, params)
    yield put(actions.getEnquiryTypeSuccess(response?.data?.data))
    yield put(actions.getEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getEnquiryTypeFinish())
  }
}

function* getAllEnquiryTypeSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllEnquiryType, params)
    yield put(actions.getAllEnquiryTypeSuccess(response?.data?.data))
    yield put(actions.getAllEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllEnquiryTypeFinish())
  }
}

function* addEnquiryTypeSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addEnquiryType, body)
    yield put(actions.addEnquiryTypeSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addEnquiryTypeFinish())
  }
}

function* enableEnquiryTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableEnquiryType, selectedUsers)
    yield put(actions.enableEnquiryTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableEnquiryTypeFinish())
  }
}

function* disableEnquiryTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableEnquiryType, selectedUsers)
    yield put(actions.disableEnquiryTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableEnquiryTypeFinish())
  }
}

function* singleEnableEnquiryTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableEnquiryType, selectedUsers)
    yield put(actions.singleEnableEnquiryTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableEnquiryTypeFinish())
  }
}

function* singleDisableEnquiryTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableEnquiryType, selectedUsers)
    yield put(actions.singleDisableEnquiryTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableEnquiryTypeFinish())
  }
}

function* updateEnquiryTypeSaga(action: ActionModel) {
  try {
    const body: ServiceTypeModel = action.payload
    const response: ResponseModel = yield call(service.updateEnquiryType, body, action.payload?.id)
    yield put(actions.updateEnquiryTypeSuccess(response.data?.data))
    yield put(actions.updateEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateEnquiryTypeFinish())
  }
}

function* deleteEnquiryTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteEnquiryType, body)
    yield put(actions.deleteEnquiryTypeSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteEnquiryTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteEnquiryTypeFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ENQUIRY_TYPE_START, getEnquiryTypeSaga)
  yield takeLatest(actionTypes.GET_ALL_ENQUIRY_TYPE_START, getAllEnquiryTypeSaga)
  yield takeLatest(actionTypes.ADD_ENQUIRY_TYPE_START, addEnquiryTypeSaga)
  yield takeLatest(actionTypes.UPDATE_ENQUIRY_TYPE_START, updateEnquiryTypeSaga)
  yield takeLatest(actionTypes.DELETE_ENQUIRY_TYPE_START, deleteEnquiryTypeSaga)
  yield takeLatest(actionTypes.ENABLE_ENQUIRY_TYPE_REQUEST, enableEnquiryTypeSaga)
  yield takeLatest(actionTypes.DISABLE_ENQUIRY_TYPE_REQUEST, disableEnquiryTypeSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_ENQUIRY_TYPE_REQUEST, singleEnableEnquiryTypeSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_ENQUIRY_TYPE_REQUEST, singleDisableEnquiryTypeSaga)
}
