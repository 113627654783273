export const actionTypes: any = {
  // get
  GET_ALL_OFFER_START: 'GET_ALL_OFFER_START',
  GET_ALL_OFFER_SUCCESS: 'GET_ALL_OFFER_SUCCESS',
  GET_ALL_OFFER_FINISH: 'GET_ALL_OFFER_FINISH',

  // Discount Type
  GET_DISCOUNT_TYPE_START: 'GET_DISCOUNT_TYPE_START',
  GET_DISCOUNT_TYPE_SUCCESS: 'GET_DISCOUNT_TYPE_SUCCESS',
  GET_DISCOUNT_TYPE_FINISH: 'GET_DISCOUNT_TYPE_FINISH',

  // extended hours
  GET_OFFER_START: 'GET_OFFER_START',
  GET_OFFER_SUCCESS: 'GET_OFFER_SUCCESS',
  GET_OFFER_FINISH: 'GET_OFFER_FINISH',

  // add branch
  ADD_OFFER_START: 'ADD_OFFER_START',
  ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
  ADD_OFFER_FINISH: 'ADD_OFFER_FINISH',
  RESET_OFFER: 'RESET_OFFER',

  // update OFFER
  UPDATE_OFFER_START: 'UPDATE_OFFER_START',
  UPDATE_OFFER_SUCCESS: 'UPDATE_OFFER_SUCCESS',
  UPDATE_OFFER_FINISH: 'UPDATE_OFFER_FINISH',

  // delete OFFER
  DELETE_OFFER_START: 'DELETE_OFFER_START',
  DELETE_OFFER_SUCCESS: 'DELETE_OFFER_SUCCESS',
  DELETE_OFFER_FINISH: 'DELETE_OFFER_FINISH',

  // Enable OFFER
  ENABLE_OFFER_REQUEST: 'ENABLE_OFFER_REQUEST',
  ENABLE_OFFER_SUCCESS: 'ENABLE_OFFER_SUCCESS',
  ENABLE_OFFER_FINISH: 'ENABLE_OFFER_FINISH',

  // Disable OFFER
  DISABLE_OFFER_REQUEST: 'DISABLE_OFFER_REQUEST',
  DISABLE_OFFER_SUCCESS: 'DISABLE_OFFER_SUCCESS',
  DISABLE_OFFER_FINISH: 'DISABLE_OFFER_FINISH',

  // Enable OFFER
  SINGLE_ENABLE_OFFER_REQUEST: 'SINGLE_ENABLE_OFFER_REQUEST',
  SINGLE_ENABLE_OFFER_SUCCESS: 'SINGLE_ENABLE_OFFER_SUCCESS',
  SINGLE_ENABLE_OFFER_FINISH: 'SINGLE_ENABLE_OFFER_FINISH',

  // Disable OFFER
  SINGLE_DISABLE_OFFER_REQUEST: 'SINGLE_DISABLE_OFFER_REQUEST',
  SINGLE_DISABLE_OFFER_SUCCESS: 'SINGLE_DISABLE_OFFER_SUCCESS',
  SINGLE_DISABLE_OFFER_FINISH: 'SINGLE_DISABLE_OFFER_FINISH',

  // sort
  SORT_OFFER_MANAGER_START: 'SORT_OFFER_MANAGER_START',
  SORT_OFFER_MANAGER_SUCCESS: 'SORT_OFFER_MANAGER_SUCCESS',
  SORT_OFFER_MANAGER_FINISH: 'SORT_OFFER_MANAGER_FINISH',
  SORT_OFFER_MANAGER_RESET: 'SORT_OFFER_MANAGER_RESET',

  // export
  EXPORT_OFFER_TEMPLATE_FILE_START: 'EXPORT_OFFER_TEMPLATE_FILE_START',
  EXPORT_OFFER_TEMPLATE_FILE_SUCCESS: 'EXPORT_OFFER_TEMPLATE_FILE_SUCCESS',
  EXPORT_OFFER_TEMPLATE_FILE_FINISH: 'EXPORT_OFFER_TEMPLATE_FILE_FINISH',

  // import
  IMPORT_OFFER_START: 'IMPORT_OFFER_START',
  IMPORT_OFFER_SUCCESS: 'IMPORT_OFFER_SUCCESS',
  IMPORT_OFFER_FINISH: 'IMPORT_OFFER_FINISH',
}
