export const actionTypes: any = {
  // get sub category
  GET_QUALIFICATION_START: 'GET_QUALIFICATION_START',
  GET_QUALIFICATION_SUCCESS: 'GET_QUALIFICATION_SUCCESS',
  GET_QUALIFICATION_FINISH: 'GET_QUALIFICATION_FINISH',

  // ADD sub category
  ADD_QUALIFICATION_START: 'ADD_QUALIFICATION_START',
  ADD_QUALIFICATION_SUCCESS: 'ADD_QUALIFICATION_SUCCESS',
  ADD_QUALIFICATION_FINISH: 'ADD_QUALIFICATION_FINISH',
  ADD_QUALIFICATION_RESET: 'ADD_QUALIFICATION_RESET',

  // Update sub category
  UPDATE_QUALIFICATION_START: 'UPDATE_QUALIFICATION_START',
  UPDATE_QUALIFICATION_SUCCESS: 'UPDATE_QUALIFICATION_SUCCESS',
  UPDATE_QUALIFICATION_FINISH: 'UPDATE_QUALIFICATION_FINISH',
  UPDATE_QUALIFICATION_RESET: 'UPDATE_QUALIFICATION_RESET',

  // delete sub category
  DELETE_QUALIFICATION_START: 'DELETE_QUALIFICATION_START',
  DELETE_QUALIFICATION_SUCCESS: 'DELETE_QUALIFICATION_SUCCESS',
  DELETE_QUALIFICATION_FINISH: 'DELETE_QUALIFICATION_FINISH',

  // Enable QUALIFICATION
  ENABLE_QUALIFICATION_REQUEST: 'ENABLE_QUALIFICATION_REQUEST',
  ENABLE_QUALIFICATION_SUCCESS: 'ENABLE_QUALIFICATION_SUCCESS',
  ENABLE_QUALIFICATION_FINISH: 'ENABLE_QUALIFICATION_FINISH',

  // Disable QUALIFICATION
  DISABLE_QUALIFICATION_REQUEST: 'DISABLE_QUALIFICATION_REQUEST',
  DISABLE_QUALIFICATION_SUCCESS: 'DISABLE_QUALIFICATION_SUCCESS',
  DISABLE_QUALIFICATION_FINISH: 'DISABLE_QUALIFICATION_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_QUALIFICATION_REQUEST: 'SINGLE_ACTIVATE_QUALIFICATION_REQUEST',
  SINGLE_ACTIVATE_QUALIFICATION_SUCCESS: 'SINGLE_ACTIVATE_QUALIFICATION_SUCCESS',
  SINGLE_ACTIVATE_QUALIFICATION_FINISH: 'SINGLE_ACTIVATE_QUALIFICATION_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_QUALIFICATION_REQUEST: 'SINGLE_DEACTIVATE_QUALIFICATION_REQUEST',
  SINGLE_DEACTIVATE_QUALIFICATION_SUCCESS: 'SINGLE_DEACTIVATE_QUALIFICATION_SUCCESS',
  SINGLE_DEACTIVATE_QUALIFICATION_FINISH: 'SINGLE_DEACTIVATE_QUALIFICATION_FINISH',

  //sort
  SORT_QUALIFICATION_REQUEST: 'SORT_QUALIFICATION_REQUEST',
  SORT_QUALIFICATION_SUCCESS: 'SORT_QUALIFICATION_SUCCESS',
  SORT_QUALIFICATION_FINISH: 'SORT_QUALIFICATION_FINISH',
  SORT_QUALIFICATION_RESET: 'SORT_QUALIFICATION_RESET',
}
