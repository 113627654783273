export const actionTypes: any = {
  GET_BALANCE_CERTIFICATE_START: 'GET_BALANCE_CERTIFICATE_START',
  GET_BALANCE_CERTIFICATE_SUCCESS: 'GET_BALANCE_CERTIFICATE_SUCCESS',
  GET_BALANCE_CERTIFICATE_FINISH: 'GET_BALANCE_CERTIFICATE_FINISH',
  RESET_APPLY_LOAN: 'RESET_APPLY_LOAN',

  DELETE_BALANCE_CERTIFICATE_START: 'DELETE_BALANCE_CERTIFICATE_START',
  DELETE_BALANCE_CERTIFICATE_SUCCESS: 'DELETE_BALANCE_CERTIFICATE_SUCCESS',
  DELETE_BALANCE_CERTIFICATE_FINISH: 'DELETE_BALANCE_CERTIFICATE_FINISH',
}
