export const actionTypes: any = {
  GET_CUSTOMER_LOGIN_START: 'GET_CUSTOMER_LOGIN_START',
  GET_CUSTOMER_LOGIN_SUCCESS: 'GET_CUSTOMER_LOGIN_SUCCESS',
  GET_CUSTOMER_LOGIN_FINISH: 'GET_CUSTOMER_LOGIN_FINISH',
  RESET_CUSTOMER_LOGIN: 'RESET_CUSTOMER_LOGIN',

  DELETE_CUSTOMER_LOGIN_START: 'DELETE_CUSTOMER_LOGIN_START',
  DELETE_CUSTOMER_LOGIN_SUCCESS: 'DELETE_CUSTOMER_LOGIN_SUCCESS',
  DELETE_CUSTOMER_LOGIN_FINISH: 'DELETE_CUSTOMER_LOGIN_FINISH',

  ACTIVATE_CUSTOMER_LOGIN_REQUEST: 'ACTIVATE_CUSTOMER_LOGIN_REQUEST',
  ACTIVATE_CUSTOMER_LOGIN_SUCCESS: 'ACTIVATE_CUSTOMER_LOGIN_SUCCESS',
  ACTIVATE_CUSTOMER_LOGIN_FINISH: 'ACTIVATE_CUSTOMER_LOGIN_FINISH',

  DEACTIVATE_CUSTOMER_LOGIN_REQUEST: 'DEACTIVATE_CUSTOMER_LOGIN_REQUEST',
  DEACTIVATE_CUSTOMER_LOGIN_SUCCESS: 'DEACTIVATE_CUSTOMER_LOGIN_SUCCESS',
  DEACTIVATE_CUSTOMER_LOGIN_FINISH: 'DEACTIVATE_CUSTOMER_LOGIN_FINISH',
}
