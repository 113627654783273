import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {FcViewDetails} from 'react-icons/fc'
import {FiEye} from 'react-icons/fi'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {getTodayDate} from 'src/cms/helpers'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as vacancy from '../redux'
import AddVacancy from './AddVacancy'

const Vacancy = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {
    data,
    loading,
    toggleLoading,
    importSuccess,
    exportSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    activateSuccess,
    deactivateSuccess,
    success,
    deleteSuccess,
  } = useSelector((state: any) => state.vacancy)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const enableVacancyData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(vacancy.actions.activateVacancy(formData))
    } else {
      toast.error('No data Selected')
    }
  }
  const handleToggleAction = (data: {[key: string]: string}) => {
    data?.status
      ? dispatch(vacancy.actions.singleDeactivateVacancy({id: data.id}))
      : dispatch(vacancy.actions.singleActivateVacancy({id: data.id}))
  }
  const disableVacancyData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(vacancy.actions.deactivateVacancy(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Applicants</Tooltip>}>
          <IconButton
            onClick={() => history.push(`/career/vacancy/${rowData?.id}`, {state: rowData})}
            appearance='subtle'
            icon={<FiEye />}
            data-cy='view-applicants'
          />
        </Whisper>
        <Whisper
          placement='top'
          trigger='hover'
          speaker={<Tooltip>View Applicants Status</Tooltip>}
        >
          <IconButton
            onClick={() =>
              history.push(`/career/vacancy-applicant-report/${rowData?.id}`, {state: rowData})
            }
            data-cy='view-applicants-status'
            appearance='subtle'
            icon={<FcViewDetails />}
          />

          {/* {rowData?.applicantStatus?.shortListedForInterview} Vac */}
        </Whisper>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              data-cy='edit-button'
              icon={<Edit2 />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let atmId = [rowData.id]
                setCheckedValues(atmId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
            <Toggle
              size='sm'
              checked={rowData.status}
              data-cy='status-button'
              onClick={() => handleToggleAction(rowData)}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Job Code',
      dataKey: 'jobCode',
      flexGrow: 1,
      cell: <Cell dataKey='jobCode' />,
      sortable: true,
    },
    {
      label: 'Job Title',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    {
      label: 'Posted Date',
      dataKey: 'postedDate',
      flexGrow: 1,
      cell: <Cell dataKey='postedDate' />,
      sortable: false,
    },
    {
      label: 'Expiry Date',
      dataKey: 'expiryDate',
      width: 100,
      cell: <Cell dataKey='expiryDate' />,
      sortable: false,
    },
    {
      label: 'Vacancy Status',
      dataKey: 'expiryDate',
      width: 150,
      cell: (
        <Cell>
          {(rowData) => {
            return <p>Total Applied : {rowData?.applicantStatus?.totalApplied}</p>
          }}
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 200,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(vacancy.actions.getVacancy(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Vacancy deleted successfully')
    }
    if (activateSuccess) {
      toast.success('Vacancy enabled successfully')
    }
    if (deactivateSuccess) {
      toast.success('Vacancy disabled successfully')
    }
    if (importSuccess) {
      toast.success('Vacancy imported successfully')
    }
    handleChecked([])
    dispatch(vacancy?.actions.getVacancy(params))
  }, [deleteSuccess, success, activateSuccess, deactivateSuccess, exportSuccess, importSuccess])

  useEffect(() => {
    if (singleActivateSuccess) {
      dispatch(vacancy?.actions.getVacancy(params))
      toast.success('Vacancy enabled successfully')
    }

    handleChecked([])
  }, [singleActivateSuccess])
  useEffect(() => {
    if (singleDeactivateSuccess) {
      toast.success('Vacancy disabled successfully')
    }
    handleChecked([])
  }, [singleDeactivateSuccess])

  const vacancyData = data?.vacancy
    ? data?.vacancy?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        jobCode: item.jobCode,
        slug: item.slug,
        title: item.title,
        titleNp: item.titleNp,
        seats: item.seats,
        startingSalary: item.startingSalary,
        fromAge: item.fromAge,
        toAge: item.toAge,
        postedDate: item.postedDate,
        expiryDate: item.expiryDate,
        expiryTime: item?.expiryTime
          ? moment(`${getTodayDate()} ${item?.expiryTime}`).toDate()
          : null,
        experienceMonth: item.experienceMonth,
        branches: item?.branches?.branch,
        positions: item.positions.position,
        provinces: item?.provinces?.province || [],
        departments: item.departments.department,
        qualifications: item.qualifications?.qualification,
        examCenters: item.examCenters?.examCenter,
        details: item.details,
        detailsNp: item.detailsNp,
        foreignDegree: item.foreignDegree,
        experience: item.experience,
        position: item.position,
        department: item.department,
        experienceInQuickReq: item.experienceInQuickReq,
        deadlineInQuickReq: item.deadlineInQuickReq,
        qualificationInQuickReq: item.qualificationInQuickReq,
        expectedSalary: item.expectedSalary,
        currentExperience: item.currentExperience,
        licenseHolder: item.licenseHolder,
        applyButton: item.applyButton,
        examCenter: item.examCenter,
        interview: item.interview,
        groupDiscussion: item.groupDiscussion,
        status: item.status,
        exam: item.exam,
        applicantStatus: item.applicantStatus,
        vendor: item.vendor,
        vendorList: item.vendorList.vendors,
      }))
    : []
  const filteredVacancy = vacancyData.filter((items: any) => items.vendor === true)
  const vendorRoleId: any = sessionStorage.getItem('vendorRoleId')
  const vendorId: any = sessionStorage.getItem('vendorId')
  const vendorFilter = filteredVacancy?.filter(
    (item: any) => item.vendorList.some((data: any) => data.id === vendorId) && item
  )

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleExport = () => {
    //dispatch(atm.actions.exportFile('Vacancy Data', params))
  }

  const handleDelete = () => {
    let vacancyId: any = checkedValues?.map((value) => ({vacancyId: value}))
    dispatch(vacancy?.actions?.deleteVacancy(vacancyId))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(vacancy.actions.getVacancy(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Vacancy Manager'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleExport={handleExport}
          enableMultiple={enableVacancyData}
          disableMultiple={disableVacancyData}
          importShow={false}
          exportShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={vendorRoleId == 'true' ? vendorFilter : vacancyData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddVacancy
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}

          <>
            {alertOpen &&
              (!isEmpty(checkedValues) ? (
                <DeleteModal
                  handleClick={() => handleDelete()}
                  isOpen={alertOpen}
                  handleClose={() => handleAlertClose()}
                />
              ) : (
                toast.error('No data selected') && setAlertOpen(false)
              ))}
          </>
        </div>
      </div>
    </>
  )
}

export default Vacancy
