import {ParamsModel} from 'src/app/modules/common/Model'
import {LayoutModel, SortLayoutModel} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  // get Layout
  getAllLayout: () => ({
    type: actionTypes.GET_ALL_LAYOUT_START,
  }),
  getAllLayoutSuccess: (data: LayoutModel | any) => ({
    type: actionTypes.GET_ALL_LAYOUT_SUCCESS,
    payload: data,
  }),
  getAllLayoutFinish: () => ({
    type: actionTypes.GET_ALL_LAYOUT_FINISH,
  }),

  // get Layout DATA
  getLayout: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_LAYOUT_START,
    payload: params,
  }),
  getLayoutSuccess: (data: LayoutModel | any) => ({
    type: actionTypes.GET_LAYOUT_SUCCESS,
    payload: data,
  }),
  getLayoutFinish: () => ({
    type: actionTypes.GET_LAYOUT_FINISH,
  }),

  // create key
  addLayout: (data: LayoutModel | any) => ({
    type: actionTypes.ADD_LAYOUT_START,
    payload: data,
  }),
  addLayoutSuccess: (task: any) => ({
    type: actionTypes.ADD_LAYOUT_SUCCESS,
    payload: task,
  }),
  addLayoutFinish: () => ({
    type: actionTypes.ADD_LAYOUT_FINISH,
  }),
  resetLayout: () => ({
    type: actionTypes.RESET_LAYOUT,
  }),

  //Update Layout
  updateLayout: (data: LayoutModel | any, id: string) => ({
    type: actionTypes.UPDATE_LAYOUT_START,
    payload: data,
    id,
  }),

  updateLayoutSuccess: (data: LayoutModel | any) => ({
    type: actionTypes.UPDATE_LAYOUT_SUCCESS,
    payload: data,
  }),

  updateLayoutFinish: () => ({
    type: actionTypes.UPDATE_LAYOUT_FINISH,
  }),

  // delete key
  deleteLayout: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_LAYOUT_START,
    payload: {layoutId: data},
  }),
  deleteLayoutSuccess: (data: any) => ({
    type: actionTypes.DELETE_LAYOUT_SUCCESS,
    payload: data,
  }),
  deleteLayoutFinish: () => ({
    type: actionTypes.DELETE_LAYOUT_FINISH,
  }),

  //Enable Layout
  enableLayout: (data: any) => ({
    type: actionTypes.ENABLE_LAYOUT_REQUEST,
    payload: {data},
  }),

  enableLayoutSuccess: (task: any) => ({
    type: actionTypes.ENABLE_LAYOUT_SUCCESS,
    payload: task,
  }),
  enableLayoutFinish: () => ({
    type: actionTypes.ENABLE_LAYOUT_FINISH,
  }),

  //Disable Layout
  disableLayout: (data: any) => ({
    type: actionTypes.DISABLE_LAYOUT_REQUEST,
    payload: {data},
  }),

  disableLayoutSuccess: (task: any) => ({
    type: actionTypes.DISABLE_LAYOUT_SUCCESS,
    payload: task,
  }),
  disableLayoutFinish: () => ({
    type: actionTypes.DISABLE_LAYOUT_FINISH,
  }),

  //Enable Layout
  singleEnableLayout: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_LAYOUT_REQUEST,
    payload: {data},
  }),

  singleEnableLayoutSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_LAYOUT_SUCCESS,
    payload: task,
  }),
  singleEnableLayoutFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_LAYOUT_FINISH,
  }),

  //Disable Layout
  singleDisableLayout: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_LAYOUT_REQUEST,
    payload: {data},
  }),

  singleDisableLayoutSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_LAYOUT_SUCCESS,
    payload: task,
  }),
  singleDisableLayoutFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_LAYOUT_FINISH,
  }),

  // sort
  sortLayout: (data: SortLayoutModel) => ({
    type: actionTypes.SORT_LAYOUT_START,
    payload: data,
  }),
  sortLayoutSuccess: (data: Array<LayoutModel>) => ({
    type: actionTypes.SORT_LAYOUT_SUCCESS,
    payload: data,
  }),
  sortLayoutFinish: () => ({
    type: actionTypes.SORT_LAYOUT_FINISH,
  }),
  sortLayoutReset: () => ({
    type: actionTypes.SORT_LAYOUT_RESET,
  }),
}
