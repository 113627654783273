export const actionTypes: any = {
  // get
  GET_SUBSCRIBE_START: 'GET_SUBSCRIBE_START',
  GET_SUBSCRIBE_SUCCESS: 'GET_SUBSCRIBE_SUCCESS',
  GET_SUBSCRIBE_FINISH: 'GET_SUBSCRIBE_FINISH',

  // delete SUBSCRIBE
  DELETE_SUBSCRIBE_START: 'DELETE_SUBSCRIBE_START',
  DELETE_SUBSCRIBE_SUCCESS: 'DELETE_SUBSCRIBE_SUCCESS',
  DELETE_SUBSCRIBE_FINISH: 'DELETE_SUBSCRIBE_FINISH',

  // get File csv xlsx
  EXPORT_SUBSCRIBE_FILE_START: 'EXPORT_SUBSCRIBE_FILE_START',
  EXPORT_SUBSCRIBE_FILE_SUCCESS: 'EXPORT_SUBSCRIBE_FILE_SUCCESS',
  EXPORT_SUBSCRIBE_FILE_FINISH: 'EXPORT_SUBSCRIBE_FILE_FINISH',
}
