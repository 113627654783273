export const actionTypes: any = {
  GET_ACTIVE_BENEFIT_SUB_CATEGORY_START: 'GET_ACTIVE_BENEFIT_SUB_CATEGORY_START',
  GET_ACTIVE_BENEFIT_SUB_CATEGORY_SUCCESS: 'GET_ACTIVE_BENEFIT_SUB_CATEGORY_SUCCESS',
  GET_ACTIVE_BENEFIT_SUB_CATEGORY_FINISH: 'GET_ACTIVE_BENEFIT_SUB_CATEGORY_FINISH',

  GET_BENEFIT_SUB_CATEGORY_START: 'GET_BENEFIT_SUB_CATEGORY_START',
  GET_BENEFIT_SUB_CATEGORY_SUCCESS: 'GET_BENEFIT_SUB_CATEGORY_SUCCESS',
  GET_BENEFIT_SUB_CATEGORY_FINISH: 'GET_BENEFIT_SUB_CATEGORY_FINISH',

  ADD_BENEFIT_SUB_CATEGORY_START: 'ADD_BENEFIT_SUB_CATEGORY_START',
  ADD_BENEFIT_SUB_CATEGORY_SUCCESS: 'ADD_BENEFIT_SUB_CATEGORY_SUCCESS',
  ADD_BENEFIT_SUB_CATEGORY_FINISH: 'ADD_BENEFIT_SUB_CATEGORY_FINISH',
  ADD_BENEFIT_SUB_CATEGORY_RESET: 'ADD_BENEFIT_SUB_CATEGORY_RESET',

  UPDATE_BENEFIT_SUB_CATEGORY_START: 'UPDATE_BENEFIT_SUB_CATEGORY_START',
  UPDATE_BENEFIT_SUB_CATEGORY_SUCCESS: 'UPDATE_BENEFIT_SUB_CATEGORY_SUCCESS',
  UPDATE_BENEFIT_SUB_CATEGORY_FINISH: 'UPDATE_BENEFIT_SUB_CATEGORY_FINISH',
  UPDATE_BENEFIT_SUB_CATEGORY_RESET: 'UPDATE_BENEFIT_SUB_CATEGORY_RESET',

  DELETE_BENEFIT_SUB_CATEGORY_START: 'DELETE_BENEFIT_SUB_CATEGORY_START',
  DELETE_BENEFIT_SUB_CATEGORY_SUCCESS: 'DELETE_BENEFIT_SUB_CATEGORY_SUCCESS',
  DELETE_BENEFIT_SUB_CATEGORY_FINISH: 'DELETE_BENEFIT_SUB_CATEGORY_FINISH',

  SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_FINISH: 'SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_FINISH',

  SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_FINISH',

  SORT_BENEFIT_SUB_CATEGORY_START: 'SORT_BENEFIT_SUB_CATEGORY_START',
  SORT_BENEFIT_SUB_CATEGORY_SUCCESS: 'SORT_BENEFIT_SUB_CATEGORY_SUCCESS',
  SORT_BENEFIT_SUB_CATEGORY_FINISH: 'SORT_BENEFIT_SUB_CATEGORY_FINISH',
  SORT_BENEFIT_SUB_CATEGORY_RESET: 'SORT_BENEFIT_SUB_CATEGORY_RESET',
}
