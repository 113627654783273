import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as layoutRedux from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddLayout = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.layout)

  useEffect(() => {
    if (success) {
      dispatch(layoutRedux?.actions.getLayout(params))
      isEmpty(editSelectedData)
        ? toast.success('Layout added successfully')
        : toast.success('Layout edited successfully')
      dispatch(layoutRedux?.actions?.resetLayout())
      handleClose()
    }
  }, [success])

  const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    layouts: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required('Layout title is required').nullable(),
        image: Yup.string().required('Image is required').nullable(),
      })
    ),
  })

  return (
    <div className='modal-container' data-cy='search-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Layout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                layouts: [
                  {
                    title: '',
                    image: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  layouts: !isEmpty(values?.layouts?.[0]?.image)
                    ? values?.layouts?.map((item: {[key: string]: string}) => ({
                        ...item,
                        id: !isEmpty(editSelectedData) ? item?.id : null,
                      }))
                    : null,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(layoutRedux.actions.updateLayout(formData, editSelectedData?.id))
                } else {
                  dispatch(layoutRedux.actions.addLayout(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['id', 'title', 'layouts']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='search-category-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='search-category-name-text-fields'
                          />
                        </div>

                        <div className='row'>
                          <FieldArray
                            name='layouts'
                            render={(arrayHelpers) => (
                              <div>
                                <h5 className='rs-modal-title mb-5'>Layouts</h5>
                                {values?.layouts && values?.layouts.length > 0
                                  ? values?.layouts?.map((responseData: any, index: any) => (
                                      <div className='row mb-4' key={index}>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Layout Name'
                                            name={`layouts[${index}].title`}
                                            label='Layout Title'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            required
                                            touched={touched}
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormInputMediaManager
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            label='Image'
                                            name={`layouts[${index}].image`}
                                            setFieldValue={setFieldValue}
                                            value={values?.layouts[index]?.image}
                                            showImageOnly={true}
                                            required
                                            disable={
                                              isEmpty(values?.layouts[index]?.title) ? true : false
                                            }
                                          />
                                          {!isEmpty(responseData?.image) ? (
                                            <>
                                              <li className='listing'>
                                                <div className='thumbImageBlock'>
                                                  <button
                                                    type='button'
                                                    title='Remove'
                                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                    onClick={() => {
                                                      setFieldValue(`layouts[${index}].image`, '')
                                                    }}
                                                    data-cy='modal-thumbnail-remove'
                                                  >
                                                    X
                                                  </button>

                                                  <img
                                                    className='thumbImage w-100 h-100'
                                                    src={`${imageBaseUrl}/${responseData?.image}`}
                                                    alt='image'
                                                  />
                                                </div>
                                              </li>
                                            </>
                                          ) : null}
                                        </div>

                                        <div className='col-md-6 col-xs-12' />
                                        {index > 0 && (
                                          <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                              data-cy='modal-field-remove'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}
                                {values?.layouts[values?.layouts?.length - 1]?.image && (
                                  <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                    <button
                                      className='p-2 ps-5 pe-5 btn btn-primary'
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.push({
                                          title: '',
                                          image: '',
                                        })
                                      }
                                      data-cy='modal-field-add'
                                    >
                                      + Add More
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddLayout
