export const actionTypes: any = {
  // ADD push notification
  ADD_PUSH_NOTIFICATION_SCHEDULE_START: 'ADD_PUSH_NOTIFICATION_SCHEDULE_START',
  ADD_PUSH_NOTIFICATION_SCHEDULE_SUCCESS: 'ADD_PUSH_NOTIFICATION_SCHEDULE_SUCCESS',
  ADD_PUSH_NOTIFICATION_SCHEDULE_FINISH: 'ADD_PUSH_NOTIFICATION_SCHEDULE_FINISH',
  ADD_PUSH_NOTIFICATION_SCHEDULE_RESET: 'ADD_PUSH_NOTIFICATION_SCHEDULE_RESET',

  // get push notification schedule
  GET_PUSH_NOTIFICATION_SCHEDULE_START: 'GET_PUSH_NOTIFICATION_SCHEDULE_START',
  GET_PUSH_NOTIFICATION_SCHEDULE_SUCCESS: 'GET_PUSH_NOTIFICATION_SCHEDULE_SUCCESS',
  GET_PUSH_NOTIFICATION_SCHEDULE_FINISH: 'GET_PUSH_NOTIFICATION_SCHEDULE_FINISH',

  // Update push notification schedule
  UPDATE_PUSH_NOTIFICATION_SCHEDULE_START: 'UPDATE_PUSH_NOTIFICATION_SCHEDULE_START',
  UPDATE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS: 'UPDATE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS',
  UPDATE_PUSH_NOTIFICATION_SCHEDULE_FINISH: 'UPDATE_PUSH_NOTIFICATION_SCHEDULE_FINISH',
  UPDATE_PUSH_NOTIFICATION_SCHEDULE_RESET: 'UPDATE_PUSH_NOTIFICATION_SCHEDULE_RESET',

  // delete sub category
  DELETE_PUSH_NOTIFICATION_SCHEDULE_START: 'DELETE_PUSH_NOTIFICATION_SCHEDULE_START',
  DELETE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS: 'DELETE_PUSH_NOTIFICATION_SCHEDULE_SUCCESS',
  DELETE_PUSH_NOTIFICATION_SCHEDULE_FINISH: 'DELETE_PUSH_NOTIFICATION_SCHEDULE_FINISH',

  // get sub category
  GET_NOTIFICATION_START: 'GET_NOTIFICATION_START',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FINISH: 'GET_NOTIFICATION_FINISH',

  // ADD sub category
  ADD_NOTIFICATION_START: 'ADD_NOTIFICATION_START',
  ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FINISH: 'ADD_NOTIFICATION_FINISH',
  ADD_NOTIFICATION_RESET: 'ADD_NOTIFICATION_RESET',

  // Update sub category
  UPDATE_NOTIFICATION_START: 'UPDATE_NOTIFICATION_START',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FINISH: 'UPDATE_NOTIFICATION_FINISH',
  UPDATE_NOTIFICATION_RESET: 'UPDATE_NOTIFICATION_RESET',

  // delete sub category
  DELETE_NOTIFICATION_START: 'DELETE_NOTIFICATION_START',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FINISH: 'DELETE_NOTIFICATION_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_NOTIFICATION_REQUEST: 'SINGLE_ACTIVATE_NOTIFICATION_REQUEST',
  SINGLE_ACTIVATE_NOTIFICATION_SUCCESS: 'SINGLE_ACTIVATE_NOTIFICATION_SUCCESS',
  SINGLE_ACTIVATE_NOTIFICATION_FINISH: 'SINGLE_ACTIVATE_NOTIFICATION_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_NOTIFICATION_REQUEST: 'SINGLE_DEACTIVATE_NOTIFICATION_REQUEST',
  SINGLE_DEACTIVATE_NOTIFICATION_SUCCESS: 'SINGLE_DEACTIVATE_NOTIFICATION_SUCCESS',
  SINGLE_DEACTIVATE_NOTIFICATION_FINISH: 'SINGLE_DEACTIVATE_NOTIFICATION_FINISH',
}
