export const actionTypes: any = {
  // get sub category
  GET_NEWS_START: 'GET_NEWS_START',
  GET_NEWS_SUCCESS: 'GET_NEWS_SUCCESS',
  GET_NEWS_FINISH: 'GET_NEWS_FINISH',

  // ADD sub category
  ADD_NEWS_START: 'ADD_NEWS_START',
  ADD_NEWS_SUCCESS: 'ADD_NEWS_SUCCESS',
  ADD_NEWS_FINISH: 'ADD_NEWS_FINISH',
  ADD_NEWS_RESET: 'ADD_NEWS_RESET',

  // Update sub category
  UPDATE_NEWS_START: 'UPDATE_NEWS_START',
  UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
  UPDATE_NEWS_FINISH: 'UPDATE_NEWS_FINISH',
  UPDATE_NEWS_RESET: 'UPDATE_NEWS_RESET',

  // delete sub category
  DELETE_NEWS_START: 'DELETE_NEWS_START',
  DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',
  DELETE_NEWS_FINISH: 'DELETE_NEWS_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_NEWS_REQUEST: 'SINGLE_ACTIVATE_NEWS_REQUEST',
  SINGLE_ACTIVATE_NEWS_SUCCESS: 'SINGLE_ACTIVATE_NEWS_SUCCESS',
  SINGLE_ACTIVATE_NEWS_FINISH: 'SINGLE_ACTIVATE_NEWS_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_NEWS_REQUEST: 'SINGLE_DEACTIVATE_NEWS_REQUEST',
  SINGLE_DEACTIVATE_NEWS_SUCCESS: 'SINGLE_DEACTIVATE_NEWS_SUCCESS',
  SINGLE_DEACTIVATE_NEWS_FINISH: 'SINGLE_DEACTIVATE_NEWS_FINISH',
}
