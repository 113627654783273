/* eslint-disable */
import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as appointmentTypeRedux from 'src/app/modules/appointments/components/applyAppointment/redux'
import {IAppointmentTypeState} from 'src/app/modules/appointments/components/applyAppointment/redux'
import * as appointmentStatusRedux from 'src/app/modules/appointments/components/appointmentRequest/appointmentStatus/redux'
import {IAppointmentStatusState} from 'src/app/modules/appointments/components/appointmentRequest/appointmentStatus/redux'
import * as appointmentRequestRedux from 'src/app/modules/appointments/components/appointmentRequest/redux'
import * as appointmentReasonRedux from 'src/app/modules/appointments/components/reasonForAppointment/redux'
import {IAppointmentReasonState} from 'src/app/modules/appointments/components/reasonForAppointment/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {IBranchState} from 'src/app/modules/network/components/branch'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as branchRedux from '../../../../network/index'

const FORM_VALIDATION = Yup.object().shape({
  statusId: Yup.string().required('Status Id is required'),
  remarks: Yup.string().required('Remarks is required'),
})

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
  appointmentTypeId?: string
}
const AddStatus = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
  appointmentTypeId,
}: Props) => {
  const dispatch = useDispatch()
  // const statusParams: {id: string} = useParams()

  const appointmentStatusData: IAppointmentStatusState = useSelector(
    (state: any) => state.appointmentStatus
  )

  const appointmentRequestData = useSelector((state: any) => state.appointmentRequest)
  const appointmentTypeData: IAppointmentTypeState = useSelector(
    (state: any) => state.appointmentType
  )

  const {meetingType} = appointmentTypeData

  const appointmentReasonData: IAppointmentReasonState = useSelector(
    (state: any) => state.appointmentReason
  )

  const branchData: IBranchState = useSelector((state: any) => state.branch)

  const {loading, success} = useSelector((state: any) => state.appointmentRequest)

  useEffect(() => {
    dispatch(appointmentStatusRedux.actions.getAppointmentStatus())
    dispatch(appointmentTypeRedux.actions.getAppointmentType(params))
    dispatch(appointmentTypeRedux.actions.getMeetingType())
    dispatch(appointmentReasonRedux.actions.getAllAppointmentReason())
    dispatch(branchRedux.branch.actions.getAllBranchList())
  }, [])

  /* const appointmentStatusOptions = appointmentStatusData?.data?.appointmentStatus.map((item:{displayName:string,id:string}) => ({
    label: item.displayName,
    value: item.id,
  }))  */

  const appointmentTypeOptions = appointmentTypeData?.data?.appointmentType?.map((item: any) => ({
    label: item.title,
    value: item.id,
  }))

  const appointmentReasonOptions = appointmentReasonData?.data?.appointmentReason?.map(
    (reasonItem: any) => ({
      label: reasonItem.title,
      value: reasonItem.id,
    })
  )

  const branchOptions = branchData?.data?.branch?.map((branchitem: any) => ({
    label: branchitem.title,
    value: branchitem.id,
  }))

  const meetingTypeOptions = meetingType?.map((item: any) => ({
    label: item.displayName,
    value: item.id,
  }))

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const settingType: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'meetingType')
    .map((item: any) => item)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(appointmentRequestRedux?.actions.getAppointmentRequest(params))
      isEmpty(editSelectedData)
        ? toast.success('Appointment status updated successfully')
        : toast.success('Appointment Status edited successfully')
      //dispatch(appointmentRequestRedux?.actions.createAppointmentRequestReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>View Appointment List </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                //appointmentTypeId: statusParams.id,
                appointmentTypeId: '',
                appointmentReasonId: '',
                meetingTypeId: '',
                appointmentDate: '',
                appointmentTime: '',
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                mobileNumber: '',
                additionalAttendees: '',
                branchId: '',
                notes: '',
              }}
              //validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                }
                /*  if (!isEmpty(editSelectedData)) {}
            dispatch(
              appointmentRequestRedux.actions.updateAppointmentRequest(formData, statusParams?.id)
            )

             else {
              dispatch(appointmentRequestRedux.actions.CreateAppointmentRequest(formData))
            } */
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'appointmentTypeId',
                      'appointmentReasonId',
                      'meetingTypeId',
                      'appointmentDate',
                      'appointmentTime',
                      'firstName',
                      'middleName',
                      'lastName',
                      'email',
                      'mobileNumber',
                      'additionalAttendees',
                      'branchId',
                      'notes',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-md-6 '>
                          <FormSelect
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Appointment Type'
                            label='Appointment Type'
                            name='appointmentTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={appointmentTypeOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                            disabled={!isEmpty(editSelectedData) ? true : false}
                          />
                        </div>
                        <div className='col-md-6 '>
                          <FormSelect
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Appointment Reason'
                            label='Appointment Reason'
                            name='appointmentReasonId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={appointmentReasonOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                            disabled={!isEmpty(editSelectedData) ? true : false}
                          />
                        </div>
                      </div>
                      {settingType[0]?.value !== '1' && (
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select  Meeting Type'
                            label='Meeting Type'
                            name='meetingTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={meetingTypeOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                            multiple={true}
                            disabled={!isEmpty(editSelectedData) ? true : false}
                          />
                        </div>
                      )}

                      <div className='col-md-6 col-xs-12'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-md-12'
                          placeholder='Please select  a Branch'
                          label='Branch '
                          name='branchId'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          options={branchOptions}
                          values={values}
                          setFieldValue={setFieldValue}
                          required
                          multiple={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='First Name'
                          name='firstName'
                          label='First Name'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='Last Name'
                          name='middleName'
                          label='Middle Name'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='Last Name'
                          name='lastName'
                          label='Last Name'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='Email'
                          name='email'
                          label='Email'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='Mobile Number'
                          name='mobileNumber'
                          label='Mobile Number'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='text'
                          placeholder='Additional Attendees'
                          name='additionalAttendees'
                          label='Additional Attendees'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                      <div className='col-12'>
                        <FormTextBox
                          type='textarea'
                          placeholder='Notes'
                          name='notes'
                          label='Notes'
                          containerClassName=''
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required={true}
                          disabled={!isEmpty(editSelectedData) ? true : false}
                        />
                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                      >
                        Cancel
                      </button>
                    </div> */}
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddStatus
