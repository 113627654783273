export const actionTypes: any = {
  // get
  GET_GRIEVANCE_START: 'GET_GRIEVANCE_START',
  GET_GRIEVANCE_SUCCESS: 'GET_GRIEVANCE_SUCCESS',
  GET_GRIEVANCE_FINISH: 'GET_GRIEVANCE_FINISH',

  // delete GRIEVANCE
  DELETE_GRIEVANCE_START: 'DELETE_GRIEVANCE_START',
  DELETE_GRIEVANCE_SUCCESS: 'DELETE_GRIEVANCE_SUCCESS',
  DELETE_GRIEVANCE_FINISH: 'DELETE_GRIEVANCE_FINISH',

  // get File csv xlsx
  EXPORT_GRIEVANCE_FILE_START: 'EXPORT_GRIEVANCE_FILE_START',
  EXPORT_GRIEVANCE_FILE_SUCCESS: 'EXPORT_GRIEVANCE_FILE_SUCCESS',
  EXPORT_GRIEVANCE_FILE_FINISH: 'EXPORT_GRIEVANCE_FILE_FINISH',
}
