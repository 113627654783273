import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {SortBannerActionModelOffer, SortBannerModelOffer} from '../Model'

//Get Banner saga
function* getBannerOfferSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getBannerOffer, params)
    const data: any = response?.data
    yield put(actions.getBannerOfferSuccess(data))
  } catch (error: any) {
    yield put(actions.getBannerOfferError(error))
  }
}

//Get Banner saga
function* getAllBannerSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAllBanner)
    const data: any = response?.data
    yield put(actions.getAllBannerOfferSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAllBannerOfferError())
  }
}

//Get Banner saga
function* getColorOptionSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getColorOption)
    const data: any = response?.data
    yield put(actions.getBannerOfferColorOptionSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getBannerOfferColorOptionError())
  }
}

//Create Banner
function* createBannerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addBanner, body)
    yield put({type: actionTypes.ADD_BANNER_OFFER_SUCCESS, payload: response.data})
    yield delay(100)
    yield put({type: actionTypes.ADD_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_BANNER_OFFER_FINISH})
  }
}

//Update Banner
function* updateBanner(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(service.updateBanner, body, action.payload?.id)
    yield put({type: actionTypes.UPDATE_BANNER_OFFER_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_BANNER_OFFER_FINISH})
  }
}

//Activate Deactivate Banner Saga
function* activateBannerSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateBanner, selectedBanners)
    yield put({type: actionTypes.ACTIVATE_BANNER_OFFER_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.ACTIVATE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_BANNER_OFFER_FINISH})
  }
}

function* singleActivateBannerSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleActivateBanner, selectedBanner)

    yield put({type: actionTypes.SINGLE_ACTIVATE_BANNER_OFFER_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_BANNER_OFFER_FINISH})
  }
}

function* deactivateBannerSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateBanner, selectedBanner)

    yield put({type: actionTypes.DEACTIVATE_BANNER_OFFER_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.DEACTIVATE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_BANNER_OFFER_FINISH})
  }
}

function* singleDeactivateBannerSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDeactivateBanner, selectedBanner)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BANNER_OFFER_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BANNER_OFFER_FINISH})
  }
}

function* deleteBannerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBanner, body)
    yield put({type: actionTypes.DELETE_BANNER_OFFER_SUCCESS})
    yield delay(0.1)
    yield put({type: actionTypes.DELETE_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_BANNER_OFFER_FINISH})
  }
}

function* sortBanner(action: SortBannerActionModelOffer) {
  try {
    const body: SortBannerModelOffer = action.payload
    const response: ResponseModel = yield call(service.sortBanner, body)

    yield put({
      type: actionTypes.SORT_BANNER_OFFER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_BANNER_OFFER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BANNER_OFFER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_BANNER_OFFER_START, getBannerOfferSaga)
  yield takeLatest(actionTypes.GET_ALL_BANNER_OFFER_START, getAllBannerSaga)
  yield takeLatest(actionTypes.GET_BANNER_COLOR_OPTION_START, getColorOptionSaga)
  yield takeLatest(actionTypes.ADD_BANNER_OFFER_REQUEST, createBannerSaga)
  yield takeLatest(actionTypes.UPDATE_BANNER_OFFER_START, updateBanner)
  yield takeLatest(actionTypes.DELETE_BANNER_OFFER_START, deleteBannerSaga)
  yield takeLatest(actionTypes.ACTIVATE_BANNER_OFFER_REQUEST, activateBannerSaga)
  yield takeLatest(actionTypes.DEACTIVATE_BANNER_OFFER_REQUEST, deactivateBannerSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_BANNER_OFFER_REQUEST, singleActivateBannerSaga)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_BANNER_OFFER_REQUEST, singleDeactivateBannerSaga)
  yield takeLatest(actionTypes.SORT_BANNER_OFFER_START, sortBanner)
}
