import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {CheckTree} from 'rsuite'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as Yup from 'yup'
import * as roles from '../roles/index'
import * as users from '../users/index'
import * as userRedux from './index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  userId: Yup.string().required('User is required'),
})

const AddUserRole = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [selectedRoles, setSelectedRoles] = useState<Array<string>>([])

  const {loading, success} = useSelector((state: any) => state.userRoles)

  const userData = useSelector((state: any) => state.userManagement)

  const userRole = useSelector((state: any) => state.role)

  useEffect(() => {
    dispatch(
      users?.actions.getUsers({
        page: 1,
        limit: 100,
      })
    )
    dispatch(roles?.actions.getRole())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(userRedux?.actions.getUserRole(params))
      isEmpty(editSelectedData)
        ? toast.success('User Role added successfully')
        : toast.success('User Role edited successfully')
      dispatch(userRedux?.actions.updateUserRoleReset())
      handleClose()
    }
  }, [success])

  const userOptions = userData?.data?.user?.map((user: any) => ({
    label: user?.userName,
    value: user?.id,
  }))

  const roleOptions = userRole?.data?.role?.map((role: any) => ({
    label: role?.name,
    value: role?.id,
  }))

  const [selectedPermissions, setSelectedPermission] = useState<Array<string>>([])
  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      let roleIds: Array<string> = []
      editSelectedData?.roles?.map((rolesId: any) => roleIds.push(rolesId.id))

      setSelectedPermission(roleIds)
    }
  }, [editSelectedData])

  const permissionChange = (data: any) => {
    setSelectedPermission(data)
  }
  return (
    <div className='modal-container' data-cy='modal-user-role-container'>
      <Modal
        open={open}
        onClose={handleClose}
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Role User </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                userId: '',
                roles: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any, {setSubmitting}) => {
                const formData: any = {
                  ...values,
                  roles: selectedPermissions?.map((data: string) => ({
                    id: data,
                  })),
                }
                if (!isEmpty(editSelectedData)) {
                  delete formData['userId']
                  dispatch(userRedux.actions.updateUserRole(formData, editSelectedData?.id))
                  setSelectedPermission([])
                } else {
                  dispatch(userRedux?.actions.CreateUserRole(formData))
                  setSelectedPermission([])
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['userId', 'roles']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='card-user-role-body'>
                      <FormSelect
                        containerClassName='col-lg-8 '
                        placeholder='Select a user'
                        label='User'
                        name='userId'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                        options={userOptions}
                        values={values}
                        setFieldValue={setFieldValue}
                        disabled={actionType === 'Edit'}
                        data-cy='add-user-role-field'
                      />
                      <label
                        className='form-label fw-bolder text-dark fs-6 '
                        data-cy='form-user-role-label-check-box'
                      >
                        Select Roles
                      </label>
                      {/*  {roleOptions?.map((role: {label: string; value: string}) => ( */}
                      <div className='mb-5'>
                        <CheckTree
                          data={roleOptions}
                          // name='permission'
                          id='permission'
                          value={selectedPermissions}
                          onChange={(e: any) => permissionChange(e)}
                          style={{height: 'auto'}}
                          data-cy='permission-tree'
                        />
                        {/* <FormCheckbox
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            name='roles'
                            touched={touched}
                            errors={errors}
                            onChange={handleChange}
                            checkBoxText={role?.label}
                            checked={values?.roles[0]}
                          /> */}
                        {/* <FormRadio
                            containerClassName='col-lg-12 fv-row'
                            label='Select Roles'
                            name='roles'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            checkBoxText={role?.label}
                            value={role?.value}
                          /> */}
                      </div>
                      {/*  ))} */}
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddUserRole
