import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getCookieSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCookie, params)
    yield put(actions.getCookieSuccess(response?.data?.data))
    yield put(actions.getCookieFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCookieFinish())
  }
}

function* addCookieSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCookie, body)
    yield put(actions.addCookieSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCookieFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCookieFinish())
  }
}

function* updateCookieSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateCookie, body, action.payload?.id)
    yield put(actions.updateCookieSuccess(response.data?.data))
    yield put(actions.updateCookieFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCookieFinish())
  }
}

function* deleteCookieSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCookie, body)
    yield put(actions.deleteCookieSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteCookieFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCookieFinish())
  }
}

function* singleActivateCookie(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateCookie,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_COOKIE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_COOKIE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_COOKIE_FINISH})
  }
}

function* singleDeactivateCookie(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateCookie,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_COOKIE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_COOKIE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_COOKIE_FINISH})
  }
}

function* sortCookie(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortCookie, body)

    yield put({
      type: actionTypes.SORT_COOKIE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_COOKIE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_COOKIE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_COOKIE_START, getCookieSaga)
  yield takeLatest(actionTypes.ADD_COOKIE_START, addCookieSaga)
  yield takeLatest(actionTypes.UPDATE_COOKIE_START, updateCookieSaga)
  yield takeLatest(actionTypes.DELETE_COOKIE_START, deleteCookieSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_COOKIE_REQUEST, singleActivateCookie)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_COOKIE_REQUEST, singleDeactivateCookie)
  yield takeLatest(actionTypes.SORT_COOKIE_START, sortCookie)
}
