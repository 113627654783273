import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as cms from 'src/app/modules/cms'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as Yup from 'yup'
import * as productCategoryRedux from '../index'

import {IMenuState} from 'src/app/modules/cms/components/menu'
import {MenuOptionModal} from 'src/app/modules/cms/components/menu/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {convertToSlug} from 'src/cms/helpers/helperFunction'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  status: Yup.string().required('Status is required'),
})

const AddProductCategory = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.productCategory)
  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(productCategoryRedux?.actions.getProductCategory(params))
      isEmpty(editSelectedData)
        ? toast.success('Product category added successfully')
        : toast.success('Product category edited successfully')
      dispatch(productCategoryRedux?.actions?.resetProductCategory())
      handleClose()
    }
  }, [success])

  const topMenuOptions = topMenu?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const featuredOptions = [
    {label: 'Yes', value: 'yes'},
    {label: 'No', value: 'no'},
  ]

  const showProductCategoryItemsSection = checkBackendSetting('showProductCategoryItemsSection')
  const showProductCategoryTopMenuDropdown = checkBackendSetting(
    'showProductCategoryTopMenuDropdown'
  )
  const showProductCategoryTitle = checkBackendSetting('showProductCategoryTitle')
  const showProductCategoryShowInHomepageDropdown = checkBackendSetting(
    'showProductCategoryShowInHomepageDropdown'
  )

  const enableProductCategorySlugFiled = checkBackendSetting('enableProductCategorySlugFiled')

  useEffect(() => {
    if (showProductCategoryTopMenuDropdown) {
      dispatch(cms.menu.actions.getTopMenu())
    }
  }, [])
  return (
    <div className='modal-container' data-cy='product-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Product Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                topMenuId: '',
                name: '',
                text: '',
                show_in_homepage: '',
                slug: '',
                status: '',
                items: [
                  {
                    title: '',
                    image: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  topMenuId: values?.topMenuId,
                  slug: enableProductCategorySlugFiled
                    ? convertToSlug(values?.slug || values?.name)
                    : !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.name),
                  show_in_homepage: values?.show_in_homepage === 'yes' ? true : false,
                  items: values?.items?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item?.id : null,
                  })),
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    productCategoryRedux.actions.updateProductCategory(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(productCategoryRedux.actions.addProductCategory(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'slug',
                      'id',
                      'name',
                      'nameNp',
                      'items',
                      'topMenuId',
                      'text',
                      'textNp',
                      'show_in_homepage',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'show_in_homepage',
                      editSelectedData?.show_in_homepage === true ? 'yes' : 'no',
                      false
                    )
                  } else {
                    setFieldValue('show_in_homepage', 'no', false)
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='product-category-body'>
                      {showProductCategoryTopMenuDropdown && (
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select top menu'
                              label='Top Menu'
                              name='topMenuId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={topMenuOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='product-category-name-text-fields'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (NP)'
                            label='Name (NP)'
                            name='nameNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='product-category-name-np-text-field'
                          />
                        </div>
                        {showProductCategoryTitle && (
                          <>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Title'
                                label='Title (EN)'
                                name='text'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                data-cy='product-category-name-text-fields'
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Title (NP)'
                                label='Title (NP)'
                                name='textNp'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                data-cy='product-category-name-np-text-field'
                              />
                            </div>
                          </>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          {enableProductCategorySlugFiled ? (
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter slug'
                              label='Slug'
                              name='slug'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              value={values.slug || convertToSlug(values?.name)}
                              required
                              data-cy='add-news-slug-text-field'
                            />
                          ) : (
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter slug'
                              label='Slug'
                              name='slug'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              disabled={true}
                              value={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.slug
                                  : convertToSlug(values?.name)
                              }
                              data-cy='add-news-slug-text-field'
                            />
                          )}
                        </div>
                        {showProductCategoryShowInHomepageDropdown && (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select'
                              label='Show In Homepage'
                              name='show_in_homepage'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={featuredOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='product-category-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        {showProductCategoryItemsSection && (
                          <div className='row'>
                            <FieldArray
                              name='items'
                              render={(arrayHelpers) => (
                                <div>
                                  <h5 className='rs-modal-title mb-5'>Items</h5>
                                  {values?.items && values?.items.length > 0
                                    ? values?.items?.map((responseData: any, index: any) => (
                                        <div className='row mb-4' key={index}>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Title'
                                              name={`items[${index}].title`}
                                              label='Title'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormInputMediaManager
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              label='Image'
                                              name={`items[${index}].image`}
                                              setFieldValue={setFieldValue}
                                              value={values?.items[index]?.image}
                                              showImageOnly={true}
                                            />
                                            {!isEmpty(responseData?.image) ? (
                                              <>
                                                <li className='listing'>
                                                  <div className='thumbImageBlock'>
                                                    <button
                                                      type='button'
                                                      title='Remove'
                                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                      onClick={() => {
                                                        setFieldValue(`items[${index}].image`, '')
                                                      }}
                                                      data-cy='modal-thumbnail-remove'
                                                    >
                                                      X
                                                    </button>

                                                    <img
                                                      className='thumbImage w-100 h-100'
                                                      src={`${imageBaseUrl}/${responseData?.image}`}
                                                      alt='image'
                                                    />
                                                  </div>
                                                </li>
                                              </>
                                            ) : null}
                                          </div>

                                          <div className='col-md-6 col-xs-12' />
                                          {index > 0 && (
                                            <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                                data-cy='modal-field-remove'
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    : null}
                                  {values?.items[values?.items?.length - 1]?.image && (
                                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                      <button
                                        className='p-2 ps-5 pe-5 btn btn-primary'
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            image: '',
                                            title: '',
                                          })
                                        }
                                        data-cy='modal-field-add'
                                      >
                                        + Add More
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddProductCategory
