import {useEffect, useState} from 'react'

// rsuite
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiEdit2, FiEye, FiUserPlus, FiUserX, FiUsers, FiX} from 'react-icons/fi'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {careerValidation} from 'src/cms/helpers/constants'
import GiveScoreModal from '../../shortlistedCandidatesForExamReport/component/giveScoreModal'
import ViewCandidateDetails from '../../vacancy/component/ViewCandidateDetails'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CandidatesList = () => {
  const [showViewCandidateDetailsModal, setShowViewCandidatesModal] = useState(false)
  const [selectedCandidateId, setSelectedCandidateId] = useState('')
  const parameters: any = useParams()
  const vacancyStateData: any = useHistory()
  const vacancyDetail = vacancyStateData?.location?.state
  const [data, setData] = useState<any>([])
  const [metaData, setMetaData] = useState({total: 0})
  const [loading, setLoading] = useState(false)
  const [examScore, setExamScore] = useState(0)
  const [showScoreModal, setShowScoreModal] = useState(false)
  const [disableScoreSubmitButton, setDisableScoreSubmitButton] = useState(false)
  const [showRemoveFromShortlistConfirmPopup, setShowRemoveFromShortlistConfirmPopup] =
    useState(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    search: '',
    fromDate: '',
    toDate: '',
  })

  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [municipalityData, setMunicipalityData] = useState([])
  const [zoneData, setZoneData] = useState([])
  const [functionalAreaData, setFunctionalAreaData] = useState([])
  const [branchData, setBranchData] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [vacancyApplicantStatusData, setVacancyApplicantStatusData] = useState<any>([])

  useEffect(() => {
    axios.get(API_URL + '/province').then((res) => {
      setProvinceData(res.data.data.province)
    })
    axios.get(API_URL + '/district').then((res) => {
      setDistrictData(res.data.data.district)
    })
    axios.get(API_URL + '/zone').then((res) => {
      setZoneData(res.data.data.zone)
    })
    axios.get(API_URL + '/municipality').then((res) => {
      setMunicipalityData(res.data.data.municipality)
    })
    axios.get(API_URL + '/functionalarea').then((res) => {
      setFunctionalAreaData(res.data.data.functionalArea)
    })
    axios.get(API_URL + '/branch').then((res) => {
      setBranchData(res.data.data.branch)
    })
    axios.get(API_URL + '/department').then((res) => {
      setDepartmentData(res.data.data.department)
    })
    axios.get(API_URL + '/vacancy-applicant-status').then((res) => {
      setVacancyApplicantStatusData(res.data.data.vacancyApplicantStatus)
    })
  }, [])

  const getData = () => {
    setLoading(true)
    axios
      .get(
        API_URL +
          `/shortlisted-for-interview?vacancyId=${parameters?.vacancyId}&limit=${params.limit}&page=${params.page}&search=${params.search}&fromDate=${params.fromDate}&toDate=${params.toDate}`
      )
      .then((res) => {
        setData(res.data.data.vacancyApplicant)
        setMetaData(res.data.data.meta)
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
  }, [parameters?.vacancyId, params])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  //shortlisting functions
  const shortList = (applicantId: string, actionType: string) => {
    let selectedStatusId = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id
    axios
      .put(API_URL + `/apply-vacancy/${applicantId}`, {
        vacancyApplicantStatusId: selectedStatusId,
      })
      .then((res) => {
        toast.success('Success.')
        const updatedApplicantIndex = data.findIndex((dta: any) => dta.id === applicantId)
        const updatedData = [...data]
        updatedData[updatedApplicantIndex] = res.data?.data
        setData(updatedData)
      })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
            <IconButton
              onClick={() => {
                setShowViewCandidatesModal(true)
                setSelectedCandidateId(rowData?.id)
              }}
              data-cy='edit--view-details-button'
              appearance='subtle'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Edit'>
          <Whisper
            placement='top'
            trigger='hover'
            speaker={
              <Tooltip>
                {rowData?.vacancyApplicantInterviewMark?.interviewMark ? 'Update' : 'Give'}{' '}
                interview score
              </Tooltip>
            }
          >
            <IconButton
              onClick={() => {
                setShowScoreModal(true)
                setSelectedCandidateId(rowData?.id)
                setExamScore(rowData?.vacancyApplicantInterviewMark?.interviewMark)
              }}
              data-cy='edit-interview-score'
              appearance='subtle'
              icon={<FiEdit2 />}
            />
          </Whisper>
        </CheckPermissions>
        {careerValidation === 'true' ? (
          <>
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                <IconButton
                  onClick={() => {
                    if (confirm('Are you sure?')) {
                      changeCandidateStatus(rowData?.id, 'onboarded')
                    }
                  }}
                  data-cy='onboard-are-you-sure'
                  appearance='subtle'
                  icon={<FiUserPlus />}
                  disabled={rowData?.vacancyApplicantInterviewMark?.interviewMark ? false : true}
                />
              </Whisper>
            </CheckPermissions>
          </>
        ) : (
          <>
            <CheckPermissions type='Edit'>
              <Whisper
                placement='top'
                trigger='hover'
                speaker={<Tooltip>Shortlist for exam</Tooltip>}
              >
                <IconButton
                  onClick={() => {
                    if (confirm('Are you sure?')) {
                      changeCandidateStatus(rowData?.id, 'shortlisted')
                    }
                  }}
                  data-cy='shortlist-for-exam'
                  appearance='subtle'
                  icon={<FiUsers />}
                />
              </Whisper>
            </CheckPermissions>
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                <IconButton
                  onClick={() => {
                    if (confirm('Are you sure?')) {
                      changeCandidateStatus(rowData?.id, 'shortlisted_for_onboarding')
                    }
                  }}
                  data-cy='board-are-you-sure'
                  appearance='subtle'
                  icon={<FiUserPlus />}
                />
              </Whisper>
            </CheckPermissions>
          </>
        )}

        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Reject</Tooltip>}>
            <IconButton
              onClick={() => {
                if (confirm('Are you sure?')) {
                  shortList(rowData?.id, 'rejected')
                }
              }}
              data-cy='reject-are-you-sure'
              appearance='subtle'
              icon={<FiUserX />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper
            placement='top'
            trigger='hover'
            speaker={<Tooltip>Remove From Shortlist</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowRemoveFromShortlistConfirmPopup(true)
                setSelectedCandidateId(rowData?.id)
              }}
              data-cy='remove-from-shortlist'
              icon={<FiX />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns =
    careerValidation === 'true'
      ? [
          {
            label: 'S.N.',
            dataKey: 'sn',
            width: 60,
            cell: <Cell dataKey='sn' />,
          },
          {
            label: 'Unique Code',
            dataKey: 'uniqueCode',
            width: 120,
            cell: <Cell dataKey='uniqueCode' />,
            sortable: true,
          },
          {
            label: 'Name',
            dataKey: 'name',
            flexGrow: 1,
            cell: <Cell dataKey='name' />,
            sortable: true,
          },
          {
            label: 'Email',
            dataKey: 'email',
            flexGrow: 1,
            cell: <Cell dataKey='email' />,
            sortable: false,
          },
          {
            label: 'Exam Marks',
            flexGrow: 1,
            cell: (
              <Cell>
                {(rowData) => {
                  return <p>{rowData?.vacancyApplicantExamMark?.examMark || 'N/A'}</p>
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Group Discussion Marks',
            flexGrow: 1,
            cell: (
              <Cell>
                {(rowData) => {
                  return (
                    <p>
                      {rowData?.vacancyApplicantGroupDiscussionMark?.groupDiscussionMark || 'N/A'}
                    </p>
                  )
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Interview Marks',
            flexGrow: 1,
            cell: (
              <Cell>
                {(rowData) => {
                  return <p>{rowData?.vacancyApplicantInterviewMark?.interviewMark || 'N/A'}</p>
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Applied Date',
            dataKey: 'appliedDate',
            flexGrow: 1,
            width: 85,
            cell: <Cell dataKey='appliedDate' />,
            sortable: false,
          },
          {
            label: 'Status',
            dataKey: 'status',
            width: 200,
            cell: (
              <Cell>
                {(rowData) => {
                  return (
                    <p>
                      {vacancyApplicantStatusData.filter(
                        (d: {id: string}) => d.id === rowData?.vacancyApplicantStatusId
                      )[0]?.displayName || 'N/A'}
                    </p>
                  )
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Action',
            width: 220,
            align: 'center',
            cell: <ActionCell dataKey='id' />,
          },
        ]
      : [
          {
            label: 'S.N.',
            dataKey: 'sn',
            width: 50,
            cell: <Cell dataKey='sn' />,
          },
          {
            label: 'Unique Code',
            dataKey: 'uniqueCode',
            width: 120,
            cell: <Cell dataKey='uniqueCode' />,
            sortable: true,
          },
          {
            label: 'Name',
            dataKey: 'name',
            width: 180,
            cell: <Cell dataKey='name' />,
            sortable: true,
          },
          {
            label: 'Email',
            dataKey: 'email',
            width: 200,
            cell: <Cell dataKey='email' />,
            sortable: false,
          },
          {
            label: 'Exam Marks',
            width: 90,
            cell: (
              <Cell>
                {(rowData) => {
                  return <p>{rowData?.vacancyApplicantExamMark?.examMark || 'N/A'}</p>
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Interview Marks',
            width: 110,
            cell: (
              <Cell>
                {(rowData) => {
                  return (
                    <p>
                      {rowData?.vacancyApplicantInterviewMark?.interviewMark ||
                        rowData?.vacancyApplicantInterviewMark?.interview_marks ||
                        'N/A'}
                    </p>
                  )
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Applied Date',
            dataKey: 'appliedDate',
            width: 105,

            cell: <Cell dataKey='appliedDate' />,
            sortable: false,
          },
          {
            label: 'Status',
            dataKey: 'status',
            width: 160,
            cell: (
              <Cell>
                {(rowData) => {
                  return (
                    <p>
                      {vacancyApplicantStatusData.filter(
                        (d: {id: string}) => d.id === rowData?.vacancyApplicantStatusId
                      )[0]?.displayName || 'N/A'}
                    </p>
                  )
                }}
              </Cell>
            ),
            sortable: false,
          },
          {
            label: 'Action',
            width: 220,
            align: 'center',
            cell: <ActionCell dataKey='id' />,
          },
        ]

  const vacancyData =
    data?.length > 0
      ? data?.map((item: any, i: number) => ({
          sn: (params?.page - 1) * params?.limit + (i + 1),
          id: item.id,
          jobCode: item.jobCode,
          slug: item.slug,
          name: item.name,
          uniqueCode: item.uniqueCode,
          appliedDate: item.appliedDate,
          email: item.email,
          status: item.status,
          vacancyApplicantExamMark: item.vacancyApplicantExamMark,
          vacancyApplicantInterviewMark: item.vacancyApplicantInterviewMark,
          vacancyApplicantGroupDiscussionMark: item.vacancyApplicantGroupDiscussionMark,
          vacancyApplicantStatusId: item.vacancyApplicantStatusId,
        }))
      : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }
  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }
  const handleExport = () => {
    axios
      .get(
        API_URL +
          `/shortlisted-for-interview/export?vacancyId=${parameters.vacancyId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
      )
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'interview-candidates.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  const handleDelete = () => {
    let vacancyId: any = checkedValues?.map((value) => ({vacancyId: value}))
    //dispatch(vacancy?.actions?.deleteVacancy(vacancyId))
    handleAlertClose()
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleRefresh = () => {
    getData()
  }

  const handleScoreSubmit = () => {
    setDisableScoreSubmitButton(true)

    axios
      .patch(API_URL + '/shortlisted-interview-mark', {
        interviewMarks: [
          {
            id: selectedCandidateId,
            interviewMark: examScore,
          },
        ],
      })
      .then((res) => {
        toast.success('Successfully given the score')
        setShowScoreModal(false)
        const updatedData = [...data]
        const selectedCandidateIndex = data.findIndex(
          (d: {id: string}) => d.id === selectedCandidateId
        )
        updatedData[selectedCandidateIndex] = res.data.data.vacancyApplicant[0]
        setData(updatedData)
        setDisableScoreSubmitButton(false)
      })
      .catch((err) => {
        setDisableScoreSubmitButton(false)
        toast.error(err.response.data.message)
      })
  }

  const changeCandidateStatus = (candidateId: string, actionType: string) => {
    setShowRemoveFromShortlistConfirmPopup(false)

    let selectedStatusId = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id
    axios
      .put(API_URL + `/apply-vacancy/${candidateId || selectedCandidateId}`, {
        vacancyApplicantStatusId: selectedStatusId,
      })
      .then((res) => {
        toast.success('Success.')
        const updatedData = [...data]
        setData(
          updatedData.filter((d: {id: string}) => d.id !== (candidateId || selectedCandidateId))
        )
      })
  }

  let inputTimer: any

  const changeButtonName = () => {
    switch (vacancyDetail.interview) {
      case true:
        return 'Onboard Candidates'
      case false:
        return 'Onboard'
      default:
        return 'Onboard'
    }
  }

  const changeApplicantStatus = () => {
    switch (vacancyDetail.interview) {
      case true:
        return vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'shortlisted_for_interview'
        )[0].id
      case false:
        return vacancyApplicantStatusData?.filter((item: any) => item.systemName === 'onboarded')[0]
          .id
      default:
        return vacancyApplicantStatusData?.filter((item: any) => item.systemName === 'onboarded')[0]
          .id
    }
  }

  const shortListMultipleCandidates = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((applicantId: string) => ({
        vacancyApplicantId: applicantId,
        vacancyApplicantStatusId: changeApplicantStatus(),
      }))
      axios
        .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
        .then((res) => {
          toast.success('Success.')
          setCheckedValues([])
          getData()
        })
    } else {
      toast.error('No data Selected')
    }
  }

  const rejectMultipleCandidates = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((applicantId: string) => ({
        vacancyApplicantId: applicantId,
        vacancyApplicantStatusId: vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'rejected'
        )[0].id,
      }))
      axios
        .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
        .then((res) => {
          toast.success('Candidates Rejected.')
          setCheckedValues([])
          getData()
        })
    } else {
      toast.error('No data Selected')
    }
  }

  const [markStatus, setMarkStatus] = useState<any>([])
  useEffect(() => {
    if (!isEmpty(checkedValues)) {
      checkedValues.map((candidateId: any) => {
        vacancyData
          .filter((candidate: any) => candidate.id == candidateId)
          .map((item: any) => {
            !isEmpty(item.vacancyApplicantInterviewMark)
              ? setMarkStatus([...markStatus, true])
              : setMarkStatus([false])
          })
      })
    } else {
      setMarkStatus([])
    }
  }, [checkedValues])

  const handleDownloadResume = () => {
    let applicantIds = ''
    if (checkedValues.length > 0) {
      for (let e in checkedValues) {
        //creating comma seperated Ids
        applicantIds = applicantIds ? applicantIds + ',' + checkedValues[e] : checkedValues[e]
      }
    }

    !isEmpty(checkedValues)
      ? axios
          .get(API_URL + `/apply-vacancy/get-resume?vacancyApplicantId=${applicantIds}`)
          .then((res) => {
            const link = document.createElement('a')
            link.href = res.data?.data?.fileName
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
            handleChecked([])
          })
          .catch((err) => toast.error(err.message))
      : toast.error('No data selected')
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Interview Candidates List'
          params={params}
          setParams={(data: any) => {
            const duration = 1000
            clearTimeout(inputTimer)
            inputTimer = setTimeout(() => {
              setParams(data)
            }, duration)
          }}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          handleExport={handleExport}
          importShow={false}
          toggleMultipleShow={careerValidation === 'true' ? true : false}
          activeButtonName={changeButtonName()}
          inactiveButtonName={'Reject Candidates'}
          enableMultiple={shortListMultipleCandidates}
          disableMultiple={rejectMultipleCandidates}
          disableButton={markStatus[0] === true ? false : true}
          addShow={false}
          deleteShow={careerValidation === 'true' ? true : false}
          filterData={true}
          downloadResumeShow
          handleDownloadResume={handleDownloadResume}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={setCheckedValues}
            showCheckbox={true}
            columns={columns}
            data={vacancyData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />

          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={metaData?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {showViewCandidateDetailsModal && (
            <ViewCandidateDetails
              handleClose={() => setShowViewCandidatesModal(false)}
              data={data?.filter((d: any) => d.id === selectedCandidateId)[0]}
              provinceList={provinceData}
              districtList={districtData}
              zoneList={zoneData}
              functionalAreaList={functionalAreaData}
              municipalityList={municipalityData}
              departmentList={departmentData}
              branchList={branchData}
            />
          )}

          <>
            {alertOpen &&
              (!isEmpty(checkedValues) ? (
                <DeleteModal
                  handleClick={() => handleDelete()}
                  isOpen={alertOpen}
                  handleClose={() => handleAlertClose()}
                />
              ) : (
                toast.error('No data selected') && setAlertOpen(false)
              ))}
          </>

          {showRemoveFromShortlistConfirmPopup && (
            <DeleteModal
              handleClick={() => changeCandidateStatus('', 'pending')}
              isOpen={showRemoveFromShortlistConfirmPopup}
              handleClose={() => setShowRemoveFromShortlistConfirmPopup(false)}
              title='Remove'
            />
          )}

          {showScoreModal && (
            <GiveScoreModal
              handleClick={handleScoreSubmit}
              handleClose={() => setShowScoreModal(false)}
              isOpen={showScoreModal}
              score={examScore}
              setScore={setExamScore}
              disableSubmitButton={disableScoreSubmitButton}
              modalFor='Interview'
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CandidatesList
