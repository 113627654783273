export const actionTypes: any = {
  GET_SUCCESS_STORY_FILE_TYPE_START: 'GET_SUCCESS_STORY_FILE_TYPE_START',
  GET_SUCCESS_STORY_FILE_TYPE_SUCCESS: 'GET_SUCCESS_STORY_FILE_TYPE_SUCCESS',
  GET_SUCCESS_STORY_FILE_TYPE_FINISH: 'GET_SUCCESS_STORY_FILE_TYPE_FINISH',

  GET_SUCCESS_STORY_LIST_START: 'GET_SUCCESS_STORY_LIST_START',
  GET_SUCCESS_STORY_LIST_SUCCESS: 'GET_SUCCESS_STORY_LIST_SUCCESS',
  GET_SUCCESS_STORY_LIST_FINISH: 'GET_SUCCESS_STORY_LIST_FINISH',

  GET_SUCCESS_STORY_DATA_START: 'GET_SUCCESS_STORY_DATA_START',
  GET_SUCCESS_STORY_DATA_SUCCESS: 'GET_SUCCESS_STORY_DATA_SUCCESS',
  GET_SUCCESS_STORY_DATA_FINISH: 'GET_SUCCESS_STORY_DATA_FINISH',

  ADD_SUCCESS_STORY_ITEM_START: 'ADD_SUCCESS_STORY_ITEM_START',
  ADD_SUCCESS_STORY_ITEM_SUCCESS: 'ADD_SUCCESS_STORY_ITEM_SUCCESS',
  ADD_SUCCESS_STORY_ITEM_FINISH: 'ADD_SUCCESS_STORY_ITEM_FINISH',
  ADD_SUCCESS_STORY_ITEM_RESET: 'ADD_SUCCESS_STORY_ITEM_RESET',

  UPDATE_SUCCESS_STORY_ITEM_START: 'UPDATE_SUCCESS_STORY_ITEM_START',
  UPDATE_SUCCESS_STORY_ITEM_SUCCESS: 'UPDATE_SUCCESS_STORY_ITEM_SUCCESS',
  UPDATE_SUCCESS_STORY_ITEM_FINISH: 'UPDATE_SUCCESS_STORY_ITEM_FINISH',
  UPDATE_SUCCESS_STORY_ITEM_RESET: 'UPDATE_SUCCESS_STORY_ITEM_RESET',

  DELETE_BULK_SUCCESS_STORY_START: 'DELETE_BULK_SUCCESS_STORY_START',
  DELETE_BULK_SUCCESS_STORY_SUCCESS: 'DELETE_BULK_SUCCESS_STORY_SUCCESS',
  DELETE_BULK_SUCCESS_STORY_FINISH: 'DELETE_BULK_SUCCESS_STORY_FINISH',

  ACTIVATE_SUCCESS_STORY_START: 'ACTIVATE_SUCCESS_STORY_START',
  ACTIVATE_SUCCESS_STORY_SUCCESS: 'ACTIVATE_SUCCESS_STORY_SUCCESS',
  ACTIVATE_SUCCESS_STORY_FINISH: 'ACTIVATE_SUCCESS_STORY_FINISH',

  DEACTIVATE_SUCCESS_STORY_START: 'DEACTIVATE_SUCCESS_STORY_START',
  DEACTIVATE_SUCCESS_STORY_SUCCESS: 'DEACTIVATE_SUCCESS_STORY_SUCCESS',
  DEACTIVATE_SUCCESS_STORY_FINISH: 'DEACTIVATE_SUCCESS_STORY_FINISH',

  // ACTIVATE SUCCESS_STORY
  SINGLE_ACTIVATE_SUCCESS_STORY_START: 'SINGLE_ACTIVATE_SUCCESS_STORY_START',
  SINGLE_ACTIVATE_SUCCESS_STORY_SUCCESS: 'SINGLE_ACTIVATE_SUCCESS_STORY_SUCCESS',
  SINGLE_ACTIVATE_SUCCESS_STORY_FINISH: 'SINGLE_ACTIVATE_SUCCESS_STORY_FINISH',

  // DEACTIVATE SUCCESS_STORY
  SINGLE_DEACTIVATE_SUCCESS_STORY_START: 'SINGLE_DEACTIVATE_SUCCESS_STORY_START',
  SINGLE_DEACTIVATE_SUCCESS_STORY_SUCCESS: 'SINGLE_DEACTIVATE_SUCCESS_STORY_SUCCESS',
  SINGLE_DEACTIVATE_SUCCESS_STORY_FINISH: 'SINGLE_DEACTIVATE_SUCCESS_STORY_FINISH',

  // sort csr
  SORT_SUCCESS_STORY_START: 'SORT_SUCCESS_STORY_START',
  SORT_SUCCESS_STORY_SUCCESS: 'SORT_SUCCESS_STORY_SUCCESS',
  SORT_SUCCESS_STORY_FINISH: 'SORT_SUCCESS_STORY_FINISH',
  SORT_SUCCESS_STORY_RESET: 'SORT_SUCCESS_STORY_RESET',
}
