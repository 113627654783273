export const actionTypes: any = {
  // get sub category
  GET_EVENT_START: 'GET_EVENT_START',
  GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
  GET_EVENT_FINISH: 'GET_EVENT_FINISH',

  // ADD sub category
  ADD_EVENT_START: 'ADD_EVENT_START',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
  ADD_EVENT_FINISH: 'ADD_EVENT_FINISH',
  ADD_EVENT_RESET: 'ADD_EVENT_RESET',

  // Update sub category
  UPDATE_EVENT_START: 'UPDATE_EVENT_START',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FINISH: 'UPDATE_EVENT_FINISH',
  UPDATE_EVENT_RESET: 'UPDATE_EVENT_RESET',

  // delete sub category
  DELETE_EVENT_START: 'DELETE_EVENT_START',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FINISH: 'DELETE_EVENT_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_EVENT_REQUEST: 'SINGLE_ACTIVATE_EVENT_REQUEST',
  SINGLE_ACTIVATE_EVENT_SUCCESS: 'SINGLE_ACTIVATE_EVENT_SUCCESS',
  SINGLE_ACTIVATE_EVENT_FINISH: 'SINGLE_ACTIVATE_EVENT_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_EVENT_REQUEST: 'SINGLE_DEACTIVATE_EVENT_REQUEST',
  SINGLE_DEACTIVATE_EVENT_SUCCESS: 'SINGLE_DEACTIVATE_EVENT_SUCCESS',
  SINGLE_DEACTIVATE_EVENT_FINISH: 'SINGLE_DEACTIVATE_EVENT_FINISH',

  //GET BANNER OPTION CONSTANTS
  GET_EVENT_BANNER_OPTION_START: 'GET_EVENT_BANNER_OPTION_START',
  GET_EVENT_BANNER_OPTION_SUCCESS: 'GET_EVENT_BANNER_OPTION_SUCCESS',
  GET_EVENT_BANNER_OPTION_FINISH: 'GET_EVENT_BANNER_OPTION_FINISH',

  //GET ALBUM OPTION CONSTANTS
  GET_ALBUM_OPTION_START: 'GET_ALBUM_OPTION_START',
  GET_ALBUM_OPTION_SUCCESS: 'GET_ALBUM_OPTION_SUCCESS',
  GET_ALBUM_OPTION_FINISH: 'GET_ALBUM_OPTION_FINISH',
}
