import TrashIcon from '@rsuite/icons/Trash'
import axios from 'axios'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import {FiEye} from 'react-icons/fi'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IPortfolioState} from '../redux'
import {deletePortfolio, getAllPortfolio} from '../redux/action'
import AddPortfolio from './AddPortfolio'
import ViewPortfolioDetails from './ViewPortfolioDetails'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const Portfolio = () => {
  const dispatch = useDispatch()
  const {
    data: portfolioData,
    loading,
    deleteSuccess,
    success,
  } = useSelector((state: any) => state.portfolio)

  const PortfolioData: IPortfolioState = useSelector((state: any) => state.portfolio)

  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState<any>([])
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [selectedCandidateId, setSelectedCandidateId] = useState('')
  const [showPortfolioModal, setShowPortfolioModal] = useState(false)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [alertOpen, setAlertOpen] = useState(false)

  useEffect(() => {
    dispatch(getAllPortfolio(params))
  }, [params])

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Portfolio deleted successfully')
    }
    handleChecked([])
    dispatch(getAllPortfolio(params))
  }, [deleteSuccess, success])

  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const handlePortfolioExport = () => {
    const GET_PORTFOLIO_FILE = `${API_URL}/portfolio/export`
    axios(`${GET_PORTFOLIO_FILE}`, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Portfolio.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
            <IconButton
              onClick={() => {
                setShowPortfolioModal(true)
                setSelectedCandidateId(rowData?.id)
              }}
              appearance='subtle'
              icon={<FiEye />}
              data-cy='view-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let portfolio = [rowData.id]
                setCheckedValues(portfolio)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'full_name',
      flexGrow: 1,
      cell: <Cell dataKey='full_name' />,
      sortable: true,
    },
    {
      label: 'Mobile',
      dataKey: 'mobile_number',
      flexGrow: 1,
      cell: <Cell dataKey='mobile_number' />,
      sortable: true,
    },
    {
      label: 'Email',
      dataKey: 'email_address',
      flexGrow: 1,
      cell: <Cell dataKey='email_address' />,
      sortable: true,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleRefresh = () => {
    dispatch(getAllPortfolio(params))
  }

  const handleAddModal = () => {
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let portfolioId = checkedValues?.map((value) => ({id: value}))
    dispatch(deletePortfolio(portfolioId))
    handleAlertClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const data = PortfolioData?.data?.portfolio
    ? PortfolioData?.data?.portfolio?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        full_name: item.fullName,
        email_address: item.emailAddress,
        mobile_number: item.mobileNumber,
      }))
    : []

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Portfolio'
          addShow={true}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          exportButtonName='Export CSV'
          handleExport={handlePortfolioExport}
          toggleMultipleShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={portfolioData?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddPortfolio
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
            />
          )}

          {showPortfolioModal && (
            <ViewPortfolioDetails
              handleClose={() => setShowPortfolioModal(false)}
              data={portfolioData?.portfolio?.filter((d: any) => d.id === selectedCandidateId)[0]}
            />
          )}

          <>
            {alertOpen &&
              (!isEmpty(checkedValues) ? (
                <DeleteModal
                  handleClick={() => handleDelete()}
                  isOpen={alertOpen}
                  handleClose={() => handleAlertClose()}
                />
              ) : (
                toast.error('No data selected') && setAlertOpen(false)
              ))}
          </>
        </div>
      </div>
    </>
  )
}

export default Portfolio
