import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteApplicationModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getApplications: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_GOOD_PAYMENT_START,
    payload: params,
  }),
  getApplicationsSuccess: (data: any) => ({
    type: actionTypes.GET_GOOD_PAYMENT_SUCCESS,
    payload: data,
  }),
  getApplicationsFinish: () => ({
    type: actionTypes.GET_GOOD_PAYMENT_FINISH,
  }),

  deleteApplications: (data: DeleteApplicationModel[]) => ({
    type: actionTypes.DELETE_GOOD_PAYMENT_START,
    payload: {goodPaymentVerification: data},
  }),
  deleteApplicationsSuccess: (data: any) => ({
    type: actionTypes.DELETE_GOOD_PAYMENT_SUCCESS,
    payload: data,
  }),
  deleteApplicationsFinish: () => ({
    type: actionTypes.DELETE_GOOD_PAYMENT_FINISH,
  }),
}
