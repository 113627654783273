import CloseIcon from '@rsuite/icons/Close'
import ReloadIcon from '@rsuite/icons/Reload'
import SearchIcon from '@rsuite/icons/Search'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {FaPlus} from 'react-icons/fa'
import {RiDeleteBin6Line} from 'react-icons/ri'
import Select from 'react-select'
import Input from 'rsuite/Input'
import InputGroup from 'rsuite/InputGroup'
import {ItemType, StateParamsModel} from '../Model'
import CheckPermissions from '../checkPermission'

//includes
import {MenuProps} from 'antd'
import {Form, Formik} from 'formik'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {handleLabelNameChanges} from 'src/cms/helpers/automation'
import DatePickerForGrievance from 'src/cms/helpers/components/forms/DatePickerForGrievance'
import {ISettingTypeState} from '../../siteSettings/components/settings'
import SortModal from './sortModal'

interface Props {
  moduleName: string
  params?: StateParamsModel
  setParams?: any
  handleAddModal?: () => void
  handleAddStatus?: () => void
  handleAddNotice?: () => void
  handleAddNotice1?: () => void
  handleAlertOpen?: () => void
  enableMultiple?: () => void
  disableMultiple?: () => void
  handleExport?: () => void
  handleRefresh?: () => void
  handleGetAllData?: () => void
  handleTemplateExport?: () => void
  templateLinkShow?: boolean
  templateButtonName?: string
  handleImport?: any
  handleClickImport?: any
  filterData?: boolean
  addShow?: boolean
  updateShow?: boolean
  addNoteShow?: boolean
  addNoteMemberTypeShow?: any
  addNoteShow1?: boolean
  deleteShow?: boolean
  exportShow?: boolean
  importShow?: boolean
  importShow1?: boolean
  toggleMultipleShow?: boolean
  disableButton?: boolean
  exportButtonName?: string
  sortShow?: boolean
  sortButtonName?: string
  activeButtonName?: any
  inactiveButtonName?: string
  openSortModal?: boolean
  setOpenSortModal?: any
  data?: Array<{[key: string]: string | number | Date}> | [] | undefined
  handleSubmitSort?: (data: Array<ItemType>) => void
  loading?: boolean
  downloadResumeShow?: boolean
  groupDiscussion?: boolean
  interview?: boolean
  handleDownloadResume?: () => void
  enableDiscussion?: () => void
  enableInterview?: () => void
  enableDropdownFilter?: boolean
  dropdownOption?: any
  handleDropdownChange?: any
  dropdownValue?: any
  dropdownPlaceholder?: string
}

const DesignComponent = ({
  moduleName,
  params,
  setParams,
  groupDiscussion = false,
  interview = false,
  handleAddModal,
  handleAddStatus,
  handleAddNotice,
  handleAddNotice1,
  handleAlertOpen,
  handleClickImport,
  handleExport,
  enableMultiple,
  disableMultiple,
  handleRefresh,
  handleGetAllData,
  handleTemplateExport,
  handleImport,
  templateLinkShow = false,
  templateButtonName,
  filterData = false,
  addShow = true,
  updateShow = false,
  addNoteShow = false,
  addNoteMemberTypeShow = false,
  addNoteShow1 = false,
  deleteShow = true,
  exportShow = true,
  importShow = true,
  importShow1 = false,
  toggleMultipleShow = true,
  exportButtonName,
  sortShow,
  sortButtonName,
  activeButtonName,
  inactiveButtonName,
  disableButton,
  data,
  openSortModal,
  setOpenSortModal,
  handleSubmitSort,
  loading,
  handleDownloadResume,
  downloadResumeShow,
  enableDiscussion,
  enableInterview,
  enableDropdownFilter = false,
  dropdownOption,
  handleDropdownChange,
  dropdownValue,
  dropdownPlaceholder,
}: Props) => {
  const [sortData, setSortData] = useState<Array<ItemType>>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [refreshClick, setRefreshClick] = useState(false)
  ///just for menu manager
  const setSortInnerData = (data: Array<any>) => {
    if (data.length > 0) {
      const parentIndex = sortData.findIndex((item) => item.keyId === data[0]?.parentId)
      const updatedSortData = [...sortData]
      if (parentIndex >= 0) {
        updatedSortData[parentIndex].children = data
      }
      setSortData(updatedSortData)
    }
  }
  ///just for menu manager
  const setSortInnerMostData = (data: Array<any>) => {
    if (data?.length > 0) {
      const gParentIndex = sortData?.findIndex((item) => item.keyId === data[0]?.topmenuId)

      const parentIndex = sortData[gParentIndex]?.children?.findIndex(
        (item: any) => item.id === data[0]?.parentId
      )

      const updatedSortData = [...sortData]
      if (!isEmpty(updatedSortData?.[gParentIndex]?.children)) {
        if (!isEmpty(updatedSortData?.[gParentIndex]?.children?.[parentIndex]?.children)) {
          updatedSortData[gParentIndex].children[parentIndex].children = [...data]
        }
      }
      setSortData([...updatedSortData])
    }
  }
  const setSortInnerMostChildData = (
    data: Array<any>,
    index1: number,
    index2: number,
    index3: number
  ) => {
    if (data?.length > 0 && index1 >= 0 && index2 >= 0 && index3 >= 0) {
      const updatedSortData = [...sortData]

      if (!isEmpty(updatedSortData?.[index1]?.children)) {
        if (!isEmpty(updatedSortData?.[index1]?.children?.[index2]?.children)) {
          if (!isEmpty(updatedSortData?.[index1]?.children?.[index2]?.children[index3].children)) {
            updatedSortData[index1].children[index2].children[index3].children = [...data]
          }
        }
      }
      setSortData([...updatedSortData])
    }
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      let newSortData: any = data
        ? data?.map((item, i) => {
            return {
              id: i,
              keyId: item?.id,
              name: item?.name,
              title: item?.title,
              parentId: item?.parentId ? item?.parentId : null,
              children: !isEmpty(item?.children) ? item?.children : null,
              menuType: item.menuType,
            }
          })
        : []
      setSortData(newSortData)
    }
  }, [data])

  const handleCloseOpenSortModal = () => {
    setOpenSortModal(false)
  }

  useEffect(() => {
    setParams({...params, page: 1, search: searchQuery})
  }, [searchQuery])
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showProductAndInterest =
    settings?.data?.setting?.filter((d: any) => d?.name === 'showPopularityAndInterest')[0]
      ?.value === '1'
      ? true
      : false

  const items: MenuProps['items'] = [
    {
      label: <a href='https://www.antgroup.com'>1st menu item</a>,
      key: '0',
    },
    {
      label: <a href='https://www.aliyun.com'>2nd menu item</a>,
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: '3rd menu item',
      key: '3',
    },
  ]

  return (
    <>
      <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
        <div className='col-lg-6'>
          <div
            className='heading__component__title fw-bolder'
            data-cy={moduleName ? `${handleLabelNameChanges(moduleName)}-title` : ''}
          >
            {moduleName}
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='d-flex justify-content-end'>
            {addShow ? (
              <div className='sub_button' onClick={handleAddModal}>
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                    <FaPlus className='me-2' />
                    Add
                  </button>
                </CheckPermissions>
              </div>
            ) : null}
            {updateShow ? (
              <div
                className='sub_button'
                data-cy='design-update-status-button'
                onClick={handleAddStatus}
              >
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-primary' data-cy='update-button'>
                    <FaPlus className='me-2' />
                    Update Status
                  </button>
                </CheckPermissions>
              </div>
            ) : null}
            {deleteShow ? (
              <CheckPermissions type='Delete'>
                <div className='sub_button'>
                  <button
                    className='dt-btn dt-btn-secondary'
                    data-cy='delete-button'
                    onClick={handleAlertOpen}
                  >
                    <RiDeleteBin6Line className='me-2' />
                    Delete
                  </button>
                </div>
              </CheckPermissions>
            ) : null}
          </div>
        </div>
      </div>
      {filterData ? (
        <div className='d-flex me-5 mb-3'>
          <div className='d-flex'>
            <Formik
              initialValues={{
                fromDate: '',
                toDate: '',
              }}
              onSubmit={(values) => {
                setParams({
                  ...params,
                  fromDate: values.fromDate ? moment(values?.fromDate).format('YYYY-MM-DD') : '',
                  toDate: values.toDate ? moment(values?.toDate).format('YYYY-MM-DD') : '',
                })
              }}
              data-cy='design-form'
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (refreshClick) {
                    setFieldValue('fromDate', null)
                    setFieldValue('toDate', null)
                    setParams({...params, fromDate: '', toDate: ''})
                    setRefreshClick(false)
                  }
                }, [refreshClick])

                return (
                  <Form>
                    <div className='d-flex flex-row'>
                      <div className='col-5' style={{zIndex: 10}}>
                        <DatePickerForGrievance
                          containerClassName='col-12'
                          name='fromDate'
                          placeholderText='From date'
                          setFieldValue={setFieldValue}
                          value={values.fromDate || false}
                          touched={touched}
                          required={true}
                          showIcon={true}
                          errors={errors}
                        />
                      </div>
                      <div className='col-5 ms-5' style={{zIndex: 10}}>
                        <DatePickerForGrievance
                          containerClassName='col-12'
                          name='toDate'
                          placeholderText='To date'
                          setFieldValue={setFieldValue}
                          value={values.toDate || false}
                          touched={touched}
                          required={true}
                          showIcon={true}
                          errors={errors}
                        />
                      </div>
                      <div className='col-5 ms-5'>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn'
                          data-cy='design-filter-button'
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      ) : null}

      {templateLinkShow ? (
        <div className='d-flex justify-content-end align-items-center mb-5'>
          <div className='sub_button'>
            <button
              className='dt-btn dt-btn-outline-primary dt-btn-sm'
              onClick={handleTemplateExport}
              data-cy={`${handleLabelNameChanges(moduleName)}-export-button`}
            >
              {templateButtonName}
            </button>
          </div>
        </div>
      ) : null}

      <div className='d-flex flex-wrap mb-5'>
        <div className='flex-shrink-0'>
          <div className='row'>
            <div className={`${enableDropdownFilter ? 'col-md-6' : 'col-md-12'}`}>
              <InputGroup inside className='h-100'>
                <Input
                  placeholder='Search'
                  value={searchQuery}
                  onChange={(value: string) => setSearchQuery(value)}
                  data-cy='search-field'
                />
                <InputGroup.Addon>
                  {!isEmpty(params?.search) ? (
                    <CloseIcon
                      style={{cursor: 'pointer'}}
                      onClick={() => setSearchQuery('')}
                      data-cy='search-close-button'
                    />
                  ) : (
                    <SearchIcon />
                  )}
                  {/* <SearchIcon /> */}
                </InputGroup.Addon>
              </InputGroup>
            </div>

            {enableDropdownFilter && (
              <div style={{zIndex: 10}} className='col-md-6 position-relative'>
                <Select
                  id='tableFilter'
                  className='basic-single'
                  isClearable={true}
                  menuPlacement='bottom'
                  options={dropdownOption}
                  onChange={handleDropdownChange}
                  value={dropdownValue}
                  placeholder={dropdownPlaceholder || 'Filter by ....'}
                />
              </div>
            )}
          </div>
        </div>
        <div className='flex-grow-1'>
          <div className='d-flex flex-wrap gap-2 justify-content-end'>
            <div
              className='sub_button'
              onClick={() => {
                setRefreshClick(true)
              }}
            >
              <button
                id='refresh-button'
                className='dt-btn dt-btn-outline-primary dt-btn-sm'
                onClick={handleRefresh}
                data-cy='refresh-button'
              >
                <ReloadIcon />
              </button>
            </div>
            {addNoteShow ? (
              <div className='sub_button' onClick={handleAddNotice} data-cy='update-note-button'>
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-outline-primary dt-btn-sm'>
                    {addNoteShow1 ? 'Update Deposit Note' : 'Update Note'}
                  </button>
                </CheckPermissions>
              </div>
            ) : null}

            {addNoteMemberTypeShow ? (
              <div
                className='sub_button'
                onClick={handleAddNotice}
                data-cy='update-member-note-button'
              >
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-outline-primary dt-btn-sm'>
                    {addNoteShow1 ? 'Update Deposit Note' : 'Update Note'}
                  </button>
                </CheckPermissions>
              </div>
            ) : null}

            {addNoteShow1 ? (
              <div
                className='sub_button'
                onClick={handleAddNotice1}
                data-cy='update-loan-note-button'
              >
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-outline-primary dt-btn-sm'>
                    Update Loan Note
                  </button>
                </CheckPermissions>
              </div>
            ) : null}

            {downloadResumeShow ? (
              <div
                className='sub_button'
                onClick={handleDownloadResume}
                data-cy='download-document-button'
              >
                <CheckPermissions type='Add'>
                  <button className='dt-btn dt-btn-outline-primary dt-btn-sm'>
                    Download Document
                  </button>
                </CheckPermissions>
              </div>
            ) : null}

            {!showProductAndInterest ? (
              ''
            ) : (
              <>
                {groupDiscussion ? (
                  <div className='sub_button'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm'
                      onClick={enableDiscussion}
                      disabled={disableButton}
                      data-cy='group-discussion-button'
                    >
                      {'Shortlist for Group Discussion'}
                    </button>
                  </div>
                ) : null}
                {interview ? (
                  <div className='sub_button'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm'
                      onClick={enableInterview}
                      disabled={disableButton}
                      data-cy='interview-button'
                    >
                      {'Shortlist for Interview'}
                    </button>
                  </div>
                ) : null}
              </>
            )}
            {toggleMultipleShow ? (
              <>
                <div className='sub_button'>
                  <button
                    className='dt-btn dt-btn-outline-primary dt-btn-sm'
                    onClick={enableMultiple}
                    disabled={disableButton}
                    data-cy='toggle-multiple-show-button'
                  >
                    {activeButtonName || 'Active'}
                  </button>
                </div>
                <div className='sub_button'>
                  <button
                    className='dt-btn dt-btn-outline-primary dt-btn-sm'
                    onClick={disableMultiple}
                    data-cy='toggle-multiple-active-inactive-button'
                  >
                    {inactiveButtonName || 'Inactive'}
                  </button>
                </div>
              </>
            ) : null}
            {exportShow ? (
              <div className='sub_button'>
                <button
                  className='dt-btn dt-btn-outline-primary dt-btn-sm'
                  onClick={handleExport}
                  data-cy='export-button'
                >
                  {exportButtonName || 'Export to CSV'}
                </button>
              </div>
            ) : null}
            {importShow ? (
              <div className='sub_button'>
                <form action='#'>
                  <div className='input-file-container'>
                    <input
                      className='input-file'
                      id='my-file'
                      type='file'
                      onChange={handleImport}
                      data-cy='import-file-link'
                    />
                    <label
                      className='dt-btn dt-btn-outline-primary dt-btn-sm input-file-trigger'
                      htmlFor='my-file'
                    >
                      Import
                    </label>
                  </div>
                </form>
              </div>
            ) : null}

            {importShow1 ? (
              <button
                className='dt-btn dt-btn-outline-primary dt-btn-sm ms-2'
                onClick={handleClickImport}
                data-cy='import-show-button'
              >
                Import
              </button>
            ) : null}

            {sortShow ? (
              <div
                className='sub_button'
                onClick={async () => {
                  handleGetAllData && handleGetAllData()
                  setOpenSortModal(true)
                }}
                data-cy='sort-show-button'
              >
                <button className='dt-btn dt-btn-outline-primary dt-btn-sm' data-cy='sort-button'>
                  {sortButtonName || 'Sort'}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <SortModal
        isOpen={openSortModal}
        handleClose={handleCloseOpenSortModal}
        data={sortData}
        setSortData={setSortData}
        sortButtonName={sortButtonName}
        handleSubmitSort={handleSubmitSort}
        handleRefresh={handleRefresh || (() => {})}
        loading={loading}
        setSortInnerData={setSortInnerData}
        setSortInnerMostData={setSortInnerMostData}
        setSortInnerMostChildData={setSortInnerMostChildData}
      />
    </>
  )
}

export default DesignComponent
