import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as abroadRedux from '../index'
import AbroadRepresentative from './AddAbroadRepresentative'
import {useHistory} from 'react-router-dom'

const AbroadRepresentativeManager = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {data, loading, enableSuccess, disableSuccess, success, deleteSuccess, sortAuctionData} =
    useSelector((state: any) => state.abroadRepresentative)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [openSortModal, setOpenSortModal] = useState(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group' data-cy='search-category-link-group'>
        {/* <Whisper placement='top' trigger='hover' speaker={<Tooltip>Auction Enquiry</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              history.push(`/auction/auction-manager/auction-enquiry/${rowData.id}`)
            }}
            icon={<UserInfoIcon />}
            data-cy='edit-button'
          />
        </Whisper> */}
        <CheckPermissions type='Edit'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='search-category-edit-button'
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='search-category-delete-button'
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let auctionId = [rowData.id]
                setCheckedValues(auctionId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        {/* <Whisper
          placement='top'
          trigger='hover'
          data-cy='search-category-status-button'
          speaker={<Tooltip>Status</Tooltip>}
        >
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper> */}
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Country Name',
      dataKey: 'countryName',
      flexGrow: 1,
      cell: <Cell dataKey='countryName' />,
      sortable: true,
    },

    // {
    //   label: 'Status',
    //   dataKey: 'status',
    //   width: 85,
    //   cell: (
    //     <Cell>
    //       {(rowData) =>
    //         rowData?.status ? (
    //           <Tag color='green' size='sm'>
    //             Active
    //           </Tag>
    //         ) : (
    //           <Tag color='red' size='sm'>
    //             Inactive
    //           </Tag>
    //         )
    //       }
    //     </Cell>
    //   ),
    //   sortable: false,
    // },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(abroadRedux.actions.getAbroadRepresentatives(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Abroad Representatives deleted successfully')
    }
    if (enableSuccess) {
      toast.success('Abroad Representatives enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Abroad Representatives disabled successfully')
    }
    handleChecked([])
    dispatch(abroadRedux?.actions.getAbroadRepresentatives(params))
  }, [deleteSuccess, success, enableSuccess, disableSuccess])

  const abroadData = data?.abroad_representatives
    ? data?.abroad_representatives?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        country_id: item?.country?.id,
        countryName: item?.country?.title,
        description: item?.description,
        description_np: item?.description_np,
        image: item?.image,
        representatives: item?.representatives?.representatives.map(
          (item: {[key: string]: string}) => ({
            id: item.id,
            name: item?.name,
            name_np: item?.name_np,
            email: item?.email,
            number: item?.number,
            designation: item?.designation,
            designation_np: item?.designation_np,
          })
        ),
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let auctionId = checkedValues?.map((value) => ({id: value}))
    dispatch(abroadRedux?.actions?.deleteAbroadRepresentatives(auctionId))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(abroadRedux.actions.getAbroadRepresentatives(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: data?.meta?.total})
    dispatch(abroadRedux.actions.getAbroadRepresentatives(params))
  }

  useEffect(() => {
    if (success) {
      setParams({...params, limit: 10})
      dispatch(abroadRedux.actions.getAbroadRepresentatives(params))
      setOpenSortModal(false)
    }
  }, [success])

  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='search-category-shadow-comp'>
        <DesignComponent
          moduleName='Aboard Representative'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          toggleMultipleShow={false}
          data={abroadData}
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
          data-cy='search-category-design-comp'
        />

        <div className='datatable' data-cy='search-category-datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={abroadData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
            data-cy='search-category-r-suite'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='search-category-pagination'
          />
          {open && (
            <AbroadRepresentative
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>

        {/* Delete Modal */}
        <>
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
    </>
  )
}

export default AbroadRepresentativeManager
