import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import TrashIcon from '@rsuite/icons/Trash'
import Table from 'rsuite/Table'
// includes
import {isEmpty} from 'lodash'
import {FiEye} from 'react-icons/fi'
import {toast} from 'react-toastify'
import {IconButton, Tooltip, Whisper} from 'rsuite'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as departments from '../../../../userMgmt/components/departments/index'
import * as feedbackFormRedux from '../index'
import ViewFeedbackDetails from './ViewFeedbackDetails'

const ContentFeedbackFormComponent = () => {
  const dispatch = useDispatch()
  const {data, success, loading, deleteSuccess} = useSelector(
    (state: any) => state.feedbackFormState
  )

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  useEffect(() => {
    dispatch(feedbackFormRedux.actions.getFeedbackFormDetail(params))
  }, [params])

  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              data-cy='edit-button'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const handleClose = () => setOpen(false)

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.name) ? rowData?.name : '-')}</Cell>,
      sortable: true,
    },

    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.email) ? rowData?.email : '-')}</Cell>,

      sortable: false,
    },
    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      flexGrow: 1,
      cell: (
        <Cell>{(rowData) => (!isEmpty(rowData?.mobileNumber) ? rowData?.mobileNumber : '-')}</Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const contentFeedbackData = !isEmpty(data?.feedbacks)
    ? data?.feedbacks?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        name: item.full_name,
        email: item.email,
        mobileNumber: item.mobile_number,
        message: item.message,
      }))
    : []

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  useEffect(() => {
    handleChecked([])
    dispatch(feedbackFormRedux?.actions.getFeedbackFormDetail(params))
    dispatch(departments.actions.getDepartment())
  }, [success])

  const handleExport = () => {
    dispatch(feedbackFormRedux.actions.exportFile('Feedback Data', params))
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({id: value}))
    dispatch(feedbackFormRedux?.actions?.deleteFeedback(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(feedbackFormRedux.actions.getFeedbackFormDetail(params))
  }
  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Feedback deleted successfully')
      dispatch(feedbackFormRedux?.actions.getFeedbackFormDetail(params))
    }
  }, [deleteSuccess])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Feedback Manager'
          params={params}
          setParams={setParams}
          filterData={true}
          handleExport={handleExport}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          addShow={false}
          deleteShow={true}
          importShow={false}
          toggleMultipleShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            showLoading={loading}
            data={contentFeedbackData}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>
        {showDetailsModal && (
          <ViewFeedbackDetails
            handleClose={() => setShowDetailsModal(false)}
            data={data?.feedbacks?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}
        <>
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => modalSubmit()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
    </>
  )
}

export default ContentFeedbackFormComponent
