import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as place from 'src/app/modules/common'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import MapView from 'src/app/modules/common/components/Map/MapView'
import getBackendSettingValue from 'src/app/modules/common/getBackendSetting'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {branch} from 'src/app/modules/network'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as auctionRedux from '../index'
import {PlaceModel} from '../Model/Model'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddAuction = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [provinceValue, setProvinceValue] = useState('')
  const [locationCategoryValue, setLocationCategoryValue] = useState('')
  const [valleyProvinceOptions, setValleyProvinceOptions] = useState([])
  const [valleyDistrictOptions, setValleyDistrictOptions] = useState([])
  const [attachedFile, setAttachedFile] = useState('')

  const {loading, success, auctionTypeList, propertyTypeList, auctionImageTypeList} = useSelector(
    (state: any) => state.auction
  )
  const {category} = useSelector((state: any) => state.branch)
  const {district, province} = useSelector((state: any) => state.place)

  const defaultLongitude = getBackendSettingValue('defaultLongitude')
  const defaultLatitude = getBackendSettingValue('defaultLatitude')

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const YesNoOptions = [
    {label: 'NO', value: 'false'},
    {label: 'YES', value: 'true'},
  ]

  const OptionsDropdown = (data: any) => {
    const responseData = data?.map((item: any) => {
      return {
        label: item?.name || item?.title || item?.displayName,
        value: item?.id,
        systemName: item?.systemName,
      }
    })
    return responseData ?? []
  }

  useMemo(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(auctionRedux.actions.getAuctionType())
    dispatch(auctionRedux.actions.getPropertyTypeList())
    dispatch(auctionRedux.actions.getAuctionImageTypeList())
    dispatch(branch.actions.getBranchCategory())
    dispatch(place.action.getProvince())
  }, [])

  useMemo(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (provinceValue) {
      dispatch(place.action.getDistrict(provinceValue))
    }
  }, [provinceValue])

  useEffect(() => {
    if (locationCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
      const provinceOptions = province
        ?.filter((d: any) => d.title === 'Bagmati Pradesh')
        .map((items: PlaceModel) => ({
          label: items.title,
          value: items.id,
        }))
      setValleyProvinceOptions(provinceOptions)
    } else {
      const provinceOptions = province?.map((items: PlaceModel) => ({
        label: items.title,
        value: items.id,
      }))
      setValleyProvinceOptions(provinceOptions)
    }
  }, [locationCategoryValue])

  useEffect(() => {
    if (!isEmpty(locationCategoryValue) && !isEmpty(provinceValue)) {
      if (locationCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId === 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      } else {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId !== 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      }
    }
  }, [locationCategoryValue, provinceValue, district])

  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
      setLocationCategoryValue(editSelectedData?.addressCategory?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (success) {
      dispatch(auctionRedux?.actions.getAuction(params))
      isEmpty(editSelectedData)
        ? toast.success('Auction added successfully')
        : toast.success('Auction edited successfully')
      dispatch(auctionRedux?.actions?.resetAuction())
      handleClose()
    }
  }, [success])

  const disableNBADropdown = checkBackendSetting('disableNBADropdown')
  const showAuctionDescription = checkBackendSetting('showAuctionDescription')
  const showAuctionAttachment = checkBackendSetting('showAuctionAttachment')
  const auctionImageNotRequired = checkBackendSetting('auctionImageNotRequired')
  const auctionPropertyNotRequired = checkBackendSetting('auctionPropertyNotRequired')

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    status: Yup.string().required('Status is required'),
    property_type_id: Yup.string().required('Required field'),
    auction_type_id: Yup.string().required('Required field'),
    is_nba: Yup.string().required('Required field'),
    address_category_id: Yup.string().required('Required field'),
    district_id: Yup.string().required('Required field'),
    province_id: Yup.string().required('Required field'),
    latitude: Yup.string().required('Required field'),
    longitude: Yup.string().required('Required field'),
    start_date: Yup.date().required('Required field').nullable(),
    start_time: Yup.date().required('Required field').nullable(),
    end_date: Yup.date()
      .required('Required field')
      .nullable()
      .when('start_date', (start_date, schema) => {
        return schema.test({
          test: (end_date: string) => {
            if (!end_date || !start_date) return true //Skip validation if none of the dates are provided
            return moment(end_date).isSameOrAfter(start_date)
          },
          message: 'End date cannot be before the start date',
        })
      }),
    end_time: Yup.date().required('Required field').nullable(),
    images: Yup.array().of(
      Yup.object().shape({
        image_type_id: Yup.string().when(`auctionImageNotRequired`, {
          is: (value: any) => !value && auctionImageNotRequired,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required('Image type is required').nullable(),
        }),
        path: Yup.string().when([`auctionImageNotRequired`, `image_type_id`], {
          is: (value: any, image_type_id) =>
            !value && auctionImageNotRequired && isEmpty(image_type_id),
          then: Yup.string().nullable(),
          otherwise: Yup.string().required('Image is required').nullable(),
        }),
        show_in_listing: Yup.string()
          .required('Please select an option')
          .oneOf(['true', 'false'], 'Please select a valid option')
          .nullable(),
      })
    ),
    // .min(1, 'At least one property is required'),
    properties: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().when(`auctionImageNotRequired`, {
          is: (value: any) => !value && auctionImageNotRequired,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required('Key is required').nullable(),
        }),
        value: Yup.string().when(`auctionPropertyNotRequired`, {
          is: (value: any) => !value && auctionPropertyNotRequired,
          then: Yup.string().nullable(),
          otherwise: Yup.string().required('Value is required').nullable(),
        }),
        show_in_listing: Yup.string()
          .required('Please select an option')
          .oneOf(['true', 'false'], 'Please select a valid option')
          .nullable(),
      })
    ),
    // .min(1, 'At least one property is required'),
  })

  return (
    <div className='modal-container' data-cy='search-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Auction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                slug: '',
                description: '',
                descriptionNp: '',
                attachments: '',
                property_type_id: '',
                auction_type_id: '',
                is_nba: YesNoOptions?.[0]?.value,
                address_category_id: '',
                district_id: '',
                province_id: '',
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
                latitude: '',
                longitude: '',
                images: [
                  {
                    path: '',
                    image_type_id: '',
                    show_in_listing: YesNoOptions?.[0]?.value,
                  },
                ],
                properties: [
                  {
                    key: '',
                    value: '',
                    show_in_listing: YesNoOptions?.[0]?.value,
                  },
                ],
                plus_code: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name?.replace(/\s/g, '-')?.toLowerCase(),
                  start_date: moment(values?.start_date).format('YYYY-MM-DD HH:mm:ss'),
                  start_time: moment(values.start_date)
                    .set({
                      hour: moment(values.start_time).hour(),
                      minute: moment(values.start_time).minute(),
                      second: moment(values.start_time).second(),
                    })
                    .format('YYYY-MM-DD HH:mm:ss'),
                  end_date: moment(values?.end_date).format('YYYY-MM-DD HH:mm:ss'),
                  end_time: moment(values.end_date)
                    .set({
                      hour: moment(values.end_time).hour(),
                      minute: moment(values.end_time).minute(),
                      second: moment(values.end_time).second(),
                    })
                    .format('YYYY-MM-DD HH:mm:ss'),
                  is_nba: values?.is_nba === 'true' ? true : false,
                  latitude: values?.latitude.toString(),
                  longitude: values?.longitude.toString(),
                  images:
                    !isEmpty(values?.images?.[0]?.path) &&
                    !isEmpty(values?.images?.[0]?.image_type_id)
                      ? values?.images?.map((item: {[key: string]: string}) => ({
                          ...item,
                          id: !isEmpty(editSelectedData) ? item?.id : null,
                          show_in_listing: item?.show_in_listing === 'true' ? true : false,
                        }))
                      : null,
                  properties:
                    !isEmpty(values?.properties?.[0]?.key) &&
                    !isEmpty(values?.properties?.[0]?.value)
                      ? values?.properties?.map((item: {[key: string]: string}) => ({
                          ...item,
                          id: !isEmpty(editSelectedData) ? item?.id : null,
                          show_in_listing: item?.show_in_listing === 'true' ? true : false,
                        }))
                      : null,
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(auctionRedux.actions.updateAuction(formData, editSelectedData?.id))
                } else {
                  dispatch(auctionRedux.actions.addAuction(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'slug',
                      'property_type_id',
                      'auction_type_id',
                      'is_nba',
                      'address_category_id',
                      'district_id',
                      'province_id',
                      'latitude',
                      'longitude',
                      'images',
                      'properties',
                      'plus_code',
                      'start_date',
                      'start_time',
                      'end_date',
                      'end_time',
                      'description',
                      'descriptionNp',
                      'attachments',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                useEffect(() => {
                  if (values.province_id && values.province_id !== editSelectedData.province_id) {
                    setFieldValue('province_id', '')
                  }
                }, [values.address_category_id, editSelectedData])

                useEffect(() => {
                  if (values.district_id && values.district_id !== editSelectedData.district_id) {
                    setFieldValue('district_id', '')
                  }
                }, [values.province_id, editSelectedData])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='search-category-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='search-category-name-text-fields'
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.name?.replace(/\s/g, '-')?.toLowerCase()
                            }
                            disabled={true}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='Start date'
                            name='start_date'
                            placeholderText='Please select a Start date'
                            setFieldValue={setFieldValue}
                            value={values?.start_date || false}
                            errors={errors}
                            touched={touched}
                            required
                            showIcon={true}
                            data-cy='publish-date-drop-down'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='start_time'
                            label='Start time'
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values?.start_time || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required
                            data-cy='publish-time-drop-down'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='End date'
                            name='end_date'
                            placeholderText='Please select a End date'
                            setFieldValue={setFieldValue}
                            value={values?.end_date || false}
                            minDate={values?.start_date}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            disabled={values.start_date ? false : true}
                            required
                            data-cy='end-date-drop-down'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='end_time'
                            label='End time'
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values?.end_time || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            disabled={values.start_date ? false : true}
                            required
                            data-cy='end-time-drop-down'
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Auction Type'
                            name='auction_type_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(auctionTypeList?.auctionType)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Property Type'
                            name='property_type_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(propertyTypeList?.propertyType)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        {disableNBADropdown ? null : (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='NBA'
                              name='is_nba'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={YesNoOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                        )}
                        {showAuctionAttachment && (
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Attached File'
                              name='attachments'
                              setFieldValue={setFieldValue}
                              setImageUrl={setAttachedFile}
                              value={values?.attachments}
                              data-cy='add-attached-file-field'
                              buttonName='Add Attachment'
                            />
                            {!isEmpty(values?.attachments) ? (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setAttachedFile('')
                                        setFieldValue('attachments', '')
                                      }}
                                      data-cy='attachment-remove-image-button'
                                    >
                                      X
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={
                                        values?.attachments?.slice(-3).toLowerCase() === 'pdf'
                                          ? '/media/logos/pdfDefault.png'
                                          : `${imageBaseUrl}/${values?.attachments}`
                                      }
                                      alt=''
                                      data-cy='attached-file-preview'
                                    />
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {showAuctionDescription && (
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              name='description'
                              handleChange={handleChange}
                              label='Description (EN)'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                              }
                            />
                          </div>

                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              name='descriptionNp'
                              handleChange={handleChange}
                              label=' Description (NP)'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Auction Location'
                            name='address_category_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(category)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <>
                          {!isEmpty(values?.address_category_id)
                            ? setLocationCategoryValue(values?.address_category_id)
                            : ''}
                        </>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Province'
                            name='province_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(locationCategoryValue)
                                ? valleyProvinceOptions
                                : OptionsDropdown(province)
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <>
                          {!isEmpty(values?.province_id)
                            ? setProvinceValue(values?.province_id)
                            : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='District'
                            name='district_id'
                            disabled={
                              !isEmpty(editSelectedData) || values?.province_id ? false : true
                            }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(locationCategoryValue) && !isEmpty(valleyProvinceOptions)
                                ? valleyDistrictOptions
                                : OptionsDropdown(district)
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <MapView
                          latName='latitude'
                          lngName='longitude'
                          location={{
                            lat: values.latitude || defaultLatitude,
                            lng: values.longitude || defaultLongitude,
                          }}
                          setFieldValue={setFieldValue}
                          data-cy='modal-map-view'
                        />
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Longitude'
                            label='Longitude'
                            name='longitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Latitude'
                            label='Latitude'
                            name='latitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Google Plus Code'
                            label='Google Plus Code'
                            name='plus_code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='images'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Auction Images</h5>
                              {values?.images && values?.images.length > 0
                                ? values?.images?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Auction Image Type'
                                          name={`images[${index}].image_type_id`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={OptionsDropdown(
                                            auctionImageTypeList?.auctionImageType
                                          )}
                                          values={values?.images[index]?.image_type_id}
                                          arrValue={values?.images[index]?.image_type_id}
                                          required={auctionImageNotRequired ? false : true}
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormInputMediaManager
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Image'
                                          name={`images[${index}].path`}
                                          setFieldValue={setFieldValue}
                                          value={values?.images[index]?.path}
                                          required={
                                            auctionImageNotRequired &&
                                            isEmpty(values?.images[index]?.image_type_id)
                                              ? false
                                              : true
                                          }
                                          showImageOnly={true}
                                          disable={
                                            isEmpty(values?.images[index]?.image_type_id)
                                              ? true
                                              : false
                                          }
                                        />
                                        {!isEmpty(responseData?.path) ? (
                                          <>
                                            <li className='listing'>
                                              <div className='thumbImageBlock'>
                                                <button
                                                  type='button'
                                                  title='Remove'
                                                  className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                  onClick={() => {
                                                    setFieldValue(`images[${index}].path`, '')
                                                  }}
                                                  data-cy='modal-thumbnail-remove'
                                                >
                                                  X
                                                </button>

                                                <img
                                                  className='thumbImage w-100 h-100'
                                                  src={`${imageBaseUrl}/${responseData?.path}`}
                                                  alt='image'
                                                />
                                              </div>
                                            </li>
                                          </>
                                        ) : null}
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Show in listing'
                                          name={`images[${index}].show_in_listing`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={YesNoOptions}
                                          values={values?.images[index]?.show_in_listing}
                                          arrValue={values?.images[index]?.show_in_listing}
                                          required={auctionImageNotRequired ? false : true}
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}
                              {values?.images[values?.images?.length - 1]?.path && (
                                <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                  <button
                                    className='p-2 ps-5 pe-5 btn btn-primary'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        path: '',
                                        image_type_id: '',
                                        show_in_listing: 'false',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='properties'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Auction Property</h5>
                              {values?.properties && values?.properties.length > 0
                                ? values?.properties?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='key'
                                          name={`properties[${index}].key`}
                                          label='Key'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={auctionPropertyNotRequired ? false : true}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Value'
                                          name={`properties[${index}].value`}
                                          label='Value'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required={auctionPropertyNotRequired ? false : true}
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          placeholder='Please select a file type'
                                          label='Show in listing'
                                          name={`properties[${index}].show_in_listing`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={YesNoOptions}
                                          values={values?.properties[index]?.show_in_listing}
                                          arrValue={values?.properties[index]?.show_in_listing}
                                          setFieldValue={setFieldValue}
                                          required={auctionPropertyNotRequired ? false : true}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}

                              <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                <button
                                  className='p-2 ps-5 pe-5 btn btn-primary'
                                  type='button'
                                  onClick={() =>
                                    arrayHelpers.push({
                                      key: '',
                                      value: '',
                                      show_in_listing: 'false',
                                    })
                                  }
                                  data-cy='modal-field-add'
                                >
                                  + Add More
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='search-category-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAuction
