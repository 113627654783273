export const actionTypes: any = {
  // get Interest Rate
  GET_SERVICE_PARTNER_START: 'GET_SERVICE_PARTNER_START',
  GET_SERVICE_PARTNER_SUCCESS: 'GET_SERVICE_PARTNER_SUCCESS',
  GET_SERVICE_PARTNER_FINISH: 'GET_SERVICE_PARTNER_FINISH',

  // add Interest Rate
  ADD_SERVICE_PARTNER_START: 'ADD_SERVICE_PARTNER_START',
  ADD_SERVICE_PARTNER_SUCCESS: 'ADD_SERVICE_PARTNER_SUCCESS',
  ADD_SERVICE_PARTNER_FINISH: 'ADD_SERVICE_PARTNER_FINISH',
  RESET_SERVICE_PARTNER_TYPE: 'RESET_SERVICE_PARTNER_TYPE',

  // update Interest Rate
  UPDATE_SERVICE_PARTNER_START: 'UPDATE_SERVICE_PARTNER_START',
  UPDATE_SERVICE_PARTNER_SUCCESS: 'UPDATE_SERVICE_PARTNER_SUCCESS',
  UPDATE_SERVICE_PARTNER_FINISH: 'UPDATE_SERVICE_PARTNER_FINISH',

  // delete Interest Rate
  DELETE_SERVICE_PARTNER_START: 'DELETE_SERVICE_PARTNER_START',
  DELETE_SERVICE_PARTNER_SUCCESS: 'DELETE_SERVICE_PARTNER_SUCCESS',
  DELETE_SERVICE_PARTNER_FINISH: 'DELETE_SERVICE_PARTNER_FINISH',

  // Enable Interest Rate
  ENABLE_SERVICE_PARTNER_REQUEST: 'ENABLE_SERVICE_PARTNER_REQUEST',
  ENABLE_SERVICE_PARTNER_SUCCESS: 'ENABLE_SERVICE_PARTNER_SUCCESS',
  ENABLE_SERVICE_PARTNER_FINISH: 'ENABLE_SERVICE_PARTNER_FINISH',

  // Disable Interest Rate
  DISABLE_SERVICE_PARTNER_REQUEST: 'DISABLE_SERVICE_PARTNER_REQUEST',
  DISABLE_SERVICE_PARTNER_SUCCESS: 'DISABLE_SERVICE_PARTNER_SUCCESS',
  DISABLE_SERVICE_PARTNER_FINISH: 'DISABLE_SERVICE_PARTNER_FINISH',

  // Enable Interest Rate
  SINGLE_ENABLE_SERVICE_PARTNER_REQUEST: 'SINGLE_ENABLE_SERVICE_PARTNER_REQUEST',
  SINGLE_ENABLE_SERVICE_PARTNER_SUCCESS: 'SINGLE_ENABLE_SERVICE_PARTNER_SUCCESS',
  SINGLE_ENABLE_SERVICE_PARTNER_FINISH: 'SINGLE_ENABLE_SERVICE_PARTNER_FINISH',

  // Disable Interest Rate
  SINGLE_DISABLE_SERVICE_PARTNER_REQUEST: 'SINGLE_DISABLE_SERVICE_PARTNER_REQUEST',
  SINGLE_DISABLE_SERVICE_PARTNER_SUCCESS: 'SINGLE_DISABLE_SERVICE_PARTNER_SUCCESS',
  SINGLE_DISABLE_SERVICE_PARTNER_FINISH: 'SINGLE_DISABLE_SERVICE_PARTNER_FINISH',

  // Download ServicePartner Sample
  DOWNLOAD_SERVICE_PARTNER_SAMPLE_REQUEST: 'DOWNLOAD_SERVICE_PARTNER_SAMPLE_REQUEST',
  DOWNLOAD_SERVICE_PARTNER_SAMPLE_SUCCESS: 'DOWNLOAD_SERVICE_PARTNER_SAMPLE_SUCCESS',
  DOWNLOAD_SERVICE_PARTNER_SAMPLE_ERROR: 'DOWNLOAD_SERVICE_PARTNER_SAMPLE_ERROR',

  // Import ServicePartner
  IMPORT_SERVICE_PARTNER_REQUEST: 'IMPORT_SERVICE_PARTNER_REQUEST',
  IMPORT_SERVICE_PARTNER_SUCCESS: 'IMPORT_SERVICE_PARTNER_SUCCESS',
  IMPORT_SERVICE_PARTNER_FINISH: 'IMPORT_SERVICE_PARTNER_FINISH',

  // Country List
  GET_SERVICE_PARTNER_COUNTRY_REQUEST: 'GET_SERVICE_PARTNER_COUNTRY_REQUEST',
  GET_SERVICE_PARTNER_COUNTRY_SUCCESS: 'GET_SERVICE_PARTNER_COUNTRY_SUCCESS',
  GET_SERVICE_PARTNER_COUNTRY_FINISH: 'GET_SERVICE_PARTNER_FINISH',

  // Currency List
  GET_SERVICE_PARTNER_CURRENCY_REQUEST: 'GET_SERVICE_PARTNER_CURRENCY_REQUEST',
  GET_SERVICE_PARTNER_CURRENCY_SUCCESS: 'GET_SERVICE_PARTNER_CURRENCY_SUCCESS',
  GET_SERVICE_PARTNER_CURRENCY_FINISH: 'GET_SERVICE_PARTNER_CURRENCY_FINISH',
}
