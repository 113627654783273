export const actionTypes: any = {
  // get Interest Rate
  GET_INTEREST_RATE_START: 'GET_INTEREST_RATE_START',
  GET_INTEREST_RATE_SUCCESS: 'GET_INTEREST_RATE_SUCCESS',
  GET_INTEREST_RATE_FINISH: 'GET_INTEREST_RATE_FINISH',

  // add Interest Rate
  ADD_INTEREST_RATE_START: 'ADD_INTEREST_RATE_START',
  ADD_INTEREST_RATE_SUCCESS: 'ADD_INTEREST_RATE_SUCCESS',
  ADD_INTEREST_RATE_FINISH: 'ADD_INTEREST_RATE_FINISH',
  RESET_INTEREST_RATE_TYPE: 'RESET_INTEREST_RATE_TYPE',

  // update Interest Rate
  UPDATE_INTEREST_RATE_START: 'UPDATE_INTEREST_RATE_START',
  UPDATE_INTEREST_RATE_SUCCESS: 'UPDATE_INTEREST_RATE_SUCCESS',
  UPDATE_INTEREST_RATE_FINISH: 'UPDATE_INTEREST_RATE_FINISH',

  // delete Interest Rate
  DELETE_INTEREST_RATE_START: 'DELETE_INTEREST_RATE_START',
  DELETE_INTEREST_RATE_SUCCESS: 'DELETE_INTEREST_RATE_SUCCESS',
  DELETE_INTEREST_RATE_FINISH: 'DELETE_INTEREST_RATE_FINISH',

  // Enable Interest Rate
  ENABLE_INTEREST_RATE_REQUEST: 'ENABLE_INTEREST_RATE_REQUEST',
  ENABLE_INTEREST_RATE_SUCCESS: 'ENABLE_INTEREST_RATE_SUCCESS',
  ENABLE_INTEREST_RATE_FINISH: 'ENABLE_INTEREST_RATE_FINISH',

  // Disable Interest Rate
  DISABLE_INTEREST_RATE_REQUEST: 'DISABLE_INTEREST_RATE_REQUEST',
  DISABLE_INTEREST_RATE_SUCCESS: 'DISABLE_INTEREST_RATE_SUCCESS',
  DISABLE_INTEREST_RATE_FINISH: 'DISABLE_INTEREST_RATE_FINISH',

  // Enable Interest Rate
  SINGLE_ENABLE_INTEREST_RATE_REQUEST: 'SINGLE_ENABLE_INTEREST_RATE_REQUEST',
  SINGLE_ENABLE_INTEREST_RATE_SUCCESS: 'SINGLE_ENABLE_INTEREST_RATE_SUCCESS',
  SINGLE_ENABLE_INTEREST_RATE_FINISH: 'SINGLE_ENABLE_INTEREST_RATE_FINISH',

  // Disable Interest Rate
  SINGLE_DISABLE_INTEREST_RATE_REQUEST: 'SINGLE_DISABLE_INTEREST_RATE_REQUEST',
  SINGLE_DISABLE_INTEREST_RATE_SUCCESS: 'SINGLE_DISABLE_INTEREST_RATE_SUCCESS',
  SINGLE_DISABLE_INTEREST_RATE_FINISH: 'SINGLE_DISABLE_INTEREST_RATE_FINISH',

  // get interest rate loan File csv xlsx
  GET_INTEREST_RATE_LOAN_FILE_START: 'GET_INTEREST_RATE_LOAN_FILE_START',
  GET_INTEREST_RATE_LOAN_FILE_SUCCESS: 'GET_INTEREST_RATE_LOAN_FILE_SUCCESS',
  GET_INTEREST_RATE_LOAN_FILE_FINISH: 'GET_INTEREST_RATE_LOAN_FILE_FINISH',

  // get interest rate deposit file csv xlsx
  GET_INTEREST_RATE_DEPOSIT_FILE_START: 'GET_INTEREST_RATE_DEPOSIT_FILE_START',
  GET_INTEREST_RATE_DEPOSIT_FILE_SUCCESS: 'GET_INTEREST_RATE_DEPOSIT_FILE_SUCCESS',
  GET_INTEREST_RATE_DEPOSIT_FILE_FINISH: 'GET_INTEREST_RATE_DEPOSIT_FILE_FINISH',

  // get interest rate deposit file csv xlsx
  GET_INTEREST_RATE_LYC_FILE_START: 'GET_INTEREST_RATE_LYC_FILE_START',
  GET_INTEREST_RATE_LYC_FILE_SUCCESS: 'GET_INTEREST_RATE_LYC_FILE_SUCCESS',
  GET_INTEREST_RATE_LYC_FILE_FINISH: 'GET_INTEREST_RATE_LYC_FILE_FINISH',

  // get interest rate deposit file csv xlsx
  GET_INTEREST_RATE_FYC_FILE_START: 'GET_INTEREST_RATE_FYC_FILE_START',
  GET_INTEREST_RATE_FYC_FILE_SUCCESS: 'GET_INTEREST_RATE_FYC_FILE_SUCCESS',
  GET_INTEREST_RATE_FYC_FILE_FINISH: 'GET_INTEREST_RATE_FYC_FILE_FINISH',

  // get interest rate fixed deposit file csv xlsx
  GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_START: 'GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_START',
  GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_SUCCESS: 'GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_SUCCESS',
  GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_FINISH: 'GET_INTEREST_RATE_FIXED_DEPOSIT_FILE_FINISH',

  // get interest rate fixed loan file csv xlsx
  GET_INTEREST_RATE_FIXED_LOAN_FILE_START: 'GET_INTEREST_RATE_FIXED_LOAN_FILE_START',
  GET_INTEREST_RATE_FIXED_LOAN_FILE_SUCCESS: 'GET_INTEREST_RATE_FIXED_LOAN_FILE_SUCCESS',
  GET_INTEREST_RATE_FIXED_LOAN_FILE_FINISH: 'GET_INTEREST_RATE_FIXED_LOAN_FILE_FINISH',
}
