import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const NOTIFICATION = `${API_URL}/news`
export const PUSH_NOTIFICATION_SCHEDULE = `${API_URL}/push-notification/schedule`
export const UPDATE_NOTIFICATION_SCHEDULE = `${API_URL}/push-notification`

export const service = {
  addPushNotificationSchedule: (data: any) => {
    return axios.post(PUSH_NOTIFICATION_SCHEDULE, data)
  },

  getNewsPushNotificationSchedule: (params: ParamsModel) => {
    return axios.get(PUSH_NOTIFICATION_SCHEDULE, {params})
  },

  updatePushNotificationSchedule: (body: any, id: string) => {
    return axios.patch(`${UPDATE_NOTIFICATION_SCHEDULE}/${id}/schedule`, body)
  },

  deletePushNotificationSchedule: (data: DeleteModel) => {
    return axios.delete(PUSH_NOTIFICATION_SCHEDULE, {data})
  },

  getNews: (params: ParamsModel) => {
    return axios.get(NOTIFICATION, {params})
  },

  addNews: (data: any) => {
    return axios.post(NOTIFICATION, data)
  },

  updateNews: (body: any, id: string) => {
    return axios.patch(`${NOTIFICATION}/${id}`, body)
  },

  deleteNews: (data: DeleteModel) => {
    return axios.delete(NOTIFICATION, {data})
  },
  singleActivateNews: (id: any) => {
    return axios.patch(`${NOTIFICATION}/enable`, id)
  },

  singleDeactivateNews: (id: any) => {
    return axios.patch(`${NOTIFICATION}/disable`, id)
  },
}
