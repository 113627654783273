export const actionTypes: any = {
  // get
  GET_FEEDBACK_FORM_DETAIL_START: 'GET_FEEDBACK_FORM_DETAIL_START',
  GET_FEEDBACK_FORM_DETAIL_SUCCESS: 'GET_FEEDBACK_FORM_DETAIL_SUCCESS',
  GET_FEEDBACK_FORM_DETAIL_FINISH: 'GET_FEEDBACK_FORM_DETAIL_FINISH',

  // get File csv xlsx
  EXPORT_FEEDBACK_FORM_DETAIL_FILE_START: 'EXPORT_FEEDBACK_FORM_DETAIL_FILE_START',
  EXPORT_FEEDBACK_FORM_DETAIL_FILE_SUCCESS: 'EXPORT_FEEDBACK_FORM_DETAIL_FILE_SUCCESS',
  EXPORT_FEEDBACK_FORM_DETAIL_FILE_FINISH: 'EXPORT_FEEDBACK_FORM_DETAIL_FILE_FINISH',

  // delete
  DELETE_FEEDBACK_START: 'DELETE_FEEDBACK_START',
  DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK_FINISH: 'DELETE_FEEDBACK_FINISH',
}
