import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteApplicationModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getApplications: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BALANCE_CERTIFICATE_START,
    payload: params,
  }),
  getApplicationsSuccess: (data: any) => ({
    type: actionTypes.GET_BALANCE_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  getApplicationsFinish: () => ({
    type: actionTypes.GET_BALANCE_CERTIFICATE_FINISH,
  }),

  deleteApplications: (data: DeleteApplicationModel[]) => ({
    type: actionTypes.DELETE_BALANCE_CERTIFICATE_START,
    payload: {balanceCertificateVerification: data},
  }),
  deleteApplicationsSuccess: (data: any) => ({
    type: actionTypes.DELETE_BALANCE_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  deleteApplicationsFinish: () => ({
    type: actionTypes.DELETE_BALANCE_CERTIFICATE_FINISH,
  }),
}
