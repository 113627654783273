import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortLayoutModel, SortSearchActionModel} from '../Model/Model'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getLayoutSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getLayout, params)
    yield put(actions.getLayoutSuccess(response?.data?.data))
    yield put(actions.getLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getLayoutFinish())
  }
}

function* getAllLayoutSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllLayout)
    yield put(actions.getAllLayoutSuccess(response?.data?.data))
    yield put(actions.getAllLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAllLayoutFinish())
  }
}

function* addLayoutSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addLayout, body)
    yield put(actions.addLayoutSuccess(response.data?.data))
    yield put(actions.addLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.addLayoutFinish())
  }
}

function* enableLayoutSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableLayout, selectedUsers)
    yield put(actions.enableLayoutSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableLayoutFinish())
  }
}

function* disableLayoutSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableLayout, selectedUsers)
    yield put(actions.disableLayoutSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableLayoutFinish())
  }
}

function* singleEnableLayoutSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableLayout, selectedUsers)
    yield put(actions.singleEnableLayoutSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleEnableLayoutFinish())
  }
}

function* singleDisableLayoutSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableLayout, selectedUsers)
    yield put(actions.singleDisableLayoutSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleDisableLayoutFinish())
  }
}

function* updateLayoutSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(service.updateLayout, body, action.payload?.id)
    yield put(actions.updateLayoutSuccess(response.data?.data))
    yield put(actions.updateLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.updateLayoutFinish())
  }
}

function* deleteLayoutSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteLayout, body)
    yield put(actions.deleteLayoutSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.deleteLayoutFinish())
  }
}

function* sortLayout(action: SortSearchActionModel) {
  try {
    const body: SortLayoutModel = action.payload
    const response: ResponseModel = yield call(service.sortLayout, body)

    yield put({
      type: actionTypes.SORT_LAYOUT_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_LAYOUT_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put({type: actionTypes.SORT_LAYOUT_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_LAYOUT_START, getLayoutSaga)
  yield takeLatest(actionTypes.GET_ALL_LAYOUT_START, getAllLayoutSaga)
  yield takeLatest(actionTypes.ADD_LAYOUT_START, addLayoutSaga)
  yield takeLatest(actionTypes.UPDATE_LAYOUT_START, updateLayoutSaga)
  yield takeLatest(actionTypes.DELETE_LAYOUT_START, deleteLayoutSaga)
  yield takeLatest(actionTypes.ENABLE_LAYOUT_REQUEST, enableLayoutSaga)
  yield takeLatest(actionTypes.DISABLE_LAYOUT_REQUEST, disableLayoutSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_LAYOUT_REQUEST, singleEnableLayoutSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_LAYOUT_REQUEST, singleDisableLayoutSaga)
  yield takeLatest(actionTypes.SORT_LAYOUT_START, sortLayout)
}
