import ReloadIcon from '@rsuite/icons/Reload'
import TrashIcon from '@rsuite/icons/Trash'
import {RiDeleteBin6Line} from 'react-icons/ri'

import SearchIcon from '@rsuite/icons/Search'
import {useEffect, useState} from 'react'
import {FaPlus} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {SortType} from 'rsuite-table/lib/@types/common'
import IconButton from 'rsuite/IconButton'
import Input from 'rsuite/Input'
import InputGroup from 'rsuite/InputGroup'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

//Manual import
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as faqManagerRedux from '../../faqManager/redux'
import {IFaqManagerState} from '../redux'
import AddFaqManager from './AddFaqManager'

const Cell = Table.Cell
const FaqManager = () => {
  const dispatch = useDispatch()
  const parameter: any = useParams()
  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [open1, setOpen1] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<any>({})
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const handleClose1 = () => setOpen1(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const faqManagerData: IFaqManagerState = useSelector((state: any) => state.faqManager)

  const {deleteSuccess} = faqManagerData

  useEffect(() => {
    dispatch(faqManagerRedux.actions.getFaqManager(params, parameter?.id))
  }, [params, parameter?.id])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let selectedId = [rowData.id]
                setSelectedData(selectedId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Question',
      flexGrow: 1,
      dataKey: 'faq_qna.question',
      cell: <Cell>{(rowData) => rowData?.faq_qna.map((item: any) => item.question)}</Cell>,
      sortable: true,
    },
    {
      label: 'Question (NP)',
      flexGrow: 1,
      dataKey: 'faq_qna.question_np',
      cell: <Cell>{(rowData) => rowData?.faq_qna.map((item: any) => item.question_np)}</Cell>,
      sortable: true,
    },
    {
      label: 'Action',
      width: 250,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Faq Manager deleted successfully')
      dispatch(faqManagerRedux.actions.getFaqManager(params, parameter?.id))
      handleCheck([])
    }
  }, [deleteSuccess, parameter?.id, params])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const data = faqManagerData?.data?.faqQuestion
    ? faqManagerData?.data?.faqQuestion?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        answer: item.answer,
        answer_np: item.answer_np,
        question: item.question,
        question_np: item.question_np,
        faq_qna: [
          {
            id: item.id,
            answer: item.answer,
            answer_np: item.answer_np,
            question: item.question,
            question_np: item.question_np,
          },
        ],
      }))
    : []

  const handleRefresh = () => {
    dispatch(faqManagerRedux.actions.getFaqManager(params, parameter?.id))
  }

  const handleDelete = () => {
    let faqManagerSelectedId = selectedData?.map((value) => ({id: value}))
    dispatch(faqManagerRedux?.actions.deleteFaqManager(faqManagerSelectedId))
    handleAlertClose()
  }

  return (
    <div className='shadow p-3 bg-white rounded'>
      <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
        <div className='col-lg-6'>
          <div className='heading__component__title fw-bolder'>FAQs Category Manager</div>
        </div>
        <div className='col-lg-6'>
          <div className='d-flex justify-content-end mb-2'>
            <div className='sub_button'>
              <button
                className='dt-btn dt-btn-secondary'
                data-cy='delete-button'
                onClick={handleAlertOpen}
              >
                <RiDeleteBin6Line className='me-2' />
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between align-items-center mb-5'>
          <InputGroup inside className='w-25'>
            <Input
              placeholder='Search'
              onChange={(value: string) => setParams({...params, search: value})}
              data-cy='faq-input-field'
            />
            <InputGroup.Addon data-cy='search-button-1'>
              <SearchIcon />
            </InputGroup.Addon>
            <InputGroup.Addon data-cy='search-button-2'>
              <SearchIcon />
            </InputGroup.Addon>
          </InputGroup>
          <div className='flex-grow-1'>
            <div className='d-flex flex-wrap gap-2 justify-content-end'>
              <div className='sub_button'>
                <button
                  id='refresh-button'
                  className='dt-btn dt-btn-outline-primary dt-btn-sm'
                  onClick={handleRefresh}
                  data-cy='refresh-button'
                >
                  <ReloadIcon />
                </button>
              </div>
              <div
                className='sub_button'
                onClick={() => {
                  setEditCheckedData({faq_qna: faqManagerData?.data?.faqQuestion})
                  setActionType('Add')
                  setOpen1(true)
                }}
                data-cy='sub-button'
              >
                <button className='dt-btn dt-btn-primary dt-btn-sm'>
                  <FaPlus />
                  <span style={{paddingLeft: '5px'}}>Update Questions</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={faqManagerData?.loading}
            onChecked={handleCheck}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='xs'
            layout={['total', '-', 'limit', '|', 'pager']}
            total={faqManagerData?.data?.meta?.total}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open1 && (
          <AddFaqManager
            faqCategoryId={parameter?.id}
            open={open1}
            handleClose={handleClose1}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}

        <>
          {alertOpen &&
            (!isEmpty(selectedData) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              ></DeleteModal>
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>
      </div>
    </div>
  )
}
export default FaqManager
