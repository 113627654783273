export const actionTypes: any = {
  // get
  GET_ALL_PROGRAM_START: 'GET_ALL_PROGRAM_START',
  GET_ALL_PROGRAM_SUCCESS: 'GET_ALL_PROGRAM_SUCCESS',
  GET_ALL_PROGRAM_FINISH: 'GET_ALL_PROGRAM_FINISH',

  // extended hours
  GET_PROGRAM_START: 'GET_PROGRAM_START',
  GET_PROGRAM_SUCCESS: 'GET_PROGRAM_SUCCESS',
  GET_PROGRAM_FINISH: 'GET_PROGRAM_FINISH',

  // add branch
  ADD_PROGRAM_START: 'ADD_PROGRAM_START',
  ADD_PROGRAM_SUCCESS: 'ADD_PROGRAM_SUCCESS',
  ADD_PROGRAM_FINISH: 'ADD_PROGRAM_FINISH',
  RESET_PROGRAM_TAG: 'RESET_PROGRAM_TAG',

  // update PROGRAM_TAG
  UPDATE_PROGRAM_START: 'UPDATE_PROGRAM_START',
  UPDATE_PROGRAM_SUCCESS: 'UPDATE_PROGRAM_SUCCESS',
  UPDATE_PROGRAM_FINISH: 'UPDATE_PROGRAM_FINISH',

  // delete PROGRAM_TAG
  DELETE_PROGRAM_START: 'DELETE_PROGRAM_START',
  DELETE_PROGRAM_SUCCESS: 'DELETE_PROGRAM_SUCCESS',
  DELETE_PROGRAM_FINISH: 'DELETE_PROGRAM_FINISH',

  // Enable PROGRAM_TAG
  ENABLE_PROGRAM_REQUEST: 'ENABLE_PROGRAM_REQUEST',
  ENABLE_PROGRAM_SUCCESS: 'ENABLE_PROGRAM_SUCCESS',
  ENABLE_PROGRAM_FINISH: 'ENABLE_PROGRAM_FINISH',

  // Disable PROGRAM_TAG
  DISABLE_PROGRAM_REQUEST: 'DISABLE_PROGRAM_REQUEST',
  DISABLE_PROGRAM_SUCCESS: 'DISABLE_PROGRAM_SUCCESS',
  DISABLE_PROGRAM_FINISH: 'DISABLE_PROGRAM_FINISH',

  // Enable PROGRAM_TAG
  SINGLE_ENABLE_PROGRAM_REQUEST: 'SINGLE_ENABLE_PROGRAM_REQUEST',
  SINGLE_ENABLE_PROGRAM_SUCCESS: 'SINGLE_ENABLE_PROGRAM_SUCCESS',
  SINGLE_ENABLE_PROGRAM_FINISH: 'SINGLE_ENABLE_PROGRAM_FINISH',

  // Disable PROGRAM_TAG
  SINGLE_DISABLE_PROGRAM_REQUEST: 'SINGLE_DISABLE_PROGRAM_REQUEST',
  SINGLE_DISABLE_PROGRAM_SUCCESS: 'SINGLE_DISABLE_PROGRAM_SUCCESS',
  SINGLE_DISABLE_PROGRAM_FINISH: 'SINGLE_DISABLE_PROGRAM_FINISH',

  // sort
  SORT_PROGRAM_START: 'SORT_PROGRAM_START',
  SORT_PROGRAM_SUCCESS: 'SORT_PROGRAM_SUCCESS',
  SORT_PROGRAM_FINISH: 'SORT_PROGRAM_FINISH',
  SORT_PROGRAM_RESET: 'SORT_PROGRAM_RESET',

  //GET BANNER OPTION CONSTANTS
  GET_PROGRAM_BANNER_OPTION_START: 'GET_PROGRAM_BANNER_OPTION_START',
  GET_PROGRAM_BANNER_OPTION_SUCCESS: 'GET_PROGRAM_BANNER_OPTION_SUCCESS',
  GET_PROGRAM_BANNER_OPTION_FINISH: 'GET_PROGRAM_BANNER_OPTION_FINISH',

  //GET ALBUM OPTION CONSTANTS
  GET_PROGRAM_ALBUM_OPTION_START: 'GET_PROGRAM_ALBUM_OPTION_START',
  GET_PROGRAM_ALBUM_OPTION_SUCCESS: 'GET_PROGRAM_ALBUM_OPTION_SUCCESS',
  GET_PROGRAM_ALBUM_OPTION_FINISH: 'GET_PROGRAM_ALBUM_OPTION_FINISH',

  //GET Download OPTION CONSTANTS
  GET_PROGRAM_DOWNLOAD_OPTION_START: 'GET_PROGRAM_DOWNLOAD_OPTION_START',
  GET_PROGRAM_DOWNLOAD_OPTION_SUCCESS: 'GET_PROGRAM_DOWNLOAD_OPTION_SUCCESS',
  GET_PROGRAM_DOWNLOAD_OPTION_FINISH: 'GET_PROGRAM_DOWNLOAD_OPTION_FINISH',

  //GET LEAD FORM
  GET_PROGRAM_LEAD_FORM_START: 'GET_PROGRAM_LEAD_FORM_START',
  GET_PROGRAM_LEAD_FORM_SUCCESS: 'GET_PROGRAM_LEAD_FORM_SUCCESS',
  GET_PROGRAM_LEAD_FORM_FINISH: 'GET_PROGRAM_LEAD_FORM_FINISH',

  //GET PAGE HEADER
  GET_PROGRAM_PAGE_HEADER_START: 'GET_PROGRAM_PAGE_HEADER_START',
  GET_PROGRAM_PAGE_HEADER_SUCCESS: 'GET_PROGRAM_PAGE_HEADER_SUCCESS',
  GET_PROGRAM_PAGE_HEADER_FINISH: 'GET_PROGRAM_PAGE_HEADER_FINISH',

  //GET PRODUCT OPTION CONSTANTS
  GET_PROGRAM_OPTION_START: 'GET_PROGRAM_OPTION_START',
  GET_PROGRAM_OPTION_SUCCESS: 'GET_PROGRAM_OPTION_SUCCESS',
  GET_PROGRAM_OPTION_FINISH: 'GET_PROGRAM_OPTION_FINISH',
}
