import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getAbroadRepresentativesSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getAbroadRepresentatives, params)
    yield put(actions.getAbroadRepresentativesSuccess(response?.data?.data))
    yield put(actions.getAbroadRepresentativesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAbroadRepresentativesFinish())
  }
}

function* addAbroadRepresentativesSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAbroadRepresentatives, body)
    yield put(actions.addAbroadRepresentativesSuccess(response.data?.data))
    yield put(actions.addAbroadRepresentativesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.addAbroadRepresentativesFinish())
  }
}

function* updateAbroadRepresentativesSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(
      service.updateAbroadRepresentatives,
      body,
      action.payload?.id
    )
    yield put(actions.updateAbroadRepresentativesSuccess(response.data?.data))
    yield put(actions.updateAbroadRepresentativesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.updateAbroadRepresentativesFinish())
  }
}

function* deleteAbroadRepresentativesSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAbroadRepresentatives, body)
    yield put(actions.deleteAbroadRepresentativesSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteAbroadRepresentativesFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.deleteAbroadRepresentativesFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ABROAD_REPRESENTATIVES_START, getAbroadRepresentativesSaga)
  yield takeLatest(actionTypes.ADD_ABROAD_REPRESENTATIVES_START, addAbroadRepresentativesSaga)
  yield takeLatest(actionTypes.UPDATE_ABROAD_REPRESENTATIVES_START, updateAbroadRepresentativesSaga)
  yield takeLatest(actionTypes.DELETE_ABROAD_REPRESENTATIVES_START, deleteAbroadRepresentativesSaga)
}
