import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {CardMenuDetailModel} from '../Model'

function* getCardMenuDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCardMenuDetail, params)
    yield put(actions.getCardMenuDetailSuccess(response?.data?.data))
    yield put(actions.getCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCardMenuDetailFinish())
  }
}

function* addCardMenuDetailSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCardMenuDetail, body)
    yield put(actions.addCardMenuDetailSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCardMenuDetailFinish())
  }
}

function* enableCardMenuDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableCardMenuDetail, selectedUsers)
    yield put(actions.enableCardMenuDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCardMenuDetailFinish())
  }
}

function* disableCardMenuDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableCardMenuDetail, selectedUsers)
    yield put(actions.disableCardMenuDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableCardMenuDetailFinish())
  }
}

function* singleEnableCardMenuDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableCardMenuDetail, selectedUsers)
    yield put(actions.singleEnableCardMenuDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableCardMenuDetailFinish())
  }
}

function* singleDisableCardMenuDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableCardMenuDetail, selectedUsers)
    yield put(actions.singleDisableCardMenuDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableCardMenuDetailFinish())
  }
}

function* updateCardMenuDetailSaga(action: ActionModel) {
  try {
    const body: CardMenuDetailModel = action.payload
    const response: ResponseModel = yield call(
      service.updateCardMenuDetail,
      body,
      action.payload?.id
    )
    yield put(actions.updateCardMenuDetailSuccess(response.data?.data))
    yield put(actions.updateCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCardMenuDetailFinish())
  }
}

function* deleteCardMenuDetailSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCardMenuDetail, body)

    yield put(actions.deleteCardMenuDetailSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteCardMenuDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCardMenuDetailFinish())
  }
}

//Get CardMenuDetail saga
function* getAllCardMenuDetailSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAllCardMenuDetail)
    const data: any = response?.data
    yield put(actions.getAllCardMenuDetailSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAllCardMenuDetailError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CARD_MENU_START, getCardMenuDetailSaga)

  /*   yield takeLatest(actionTypes.GET_CARD_MENU_START, getAllCardMenuDetailSaga) */
  yield takeLatest(actionTypes.ADD_CARD_MENU_START, addCardMenuDetailSaga)
  yield takeLatest(actionTypes.UPDATE_CARD_MENU_START, updateCardMenuDetailSaga)
  yield takeLatest(actionTypes.DELETE_CARD_MENU_START, deleteCardMenuDetailSaga)
  yield takeLatest(actionTypes.ENABLE_CARD_MENU_REQUEST, enableCardMenuDetailSaga)
  yield takeLatest(actionTypes.DISABLE_CARD_MENU_REQUEST, disableCardMenuDetailSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_CARD_MENU_REQUEST, singleEnableCardMenuDetailSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_CARD_MENU_REQUEST, singleDisableCardMenuDetailSaga)
  yield takeLatest(actionTypes.GET_ALL_CARD_MENU_START, getAllCardMenuDetailSaga)
}
