export const actionTypes: any = {
  // get Extension Counter
  GET_EXTENSION_COUNTER_START: 'GET_EXTENSION_COUNTER_START',
  GET_EXTENSION_COUNTER_SUCCESS: 'GET_EXTENSION_COUNTER_SUCCESS',
  GET_EXTENSION_COUNTER_FINISH: 'GET_EXTENSION_COUNTER_FINISH',

  // get Extension Counter
  GET_ALL_BANNER_START: 'GET_ALL_BANNER_START',
  GET_ALL_BANNER_SUCCESS: 'GET_ALL_BANNER_SUCCESS',
  GET_ALL_BANNER_FINISH: 'GET_ALL_BANNER_FINISH',

  //Add Extension Counter
  ADD_EXTENSION_COUNTER_REQUEST: 'ADD_EXTENSION_COUNTER_REQUEST',
  ADD_EXTENSION_COUNTER_SUCCESS: 'ADD_EXTENSION_COUNTER_SUCCESS',
  ADD_EXTENSION_COUNTER_FINISH: 'ADD_EXTENSION_COUNTER_FINISH',
  ADD_EXTENSION_COUNTER_RESET: 'ADD_EXTENSION_COUNTER_RESET',

  // update Extension Counter
  UPDATE_EXTENSION_COUNTER_START: 'UPDATE_EXTENSION_COUNTER_START',
  UPDATE_EXTENSION_COUNTER_SUCCESS: 'UPDATE_EXTENSION_COUNTER_SUCCESS',
  UPDATE_EXTENSION_COUNTER_FINISH: 'UPDATE_EXTENSION_COUNTER_FINISH',
  UPDATE_EXTENSION_COUNTER_RESET: 'UPDATE_EXTENSION_COUNTER_RESET',

  //Bulk Activate Extension Counter
  ACTIVATE_EXTENSION_COUNTER_REQUEST: 'ACTIVATE_EXTENSION_COUNTER_REQUEST',
  ACTIVATE_EXTENSION_COUNTER_SUCCESS: 'ACTIVATE_EXTENSION_COUNTER_SUCCESS',
  ACTIVATE_EXTENSION_COUNTER_FINISH: 'ACTIVATE_EXTENSION_COUNTER_FINISH',

  //Bulk Deactivate Extension Counter
  DEACTIVATE_EXTENSION_COUNTER_REQUEST: 'DEACTIVATE_EXTENSION_COUNTER_REQUEST',
  DEACTIVATE_EXTENSION_COUNTER_SUCCESS: 'DEACTIVATE_EXTENSION_COUNTER_SUCCESS',
  DEACTIVATE_EXTENSION_COUNTER_FINISH: 'DEACTIVATE_EXTENSION_COUNTER_FINISH',

  //Single Activate Extension Counter
  SINGLE_ACTIVATE_EXTENSION_COUNTER_REQUEST: 'SINGLE_ACTIVATE_EXTENSION_COUNTER_REQUEST',
  SINGLE_ACTIVATE_EXTENSION_COUNTER_SUCCESS: 'SINGLE_ACTIVATE_EXTENSION_COUNTER_SUCCESS',
  SINGLE_ACTIVATE_EXTENSION_COUNTER_FINISH: 'SINGLE_ACTIVATE_EXTENSION_COUNTER_FINISH',

  //Single Deactivate Extension Counter
  SINGLE_DEACTIVATE_EXTENSION_COUNTER_REQUEST: 'SINGLE_DEACTIVATE_EXTENSION_COUNTER_REQUEST',
  SINGLE_DEACTIVATE_EXTENSION_COUNTER_SUCCESS: 'SINGLE_DEACTIVATE_EXTENSION_COUNTER_SUCCESS',
  SINGLE_DEACTIVATE_EXTENSION_COUNTER_FINISH: 'SINGLE_DEACTIVATE_EXTENSION_COUNTER_FINISH',

  // delete Extension Counter
  DELETE_EXTENSION_COUNTER_START: 'DELETE_EXTENSION_COUNTER_START',
  DELETE_EXTENSION_COUNTER_SUCCESS: 'DELETE_EXTENSION_COUNTER_SUCCESS',
  DELETE_EXTENSION_COUNTER_FINISH: 'DELETE_EXTENSION_COUNTER_FINISH',

  // get File csv xlsx
  GET_EXTENSION_COUNTER_FILE_START: 'GET_EXTENSION_COUNTER_FILE_START',
  GET_EXTENSION_COUNTER_FILE_SUCCESS: 'GET_EXTENSION_COUNTER_FILE_SUCCESS',
  GET_EXTENSION_COUNTER_FILE_FINISH: 'GET_EXTENSION_COUNTER_FILE_FINISH',

  // Import Extension Counter
  IMPORT_EXTENSION_COUNTER_START: 'IMPORT_EXTENSION_COUNTER_START',
  IMPORT_EXTENSION_COUNTER_SUCCESS: 'IMPORT_EXTENSION_COUNTER_SUCCESS',
  IMPORT_EXTENSION_COUNTER_FINISH: 'IMPORT_EXTENSION_COUNTER_FINISH',

  EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_START: 'EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_START',
  EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_SUCCESS: 'EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_SUCCESS',
  EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_FINISH: 'EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_FINISH',
}
