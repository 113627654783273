import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//Manual import
import * as cms from 'src/app/modules/cms'
import * as roadBlockPopupRedux from 'src/app/modules/cms/components/roadblockPopup/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {IMenuState} from '../../menu'
import {MenuOptionModal} from '../../menu/Model'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddRoadBlockPopup = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.roadBlockPopup)
  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)
  useEffect(() => {
    if (success) {
      dispatch(roadBlockPopupRedux?.actions.getRoadBlockPopup(params))
      isEmpty(editSelectedData)
        ? toast.success('Road Block Popup added successfully')
        : toast.success('Road Block Popup edited successfully')
      dispatch(roadBlockPopupRedux?.actions.createRoadBlockPopupReset())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  // check Backend Setting
  const hideRoadblockMultiLanguage = checkBackendSetting('hideRoadblockMultiLanguage')
  const hideRoadblockFirstBtnText = checkBackendSetting('hideRoadblockFirstBtnText')
  const hideRoadblockFirstBtnUrl = checkBackendSetting('hideRoadblockFirstBtnUrl')
  const isFirstButtonTextRequired = checkBackendSetting('firstButtonText')
  const isFirstButtonUrlRequired = checkBackendSetting('firstButtonUrl')

  const showRoadblockTopMenuDropdown = checkBackendSetting('showRoadblockTopMenuDropdown')

  const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description  is required'),
    startDate: Yup.string().required('Start Date  is required'),
    endDate: Yup.string().required('End Date  is required'),
    startTime: Yup.string().required('Start Time  is required'),
    endTime: Yup.string().required('End Time  is required'),
    status: Yup.string().required('Status is required'),

    first_button_text: Yup.string().when('isFirstButtonTextRequired', {
      is: (value: any) => !value && isFirstButtonTextRequired,
      then: Yup.string().required('First Button Text is required'),
      otherwise: Yup.string().nullable(),
    }),

    first_button_url: Yup.string().when('isFirstButtonUrlRequired', {
      is: (value: any) => !value && isFirstButtonUrlRequired,
      then: Yup.string().required('First Button url is required'),
      otherwise: Yup.string().nullable(),
    }),
  })

  const topMenuOptions = topMenu?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  useEffect(() => {
    if (showRoadblockTopMenuDropdown) {
      dispatch(cms.menu.actions.getTopMenu())
    }
  }, [])

  return (
    <div className='modal-container'>
      <Modal open={open} onClose={handleClose} size='lg' enforceFocus={false} data-cy='modal'>
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Add Road Block Popup </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                top_menu_id: '',
                title: '',
                titleNp: '',
                description: '',
                descriptionNp: '',
                startDate: '',
                startTime: '',
                endDate: '',
                endTime: '',
                status: '',
                first_button_text: '',
                first_button_url: '',
                top_Menu_id: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  startDate: moment(values?.startDate).format('YYYY-MM-DD'),
                  endDate: moment(values?.endDate).format('YYYY-MM-DD'),
                  startTime: moment(values?.startTime).format('HH:mm'),
                  endTime: moment(values?.endTime).format('HH:mm'),
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    roadBlockPopupRedux.actions.updateRoadBlockPopup(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(roadBlockPopupRedux.actions.CreateRoadBlockPopup(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'titleNp',
                      'description',
                      'descriptionNp',
                      'startDate',
                      'startTime',
                      'endDate',
                      'endTime',
                      'first_button_text',
                      'first_button_url',
                      'top_menu_id',
                    ]
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      {showRoadblockTopMenuDropdown && (
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select top menu'
                              label='Top Menu'
                              name='top_menu_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={topMenuOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                      )}

                      <div className='row justify-content-between'>
                        {hideRoadblockMultiLanguage ? (
                          <div className='col-6'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Title '
                              name='title'
                              label='Title '
                              containerClassName=''
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                            />
                          </div>
                        ) : (
                          <>
                            <div className='col-6'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Title (EN)'
                                name='title'
                                label='Title (EN)'
                                containerClassName=''
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                              />
                            </div>
                            <div className='col-6'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Title (NP)'
                                name='titleNp'
                                label='Title (NP)'
                                containerClassName=''
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <div className='row justify-content-between'>
                        {hideRoadblockMultiLanguage ? (
                          <div className='col-6'>
                            <FormTinyMce
                              name='description'
                              handleChange={handleChange}
                              label='Description '
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                              }
                              required={true}
                            />
                          </div>
                        ) : (
                          <>
                            <div className='col-6'>
                              <FormTinyMce
                                name='description'
                                handleChange={handleChange}
                                label='Description (EN)'
                                initialValue={
                                  !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                                }
                                required={true}
                              />
                            </div>
                            <div className='col-6'>
                              <FormTinyMce
                                name='descriptionNp'
                                handleChange={handleChange}
                                label='Description (NP)'
                                initialValue={
                                  !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <div className='row justify-content-between '>
                        <div className='col-6'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='Start Date'
                            name='startDate'
                            placeholderText='Please select a date'
                            setFieldValue={setFieldValue}
                            value={values.startDate || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>

                        <div className='col-6'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='startTime'
                            label=' Start Time '
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values.startTime || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='End Date'
                            name='endDate'
                            placeholderText='Please select a date'
                            setFieldValue={setFieldValue}
                            value={values.endDate || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>

                        <div className='col-6'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='endTime'
                            label=' End Time '
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values.endTime || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between'>
                        {hideRoadblockFirstBtnText ? null : (
                          <div className='col-6'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter button text'
                              name='first_button_text'
                              label='First Button Text'
                              containerClassName=''
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={isFirstButtonTextRequired}
                            />
                          </div>
                        )}

                        {hideRoadblockFirstBtnUrl ? null : (
                          <div className='col-6'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter button URL '
                              name='first_button_url'
                              label='First Button URL'
                              containerClassName=''
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={isFirstButtonUrlRequired}
                            />
                          </div>
                        )}
                      </div>

                      <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>
                      <div className='d-flex ms-5'>
                        {statusOptions?.map((status: {label: string; value: string}) => (
                          <FormRadio
                            containerClassName=''
                            label='Select Status'
                            name='status'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checkBoxText={status?.label}
                            value={status?.value}
                            required={true}
                          />
                        ))}
                      </div>
                      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='field-error-message'
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddRoadBlockPopup
