export const actionTypes: any = {
  // product BREADCRUMB OPTION
  GET_SERVICE_BREADCRUMB_OPTION_START: 'GET_SERVICE_BREADCRUMB_OPTION_START',
  GET_SERVICE_BREADCRUMB_OPTION_SUCCESS: 'GET_SERVICE_BREADCRUMB_OPTION_SUCCESS',
  GET_SERVICE_BREADCRUMB_OPTION_FINISH: 'GET_SERVICE_BREADCRUMB_OPTION_FINISH',

  GET_SERVICE_SLIDER_SECTION_START: 'GET_SERVICE_SLIDER_SECTION_START',
  GET_SERVICE_SLIDER_SECTION_SUCCESS: 'GET_SERVICE_SLIDER_SECTION_SUCCESS',
  GET_SERVICE_SLIDER_SECTION_FINISH: 'GET_SERVICE_SLIDER_SECTION_FINISH',

  // GET

  GET_SERVICE_BANNER_OPTION_START: 'GET_SERVICE_BANNER_OPTION_START',
  GET_SERVICE_BANNER_OPTION_SUCCESS: 'GET_SERVICE_BANNER_OPTION_SUCCESS',
  GET_SERVICE_BANNER_OPTION_FINISH: 'GET_SERVICE_BANNER_OPTION_FINISH',

  // GET
  GET_SERVICE_MANAGER_START: 'GET_SERVICE_MANAGER_START',
  GET_SERVICE_MANAGER_SUCCESS: 'GET_SERVICE_MANAGER_SUCCESS',
  GET_SERVICE_MANAGER_FINISH: 'GET_SERVICE_MANAGER_FINISH',

  GET_SERVICE_MANAGER_LIST_START: 'GET_SERVICE_MANAGER_LIST_START',
  GET_SERVICE_MANAGER_LIST_SUCCESS: 'GET_SERVICE_MANAGER_LIST_SUCCESS',
  GET_SERVICE_MANAGER_LIST_FINISH: 'GET_SERVICE_MANAGER_LIST_FINISH',

  // POST
  ADD_SERVICE_MANAGER_START: 'ADD_SERVICE_MANAGER_START',
  ADD_SERVICE_MANAGER_SUCCESS: 'ADD_SERVICE_MANAGER_SUCCESS',
  ADD_SERVICE_MANAGER_FINISH: 'ADD_SERVICE_MANAGER_FINISH',
  ADD_SERVICE_MANAGER_RESET: 'ADD_SERVICE_MANAGER_RESET',

  // UPDATE
  UPDATE_SERVICE_MANAGER_START: 'UPDATE_SERVICE_MANAGER_START',
  UPDATE_SERVICE_MANAGER_SUCCESS: 'UPDATE_SERVICE_MANAGER_SUCCESS',
  UPDATE_SERVICE_MANAGER_FINISH: 'UPDATE_SERVICE_MANAGER_FINISH',
  UPDATE_SERVICE_MANAGER_RESET: 'UPDATE_SERVICE_MANAGER_RESET',

  // Get Options
  GET_SERVICE_REVIEW_OPTIONS_START: 'GET_SERVICE_REVIEW_OPTIONS_START',
  GET_SERVICE_REVIEW_OPTIONS_SUCCESS: 'GET_SERVICE_REVIEW_OPTIONS_SUCCESS',
  GET_SERVICE_REVIEW_OPTIONS_FINISH: 'GET_SERVICE_REVIEW_OPTIONS_FINISH',

  GET_SERVICE_TAGS_LIST_START: 'GET_SERVICE_TAGS_LIST_START',
  GET_SERVICE_TAGS_LIST_SUCCESS: 'GET_SERVICE_TAGS_LIST_SUCCESS',
  GET_SERVICE_TAGS_LIST_FINISH: 'GET_SERVICE_TAGS_LIST_FINISH',

  GET_SERVICE_POPULARITY_START: 'GET_SERVICE_POPULARITY_START',
  GET_SERVICE_POPULARITY_SUCCESS: 'GET_SERVICE_POPULARITY_SUCCESS',
  GET_SERVICE_POPULARITY_FINISH: 'GET_SERVICE_POPULARITY_FINISH',

  GET_SERVICES_LEAD_FORM_START: 'GET_SERVICES_LEAD_FORM_START',
  GET_SERVICES_LEAD_FORM_SUCCESS: 'GET_SERVICES_LEAD_FORM_SUCCESS',
  GET_SERVICES_LEAD_FORM_FINISH: 'GET_SERVICES_LEAD_FORM_FINISH',

  GET_SERVICES_MEDIA_TYPE_START: 'GET_SERVICES_MEDIA_TYPE_START',
  GET_SERVICES_MEDIA_TYPE_SUCCESS: 'GET_SERVICES_MEDIA_TYPE_SUCCESS',
  GET_SERVICES_MEDIA_TYPE_FINISH: 'GET_SERVICES_MEDIA_TYPE_FINISH',

  GET_SERVICES_FEATURES_OPTION_START: 'GET_SERVICES_FEATURES_OPTION_START',
  GET_SERVICES_FEATURES_OPTION_SUCCESS: 'GET_SERVICES_FEATURES_OPTION_SUCCESS',
  GET_SERVICES_FEATURES_OPTION_FINISH: 'GET_SERVICES_FEATURES_OPTION_FINISH',

  GET_SERVICE_FAQ_OPTION_START: 'GET_SERVICE_FAQ_OPTION_START',
  GET_SERVICE_FAQ_OPTION_SUCCESS: 'GET_SERVICE_FAQ_OPTION_SUCCESS',
  GET_SERVICE_FAQ_OPTION_FINISH: 'GET_SERVICE_FAQ_OPTION_FINISH',

  GET_SERVICE_DOCUMENT_OPTION_START: 'GET_SERVICE_DOCUMENT_OPTION_START',
  GET_SERVICE_DOCUMENT_OPTION_SUCCESS: 'GET_SERVICE_DOCUMENT_OPTION_SUCCESS',
  GET_SERVICE_DOCUMENT_OPTION_FINISH: 'GET_SERVICE_DOCUMENT_OPTION_FINISH',

  GET_SERVICE_RELATED_OPTION_START: 'GET_SERVICE_RELATED_OPTION_START',
  GET_SERVICE_RELATED_OPTION_SUCCESS: 'GET_SERVICE_RELATED_OPTION_SUCCESS',
  GET_SERVICE_RELATED_OPTION_FINISH: 'GET_SERVICE_RELATED_OPTION_FINISH',

  GET_SERVICE_APPLY_NOW_OPTION_START: 'GET_SERVICE_APPLY_NOW_OPTION_START',
  GET_SERVICE_APPLY_NOW_OPTION_SUCCESS: 'GET_SERVICE_APPLY_NOW_OPTION_SUCCESS',
  GET_SERVICE_APPLY_NOW_OPTION_FINISH: 'GET_SERVICE_APPLY_NOW_OPTION_FINISH',

  // DELETE
  DELETE_SERVICE_MANAGER_START: 'DELETE_SERVICE_MANAGER_START',
  DELETE_SERVICE_MANAGER_SUCCESS: 'DELETE_SERVICE_MANAGER_SUCCESS',
  DELETE_SERVICE_MANAGER_FINISH: 'DELETE_SERVICE_MANAGER_FINISH',
  DELETE_SERVICE_MANAGER_RESET: 'DELETE_SERVICE_MANAGER_RESET',

  // ENABLE
  ENABLE_SERVICE_MANAGER_START: 'ENABLE_SERVICE_MANAGER_START',
  ENABLE_SERVICE_MANAGER_SUCCESS: 'ENABLE_SERVICE_MANAGER_SUCCESS',
  ENABLE_SERVICE_MANAGER_FINISH: 'ENABLE_SERVICE_MANAGER_FINISH',
  ENABLE_SERVICE_MANAGER_RESET: 'ENABLE_SERVICE_MANAGER_RESET',

  // DISABLE
  DISABLE_SERVICE_MANAGER_START: 'DISABLE_SERVICE_MANAGER_START',
  DISABLE_SERVICE_MANAGER_SUCCESS: 'DISABLE_SERVICE_MANAGER_SUCCESS',
  DISABLE_SERVICE_MANAGER_FINISH: 'DISABLE_SERVICE_MANAGER_FINISH',
  DISABLE_SERVICE_MANAGER_RESET: 'DISABLE_SERVICE_MANAGER_RESET',

  // sort
  SORT_SERVICE_MANAGER_START: 'SORT_SERVICE_MANAGER_START',
  SORT_SERVICE_MANAGER_SUCCESS: 'SORT_SERVICE_MANAGER_SUCCESS',
  SORT_SERVICE_MANAGER_FINISH: 'SORT_SERVICE_MANAGER_FINISH',
  SORT_SERVICE_MANAGER_RESET: 'SORT_SERVICE_MANAGER_RESET',
}
