export const actionTypes: any = {
  // get
  GET_ALL_COLLEGE_DETAIL_START: 'GET_ALL_COLLEGE_DETAIL_START',
  GET_ALL_COLLEGE_DETAIL_SUCCESS: 'GET_ALL_COLLEGE_DETAIL_SUCCESS',
  GET_ALL_COLLEGE_DETAIL_FINISH: 'GET_ALL_COLLEGE_DETAIL_FINISH',

  // extended hours
  GET_COLLEGE_DETAIL_START: 'GET_COLLEGE_DETAIL_START',
  GET_COLLEGE_DETAIL_SUCCESS: 'GET_COLLEGE_DETAIL_SUCCESS',
  GET_COLLEGE_DETAIL_FINISH: 'GET_COLLEGE_DETAIL_FINISH',

  // add branch
  ADD_COLLEGE_DETAIL_START: 'ADD_COLLEGE_DETAIL_START',
  ADD_COLLEGE_DETAIL_SUCCESS: 'ADD_COLLEGE_DETAIL_SUCCESS',
  ADD_COLLEGE_DETAIL_FINISH: 'ADD_COLLEGE_DETAIL_FINISH',
  RESET_COLLEGE_DETAIL: 'RESET_COLLEGE_DETAIL',

  // update COLLEGE_DETAIL
  UPDATE_COLLEGE_DETAIL_START: 'UPDATE_COLLEGE_DETAIL_START',
  UPDATE_COLLEGE_DETAIL_SUCCESS: 'UPDATE_COLLEGE_DETAIL_SUCCESS',
  UPDATE_COLLEGE_DETAIL_FINISH: 'UPDATE_COLLEGE_DETAIL_FINISH',

  // delete COLLEGE_DETAIL
  DELETE_COLLEGE_DETAIL_START: 'DELETE_COLLEGE_DETAIL_START',
  DELETE_COLLEGE_DETAIL_SUCCESS: 'DELETE_COLLEGE_DETAIL_SUCCESS',
  DELETE_COLLEGE_DETAIL_FINISH: 'DELETE_COLLEGE_DETAIL_FINISH',

  // Enable COLLEGE_DETAIL
  ENABLE_COLLEGE_DETAIL_REQUEST: 'ENABLE_COLLEGE_DETAIL_REQUEST',
  ENABLE_COLLEGE_DETAIL_SUCCESS: 'ENABLE_COLLEGE_DETAIL_SUCCESS',
  ENABLE_COLLEGE_DETAIL_FINISH: 'ENABLE_COLLEGE_DETAIL_FINISH',

  // Disable COLLEGE_DETAIL
  DISABLE_COLLEGE_DETAIL_REQUEST: 'DISABLE_COLLEGE_DETAIL_REQUEST',
  DISABLE_COLLEGE_DETAIL_SUCCESS: 'DISABLE_COLLEGE_DETAIL_SUCCESS',
  DISABLE_COLLEGE_DETAIL_FINISH: 'DISABLE_COLLEGE_DETAIL_FINISH',

  // Enable COLLEGE_DETAIL
  SINGLE_ENABLE_COLLEGE_DETAIL_REQUEST: 'SINGLE_ENABLE_COLLEGE_DETAIL_REQUEST',
  SINGLE_ENABLE_COLLEGE_DETAIL_SUCCESS: 'SINGLE_ENABLE_COLLEGE_DETAIL_SUCCESS',
  SINGLE_ENABLE_COLLEGE_DETAIL_FINISH: 'SINGLE_ENABLE_COLLEGE_DETAIL_FINISH',

  // Disable COLLEGE_DETAIL
  SINGLE_DISABLE_COLLEGE_DETAIL_REQUEST: 'SINGLE_DISABLE_COLLEGE_DETAIL_REQUEST',
  SINGLE_DISABLE_COLLEGE_DETAIL_SUCCESS: 'SINGLE_DISABLE_COLLEGE_DETAIL_SUCCESS',
  SINGLE_DISABLE_COLLEGE_DETAIL_FINISH: 'SINGLE_DISABLE_COLLEGE_DETAIL_FINISH',

  // sort
  SORT_COLLEGE_DETAIL_START: 'SORT_COLLEGE_DETAIL_START',
  SORT_COLLEGE_DETAIL_SUCCESS: 'SORT_COLLEGE_DETAIL_SUCCESS',
  SORT_COLLEGE_DETAIL_FINISH: 'SORT_COLLEGE_DETAIL_FINISH',
  SORT_COLLEGE_DETAIL_RESET: 'SORT_COLLEGE_DETAIL_RESET',

  // get Type Id
  GET_ABOUT_TYPE_OPTION_START: 'GET_ABOUT_TYPE_OPTION_START',
  GET_ABOUT_TYPE_OPTION_SUCCESS: 'GET_ABOUT_TYPE_OPTION_SUCCESS',
  GET_ABOUT_TYPE_OPTION_FINISH: 'GET_ABOUT_TYPE_OPTION_FINISH',
}
