import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as featuredRedux from '../../featuredImage/redux'
import {IFeaturedState} from '../../featuredImage/redux'
import {FeaturedFileTypeModel} from '../Model'
import {convertToSlug} from 'src/cms/helpers/helperFunction'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddFeatured = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [thumbImage, setThumbImage] = useState('')
  const [mediaType, setMediaType] = useState('')
  const {loading, success} = useSelector((state: any) => state?.featured ?? [])
  const featuredImageTypeData: IFeaturedState = useSelector((state: any) => state?.featured)

  const hideMediaSelectType = checkBackendSetting('hideMediaSelectType')

  const featuredImageType = featuredImageTypeData?.featuredImageType?.map(
    (items: FeaturedFileTypeModel | any) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    typeId: Yup.string().required('File type is required'),
    thumbImage: Yup.string().when('typeId', {
      is: (value: any) => !value && mediaType === 'image',
      then: Yup.string().required('Thumb Image is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    video: Yup.string().when('typeId', {
      is: (value: any) => !value && mediaType === 'video',
      then: Yup.string().required('iframe is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
  })

  useEffect(() => {
    dispatch(featuredRedux?.actions?.getFeaturedImageType())
    if (success) {
      dispatch(featuredRedux?.actions.getFeatured(params))
      isEmpty(editSelectedData)
        ? toast.success('Featured added successfully')
        : toast.success('Featured edited successfully')
      dispatch(featuredRedux?.actions?.resetFeatured())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const featuredEventsOptions = [
    {label: 'Yes', value: 'Active'},
    {label: 'No', value: 'Inactive'},
  ]

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Photos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                thumbImage: '',
                coverImage: '',
                slug: '',
                is_featured: '',
                status: '',
                typeId: '',
                video: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  is_featured: values?.is_featured === 'Active' ? true : false,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(featuredRedux.actions.updateFeatured(formData, editSelectedData?.id))
                } else {
                  dispatch(featuredRedux.actions.addFeatured(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                setMediaType(
                  featuredImageType.find((option) => option.value === values.typeId)?.systemName ===
                    'image'
                    ? 'image'
                    : 'video'
                )
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'title',
                      'titleNp',
                      'thumbImage',
                      'coverImage',
                      'slug',
                      'typeId',
                      'video',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'is_featured',
                      editSelectedData?.is_featured === '1' ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title (NP)'
                            label='Title (NP)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        {hideMediaSelectType && (
                          <>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormSelect
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Please select a file type'
                                label='File Type'
                                name='typeId'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={featuredImageType}
                                values={values}
                                setFieldValue={setFieldValue}
                                required={true}
                              />
                            </div>
                            {featuredImageType.find((option) => option.value === values.typeId)
                              ?.systemName === 'image' ? (
                              <div className='col-md-6 col-xs-12'>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Thumb Image'
                                  name='thumbImage'
                                  setFieldValue={setFieldValue}
                                  setImageUrl={setThumbImage}
                                  value={values?.thumbImage}
                                />

                                {!isEmpty(values?.thumbImage) ? (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            setThumbImage('')
                                            setFieldValue('thumbImage', '')
                                          }}
                                          data-cy='modal-thumbnail-remove'
                                        >
                                          X
                                        </button>

                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${values?.thumbImage}`}
                                          alt=''
                                        />
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            ) : null}
                            {featuredImageType.find((option) => option.value === values.typeId)
                              ?.systemName === 'video' ? (
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  as='textarea'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Iframe '
                                  label='Iframe'
                                  name='video'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  value={values?.video}
                                />
                              </div>
                            ) : null}
                          </>
                        )}

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.title)
                            }
                            disabled={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Featured ?
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {featuredEventsOptions?.map(
                              (featuredOption: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Is Featured'
                                  name='is_featured'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  checkBoxText={featuredOption?.label}
                                  value={featuredOption?.value}
                                  required
                                />
                              )
                            )}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='is_featured'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 mt-2'>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddFeatured
