import {createRoot} from 'react-dom/client'
// Redux
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import GlobalErrComponent from './cms/layout/core/GlobalErrComponent'

// Apps
import {App} from './app/App'

import './cms/assets/sass/style.react.scss'
import 'rsuite/dist/rsuite.min.css'
import './cms/assets/sass/style.scss'
import {HelmetProvider} from 'react-helmet-async'
import {MetronicI18nProvider} from './cms/i18n/Metronici18n'

const {PUBLIC_URL} = window.__RUNTIME_CONFIG__

_redux.setupAxios(axios, store)
Chart.register(...registerables)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <MetronicI18nProvider>
    {/* @ts-ignore */}
    <Provider store={store}>
      <GlobalErrComponent />
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <HelmetProvider>
          <App basename={PUBLIC_URL} />
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>
)
