import {ParamsModel} from 'src/app/modules/common/Model'

import {actionTypes} from './constants'

export const actions = {
  getHelpDesk: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_HELP_DESK_START,
    payload: params,
  }),
  getHelpDeskSuccess: (data: any) => ({
    type: actionTypes.GET_HELP_DESK_SUCCESS,
    payload: data,
  }),
  getHelpDeskFinish: () => ({
    type: actionTypes.GET_HELP_DESK_FINISH,
  }),

  updateHelpDesk: (data: any, id: string) => ({
    type: actionTypes.UPDATE_HELP_DESK_START,
    payload: {data, id},
  }),

  getHelpDeskReset: () => ({
    type: actionTypes.GET_HELP_DESK_RESET,
  }),
}
