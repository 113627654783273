import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAllMenuButtonSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllMenuButton, params)
    yield put(actions.getAllMenuButtonSuccess(response?.data?.data))
    yield put(actions.getAllMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllMenuButtonFinish())
  }
}

function* getSpecificMenuButtonSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificMenuButton, params)
    yield put(actions.getSpecificMenuButtonSuccess(response?.data?.data))
    yield put(actions.getSpecificMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificMenuButtonFinish())
  }
}

function* getMenuButtonSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getMenuButton, params)
    yield put(actions.getMenuButtonSuccess(response?.data?.data))
    yield put(actions.getMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getMenuButtonFinish())
  }
}

function* addMenuButtonSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addMenuButton, body)
    yield put(actions.addMenuButtonSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addMenuButtonFinish())
  }
}

function* updateMenuButtonSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateMenuButton, body, action.payload?.id)
    yield put(actions.updateMenuButtonSuccess(response.data?.data))
    yield put(actions.updateMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateMenuButtonFinish())
  }
}

function* deleteMenuButtonSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteMenuButton, body)
    yield put(actions.deleteMenuButtonSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteMenuButtonFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteMenuButtonFinish())
  }
}

function* singleActivateMenuButton(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateMenuButton,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_FINISH})
  }
}

function* singleDeactivateMenuButton(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateMenuButton,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_FINISH})
  }
}

function* sortMenuButton(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortMenuButton, body)

    yield put({
      type: actionTypes.SORT_MENU_BUTTON_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_MENU_BUTTON_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_MENU_BUTTON_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_MENU_BUTTON_MANAGER_START, getAllMenuButtonSaga)
  yield takeLatest(actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_START, getSpecificMenuButtonSaga)
  yield takeLatest(actionTypes.GET_MENU_BUTTON_MANAGER_START, getMenuButtonSaga)
  yield takeLatest(actionTypes.ADD_MENU_BUTTON_MANAGER_START, addMenuButtonSaga)
  yield takeLatest(actionTypes.UPDATE_MENU_BUTTON_MANAGER_START, updateMenuButtonSaga)
  yield takeLatest(actionTypes.DELETE_MENU_BUTTON_MANAGER_START, deleteMenuButtonSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    singleActivateMenuButton
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    singleDeactivateMenuButton
  )
  yield takeLatest(actionTypes.SORT_MENU_BUTTON_MANAGER_START, sortMenuButton)
}
