export const actionTypes: any = {
  // get
  GET_ALL_SEARCH_CATEGORY_START: 'GET_ALL_SEARCH_CATEGORY_START',
  GET_ALL_SEARCH_CATEGORY_SUCCESS: 'GET_ALL_SEARCH_CATEGORY_SUCCESS',
  GET_ALL_SEARCH_CATEGORY_FINISH: 'GET_ALL_SEARCH_CATEGORY_FINISH',

  // extended hours
  GET_SEARCH_CATEGORY_START: 'GET_SEARCH_CATEGORY_START',
  GET_SEARCH_CATEGORY_SUCCESS: 'GET_SEARCH_CATEGORY_SUCCESS',
  GET_SEARCH_CATEGORY_FINISH: 'GET_SEARCH_CATEGORY_FINISH',

  // add branch
  ADD_SEARCH_CATEGORY_START: 'ADD_SEARCH_CATEGORY_START',
  ADD_SEARCH_CATEGORY_SUCCESS: 'ADD_SEARCH_CATEGORY_SUCCESS',
  ADD_SEARCH_CATEGORY_FINISH: 'ADD_SEARCH_CATEGORY_FINISH',
  RESET_SEARCH_TAG: 'RESET_SEARCH_TAG',

  // update SEARCH_TAG
  UPDATE_SEARCH_CATEGORY_START: 'UPDATE_SEARCH_CATEGORY_START',
  UPDATE_SEARCH_CATEGORY_SUCCESS: 'UPDATE_SEARCH_CATEGORY_SUCCESS',
  UPDATE_SEARCH_CATEGORY_FINISH: 'UPDATE_SEARCH_CATEGORY_FINISH',

  // delete SEARCH_TAG
  DELETE_SEARCH_CATEGORY_START: 'DELETE_SEARCH_CATEGORY_START',
  DELETE_SEARCH_CATEGORY_SUCCESS: 'DELETE_SEARCH_CATEGORY_SUCCESS',
  DELETE_SEARCH_CATEGORY_FINISH: 'DELETE_SEARCH_CATEGORY_FINISH',

  // Enable SEARCH_TAG
  ENABLE_SEARCH_CATEGORY_REQUEST: 'ENABLE_SEARCH_CATEGORY_REQUEST',
  ENABLE_SEARCH_CATEGORY_SUCCESS: 'ENABLE_SEARCH_CATEGORY_SUCCESS',
  ENABLE_SEARCH_CATEGORY_FINISH: 'ENABLE_SEARCH_CATEGORY_FINISH',

  // Disable SEARCH_TAG
  DISABLE_SEARCH_CATEGORY_REQUEST: 'DISABLE_SEARCH_CATEGORY_REQUEST',
  DISABLE_SEARCH_CATEGORY_SUCCESS: 'DISABLE_SEARCH_CATEGORY_SUCCESS',
  DISABLE_SEARCH_CATEGORY_FINISH: 'DISABLE_SEARCH_CATEGORY_FINISH',

  // Enable SEARCH_TAG
  SINGLE_ENABLE_SEARCH_CATEGORY_REQUEST: 'SINGLE_ENABLE_SEARCH_CATEGORY_REQUEST',
  SINGLE_ENABLE_SEARCH_CATEGORY_SUCCESS: 'SINGLE_ENABLE_SEARCH_CATEGORY_SUCCESS',
  SINGLE_ENABLE_SEARCH_CATEGORY_FINISH: 'SINGLE_ENABLE_SEARCH_CATEGORY_FINISH',

  // Disable SEARCH_TAG
  SINGLE_DISABLE_SEARCH_CATEGORY_REQUEST: 'SINGLE_DISABLE_SEARCH_CATEGORY_REQUEST',
  SINGLE_DISABLE_SEARCH_CATEGORY_SUCCESS: 'SINGLE_DISABLE_SEARCH_CATEGORY_SUCCESS',
  SINGLE_DISABLE_SEARCH_CATEGORY_FINISH: 'SINGLE_DISABLE_SEARCH_CATEGORY_FINISH',

  // sort
  SORT_SEARCH_CATEGORY_START: 'SORT_SEARCH_CATEGORY_START',
  SORT_SEARCH_CATEGORY_SUCCESS: 'SORT_SEARCH_CATEGORY_SUCCESS',
  SORT_SEARCH_CATEGORY_FINISH: 'SORT_SEARCH_CATEGORY_FINISH',
  SORT_SEARCH_CATEGORY_RESET: 'SORT_SEARCH_CATEGORY_RESET',
}
