import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {EmptyObject} from 'chart.js/types/basic'

import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import {FiEye} from 'react-icons/fi'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as helpDesk from '../../helpDeskReport/redux'
import ViewDetailsModal from './ViewDetailsModal'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {AiOutlineEdit} from 'react-icons/ai'
import {AppointmentRequestModel} from 'src/app/modules/appointments/components/appointmentRequest/Model'
import UpdateStatus from 'src/app/modules/appointments/components/appointmentRequest/components/UpdateStatus'
import UpdateHelpDeskStatus from './UpdateHelpDeskStatus'
import axios from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const HelpDesk = () => {
  const dispatch = useDispatch()

  const helpDeskData = useSelector((state: any) => state.helpDeskApplications)
  const {loading} = useSelector((state: any) => state.helpDeskApplications)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    fromDate: '',
    toDate: '',
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<AppointmentRequestModel | EmptyObject>({})
  const [actionTypeStatus, setActionTypeStatus] = useState('AddStatus')
  const [openStatus, setOpenStatus] = useState(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleCloseStatus = () => setOpenStatus(false)

  const filterByStatusOptions = [
    {value: 'completed', label: 'Completed'},
    {value: 'pending', label: 'Pending'},
  ]

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              icon={<FiEye />}
              data-cy='view-button'
            />
          </Whisper>
        </CheckPermissions>
        <Whisper
          placement='top'
          trigger='hover'
          speaker={<Tooltip>Update Appointment Status</Tooltip>}
        >
          <IconButton
            appearance='subtle'
            onClick={() => {
              setEditCheckedData(rowData)
              setActionTypeStatus('Update')
              setOpenStatus(true)
            }}
            icon={<AiOutlineEdit />}
            disabled={rowData?.status === 'completed' ? true : false}
            data-cy='action-update'
          />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Reference Number',
      dataKey: 'ref_number',
      flexGrow: 1,
      cell: <Cell dataKey='ref_number' />,
    },
    {
      label: 'Date',
      dataKey: 'date',
      flexGrow: 1,
      cell: <Cell dataKey='date' />,
    },
    {
      label: 'Customer Name',
      dataKey: 'customer_name',
      flexGrow: 1,
      cell: <Cell dataKey='customer_name' />,
    },
    {
      label: 'Status',
      dataKey: 'status',
      flexGrow: 1,
      // cell: <Cell dataKey='status' />,
      cell: (
        <Cell>
          {(rowData) =>
            !isEmpty(rowData?.status) ? (
              <div
                className={`text-uppercase text-white text-center fw-normal ${
                  rowData?.status === 'pending' && 'badge bg-warning'
                } ${rowData?.status === 'completed' && 'badge bg-success'} 
      `}
              >
                {rowData?.status}
              </div>
            ) : (
              '-'
            )
          }
        </Cell>
      ),
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
    },
    {
      label: 'Subject',
      dataKey: 'subject',
      flexGrow: 1,
      cell: <Cell dataKey='subject' />,
    },
    {
      label: 'Product',
      dataKey: 'product',
      flexGrow: 1,
      cell: <Cell dataKey='product' />,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const data = helpDeskData?.data?.help_desk
    ? helpDeskData?.data?.help_desk?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        ref_number: item?.ref_number,
        customer_name: item?.customer_name,
        email: item?.email,
        phone: item?.phone,
        address: item?.address,
        product: item?.product,
        subject: item?.subject,
        subject_details: item?.subject_details,
        subject_file_path: item?.subject_file_path,
        status: item?.status,
        date: moment(item?.created_at).format('YYYY-MM-DD'),
      }))
    : []
  const handleRefresh = () => {
    dispatch(helpDesk?.actions.getHelpDesk(params))
  }

  useEffect(() => {
    dispatch(helpDesk?.actions?.getHelpDesk(params))
  }, [params])

  const handleExport = () => {
    axios
      .get(
        `${API_URL}/help-desk-enquiries/export?fromDate=${params.fromDate}&toDate=${params.toDate}`,
        {responseType: 'blob'}
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Explicit MIME type for Excel files
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'helpdesk.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove() // Clean up the link element
        window.URL.revokeObjectURL(url) // Release memory allocated for the object URL
      })
      .catch((error) => {
        console.error('Error exporting file: ', error)
      })
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Help Desk Enquiry'
          addShow={false}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          exportShow={true}
          importShow={false}
          toggleMultipleShow={false}
          addNoteShow={false}
          deleteShow={false}
          enableDropdownFilter={true}
          filterData={true}
          handleExport={handleExport}
          exportButtonName='Export Help Desk'
          dropdownOption={filterByStatusOptions}
          handleDropdownChange={(option: any) => {
            setParams({...params, status: option?.value})
          }}
        />
        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={false}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={helpDeskData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}

        {openStatus && (
          <UpdateHelpDeskStatus
            open={openStatus}
            handleClose={handleCloseStatus}
            actionType={actionTypeStatus}
            editSelectedData={editSelectedData}
            params={params}
          />
        )}
      </div>
    </>
  )
}

export default HelpDesk
