export const actionTypes: any = {
  GET_ACTIVE_CARD_CATEGORY_START: 'GET_ACTIVE_CARD_CATEGORY_START',
  GET_ACTIVE_CARD_CATEGORY_SUCCESS: 'GET_ACTIVE_CARD_CATEGORY_SUCCESS',
  GET_ACTIVE_CARD_CATEGORY_FINISH: 'GET_ACTIVE_CARD_CATEGORY_FINISH',

  GET_CARD_CATEGORY_START: 'GET_CARD_CATEGORY_START',
  GET_CARD_CATEGORY_SUCCESS: 'GET_CARD_CATEGORY_SUCCESS',
  GET_CARD_CATEGORY_FINISH: 'GET_CARD_CATEGORY_FINISH',

  ADD_CARD_CATEGORY_START: 'ADD_CARD_CATEGORY_START',
  ADD_CARD_CATEGORY_SUCCESS: 'ADD_CARD_CATEGORY_SUCCESS',
  ADD_CARD_CATEGORY_FINISH: 'ADD_CARD_CATEGORY_FINISH',
  ADD_CARD_CATEGORY_RESET: 'ADD_CARD_CATEGORY_RESET',

  UPDATE_CARD_CATEGORY_START: 'UPDATE_CARD_CATEGORY_START',
  UPDATE_CARD_CATEGORY_SUCCESS: 'UPDATE_CARD_CATEGORY_SUCCESS',
  UPDATE_CARD_CATEGORY_FINISH: 'UPDATE_CARD_CATEGORY_FINISH',
  UPDATE_CARD_CATEGORY_RESET: 'UPDATE_CARD_CATEGORY_RESET',

  DELETE_CARD_CATEGORY_START: 'DELETE_CARD_CATEGORY_START',
  DELETE_CARD_CATEGORY_SUCCESS: 'DELETE_CARD_CATEGORY_SUCCESS',
  DELETE_CARD_CATEGORY_FINISH: 'DELETE_CARD_CATEGORY_FINISH',

  SINGLE_ACTIVATE_CARD_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_CARD_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_CARD_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_CARD_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_CARD_CATEGORY_FINISH: 'SINGLE_ACTIVATE_CARD_CATEGORY_FINISH',

  SINGLE_DEACTIVATE_CARD_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_CARD_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_CARD_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_CARD_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_CARD_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_CARD_CATEGORY_FINISH',

  SORT_CARD_CATEGORY_START: 'SORT_CARD_CATEGORY_START',
  SORT_CARD_CATEGORY_SUCCESS: 'SORT_CARD_CATEGORY_SUCCESS',
  SORT_CARD_CATEGORY_FINISH: 'SORT_CARD_CATEGORY_FINISH',
  SORT_CARD_CATEGORY_RESET: 'SORT_CARD_CATEGORY_RESET',
}
