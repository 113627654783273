import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel, OptionModel} from '../Model'
import {actionTypes} from './constants'
// import {INewsState} from './reducer'
// import {NewsModel} from '../Model/NewsModel'
export const actions = {
  // ADD PUSH NOTIFICATION TEMPLATE

  addPushNotificationTemplate: (data: any) => {
    return {
      type: actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_START,
      payload: data,
    }
  },

  addPushNotificationTemplateSuccess: (task: any) => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_SUCCESS,
    payload: task,
  }),

  addPushNotificationTemplateFinish: () => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_FINISH,
  }),

  addPushNotificationTemplateReset: () => ({
    type: actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_RESET,
  }),

  // get push notification template

  getPushNotificationTemplate: (params: ParamsModel = {page: 0, limit: 0}) => {
    return {
      type: actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_START,
      payload: params,
    }
  },

  getPushNotificationTemplateSuccess: (data: any) => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_SUCCESS,
    payload: data,
  }),

  getPushNotificationTemplateFinish: () => ({
    type: actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_FINISH,
  }),

  // update push notification template

  updatePushNotificationTemplate: (data: any, id: string) => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_START,
    payload: {data, id},
  }),
  updatePushNotificationTemplateSuccess: (task: any) => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS,
    payload: task,
  }),
  updatePushNotificationTemplateFinish: () => ({
    type: actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_FINISH,
  }),

  // delete push notificaton template

  deletePushNotificationTemplate: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_START,
    payload: {idArray: data},
  }),

  deletePushNotificationTemplateSuccess: (data: any) => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS,
    payload: data,
  }),
  deletePushNotificationTemplateFinish: () => ({
    type: actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_FINISH,
  }),
}
