import axios from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const AUCTION_ENQUIRY_API = `${API_URL}/auction-enquiries`

export const service = {
  getAuctionEnquiry: (params: any) => {
    return axios.get(AUCTION_ENQUIRY_API, {params})
  },
}
