import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {BaseRateModel} from '../Model'

function* getBaseRateSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBaseRate, params)
    yield put(actions.getBaseRateSuccess(response?.data?.data))
    yield put(actions.getBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBaseRateFinish())
  }
}

//Get interest rate loan file
function* getBaseRateFileSaga() {
  try {
    const response: ResponseModel = yield call(service.getBaseRateFile)
    const data: any = response?.data
    yield put(actions.getBaseRateFileSuccess(data))
  } catch (error: any) {
    yield put(actions.getBaseRateFileError(error))
  }
}

//Get interest rate deposit file
function* getInterestSpreadFileSaga() {
  try {
    const response: ResponseModel = yield call(service.getInterestSpreadFile)
    const data: any = response?.data
    yield put(actions.getInterestSpreadFileSuccess(data))
  } catch (error: any) {
    yield put(actions.getInterestSpreadFileError(error))
  }
}

function* addBaseRateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBaseRate, body)
    yield put(actions.addBaseRateSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBaseRateFinish())
  }
}

function* enableBaseRateSaga(action: ActionModel) {
  try {
    const selectedInterestRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableBaseRate, selectedInterestRate)
    yield put(actions.enableBaseRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableBaseRateFinish())
  }
}

function* disableBaseRateSaga(action: ActionModel) {
  try {
    const selectedInterestRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableBaseRate, selectedInterestRate)
    yield put(actions.disableBaseRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableBaseRateFinish())
  }
}

function* singleEnableBaseRateSaga(action: ActionModel) {
  try {
    const selectedInterestRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableBaseRate, selectedInterestRate)
    yield put(actions.singleEnableBaseRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableBaseRateFinish())
  }
}

function* singleDisableBaseRateSaga(action: ActionModel) {
  try {
    const selectedInterestRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableBaseRate, selectedInterestRate)
    yield put(actions.singleDisableBaseRateSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableBaseRateFinish())
  }
}

/* function* updateInterestRateSaga(action: ActionModel) {
  try {
    const body: InterestRateModel = action.payload
    const response: ResponseModel = yield call(service.updateInterestRate, body, action.payload?.id)
    yield put(actions.updateInterestRateSuccess(response.data?.data))
    yield put(actions.updateInterestRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateInterestRateFinish())
  }
} */
function* updateBaseRateSaga(action: ActionModel) {
  try {
    const body: BaseRateModel = action.payload
    const params: any = action
    const response: ResponseModel = yield call(service.updateBaseRate, body, params.id)

    yield put(actions.updateBaseRateSuccess(response.data?.data))
    yield put(actions.updateBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBaseRateFinish())
  }
}

function* deleteBaseRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBaseRate, body)

    yield put(actions.deleteBaseRateSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteBaseRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBaseRateFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_BASE_RATE_FILE_START, getBaseRateFileSaga)
  yield takeLatest(actionTypes.GET_INTEREST_SPREAD_FILE_START, getInterestSpreadFileSaga)
  yield takeLatest(actionTypes.GET_BASE_RATE_START, getBaseRateSaga)
  yield takeLatest(actionTypes.ADD_BASE_RATE_START, addBaseRateSaga)
  yield takeLatest(actionTypes.UPDATE_BASE_RATE_START, updateBaseRateSaga)
  yield takeLatest(actionTypes.DELETE_BASE_RATE_START, deleteBaseRateSaga)
  yield takeLatest(actionTypes.ENABLE_BASE_RATE_REQUEST, enableBaseRateSaga)
  yield takeLatest(actionTypes.DISABLE_BASE_RATE_REQUEST, disableBaseRateSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_BASE_RATE_REQUEST, singleEnableBaseRateSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_BASE_RATE_REQUEST, singleDisableBaseRateSaga)
}
