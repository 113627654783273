export const actionTypes: any = {
 // GET ALL
  GET_ALL_LAYOUT_START: 'GET_ALL_LAYOUT_START',
  GET_ALL_LAYOUT_SUCCESS: 'GET_ALL_LAYOUT_SUCCESS',
  GET_ALL_LAYOUT_FINISH: 'GET_ALL_LAYOUT_FINISH',

// GET
  GET_LAYOUT_START: 'GET_LAYOUT_START',
  GET_LAYOUT_SUCCESS: 'GET_LAYOUT_SUCCESS',
  GET_LAYOUT_FINISH: 'GET_LAYOUT_FINISH',

  // ADD
  ADD_LAYOUT_START: 'ADD_LAYOUT_START',
  ADD_LAYOUT_SUCCESS: 'ADD_LAYOUT_SUCCESS',
  ADD_LAYOUT_FINISH: 'ADD_LAYOUT_FINISH',
  RESET_LAYOUT: 'RESET_LAYOUT',

  // UPDATE
  UPDATE_LAYOUT_START: 'UPDATE_LAYOUT_START',
  UPDATE_LAYOUT_SUCCESS: 'UPDATE_LAYOUT_SUCCESS',
  UPDATE_LAYOUT_FINISH: 'UPDATE_LAYOUT_FINISH',

  // DELETE
  DELETE_LAYOUT_START: 'DELETE_LAYOUT_START',
  DELETE_LAYOUT_SUCCESS: 'DELETE_LAYOUT_SUCCESS',
  DELETE_LAYOUT_FINISH: 'DELETE_LAYOUT_FINISH',

  // ENABLE
  ENABLE_LAYOUT_REQUEST: 'ENABLE_LAYOUT_REQUEST',
  ENABLE_LAYOUT_SUCCESS: 'ENABLE_LAYOUT_SUCCESS',
  ENABLE_LAYOUT_FINISH: 'ENABLE_LAYOUT_FINISH',

  // DISABLE
  DISABLE_LAYOUT_REQUEST: 'DISABLE_LAYOUT_REQUEST',
  DISABLE_LAYOUT_SUCCESS: 'DISABLE_LAYOUT_SUCCESS',
  DISABLE_LAYOUT_FINISH: 'DISABLE_LAYOUT_FINISH',

  // Enable 
  SINGLE_ENABLE_LAYOUT_REQUEST: 'SINGLE_ENABLE_LAYOUT_REQUEST',
  SINGLE_ENABLE_LAYOUT_SUCCESS: 'SINGLE_ENABLE_LAYOUT_SUCCESS',
  SINGLE_ENABLE_LAYOUT_FINISH: 'SINGLE_ENABLE_LAYOUT_FINISH',

  // Disable 
  SINGLE_DISABLE_LAYOUT_REQUEST: 'SINGLE_DISABLE_LAYOUT_REQUEST',
  SINGLE_DISABLE_LAYOUT_SUCCESS: 'SINGLE_DISABLE_LAYOUT_SUCCESS',
  SINGLE_DISABLE_LAYOUT_FINISH: 'SINGLE_DISABLE_LAYOUT_FINISH',

  // SORT
  SORT_LAYOUT_START: 'SORT_LAYOUT_START',
  SORT_LAYOUT_SUCCESS: 'SORT_LAYOUT_SUCCESS',
  SORT_LAYOUT_FINISH: 'SORT_LAYOUT_FINISH',
  SORT_LAYOUT_RESET: 'SORT_LAYOUT_RESET',
}
