export const actionTypes: any = {
  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FINISH: 'ADD_USER_FINISH',
  ADD_USER_RESET: 'ADD_USER_RESET',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FINISH: 'GET_USER_FINISH',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FINISH: 'DELETE_USER_FINISH',

  ACTIVATE_USERS_REQUEST: 'ACTIVATE_USERS_REQUEST',
  ACTIVATE_USERS_SUCCESS: 'ACTIVATE_USERS_SUCCESS',
  ACTIVATE_USERS_FINISH: 'ACTIVATE_USERS_FINISH',

  SINGLE_ACTIVATE_USER_REQUEST: 'SINGLE_ACTIVATE_USER_REQUEST',
  SINGLE_ACTIVATE_USER_SUCCESS: 'SINGLE_ACTIVATE_USER_SUCCESS',
  SINGLE_ACTIVATE_USER_FINISH: 'SINGLE_ACTIVATE_USER_FINISH',

  SINGLE_DEACTIVATE_USER_REQUEST: 'SINGLE_DEACTIVATE_USER_REQUEST',
  SINGLE_DEACTIVATE_USER_SUCCESS: 'SINGLE_DEACTIVATE_USER_SUCCESS',
  SINGLE_DEACTIVATE_USER_FINISH: 'SINGLE_DEACTIVATE_USER_FINISH',

  DEACTIVATE_USERS_REQUEST: 'DEACTIVATE_USERS_REQUEST',
  DEACTIVATE_USERS_SUCCESS: 'DEACTIVATE_USERS_SUCCESS',
  DEACTIVATE_USERS_FINISH: 'DEACTIVATE_USERS_FINISH',

  // update
  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FINISH: 'UPDATE_USER_FINISH',
  UPDATE_USER_RESET: 'UPDATE_USER_RESET',
  UPDATE_SINGLE_USER_START: 'UPDATE_SINGLE_USER_START',
  UPDATE_SINGLE_USER_SUCCESS: 'UPDATE_SINGLE_USER_SUCCESS',
}
