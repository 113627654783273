export const actionTypes: any = {
  // get setting type
  GET_SETTING_TYPE_START: 'GET_SETTING_TYPE_START',
  GET_SETTING_TYPE_SUCCESS: 'GET_SETTING_TYPE_SUCCESS',
  GET_SETTING_TYPE_FINISH: 'GET_SETTING_TYPE_FINISH',

  // get setting field
  GET_SETTING_FIELD_START: 'GET_SETTING_FIELD_START',
  GET_SETTING_FIELD_SUCCESS: 'GET_SETTING_FIELD_SUCCESS',
  GET_SETTING_FIELD_FINISH: 'GET_SETTING_FIELD_FINISH',
  // get specific setting type
  GET_SPECIFIC_SETTING_TYPE_START: 'GET_SPECIFIC_SETTING_TYPE_START',
  GET_SPECIFIC_SETTING_TYPE_SUCCESS: 'GET_SPECIFIC_SETTING_TYPE_SUCCESS',
  GET_SPECIFIC_SETTING_TYPE_FINISH: 'GET_SPECIFIC_SETTING_TYPE_FINISH',

  // create
  CREATE_SETTING_FIELD_START: 'CREATE_SETTING_FIELD_START',
  CREATE_SETTING_FIELD_SUCCESS: 'CREATE_SETTING_FIELD_SUCCESS',
  CREATE_SETTING_FIELD_FINISH: 'CREATE_SETTING_FIELD_FINISH',
  CREATE_SETTING_FIELD_FAILURE: 'CREATE_SETTING_FIELD_FAILURE',
  CREATE_SETTING_FIELD_RESET: 'CREATE_SETTING_FIELD_RESET',
  // update  setting field
  UPDATE_SETTING_FIELD_START: 'UPDATE_SETTING_FIELD_START',
  UPDATE_SETTING_FIELD_SUCCESS: 'UPDATE_SETTING_FIELD_SUCCESS',
  UPDATE_SETTING_FIELD_FINISH: 'UPDATE_SETTING_FIELD_FINISH',
  UPDATE_SETTING_FIELD_RESET: 'UPDATE_SETTING_FIELD_RESET',

  // // delete
  // DELETE_KEY_START: 'DELETE_KEY_START',
  // DELETE_KEY_SUCCESS: 'DELETE_KEY_SUCCESS',
  // DELETE_KEY_FINISH: 'DELETE_KEY_FINISH',
}
