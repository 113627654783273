import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getHistorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getHistory, params)
    yield put(actions.getHistorySuccess(response?.data?.data))
    yield put(actions.getHistoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getHistoryFinish())
  }
}

function* addHistorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addHistory, body)
    yield put(actions.addHistorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addHistoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addHistoryFinish())
  }
}

function* updateHistorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateHistory, body, action.payload?.id)
    yield put(actions.updateHistorySuccess(response.data?.data))
    yield put(actions.updateHistoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateHistoryFinish())
  }
}

function* deleteHistorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteHistory, body)
    yield put(actions.deleteHistorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteHistoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteHistoryFinish())
  }
}

function* singleActivateHistory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateHistory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_HISTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_HISTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_HISTORY_FINISH})
  }
}

function* singleDeactivateHistory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateHistory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_HISTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_HISTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_HISTORY_FINISH})
  }
}

function* sortHistory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortHistory, body)

    yield put({
      type: actionTypes.SORT_HISTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_HISTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_HISTORY_FINISH})
  }
}

function* getFiscalYearADSaga() {
  try {
    const response: ResponseModel = yield call(service.getFiscalYearAD)
    yield put(actions.getFiscalYearADSuccess(response?.data?.data))
    yield put(actions.getFiscalYearADFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getFiscalYearADFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_HISTORY_START, getHistorySaga)
  yield takeLatest(actionTypes.ADD_HISTORY_START, addHistorySaga)
  yield takeLatest(actionTypes.UPDATE_HISTORY_START, updateHistorySaga)
  yield takeLatest(actionTypes.DELETE_HISTORY_START, deleteHistorySaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_HISTORY_REQUEST, singleActivateHistory)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_HISTORY_REQUEST, singleDeactivateHistory)
  yield takeLatest(actionTypes.SORT_HISTORY_START, sortHistory)
  yield takeLatest(actionTypes.GET_FISCAL_YEAR_AD_START, getFiscalYearADSaga)
}
