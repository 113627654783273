import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as place from 'src/app/modules/common'
import * as Yup from 'yup'
//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as memberTypeRedux from '../../../../membership'
import {PlaceModel} from '../../categories/Model'
import * as applyMembershipRedux from '../redux'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  provinceId: Yup.string().required('Province Required'),
  districtId: Yup.string().required('District Required'),
  municipalityId: Yup.string().required('Municipality Required'),
  wardno: Yup.string()
    .required('Ward No. Required')
    .matches(/^[0-9][0-9]*$/, 'Enter numbers only')
    .max(3, 'Ward No. should not exceed 3 digits'),
  mobileNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^(98|97)([0-9]{8})$/, 'Enter valid mobile number')
    .max(10, 'Phone number should not exceed 10 digits'),

  email: Yup.string()
    .email('Invalid email')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid Email'),
})

const AddApplyforMembership = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()
  const {district, province, municipality} = useSelector((state: any) => state.place)
  const [provinceValue, setProvinceValue] = useState('')
  const [districtValue, setDistrictValue] = useState('')

  const {loading, success} = useSelector((state: any) => state.applyMembership)
  const {
    data: {memberType},
  } = useSelector((state: any) => state.memberType)

  useEffect(() => {
    dispatch(place.action.getProvince())
    dispatch(memberTypeRedux.memberType.actions.getAllMemberType())
  }, [])

  useEffect(() => {
    if (provinceValue) {
      dispatch(place.action.getDistrict(provinceValue))
    }
  }, [provinceValue])

  useEffect(() => {
    if (districtValue) {
      dispatch(place.action.getMunicipality(districtValue))
    }
  }, [districtValue])

  const municipalityOptions = municipality?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const districtOptions = district?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const provinceOptions = province?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const memberTypeOptions = memberType.map((item: {[key: string]: string}) => ({
    label: item.name,
    value: item.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(applyMembershipRedux?.actions.getApplyMembership(params))
      isEmpty(editSelectedData)
        ? toast.success('Apply for Membership added successfully')
        : toast.success('Apply for Membership Edited successfully')
      dispatch(applyMembershipRedux?.actions.createApplyMembershipReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Apply for Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                email: '',
                mobileNumber: '',
                provinceId: '',
                districtId: '',
                municipalityId: '',
                wardno: 0,
                memberTypeId: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,

                  // adminEmail: values?.adminEmail?.map((email: string) => email),
                  /* adminEmail: values?.adminEmail?.map((adminEmail: any) => ({
                    adminEmail: adminEmail,
                  })), */
                  /*  adminEmail: values?.adminEmail?.map((item: {[key: string]:string }) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })), */
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    applyMembershipRedux.actions.updateApplyMembership(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(applyMembershipRedux.actions.CreateApplyMembership(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'email',
                      'mobileNumber',
                      'provinceId',
                      'districtId',
                      'municipalityId',
                      'wardno',
                      'memberTypeId',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder=' Name'
                            label=' Name'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder=' Email '
                            label=' Email '
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select province'
                            label='Province'
                            name='provinceId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={provinceOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                        <>
                          {!isEmpty(values?.provinceId) ? setProvinceValue(values?.provinceId) : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select district'
                            label='District'
                            name='districtId'
                            disabled={
                              !isEmpty(editSelectedData) || values?.provinceId ? false : true
                            }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={districtOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                        <>
                          {!isEmpty(values?.districtId) ? setDistrictValue(values?.districtId) : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select municipality'
                            label='Municipality'
                            name='municipalityId'
                            disabled={
                              !isEmpty(editSelectedData) || values?.districtId ? false : true
                            }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={municipalityOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Ward No.'
                            label='Ward No.'
                            name='wardno'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12 mb-0'
                            placeholder='Mobile Number'
                            label='Mobile Number'
                            name='mobileNumber'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                          <div className='d-flex justify-content-end mb-3'>
                            <em>
                              <small className='d-block'>
                                <span className='text-danger'>Note: </span> Mobile number should
                                starts with 98|97
                              </small>
                            </em>
                          </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select member type'
                            label='Member Type'
                            name='memberTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={memberTypeOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddApplyforMembership
