import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getPushNotificationTemplateSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getPushNotificationTemplate, params)
    yield put(actions.getPushNotificationTemplateSuccess(response?.data?.data))
    yield put(actions.getPushNotificationTemplateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getPushNotificationTemplateFinish())
  }
}

function* addPushNotificationTemplateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addPushNotificationTemplate, body)
    yield put(actions.addPushNotificationTemplateSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addPushNotificationTemplateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addPushNotificationTemplateFinish())
  }
}

function* updatePushNotificationTemplateSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updatePushNotificationTemplate,
      body,
      action.payload?.id
    )
    yield put(actions.updatePushNotificationTemplateSuccess(response.data?.data))
    yield put(actions.updatePushNotificationTemplateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updatePushNotificationTemplateFinish())
  }
}

function* deletePushNotificationTemplateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deletePushNotificationTemplate, body)
    yield put(actions.deletePushNotificationTemplateSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deletePushNotificationTemplateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deletePushNotificationTemplateFinish())
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.GET_PUSH_NOTIFICATION_TEMPLATE_START,
    getPushNotificationTemplateSaga
  )
  yield takeLatest(
    actionTypes.ADD_PUSH_NOTIFICATION_TEMPLATE_START,
    addPushNotificationTemplateSaga
  )
  yield takeLatest(
    actionTypes.UPDATE_PUSH_NOTIFICATION_TEMPLATE_START,
    updatePushNotificationTemplateSaga
  )
  yield takeLatest(
    actionTypes.DELETE_PUSH_NOTIFICATION_TEMPLATE_START,
    deletePushNotificationTemplateSaga
  )
}
