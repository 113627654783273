import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getSpecificCardManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificCardManager, params)
    yield put(actions.getSpecificCardManagerSuccess(response?.data?.data))
    yield put(actions.getSpecificCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificCardManagerFinish())
  }
}

function* getCardManagerSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCardManager, params)
    yield put(actions.getCardManagerSuccess(response?.data?.data))
    yield put(actions.getCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCardManagerFinish())
  }
}

function* addCardManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCardManager, body)
    yield put(actions.addCardManagerSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCardManagerFinish())
  }
}

function* updateCardManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateCardManager, body, action.payload?.id)
    yield put(actions.updateCardManagerSuccess(response.data?.data))
    yield put(actions.updateCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCardManagerFinish())
  }
}

function* deleteCardManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCardManager, body)
    yield put(actions.deleteCardManagerSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCardManagerFinish())
  }
}

function* singleActivateCardManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateCardManager,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_FINISH})
  }
}

function* singleDeactivateCardManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateCardManager,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_FINISH})
  }
}

function* sortCardManager(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortCardManager, body)

    yield put({
      type: actionTypes.SORT_CARD_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_CARD_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CARD_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SPECIFIC_CARD_MANAGER_START, getSpecificCardManagerSaga)
  yield takeLatest(actionTypes.GET_CARD_MANAGER_START, getCardManagerSaga)
  yield takeLatest(actionTypes.ADD_CARD_MANAGER_START, addCardManagerSaga)
  yield takeLatest(actionTypes.UPDATE_CARD_MANAGER_START, updateCardManagerSaga)
  yield takeLatest(actionTypes.DELETE_CARD_MANAGER_START, deleteCardManagerSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_REQUEST, singleActivateCardManager)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST, singleDeactivateCardManager)
  yield takeLatest(actionTypes.SORT_CARD_MANAGER_START, sortCardManager)
}
