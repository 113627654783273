import {FieldArray, FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent, useEffect} from 'react'
import {FormOptionModal} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: any
  editSelectedData: any
  collapsibleSectionOptions: FormOptionModal[]
  validationState: {[key: string]: Yup.StringSchema}
  setValidationState: any
  setFieldValue: any
}

export default function CollapsibleSection({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  collapsibleSectionOptions,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const showCollapsibleSectionId = collapsibleSectionOptions?.find(
    (item: FormOptionModal) => item.value === values.show_collapsible_id
  )

  useEffect(() => {
    showCollapsibleSectionId?.systemName === 'yes'
      ? setValidationState({
          ...validationState,
          collapsible_title: Yup.string().required('Collapsible title is required'),
          collapsibleData: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
            })
          ),
        })
      : showCollapsibleSectionId?.systemName === 'no'
      ? setValidationState({
          ...validationState,
          collapsible_title: Yup.string().nullable(),
          collapsibleData: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().nullable(),
            })
          ),
        })
      : null
    if (
      showCollapsibleSectionId?.systemName === 'yes' &&
      isEmpty(values.collapsibleData?.[0]?.title)
    ) {
      showCollapsibleSectionId?.systemName === 'yes' &&
        values.collapsibleData?.length > 1 &&
        values.collapsibleData.push({
          title: '',
          title_np: '',
          description: null,
          description_np: null,
          button_title: '',
          button_title_np: '',
          link: '',
          image: '',
          order: '',
        })
    }
  }, [values.show_collapsible_id])

  const multiLanguage = checkMultiLanguage()
  const showAllContentFileExtension = checkBackendSetting('showAllContentFileExtension')
  const showContentCollapsibleTabButtonFiled = checkBackendSetting(
    'showContentCollapsibleTabButtonFiled'
  )
  const showContentCollapsibleTabLinkFiled = checkBackendSetting(
    'showContentCollapsibleTabLinkFiled'
  )
  const showContentCollapsibleTabImageFiled = checkBackendSetting(
    'showContentCollapsibleTabImageFiled'
  )
  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Collapsible Section'
              name='show_collapsible_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={collapsibleSectionOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className='row'>
          {showCollapsibleSectionId?.systemName === 'yes' ? (
            <>
              <div className='col-md-6 col-xs-12'>
                <FormTextBox
                  type='text'
                  placeholder='Enter Section Title (EN)'
                  name='collapsible_title'
                  label='Section Title (EN)'
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                />
              </div>
              {multiLanguage ? null : (
                <div className='col-md-6 col-xs-12'>
                  <FormTextBox
                    type='text'
                    placeholder='Enter Section Title (NP)'
                    name='collapsible_title_np'
                    label='Section Title (NP)'
                    labelClassName='col-md-12'
                    containerClassName='col-md-12'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              )}
              <hr />
              <FieldArray
                name='collapsibleData'
                render={(arrayHelpers) => (
                  <div>
                    {values?.collapsibleData && values?.collapsibleData.length > 0
                      ? values?.collapsibleData?.map((friend: any, index: any) => (
                          <div className='row' key={index}>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Text (EN)'
                                name={`collapsibleData[${index}].title`}
                                label='Text (EN)'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                              />
                            </div>
                            {multiLanguage ? null : (
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Text (NP)'
                                  name={`collapsibleData[${index}].title_np`}
                                  label='Text (NP)'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            )}

                            <div className='col-md-6 col-xs-12'>
                              <FormTinyMce
                                containerClassName='col-md-12'
                                labelClassName='col-md-12'
                                label='Description (EN)'
                                name={`collapsibleData[${index}].description`}
                                initialValue={
                                  !isEmpty(editSelectedData)
                                    ? editSelectedData?.collapsibleData[index]?.description
                                    : ''
                                }
                                value={values?.collapsibleData[index]?.description}
                                handleChange={handleChange}
                                required
                              />
                            </div>

                            {multiLanguage ? null : (
                              <div className='col-md-6 col-xs-12'>
                                <FormTinyMce
                                  containerClassName='col-md-12'
                                  labelClassName='col-md-12'
                                  label='Description (NP)'
                                  name={`collapsibleData[${index}].description_np`}
                                  initialValue={
                                    !isEmpty(editSelectedData)
                                      ? editSelectedData?.collapsibleData[index]?.description_np
                                      : ''
                                  }
                                  value={values?.collapsibleData[index]?.description_np}
                                  handleChange={handleChange}
                                />
                              </div>
                            )}
                            {showContentCollapsibleTabButtonFiled && (
                              <div className='row'>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    placeholder='Enter Button Title (EN)'
                                    name={`collapsibleData[${index}]button_title`}
                                    label='Button Title (EN)'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>

                                <div className='col-md-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    placeholder='Enter Button Title (NP)'
                                    name={`collapsibleData[${index}]button_title_np`}
                                    label='Button Title (NP)'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>
                            )}
                            {showContentCollapsibleTabLinkFiled && (
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Enter Link'
                                  name={`collapsibleData[${index}]link`}
                                  label='Link'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            )}
                            {showContentCollapsibleTabImageFiled && (
                              <div className='col-md-6 col-xs-12'>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Image'
                                  name={`collapsibleData[${index}].image`}
                                  setFieldValue={setFieldValue}
                                  value={values?.image}
                                  required
                                  showImageOnly={showAllContentFileExtension ? false : true}
                                />
                                {!isEmpty(friend?.image) ? (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            setFieldValue(`imageData[${index}].image`, '')
                                          }}
                                          data-cy='modal-thumbnail-remove'
                                        >
                                          X
                                        </button>

                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${friend?.image}`}
                                          alt='image'
                                        />
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            )}

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='number'
                                placeholder='Order'
                                name={`collapsibleData[${index}].order`}
                                label='Order'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            {index > 0 && (
                              <div className=' d-flex justify-content-md-end'>
                                <button
                                  type='button'
                                  className='p-2 ps-5 pe-5 btn btn-secondary'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                    <div className=' d-flex justify-content-md-end mb-2'>
                      <button
                        className='p-2 ps-5 pe-5 mt-5 btn btn-primary'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            title: '',
                            title_np: '',
                            description: null,
                            description_np: null,
                            button_title: '',
                            button_title_np: '',
                            link: '',
                            image: '',
                            order: '',
                          })
                        }
                        data-cy='modal-field-add'
                      >
                        + Add More
                      </button>
                    </div>
                  </div>
                )}
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
