import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get MeetingType saga
function* getMeetingTypeSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getMeetingTypeData)
    const data: any = response?.data?.data
    yield put(actions.getMeetingTypeSuccess(data))
  } catch (error: any) {
    yield put(actions.getMeetingTypeError(error))
  }
}
//Get AppointmentType saga
function* getAppointmentTypeSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAppointmentTypeData, params)
    const data: any = response?.data
    yield put(actions.getAppointmentTypeSuccess(data))
  } catch (error: any) {
    yield put(actions.getAppointmentTypeError(error))
  }
}

//Get AppointmentType saga
function* getAllAppointmentTypeSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAppointmentTypeList)
    const data: any = response?.data
    yield put(actions.getAllAppointmentTypeSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAllAppointmentTypeError())
  }
}

//Create AppointmentType
function* createAppointmentTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addAppointmentType, body)
    yield put({type: actionTypes.ADD_APPOINTMENT_TYPE_SUCCESS, payload: response.data})
    yield delay(100)
    yield put({type: actionTypes.ADD_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_APPOINTMENT_TYPE_FINISH})
  }
}

//Update AppointmentType
function* updateAppointmentType(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateAppointmentType,
      body,
      action.payload?.id
    )
    yield put({type: actionTypes.UPDATE_APPOINTMENT_TYPE_SUCCESS, payload: response.data})

    yield put({type: actionTypes.UPDATE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_APPOINTMENT_TYPE_FINISH})
  }
}

//Activate Deactivate AppointmentType Saga
function* activateAppointmentTypeSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateAppointmentType, selectedBanners)
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_TYPE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_TYPE_FINISH})
  }
}

function* singleActivateAppointmentTypeSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleActivateAppointmentType,
      selectedBanner
    )

    yield put({type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_TYPE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_TYPE_FINISH})
  }
}

function* deactivateAppointmentTypeSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateAppointmentType, selectedBanner)

    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_TYPE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_TYPE_FINISH})
  }
}

function* singleDeactivateAppointmentTypeSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDeactivateAppointmentType,
      selectedBanner
    )
    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_TYPE_SUCCESS,
      payload: response?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_TYPE_FINISH})
  }
}

function* deleteAppointmentTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBulkAppointmentType, body)
    yield put({type: actionTypes.DELETE_APPOINTMENT_TYPE_SUCCESS})
    yield delay(100)
    yield put({type: actionTypes.DELETE_APPOINTMENT_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_APPOINTMENT_TYPE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_MEETING_TYPE_START, getMeetingTypeSaga)
  yield takeLatest(actionTypes.GET_APPOINTMENT_TYPE_START, getAppointmentTypeSaga)
  yield takeLatest(actionTypes.GET_ALL_APPOINTMENT_TYPE_START, getAllAppointmentTypeSaga)
  yield takeLatest(actionTypes.ADD_APPOINTMENT_TYPE_REQUEST, createAppointmentTypeSaga)
  yield takeLatest(actionTypes.UPDATE_APPOINTMENT_TYPE_START, updateAppointmentType)
  yield takeLatest(actionTypes.DELETE_APPOINTMENT_TYPE_START, deleteAppointmentTypeSaga)
  yield takeLatest(actionTypes.ACTIVATE_APPOINTMENT_TYPE_REQUEST, activateAppointmentTypeSaga)
  yield takeLatest(actionTypes.DEACTIVATE_APPOINTMENT_TYPE_REQUEST, deactivateAppointmentTypeSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_APPOINTMENT_TYPE_REQUEST,
    singleActivateAppointmentTypeSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_TYPE_REQUEST,
    singleDeactivateAppointmentTypeSaga
  )
}
