import {useSelector} from 'react-redux'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'

const checkBackendSetting = (checkData: string) => {
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)
  let response =
    settings?.data?.setting?.filter((d: any) => d?.name === `${checkData}`)[0]?.value === '1'
      ? true
      : false
  return response
}

export default checkBackendSetting
