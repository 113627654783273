export const actionTypes: any = {
  GET_HELP_DESK_START: 'GET_HELP_DESK_START',
  GET_HELP_DESK_SUCCESS: 'GET_HELP_DESK_SUCCESS',
  GET_HELP_DESK_FINISH: 'GET_HELP_DESK_FINISH',
  GET_HELP_DESK_RESET: 'GET_HELP_DESK_RESET',

  // udpate
  UPDATE_HELP_DESK_START: 'UPDATE_HELP_DESK_START',
  UPDATE_HELP_DESK_SUCCESS: 'UPDATE_HELP_DESK_SUCCESS',
  UPDATE_HELP_DESK_FINISH: 'UPDATE_HELP_DESK_FINISH',
  UPDATE_HELP_DESK_RESET: 'UPDATE_HELP_DESK_RESET',
}
