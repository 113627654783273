import moment from 'moment'
import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String
    subscribeCode: String
    fullName: String
    email: String

    mobileNumber: String
    message: String

    subscribeType: {
      id: String
      name: String
    }

    status: [
      {
        id: String
        name: String
      }
    ]
  }
  handleClose: () => void
}

const ViewGrievanceDetails = ({data, handleClose}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>Subscribe Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={3}>Full Name : {data?.fullName}</Col>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Contact Number: {data?.mobileNumber}</Col>
              <Col md={3}>Subscribe Code: {data?.subscribeCode}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Message : {data?.message}</Col>
              <Col md={3}>Subscribe Type : {data?.subscribeType?.name}</Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewGrievanceDetails
