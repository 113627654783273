export const actionTypes: any = {
  GET_DISPUTE_TYPE_START: 'GET_DISPUTE_TYPE_START',
  GET_DISPUTE_TYPE_SUCCESS: 'GET_DISPUTE_TYPE_SUCCESS',
  GET_DISPUTE_TYPE_FINISH: 'GET_DISPUTE_TYPE_FINISH',

  GET_DISPUTE_CARD_START: 'GET_DISPUTE_CARD_START',
  GET_DISPUTE_CARD_SUCCESS: 'GET_DISPUTE_CARD_SUCCESS',
  GET_DISPUTE_CARD_FINISH: 'GET_DISPUTE_CARD_FINISH',

  ADD_DISPUTE_CARD_START: 'ADD_DISPUTE_CARD_START',
  ADD_DISPUTE_CARD_SUCCESS: 'ADD_DISPUTE_CARD_SUCCESS',
  ADD_DISPUTE_CARD_FINISH: 'ADD_DISPUTE_CARD_FINISH',
  ADD_DISPUTE_CARD_RESET: 'ADD_DISPUTE_CARD_RESET',

  UPDATE_DISPUTE_CARD_START: 'UPDATE_DISPUTE_CARD_START',
  UPDATE_DISPUTE_CARD_SUCCESS: 'UPDATE_DISPUTE_CARD_SUCCESS',
  UPDATE_DISPUTE_CARD_FINISH: 'UPDATE_DISPUTE_CARD_FINISH',
  UPDATE_DISPUTE_CARD_RESET: 'UPDATE_DISPUTE_CARD_RESET',

  DELETE_DISPUTE_CARD_START: 'DELETE_DISPUTE_CARD_START',
  DELETE_DISPUTE_CARD_SUCCESS: 'DELETE_DISPUTE_CARD_SUCCESS',
  DELETE_DISPUTE_CARD_FINISH: 'DELETE_DISPUTE_CARD_FINISH',

  SINGLE_ACTIVATE_DISPUTE_CARD_REQUEST: 'SINGLE_ACTIVATE_DISPUTE_CARD_REQUEST',
  SINGLE_ACTIVATE_DISPUTE_CARD_SUCCESS: 'SINGLE_ACTIVATE_DISPUTE_CARD_SUCCESS',
  SINGLE_ACTIVATE_DISPUTE_CARD_FINISH: 'SINGLE_ACTIVATE_DISPUTE_CARD_FINISH',

  SINGLE_DEACTIVATE_DISPUTE_CARD_REQUEST: 'SINGLE_DEACTIVATE_DISPUTE_CARD_REQUEST',
  SINGLE_DEACTIVATE_DISPUTE_CARD_SUCCESS: 'SINGLE_DEACTIVATE_DISPUTE_CARD_SUCCESS',
  SINGLE_DEACTIVATE_DISPUTE_CARD_FINISH: 'SINGLE_DEACTIVATE_DISPUTE_CARD_FINISH',

  SORT_DISPUTE_CARD_START: 'SORT_DISPUTE_CARD_START',
  SORT_DISPUTE_CARD_SUCCESS: 'SORT_DISPUTE_CARD_SUCCESS',
  SORT_DISPUTE_CARD_FINISH: 'SORT_DISPUTE_CARD_FINISH',
  SORT_DISPUTE_CARD_RESET: 'SORT_DISPUTE_CARD_RESET',
}
