import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getActiveCardCategorySaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getActiveCardCategory)
    yield put(actions.getActiveCardCategorySuccess(response?.data?.data))
    yield put(actions.getActiveCardCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCardCategoryFinish())
  }
}

function* getCardCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCardCategory, params)
    yield put(actions.getCardCategorySuccess(response?.data?.data))
    yield put(actions.getCardCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCardCategoryFinish())
  }
}

function* addCardCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCardCategory, body)
    yield put(actions.addCardCategorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCardCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCardCategoryFinish())
  }
}

function* updateCardCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateCardCategory, body, action.payload?.id)
    yield put(actions.updateCardCategorySuccess(response.data?.data))
    yield put(actions.updateCardCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCardCategoryFinish())
  }
}

function* deleteCardCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCardCategory, body)
    yield put(actions.deleteCardCategorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteCardCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCardCategoryFinish())
  }
}

function* singleActivateCardCategorySaga(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateCardCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_FINISH})
  }
}

function* singleDeactivateCardCategorySaga(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateCardCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_FINISH})
  }
}

function* sortCardCategorySaga(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortCardCategory, body)

    yield put({
      type: actionTypes.SORT_CARD_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_CARD_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CARD_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ACTIVE_CARD_CATEGORY_START, getActiveCardCategorySaga)
  yield takeLatest(actionTypes.GET_CARD_CATEGORY_START, getCardCategorySaga)
  yield takeLatest(actionTypes.ADD_CARD_CATEGORY_START, addCardCategorySaga)
  yield takeLatest(actionTypes.UPDATE_CARD_CATEGORY_START, updateCardCategorySaga)
  yield takeLatest(actionTypes.DELETE_CARD_CATEGORY_START, deleteCardCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_REQUEST,
    singleActivateCardCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_REQUEST,
    singleDeactivateCardCategorySaga
  )
  yield takeLatest(actionTypes.SORT_CARD_CATEGORY_START, sortCardCategorySaga)
}
