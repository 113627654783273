import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getHelpDeskSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getHelpDesk, params)
    yield put(actions.getHelpDeskSuccess(response?.data?.data))
    yield put(actions.getHelpDeskFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getHelpDeskFinish())
  }
}

function* updateHelpDeskSaga(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(service.updateHelpDesk, body, action.payload.id)
    yield put({type: actionTypes.UPDATE_HELP_DESK_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_HELP_DESK_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_HELP_DESK_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_HELP_DESK_START, getHelpDeskSaga)
  yield takeLatest(actionTypes.UPDATE_HELP_DESK_START, updateHelpDeskSaga)
}
