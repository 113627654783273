import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as place from 'src/app/modules/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import MapView from 'src/app/modules/common/components/Map/MapView'
import * as extensionCounterRedux from 'src/app/modules/network/components/extensionCounter/redux'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {PlaceModel} from '../../branch/Model'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddExtensionCounter = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.extensionCounter)
  const [provinceId, setProvinceId] = useState('')
  const {district, province} = useSelector((state: any) => state.place)

  useEffect(() => {
    if (success) {
      dispatch(extensionCounterRedux?.actions.getExtensionCounter(params))
      isEmpty(editSelectedData)
        ? toast.success('Extension Counter added successfully')
        : toast.success('Extension Counter edited successfully')
      dispatch(extensionCounterRedux?.actions.createExtensionCounterReset())
      handleClose()
    }
  }, [success])

  useEffect(() => {
    dispatch(place.action.getProvince())
  }, [])

  useEffect(() => {
    if (provinceId) {
      dispatch(place.action.getDistrict(provinceId))
    }
  }, [provinceId])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const districtOptions = district?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const provinceOptions = province?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const phoneNumberValidation = checkBackendSetting('phoneNumberValidation')

  let FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .matches(/^[A-Za-z\s,-]+$/, 'Must not contain number or symbol'),
    email: Yup.string()
      .email('Invalid email format') // Ensures that the email is valid if provided
      .notRequired()
      .nullable(),
    province: Yup.string().required('Province is required'),
    district: Yup.string().required('District is required'),
    status: Yup.string().required('Status is required'),
    // contact_no: Yup.string()
    //   .matches(/^[0-9#$%^&*()@!-/., ]*$/, 'Enter valid mobile number')
    //   .notRequired(),
  })

  if (phoneNumberValidation) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      contact_no: Yup.string()
        .matches(
          /^[a-zA-Z0-9.:+\-\/\\, ]+$/,
          'Must contain only alphanumerical characters and the symbols (- + : , /.)'
        )
        .nullable(),
    })
  } else {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      contact_no: Yup.string()
        .matches(/^[0-9]*$/, 'Must contain only numbers')
        .max(10, 'Phone number should not exceed 10 digits')
        .notRequired()
        .nullable(),
    })
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Extention Counter </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                name_np: '',
                province: '',
                district: '',
                plusCode: '',
                address: '',
                email: '',
                address_np: '',
                contact_no: '',
                latitude: '',
                longitude: '',
                contact_person_np: '',
                contact_person_en: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  longitude: values?.longitude ? values?.longitude.toString() : '',
                  latitude: values?.latitude ? values?.latitude.toString() : '',
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    extensionCounterRedux.actions.updateExtensionCounter(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(extensionCounterRedux.actions.CreateExtensionCounter(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'name_np',
                      'province',
                      'district',
                      'plusCode',
                      'email',
                      'address',
                      'address_np',
                      'contact_no',
                      'latitude',
                      'longitude',
                      'contact_person_np',
                      'contact_person_en',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Name (EN)'
                            name='name'
                            label='Name'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Name (NP)'
                            name='name_np'
                            label='Name (NP)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Address'
                            name='address'
                            label='Address (EN)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Address '
                            name='address_np'
                            label='Address (NP)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='row jsutify-content-between'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select province'
                            label='Province'
                            name='province'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={provinceOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                        <>{!isEmpty(values?.province) ? setProvinceId(values?.province) : ''}</>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select district'
                            label='District'
                            name='district'
                            onChange={handleChange}
                            disabled={values?.province ? false : true}
                            errors={errors}
                            touched={touched}
                            options={districtOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            containerClassName='col-md-12'
                            type='email'
                            placeholder='Email'
                            name='email'
                            label='Email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Contact Number'
                            name='contact_no'
                            label='Contact Number'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <MapView
                        latName='latitude'
                        lngName='longitude'
                        location={{
                          lat: values.latitude,
                          lng: values.longitude,
                        }}
                        setFieldValue={setFieldValue}
                        data-cy='modal-mapview'
                      />

                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Latitude'
                            name='latitude'
                            label='Latitude'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Longitude'
                            name='longitude'
                            label='Longitude'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Google Plus Code'
                            label='Google Plus Code'
                            name='plusCode'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Contact Person'
                            name='contact_person_en'
                            label='Contact Person (EN)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Contact Person'
                            name='contact_person_np'
                            label='Contact Person (NP)'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>

                      <div className='d-flex ms-5'>
                        {statusOptions?.map((status: {label: string; value: string}) => (
                          <FormRadio
                            containerClassName=''
                            label='Select Status'
                            name='status'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checkBoxText={status?.label}
                            value={status?.value}
                            required={true}
                          />
                        ))}
                      </div>
                      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='field-error-message'
                        />
                      </div>
                      {/* </div> */}
                      <div className='d-flex justify-content-end px-9 '>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn-sm ms-3'
                          data-cy='modal-submit'
                        >
                          Save
                        </button>
                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                          data-cy='modal-cancel'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddExtensionCounter
