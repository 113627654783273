export const actionTypes: any = {
  // get fAQ Manager
  GET_FAQ_MANAGER_START: 'GET_FAQ_MANAGER_START',
  GET_FAQ_MANAGER_SUCCESS: 'GET_FAQ_MANAGER_SUCCESS',
  GET_FAQ_MANAGER_FINISH: 'GET_FAQ_MANAGER_FINISH',

  //Add FAQ Manager
  ADD_FAQ_MANAGER_REQUEST: 'ADD_FAQ_MANAGER_REQUEST',
  ADD_FAQ_MANAGER_SUCCESS: 'ADD_FAQ_MANAGER_SUCCESS',
  ADD_FAQ_MANAGER_FINISH: 'ADD_FAQ_MANAGER_FINISH',
  ADD_FAQ_MANAGER_RESET: 'ADD_FAQ_MANAGER_RESET',

  // update Faq Manager
  UPDATE_FAQ_MANAGER_START: 'UPDATE_FAQ_MANAGER_START',
  UPDATE_FAQ_MANAGER_SUCCESS: 'UPDATE_FAQ_MANAGER_SUCCESS',
  UPDATE_FAQ_MANAGER_FINISH: 'UPDATE_FAQ_MANAGER_FINISH',
  UPDATE_FAQ_MANAGER_RESET: 'UPDATE_FAQ_MANAGER_RESET',

  // delete Faq Manager
  DELETE_FAQ_MANAGER_START: 'DELETE_FAQ_MANAGER_START',
  DELETE_FAQ_MANAGER_SUCCESS: 'DELETE_FAQ_MANAGER_SUCCESS',
  DELETE_FAQ_MANAGER_FINISH: 'DELETE_FAQ_MANAGER_FINISH',
  DELETE_FAQ_MANAGER_RESET: 'DELETE_FAQ_MANAGER_RESET',
}
