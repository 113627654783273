import {FieldArray, FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent, useEffect} from 'react'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: {[key: string]: string} | any
  editSelectedData: {[key: string]: string} | any
  setFieldValue: any
}

export function ImageDataSection({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  setFieldValue,
}: Props) {
  useEffect(() => {
    if (values.imageData.length < 1) {
      setFieldValue('imageData', [
        {
          order: '',
          image: '',
          title: '',
          title_np: '',
          text: '',
          text_np: '',
          description: '',
          description_np: '',
        },
      ])
    }
  }, [values.imageData])

  const showAllContentFileExtension = checkBackendSetting('showAllContentFileExtension')
  const showContentImageTabTitleFiled = checkBackendSetting('showContentImageTabTitleFiled')
  const showContentImageTabDescriptionFiled = checkBackendSetting(
    'showContentImageTabDescriptionFiled'
  )

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='row'>
            <FieldArray
              name='imageData'
              render={(arrayHelpers) => (
                <div>
                  <h5 className='rs-modal-title mb-5'>Image Data</h5>
                  {values.imageData && values.imageData.length > 0
                    ? values.imageData.map((image: any, index: any) => (
                        <div className='row mb-4' key={index}>
                          <div className='row mb-4' key={index}>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='number'
                                placeholder='Order'
                                name={`imageData[${index}].order`}
                                label='Order'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Title'
                                name={`imageData[${index}].title`}
                                label='Title (EN)'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Title (NP)'
                                name={`imageData[${index}].title_np`}
                                label='Title (NP)'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            {showContentImageTabTitleFiled && (
                              <div className='row'>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    placeholder='Text'
                                    name={`imageData[${index}].text`}
                                    label='Text (EN)'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    placeholder='Text (NP)'
                                    name={`imageData[${index}].text_np`}
                                    label='Text (NP)'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>
                            )}
                            {showContentImageTabDescriptionFiled && (
                              <div className='row'>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTinyMce
                                    containerClassName='col-md-12'
                                    labelClassName='col-md-12'
                                    label={'Description (EN)'}
                                    name={`imageData[${index}].description`}
                                    initialValue={
                                      !isEmpty(editSelectedData)
                                        ? editSelectedData?.imageData?.[index]?.description
                                        : ''
                                    }
                                    handleChange={handleChange}
                                    value={values?.imageData?.[index]?.description}
                                  />
                                </div>

                                <div className='col-md-6 col-xs-12'>
                                  <FormTinyMce
                                    containerClassName='col-md-12'
                                    labelClassName='col-md-12'
                                    label='Description (NP)'
                                    name={`imageData[${index}].description_np`}
                                    initialValue={
                                      !isEmpty(editSelectedData)
                                        ? editSelectedData?.imageData?.[index]?.description_np
                                        : ''
                                    }
                                    value={values?.imageData?.[index]?.description_np}
                                    handleChange={handleChange}
                                  />
                                </div>
                              </div>
                            )}

                            <div className='col-md-6 col-xs-12'>
                              <FormInputMediaManager
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Attached File'
                                name={`imageData[${index}].image`}
                                setFieldValue={setFieldValue}
                                value={values?.attachedFile}
                                required
                                showImageOnly={showAllContentFileExtension ? false : true}
                              />
                              {!isEmpty(image?.image) ? (
                                <>
                                  <li className='listing'>
                                    <div className='thumbImageBlock'>
                                      <button
                                        type='button'
                                        title='Remove'
                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                        onClick={() => {
                                          setFieldValue(`imageData[${index}].image`, '')
                                        }}
                                        data-cy='modal-thumbnail-remove'
                                      >
                                        X
                                      </button>

                                      <img
                                        className='thumbImage w-100 h-100'
                                        src={`${imageBaseUrl}/${image?.image}`}
                                        alt='image'
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </div>
                          </div>
                          {index > 0 && (
                            <div className='col-md-12 col-sm-12 col-xs-12 justify-content-md-end text-end mb-2'>
                              <button
                                type='button'
                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                onClick={() => arrayHelpers.remove(index)}
                                data-cy='modal-field-remove'
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    : null}
                  {values.imageData[values.imageData.length - 1]?.image && (
                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                      <button
                        className='p-2 ps-5 pe-5 btn btn-primary'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            order: '',
                            image: '',
                            title: '',
                            title_np: '',
                            text: '',
                            text_np: '',
                            description: '',
                            description_np: '',
                          })
                        }
                        data-cy='modal-field-add'
                      >
                        + Add More
                      </button>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
