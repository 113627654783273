export const actionTypes: any = {
  // get
  GET_ALL_ENQUIRY_TYPE_START: 'GET_ALL_ENQUIRY_TYPE_START',
  GET_ALL_ENQUIRY_TYPE_SUCCESS: 'GET_ALL_ENQUIRY_TYPE_SUCCESS',
  GET_ALL_ENQUIRY_TYPE_FINISH: 'GET_ALL_ENQUIRY_TYPE_FINISH',

  // extended hours
  GET_ENQUIRY_TYPE_START: 'GET_ENQUIRY_TYPE_START',
  GET_ENQUIRY_TYPE_SUCCESS: 'GET_ENQUIRY_TYPE_SUCCESS',
  GET_ENQUIRY_TYPE_FINISH: 'GET_ENQUIRY_TYPE_FINISH',

  // add branch
  ADD_ENQUIRY_TYPE_START: 'ADD_ENQUIRY_TYPE_START',
  ADD_ENQUIRY_TYPE_SUCCESS: 'ADD_ENQUIRY_TYPE_SUCCESS',
  ADD_ENQUIRY_TYPE_FINISH: 'ADD_ENQUIRY_TYPE_FINISH',
  RESET_ENQUIRY_TYPE: 'RESET_ENQUIRY_TYPE',

  // update ENQUIRY_TYPE
  UPDATE_ENQUIRY_TYPE_START: 'UPDATE_ENQUIRY_TYPE_START',
  UPDATE_ENQUIRY_TYPE_SUCCESS: 'UPDATE_ENQUIRY_TYPE_SUCCESS',
  UPDATE_ENQUIRY_TYPE_FINISH: 'UPDATE_ENQUIRY_TYPE_FINISH',

  // delete ENQUIRY_TYPE
  DELETE_ENQUIRY_TYPE_START: 'DELETE_ENQUIRY_TYPE_START',
  DELETE_ENQUIRY_TYPE_SUCCESS: 'DELETE_ENQUIRY_TYPE_SUCCESS',
  DELETE_ENQUIRY_TYPE_FINISH: 'DELETE_ENQUIRY_TYPE_FINISH',

  // Enable ENQUIRY_TYPE
  ENABLE_ENQUIRY_TYPE_REQUEST: 'ENABLE_ENQUIRY_TYPE_REQUEST',
  ENABLE_ENQUIRY_TYPE_SUCCESS: 'ENABLE_ENQUIRY_TYPE_SUCCESS',
  ENABLE_ENQUIRY_TYPE_FINISH: 'ENABLE_ENQUIRY_TYPE_FINISH',

  // Disable ENQUIRY_TYPE
  DISABLE_ENQUIRY_TYPE_REQUEST: 'DISABLE_ENQUIRY_TYPE_REQUEST',
  DISABLE_ENQUIRY_TYPE_SUCCESS: 'DISABLE_ENQUIRY_TYPE_SUCCESS',
  DISABLE_ENQUIRY_TYPE_FINISH: 'DISABLE_ENQUIRY_TYPE_FINISH',

  // Enable ENQUIRY_TYPE
  SINGLE_ENABLE_ENQUIRY_TYPE_REQUEST: 'SINGLE_ENABLE_ENQUIRY_TYPE_REQUEST',
  SINGLE_ENABLE_ENQUIRY_TYPE_SUCCESS: 'SINGLE_ENABLE_ENQUIRY_TYPE_SUCCESS',
  SINGLE_ENABLE_ENQUIRY_TYPE_FINISH: 'SINGLE_ENABLE_ENQUIRY_TYPE_FINISH',

  // Disable ENQUIRY_TYPE
  SINGLE_DISABLE_ENQUIRY_TYPE_REQUEST: 'SINGLE_DISABLE_ENQUIRY_TYPE_REQUEST',
  SINGLE_DISABLE_ENQUIRY_TYPE_SUCCESS: 'SINGLE_DISABLE_ENQUIRY_TYPE_SUCCESS',
  SINGLE_DISABLE_ENQUIRY_TYPE_FINISH: 'SINGLE_DISABLE_ENQUIRY_TYPE_FINISH',
}
