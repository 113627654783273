import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAllCardMasterSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllCardMaster, params)
    yield put(actions.getAllCardMasterSuccess(response?.data?.data))
    yield put(actions.getAllCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllCardMasterFinish())
  }
}

function* getSpecificCardMasterSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificCardMaster, params)
    yield put(actions.getSpecificCardMasterSuccess(response?.data?.data))
    yield put(actions.getSpecificCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificCardMasterFinish())
  }
}

function* getCardMasterSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCardMaster, params)
    yield put(actions.getCardMasterSuccess(response?.data?.data))
    yield put(actions.getCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCardMasterFinish())
  }
}

function* addCardMasterSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCardMaster, body)
    yield put(actions.addCardMasterSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCardMasterFinish())
  }
}

function* updateCardMasterSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateCardMaster, body, action.payload?.id)
    yield put(actions.updateCardMasterSuccess(response.data?.data))
    yield put(actions.updateCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCardMasterFinish())
  }
}

function* deleteCardMasterSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCardMaster, body)
    yield put(actions.deleteCardMasterSuccess(response.data?.data))
    yield delay(0.5)
    yield put(actions.deleteCardMasterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCardMasterFinish())
  }
}

function* singleActivateCardMaster(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateCardMaster,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_CARD_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_CARD_MASTER_FINISH})
  }
}

function* singleDeactivateCardMaster(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateCardMaster,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_FINISH})
  }
}

function* sortCardMaster(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialCardMaster, body)

    yield put({
      type: actionTypes.SORT_CARD_MASTER_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_CARD_MASTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CARD_MASTER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_CARD_MASTER_START, getAllCardMasterSaga)
  yield takeLatest(actionTypes.GET_SPECIFIC_CARD_MASTER_START, getSpecificCardMasterSaga)
  yield takeLatest(actionTypes.GET_CARD_MASTER_START, getCardMasterSaga)
  yield takeLatest(actionTypes.ADD_CARD_MASTER_START, addCardMasterSaga)
  yield takeLatest(actionTypes.UPDATE_CARD_MASTER_START, updateCardMasterSaga)
  yield takeLatest(actionTypes.DELETE_CARD_MASTER_START, deleteCardMasterSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_CARD_MASTER_REQUEST, singleActivateCardMaster)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_REQUEST, singleDeactivateCardMaster)
  yield takeLatest(actionTypes.SORT_CARD_MASTER_START, sortCardMaster)
}
