import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getActiveTutorialCategorySaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getActiveTutorialCategory, params)
    yield put(actions.getActiveTutorialCategorySuccess(response?.data?.data))
    yield put(actions.getActiveTutorialCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getActiveTutorialCategoryFinish())
  }
}

function* getTutorialCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getTutorialCategory, params)
    yield put(actions.getTutorialCategorySuccess(response?.data?.data))
    yield put(actions.getTutorialCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getTutorialCategoryFinish())
  }
}

function* addTutorialCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addTutorialCategory, body)
    yield put(actions.addTutorialCategorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addTutorialCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addTutorialCategoryFinish())
  }
}

function* updateTutorialCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateTutorialCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateTutorialCategorySuccess(response.data?.data))
    yield put(actions.updateTutorialCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateTutorialCategoryFinish())
  }
}

function* deleteTutorialCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteTutorialCategory, body)
    yield put(actions.deleteTutorialCategorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteTutorialCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteTutorialCategoryFinish())
  }
}

function* singleActivateTutorialCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateTutorialCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_FINISH})
  }
}

function* singleDeactivateTutorialCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateTutorialCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_FINISH})
  }
}

function* sortTutorialCategory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialTutorialCategory, body)

    yield put({
      type: actionTypes.SORT_TUTORIAL_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_TUTORIAL_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_TUTORIAL_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ACTIVE_TUTORIAL_CATEGORY_START, getActiveTutorialCategorySaga)
  yield takeLatest(actionTypes.GET_TUTORIAL_CATEGORY_START, getTutorialCategorySaga)
  yield takeLatest(actionTypes.ADD_TUTORIAL_CATEGORY_START, addTutorialCategorySaga)
  yield takeLatest(actionTypes.UPDATE_TUTORIAL_CATEGORY_START, updateTutorialCategorySaga)
  yield takeLatest(actionTypes.DELETE_TUTORIAL_CATEGORY_START, deleteTutorialCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    singleActivateTutorialCategory
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    singleDeactivateTutorialCategory
  )
  yield takeLatest(actionTypes.SORT_TUTORIAL_CATEGORY_START, sortTutorialCategory)
}
