export const actionTypes: any = {
  // get application applicant
  GET_VACANCY_APPLICANT_START: 'GET_VACANCY_APPLICANT_START',
  GET_VACANCY_APPLICANT_SUCCESS: 'GET_VACANCY_APPLICANT_SUCCESS',
  GET_VACANCY_APPLICANT_FINISH: 'GET_VACANCY_APPLICANT_FINISH',

  GET_APPLICANT_STATUS_START: 'GET_APPLICANT_STATUS_START',
  GET_APPLICANT_STATUS_SUCCESS: 'GET_APPLICANT_STATUS_SUCCESS',
  GET_APPLICANT_STATUS_FINISH: 'GET_APPLICANT_STATUS_FINISH',
}
