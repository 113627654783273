import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as productCategoryRedux from '../../productCategory'
import {ProductCategoryModel} from '../../productCategory/Model/ProductCategoryModel'
import * as productSubCategoryRedux from '../index'
import {convertToSlug} from 'src/cms/helpers/helperFunction'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  productCategoryId: Yup.string().required('Product category is required'),
  status: Yup.string().required('Status is required'),
})

const AddProductSubCategory = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.productSubCategory)
  const {
    data: {productCategory},
  } = useSelector((state: any) => state.productCategory)

  useEffect(() => {
    dispatch(productCategoryRedux.actions.getAllProductCategory())
  }, [])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const productCategoryOptions = productCategory?.map((items: ProductCategoryModel) => ({
    label: !isEmpty(items?.topMenuId?.displayName)
      ? `${items?.name} (${items?.topMenuId?.displayName})`
      : items.name,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(productSubCategoryRedux?.actions.getProductSubCategory(params))
      isEmpty(editSelectedData)
        ? toast.success('Product Sub Category added successfully')
        : toast.success('Product Sub Category edited successfully')
      dispatch(productSubCategoryRedux?.actions?.resetProductSubCategory())
      handleClose()
    }
  }, [success])

  const showProductSubCategoryTitle = checkBackendSetting('showProductSubCategoryTitle')

  const enableProductSubCategorySlugFiled = checkBackendSetting('enableProductSubCategorySlugFiled')
  return (
    <div className='modal-container' data-cy='product-sub-cat-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Product Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflow: 'visible'}}>
          <div>
            <Formik
              initialValues={{
                name: '',
                status: '',
                slug: '',
                productCategoryId: '',
                text: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: enableProductSubCategorySlugFiled
                    ? convertToSlug(values?.slug || values?.name)
                    : !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.name),
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    productSubCategoryRedux.actions.updateProductSubCategory(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(productSubCategoryRedux.actions.addProductSubCategory(formData))
                }
              }}
            >
              {({touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'nameNp',
                      'slug',
                      'productCategoryId',
                      'text',
                      'textNp',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='product-sub-cat-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Product Category'
                            name='productCategoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={productCategoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                            data-cy='product-sub-cat-category-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name (EN)'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='product-sub-cat-name-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name (NP)'
                            label='Name (NP)'
                            name='nameNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='product-sub-cat-name-np-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          {enableProductSubCategorySlugFiled ? (
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter slug'
                              label='Slug'
                              name='slug'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              value={values.slug || convertToSlug(values?.name)}
                              required
                              data-cy='add-news-slug-text-field'
                            />
                          ) : (
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter Slug'
                              label='Slug'
                              name='slug'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              disabled={true}
                              value={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.slug
                                  : values?.name.replace(/\s/g, '-').toLowerCase()
                              }
                              data-cy='product-sub-cat-slug-text-field'
                            />
                          )}
                        </div>

                        {showProductSubCategoryTitle && (
                          <>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Title'
                                label='Title (EN)'
                                name='text'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                data-cy='product-category-name-text-fields'
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Title (NP)'
                                label='Title (NP)'
                                name='textNp'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                data-cy='product-category-name-np-text-field'
                              />
                            </div>
                          </>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='product-sub-cat-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddProductSubCategory
