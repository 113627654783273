import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getListSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getList, params)
    yield put(actions.getListSuccess(response?.data?.data))
    yield put(actions.getListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getListFinish())
  }
}

function* deleteAgentSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAgent, body)
    yield put({type: actionTypes.DELETE_LIST_SUCCESS, payload: response.data})
    yield delay(100)
    yield put({type: actionTypes.DELETE_LIST_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_LIST_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_LIST_START, getListSaga)
  yield takeLatest(actionTypes.DELETE_LIST_START, deleteAgentSaga)
}
