import {FieldArray, FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent} from 'react'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: {[key: string]: string} | any
  editSelectedData: {[key: string]: string} | any
  setFieldValue: any
}

export function ProductOffersSection({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  setFieldValue,
}: Props) {
  const showAllContentFileExtension = checkBackendSetting('showAllContentFileExtension')
  return (
    <div className=''>
      <div className='row'>
        <div className='row'>
          <FieldArray
            name='productOffers'
            render={(arrayHelpers) => (
              <div>
                {values.productOffers && values.productOffers.length > 0
                  ? values.productOffers.map((friend: any, index: any) => (
                      <div className='mb-4' key={index}>
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Title (EN)'
                              name={`productOffers[${index}].title`}
                              label='Title (EN)'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Title (NP)'
                              name={`productOffers[${index}].titleNp`}
                              label='Title (NP)'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              labelClassName='col-md-12'
                              label='Description (EN)'
                              name={`productOffers.${index}.description`}
                              initialValue={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.productOffers?.[index]?.description
                                  : ''
                              }
                              handleChange={handleChange}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              labelClassName='col-md-12'
                              label='Description (NP)'
                              name={`productOffers.${index}.descriptionNp`}
                              initialValue={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.productOffers?.[index]?.descriptionNp
                                  : ''
                              }
                              handleChange={handleChange}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Link'
                              name={`productOffers[${index}].url`}
                              label='Link'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Image'
                              name={`productOffers[${index}].image`}
                              setFieldValue={setFieldValue}
                              value={values?.productOffers[index]?.image}
                              showImageOnly={showAllContentFileExtension ? false : true}
                            />
                            {!isEmpty(friend?.image) ? (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setFieldValue(`productOffers[${index}].image`, '')
                                      }}
                                      data-cy='modal-thumbnail-remove'
                                    >
                                      X
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={`${imageBaseUrl}/${friend?.image}`}
                                      alt='image'
                                    />
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              label='Publish Date'
                              name={`productOffers[${index}].publish_date`}
                              placeholderText='Please select a date'
                              setFieldValue={setFieldValue}
                              value={values?.productOffers[index].publish_date || false}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              label='Expire Date'
                              name={`productOffers[${index}].expiry_date`}
                              placeholderText='Please select a date'
                              setFieldValue={setFieldValue}
                              value={values?.productOffers[index].expiry_date || false}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                            />
                          </div>
                        </div>

                        {index > 0 && (
                          <div className='col-md-12 col-sm-12 col-xs-12 justify-content-md-end text-end mb-2'>
                            <button
                              type='button'
                              className='p-2 ps-5 pe-5 btn btn-secondary'
                              onClick={() => arrayHelpers.remove(index)}
                              data-cy='modal-field-remove'
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  : null}
                <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                  <button
                    className='p-2 ps-5 pe-5 btn btn-primary'
                    type='button'
                    onClick={() =>
                      arrayHelpers.push({
                        title: '',
                        titleNp: '',
                        description: '',
                        descriptionNp: '',
                        url: '',
                        publish_date: null,
                        expiry_date: null,
                        image: '',
                      })
                    }
                    data-cy='modal-field-add'
                  >
                    + Add More
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}
