import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as menuRedux from 'src/app/modules/cms/components/menu'
import {IMenuState} from 'src/app/modules/cms/components/menu'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as homepageSettingRedux from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title filed required').nullable(),
  subtextEn: Yup.string().max(250, 'Subtext exceeds 250 characters').nullable(),
  status: Yup.string().required('Status  required').nullable(),
})

const AddHomepageSettingModal = ({
  open,
  params,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()
  const {homePageSection, homePageLayout, success, loading} = useSelector(
    (state: any) => state.homepageSetting
  )

  const {topMenu} = useSelector((state: IMenuState | any) => state.menuManager)

  const [imageEng, setImageEng] = useState('')
  const [layoutImage, setLayoutImage] = useState('')
  const [imageNp, setImageNp] = useState('')

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
      isEmpty(editSelectedData)
        ? toast.success('Homepage setting added successfully')
        : toast.success('Homepage setting edited successfully')
      dispatch(homepageSettingRedux?.actions.addHomepageSettingReset())
      dispatch(homepageSettingRedux?.actions.updateHomepageSettingReset())
      handleClose()
    }
  }, [success])

  useEffect(() => {
    dispatch(homepageSettingRedux?.actions.getHomepageSection())
    dispatch(homepageSettingRedux?.actions.getHomepageLayout())
    dispatch(menuRedux?.actions.getTopMenu())
  }, [])

  const topMenuOptions = topMenu?.map((items: any) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const sectionOptions = homePageSection?.map((data: any) => ({
    label: data.name,
    value: data.id,
  }))

  const layoutOptions = homePageLayout?.map((data: any) => ({
    label: data.name,
    value: data.id,
  }))

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showInterestTitle =
    settings?.data?.setting?.filter((d: any) => d?.name === 'showInterestTitle')[0]?.value === '1'
      ? true
      : false

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Homepage Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                sectionId: '',
                layoutId: '',
                layoutImage: '',
                topmenuId: '',
                textEn: '',
                textNp: '',
                subtextEn: '',
                subtextNp: '',
                services: [
                  {
                    serviceTitle: '',
                    serviceTitleNp: '',
                    serviceLink: '',
                    serviceIcon: '',
                    description: '',
                    descriptionNp: '',
                    firstButton: '',
                    firstButtonNp: '',
                    firstButtonUrl: '',
                    secondButton: '',
                    secondButtonNp: '',
                    secondButtonUrl: '',
                    order: '',
                  },
                ],
                mobileAppSections: [{title: '', link: '', image: ''}],
                imageEn: '',
                imageNp: '',
                highlightedTextEn: '',
                highlightedTextNp: '',
                buttonTextEn: '',
                buttonTextNp: '',
                buttonUrl: '',
                buttonClass: '',
                secondbuttonTextEn: '',
                secondbuttonTextNp: '',
                secondbuttonUrl: '',
                secondbuttonClass: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any, {setSubmitting}) => {
                const formData = {
                  ...values,
                  layoutId: !isEmpty(values?.layoutId) ? values?.layoutId : null,
                  layoutImage: !isEmpty(values?.layoutImage) ? values?.layoutImage : null,
                  topmenuId: !isEmpty(values?.topmenuId) ? values?.topmenuId : null,
                  textEn: !isEmpty(values?.textEn) ? values?.textEn : null,
                  textNp: !isEmpty(values?.textNp) ? values?.textNp : null,
                  subtextEn: !isEmpty(values?.subtextEn) ? values?.subtextEn : null,
                  subtextNp: !isEmpty(values?.subtextNp) ? values?.subtextNp : null,
                  imageEn: !isEmpty(values?.imageEn) ? values?.imageEn : null,
                  imageNp: !isEmpty(values?.imageNp) ? values?.imageNp : null,
                  highlightedTextEn: !isEmpty(values?.highlightedTextEn)
                    ? values?.highlightedTextEn
                    : null,
                  highlightedTextNp: !isEmpty(values?.highlightedTextNp)
                    ? values?.highlightedTextNp
                    : null,
                  buttonTextEn: !isEmpty(values?.buttonTextEn) ? values?.buttonTextEn : null,
                  buttonTextNp: !isEmpty(values?.buttonTextNp) ? values?.buttonTextNp : null,
                  buttonUrl: !isEmpty(values?.buttonUrl) ? values?.buttonUrl : null,
                  buttonClass: !isEmpty(values?.buttonClass) ? values?.buttonClass : null,
                  secondbuttonTextEn: !isEmpty(values?.secondbuttonTextEn)
                    ? values?.secondbuttonTextEn
                    : null,
                  secondbuttonTextNp: !isEmpty(values?.secondbuttonTextNp)
                    ? values?.secondbuttonTextNp
                    : null,
                  secondbuttonUrl: !isEmpty(values?.secondbuttonUrl)
                    ? values?.secondbuttonUrl
                    : null,
                  secondbuttonClass: !isEmpty(values?.secondbuttonClass)
                    ? values?.secondbuttonClass
                    : null,
                  services: values?.services?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  mobileAppSections: values?.mobileAppSections?.map(
                    (item: {[key: string]: string}) => ({
                      ...item,
                      id: !isEmpty(editSelectedData) ? item.id : null,
                    })
                  ),
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    homepageSettingRedux.actions.updateHomepageSetting(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(homepageSettingRedux.actions.addHomepageSetting(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'sectionId',
                      'layoutId',
                      'layoutImage',
                      'topmenuId',
                      'textEn',
                      'textNp',
                      'services',
                      'mobileAppSections',
                      'subtextEn',
                      'subtextNp',
                      'imageEn',
                      'imageNp',
                      'highlightedTextEn',
                      'highlightedTextNp',
                      'buttonTextEn',
                      'buttonTextNp',
                      'buttonUrl',
                      'buttonClass',
                      'secondbuttonTextEn',
                      'secondbuttonTextNp',
                      'secondbuttonUrl',
                      'secondbuttonClass',
                      'status',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Title'
                            name='title'
                            label='Title'
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            disabled={showInterestTitle ? true : false}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            containerClassName='col-md-12'
                            placeholder='Select Section'
                            label='Section'
                            name='sectionId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={sectionOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Text '
                            name='textEn'
                            label='Text '
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtext '
                            name='subtextEn'
                            label='Subtext '
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Layout Image'
                            name='layoutImage'
                            setFieldValue={setFieldValue}
                            setImageUrl={setLayoutImage}
                            value={values?.layoutImage}
                          />
                          {!isEmpty(values?.layoutImage) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setLayoutImage('')
                                      setFieldValue('layoutImage', '')
                                    }}
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.layoutImage}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image'
                            name='imageEn'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageEng}
                            value={values?.imageEn}
                          />
                          {!isEmpty(values?.imageEn) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageEng('')
                                      setFieldValue('imageEn', '')
                                    }}
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.imageEn}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text '
                            name='buttonTextEn'
                            label='First Button Text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Link'
                            name='buttonUrl'
                            label='First Button Link'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text '
                            name='secondbuttonTextEn'
                            label='Second Button Text '
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Link'
                            name='secondbuttonUrl'
                            label='Second Button Link'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {values?.sectionId === '1d329eb6-8382-480c-bb9a-bed27dfecd6c' && (
                          <div className='row pt-5 border-top'>
                            <h2>Only for Collaborators Sections</h2>
                            <div className='row'>
                              <FieldArray
                                name='services'
                                render={(arrayHelpers) => (
                                  <div>
                                    {values?.services && values?.services?.length > 0
                                      ? values?.services?.map((friend: any, index: any) => (
                                          <div className='row' key={index}>
                                            <div className='col-md-6 col-xs-12'>
                                              <FormTextBox
                                                type='text'
                                                placeholder='Title '
                                                name={`services.${index}.serviceTitle`}
                                                label='Title'
                                                labelClassName='col-md-12'
                                                containerClassName='col-md-12'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </div>
                                            <div className='col-md-6 col-xs-12'>
                                              <FormTinyMce
                                                containerClassName='col-md-12'
                                                labelClassName='col-md-12'
                                                label='Description '
                                                name={`services.${index}.description`}
                                                initialValue={
                                                  !isEmpty(editSelectedData)
                                                    ? editSelectedData?.services?.[index]
                                                        ?.description
                                                    : ''
                                                }
                                                handleChange={handleChange}
                                              />
                                              {/* <FormTextBox
                                              type='text'
                                              placeholder='Description'
                                              name={`services.${index}.description`}
                                              label='Description'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            /> */}
                                            </div>
                                            <div className='col-md-6 col-xs-12 mb-2'>
                                              <FormInputMediaManager
                                                labelClassName='col-md-12'
                                                containerClassName='col-md-12'
                                                label='Collaborators Logo'
                                                name={`services.${index}.serviceIcon`}
                                                setFieldValue={setFieldValue}
                                                setImageUrl={setImageEng}
                                                value={values?.services[index]?.serviceIcon}
                                                buttonName='Add New Logo'
                                              />
                                              {!isEmpty(values?.services[index]?.serviceIcon) ? (
                                                <>
                                                  <li className='listing'>
                                                    <div className='thumbImageBlock'>
                                                      <button
                                                        type='button'
                                                        title='Remove'
                                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                        onClick={() => {
                                                          setImageEng('')
                                                          setFieldValue(
                                                            `services.${index}.serviceIcon`,
                                                            ''
                                                          )
                                                        }}
                                                        data-cy='modal-thumbnail-remove'
                                                      >
                                                        X
                                                      </button>

                                                      <img
                                                        className='thumbImage w-100 h-100'
                                                        src={`${imageBaseUrl}/${values?.services[index]?.serviceIcon}`}
                                                        alt=''
                                                      />
                                                    </div>
                                                  </li>
                                                </>
                                              ) : null}
                                            </div>

                                            <div className='col-md-6 col-xs-12'>
                                              <FormTextBox
                                                type='text'
                                                placeholder='Order'
                                                name={`services.${index}.order`}
                                                label='Order'
                                                labelClassName='col-md-12'
                                                containerClassName='col-md-12'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </div>

                                            {index > 0 && (
                                              <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                                <button
                                                  type='button'
                                                  className='p-2 ps-5 pe-5 btn btn-secondary'
                                                  onClick={() => arrayHelpers.remove(index)}
                                                  data-cy='modal-field-remove'
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      : null}
                                    {values?.services?.length == 10 ? null : (
                                      <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                        <button
                                          className='p-2 ps-5 pe-5 btn btn-primary'
                                          type='button'
                                          onClick={() =>
                                            arrayHelpers.push({
                                              id: null,
                                              serviceTitle: '',
                                              serviceTitleNp: '',
                                              serviceLink: '',
                                              serviceIcon: '',
                                              description: '',
                                              descriptionNp: '',
                                              firstButton: '',
                                              firstButtonNp: '',
                                              firstButtonUrl: '',
                                              secondButton: '',
                                              secondButtonNp: '',
                                              secondButtonUrl: '',
                                              order: '',
                                            })
                                          }
                                          data-cy='modal-field-add'
                                        >
                                          + Add More
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>
                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end px-9 '>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn-sm ms-3'
                          data-cy='modal-submit'
                        >
                          Save
                        </button>
                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                          data-cy='modal-cancel'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddHomepageSettingModal
