import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getActiveBenefitSubCategorySaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getActiveBenefitSubCategory)
    yield put(actions.getActiveBenefitSubCategorySuccess(response?.data?.data))
    yield put(actions.getActiveBenefitSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getActiveBenefitSubCategoryFinish())
  }
}

function* getBenefitSubCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBenefitSubCategory, params)
    yield put(actions.getBenefitSubCategorySuccess(response?.data?.data))
    yield put(actions.getBenefitSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBenefitSubCategoryFinish())
  }
}

function* addBenefitSubCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBenefitSubCategory, body)
    yield put(actions.addBenefitSubCategorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addBenefitSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBenefitSubCategoryFinish())
  }
}

function* updateBenefitSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateBenefitSubCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateBenefitSubCategorySuccess(response.data?.data))
    yield put(actions.updateBenefitSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBenefitSubCategoryFinish())
  }
}

function* deleteBenefitSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBenefitSubCategory, body)
    yield put(actions.deleteBenefitSubCategorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteBenefitSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBenefitSubCategoryFinish())
  }
}

function* singleActivateBenefitSubCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateBenefitSubCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_FINISH})
  }
}

function* singleDeactivateBenefitSubCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateBenefitSubCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_FINISH})
  }
}

function* sortBenefitSubCategory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialBenefitSubCategory, body)

    yield put({
      type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(
    actionTypes.GET_ACTIVE_BENEFIT_SUB_CATEGORY_START,
    getActiveBenefitSubCategorySaga
  )
  yield takeLatest(actionTypes.GET_BENEFIT_SUB_CATEGORY_START, getBenefitSubCategorySaga)
  yield takeLatest(actionTypes.ADD_BENEFIT_SUB_CATEGORY_START, addBenefitSubCategorySaga)
  yield takeLatest(actionTypes.UPDATE_BENEFIT_SUB_CATEGORY_START, updateBenefitSubCategorySaga)
  yield takeLatest(actionTypes.DELETE_BENEFIT_SUB_CATEGORY_START, deleteBenefitSubCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    singleActivateBenefitSubCategory
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    singleDeactivateBenefitSubCategory
  )
  yield takeLatest(actionTypes.SORT_BENEFIT_SUB_CATEGORY_START, sortBenefitSubCategory)
}
