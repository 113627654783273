export const actionTypes: any = {
  // get sub category
  GET_CSR_CATEGORY_START: 'GET_CSR_CATEGORY_START',
  GET_CSR_CATEGORY_SUCCESS: 'GET_CSR_CATEGORY_SUCCESS',
  GET_CSR_CATEGORY_FINISH: 'GET_CSR_CATEGORY_FINISH',

  // get CSR category dropdown
  GET_CSR_CATEGORY_DROPDOWN_START: 'GET_CSR_CATEGORY_DROPDOWN_START',
  GET_CSR_CATEGORY_DROPDOWN_SUCCESS: 'GET_CSR_CATEGORY_DROPDOWN_SUCCESS',
  GET_CSR_CATEGORY_DROPDOWN_FINISH: 'GET_CSR_CATEGORY_DROPDOWN_FINISH',

  // ADD sub category
  ADD_CSR_CATEGORY_START: 'ADD_CSR_CATEGORY_START',
  ADD_CSR_CATEGORY_SUCCESS: 'ADD_CSR_CATEGORY_SUCCESS',
  ADD_CSR_CATEGORY_FINISH: 'ADD_CSR_CATEGORY_FINISH',
  ADD_CSR_CATEGORY_RESET: 'ADD_CSR_CATEGORY_RESET',

  // Update sub category
  UPDATE_CSR_CATEGORY_START: 'UPDATE_CSR_CATEGORY_START',
  UPDATE_CSR_CATEGORY_SUCCESS: 'UPDATE_CSR_CATEGORY_SUCCESS',
  UPDATE_CSR_CATEGORY_FINISH: 'UPDATE_CSR_CATEGORY_FINISH',
  UPDATE_CSR_CATEGORY_RESET: 'UPDATE_CSR_CATEGORY_RESET',

  // delete sub category
  DELETE_CSR_CATEGORY_START: 'DELETE_CSR_CATEGORY_START',
  DELETE_CSR_CATEGORY_SUCCESS: 'DELETE_CSR_CATEGORY_SUCCESS',
  DELETE_CSR_CATEGORY_FINISH: 'DELETE_CSR_CATEGORY_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_CSR_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_CSR_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_CSR_CATEGORY_FINISH: 'SINGLE_ACTIVATE_CSR_CATEGORY_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_CSR_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_CSR_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_CSR_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_CSR_CATEGORY_FINISH',

  // sort
  SORT_CSR_CATEGORY_START: 'SORT_CSR_CATEGORY_START',
  SORT_CSR_CATEGORY_SUCCESS: 'SORT_CSR_CATEGORY_SUCCESS',
  SORT_CSR_CATEGORY_FINISH: 'SORT_CSR_CATEGORY_FINISH',
  SORT_CSR_CATEGORY_RESET: 'SORT_CSR_CATEGORY_RESET',
}
