import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import * as managerRedux from '../'
import * as branch from '../../branch'
import {BranchModel} from '../../branch/Model/BranchModel'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddBranchManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {data} = useSelector((state: any) => state.branch)
  const {success, loading} = useSelector((state: any) => state.manager)

  //Backend Setting
  const showBranchIncharge = checkBackendSetting('showBranchIncharge')
  const allowFreeTextInPhones = checkBackendSetting('allowFreeTextInPhones')

  //Conditional Validation for Phone Numbers
  const managerPhone = allowFreeTextInPhones
    ? {
        managerPhone: Yup.string()
          .matches(
            /^[A-Za-z0-9#$%^&*()@!-/.,+: ]*$/,
            'Must contain only alphanumerical characters and the symbols (- + : , /.)'
          )
          .nullable(),
      }
    : {
        managerPhone: Yup.string()
          .matches(/^[0-9#$%^&*()@!-/.,+ ]*$/, 'Must contain only number')
          .nullable(),
      }

  const phone = allowFreeTextInPhones
    ? {
        managerPhone: Yup.string()
          .matches(
            /^[A-Za-z0-9#$%^&*()@!-/.,+: ]*$/,
            'Must contain only alphanumerical characters and the symbols (- + : , /.)'
          )
          .nullable(),
      }
    : {
        managerPhone: Yup.string()
          .matches(/^[0-9#$%^&*()@!-/.,+ ]*$/, 'Must contain only number')
          .nullable(),
      }

  const FORM_VALIDATION = Yup.object().shape({
    ...managerPhone,
    ...phone,
    branchId: Yup.string().required('Branch is required'),
    name: Yup.string()
      .required('Name is required')
      .matches(/^[A-Za-z\s.]+$/, 'Must not contain numbers or symbols other than a dot'),
    email: Yup.string()
      .email('Invalid Email. Eg: example@xyz.com')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please provide valid email')
      .nullable(),
    status: Yup.string().required('Status is required'),
  })

  useEffect(() => {
    dispatch(branch?.actions.getAllBranchList())
  }, [])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const hideInWebsiteOptions = [
    {label: 'Yes', value: 'yes'},
    {label: 'No', value: 'no'},
  ]

  const branchOptions = data?.branch?.map((items: BranchModel) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(managerRedux?.actions.getBranchManagerData(params))
      isEmpty(editSelectedData)
        ? toast.success('Branch Manager added successfully')
        : toast.success('Branch Manager edited successfully')
      dispatch(managerRedux?.actions?.resetBranchManager())
      handleClose()
    }
  }, [success])

  const hideMultiLanguage = checkMultiLanguage() // check multi language and hide np filed

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Branch Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                branchId: '',
                name: '',
                status: '',
                managerPhone: '',
                phone: '',
                hideInWebsite: 'no',
                isBranchManager: 'yes',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  hideInWebsite: values?.hideInWebsite === 'yes' ? true : false,
                  isBranchManager: values?.isBranchManager === 'yes' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(managerRedux.actions.updateBranchManager(formData, editSelectedData?.id))
                } else {
                  dispatch(managerRedux.actions.addBranchManager(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['managerPhone', 'email', 'id', 'phone', 'name', 'nameNp']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('branchId', editSelectedData?.branch?.id, false)
                    setFieldValue(
                      'hideInWebsite',
                      editSelectedData?.hideInWebsite ? 'yes' : 'no',
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'isBranchManager',
                      editSelectedData?.isBranchManager === true ? 'yes' : 'no',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Branch'
                            name='branchId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={branchOptions}
                            required
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        {showBranchIncharge && (
                          <div className='col-md-6 col-xs-12'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Is Branch Manager ?
                            </label>
                            <div className='d-flex ms-5'>
                              {hideInWebsiteOptions?.map(
                                (isBranchManager: {label: string; value: string}) => (
                                  <FormRadio
                                    containerClassName=''
                                    label='Is Branch Manager ?'
                                    name='isBranchManager'
                                    type='radio'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required={true}
                                    checkBoxText={isBranchManager?.label}
                                    value={isBranchManager?.value}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder={`Enter Branch ${
                              values?.isBranchManager === 'yes' ? 'Manager' : 'Incharge'
                            } Name`}
                            label={`Branch ${
                              values?.isBranchManager === 'yes' ? 'Manager' : 'Incharge'
                            } Name`}
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder={`Enter Branch ${
                              values?.isBranchManager === 'yes' ? 'Manager' : 'Incharge'
                            } Name (NP)`}
                            label={`Branch ${
                              values?.isBranchManager === 'yes' ? 'Manager' : 'Incharge'
                            } Name (NP)`}
                            name='nameNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter E-mail'
                            label='E-mail'
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Phone Number'
                            label={`${
                              values?.isBranchManager === 'yes' ? 'Manager' : 'Incharge'
                            } Phone Number`}
                            name='managerPhone'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Telephone'
                            label='Telephone'
                            name='phone'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Hide in Website ?
                          </label>
                          <div className='d-flex ms-5'>
                            {hideInWebsiteOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Hide in Website ?'
                                name='hideInWebsite'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddBranchManager
