export const actionTypes: any = {
  GET_ONLINE_APPLICATION_START: 'GET_ONLINE_APPLICATION_START',
  GET_ONLINE_APPLICATION_SUCCESS: 'GET_ONLINE_APPLICATION_SUCCESS',
  GET_ONLINE_APPLICATION_FINISH: 'GET_ONLINE_APPLICATION_FINISH',

  ADD_ONLINE_APPLICATION_START: 'ADD_ONLINE_APPLICATION_START',
  ADD_ONLINE_APPLICATION_SUCCESS: 'ADD_ONLINE_APPLICATION_SUCCESS',
  ADD_ONLINE_APPLICATION_FINISH: 'ADD_ONLINE_APPLICATION_FINISH',
  ADD_ONLINE_APPLICATION_RESET: 'ADD_ONLINE_APPLICATION_RESET',

  UPDATE_ONLINE_APPLICATION_START: 'UPDATE_ONLINE_APPLICATION_START',
  UPDATE_ONLINE_APPLICATION_SUCCESS: 'UPDATE_ONLINE_APPLICATION_SUCCESS',
  UPDATE_ONLINE_APPLICATION_FINISH: 'UPDATE_ONLINE_APPLICATION_FINISH',
  UPDATE_ONLINE_APPLICATION_RESET: 'UPDATE_ONLINE_APPLICATION_RESET',

  DELETE_ONLINE_ONLINE_APPLICATION_START: 'DELETE_ONLINE_ONLINE_APPLICATION_START',
  DELETE_ONLINE_APPLICATION_SUCCESS: 'DELETE_ONLINE_APPLICATION_SUCCESS',
  DELETE_ONLINE_APPLICATION_FINISH: 'DELETE_ONLINE_APPLICATION_FINISH',

  SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST: 'SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST',
  SINGLE_ACTIVATE_ONLINE_APPLICATION_SUCCESS: 'SINGLE_ACTIVATE_ONLINE_APPLICATION_SUCCESS',
  SINGLE_ACTIVATE_ONLINE_APPLICATION_FINISH: 'SINGLE_ACTIVATE_ONLINE_APPLICATION_FINISH',

  SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST: 'SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST',
  SINGLE_DEACTIVATE_ONLINE_APPLICATION_SUCCESS: 'SINGLE_DEACTIVATE_ONLINE_APPLICATION_SUCCESS',
  SINGLE_DEACTIVATE_ONLINE_APPLICATION_FINISH: 'SINGLE_DEACTIVATE_ONLINE_APPLICATION_FINISH',

  SORT_ONLINE_APPLICATION_START: 'SORT_ONLINE_APPLICATION_START',
  SORT_ONLINE_APPLICATION_SUCCESS: 'SORT_ONLINE_APPLICATION_SUCCESS',
  SORT_ONLINE_APPLICATION_FINISH: 'SORT_ONLINE_APPLICATION_FINISH',
  SORT_ONLINE_APPLICATION_RESET: 'SORT_ONLINE_APPLICATION_RESET',
}
