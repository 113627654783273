import {SortCertificateCategoryModel} from '../Model'
import {CertificateCategoryModel} from '../Model/CertificateCategory'

export const actionTypes: any = {
  // get
  GET_ALL_CERTIFICATE_CATEGORY_START: 'GET_ALL_CERTIFICATE_CATEGORY_START',
  GET_ALL_CERTIFICATE_CATEGORY_SUCCESS: 'GET_ALL_CERTIFICATE_CATEGORY_SUCCESS',
  GET_ALL_CERTIFICATE_CATEGORY_FINISH: 'GET_ALL_CERTIFICATE_CATEGORY_FINISH',

  // extended hours
  GET_CERTIFICATE_CATEGORY_START: 'GET_CERTIFICATE_CATEGORY_START',
  GET_CERTIFICATE_CATEGORY_SUCCESS: 'GET_CERTIFICATE_CATEGORY_SUCCESS',
  GET_CERTIFICATE_CATEGORY_FINISH: 'GET_CERTIFICATE_CATEGORY_FINISH',

  // add branch
  ADD_CERTIFICATE_CATEGORY_START: 'ADD_CERTIFICATE_CATEGORY_START',
  ADD_CERTIFICATE_CATEGORY_SUCCESS: 'ADD_CERTIFICATE_CATEGORY_SUCCESS',
  ADD_CERTIFICATE_CATEGORY_FINISH: 'ADD_CERTIFICATE_CATEGORY_FINISH',
  RESET_CERTIFICATE_CATEGORY: 'RESET_CERTIFICATE_CATEGORY',

  // update CERTIFICATE_CATEGORY
  UPDATE_CERTIFICATE_CATEGORY_START: 'UPDATE_CERTIFICATE_CATEGORY_START',
  UPDATE_CERTIFICATE_CATEGORY_SUCCESS: 'UPDATE_CERTIFICATE_CATEGORY_SUCCESS',
  UPDATE_CERTIFICATE_CATEGORY_FINISH: 'UPDATE_CERTIFICATE_CATEGORY_FINISH',

  // delete CERTIFICATE_CATEGORY
  DELETE_CERTIFICATE_CATEGORY_START: 'DELETE_CERTIFICATE_CATEGORY_START',
  DELETE_CERTIFICATE_CATEGORY_SUCCESS: 'DELETE_CERTIFICATE_CATEGORY_SUCCESS',
  DELETE_CERTIFICATE_CATEGORY_FINISH: 'DELETE_CERTIFICATE_CATEGORY_FINISH',

  // Enable CERTIFICATE_CATEGORY
  ENABLE_CERTIFICATE_CATEGORY_REQUEST: 'ENABLE_CERTIFICATE_CATEGORY_REQUEST',
  ENABLE_CERTIFICATE_CATEGORY_SUCCESS: 'ENABLE_CERTIFICATE_CATEGORY_SUCCESS',
  ENABLE_CERTIFICATE_CATEGORY_FINISH: 'ENABLE_CERTIFICATE_CATEGORY_FINISH',

  // Disable CERTIFICATE_CATEGORY
  DISABLE_CERTIFICATE_CATEGORY_REQUEST: 'DISABLE_CERTIFICATE_CATEGORY_REQUEST',
  DISABLE_CERTIFICATE_CATEGORY_SUCCESS: 'DISABLE_CERTIFICATE_CATEGORY_SUCCESS',
  DISABLE_CERTIFICATE_CATEGORY_FINISH: 'DISABLE_CERTIFICATE_CATEGORY_FINISH',

  // Enable CERTIFICATE_CATEGORY
  SINGLE_ENABLE_CERTIFICATE_CATEGORY_REQUEST: 'SINGLE_ENABLE_CERTIFICATE_CATEGORY_REQUEST',
  SINGLE_ENABLE_CERTIFICATE_CATEGORY_SUCCESS: 'SINGLE_ENABLE_CERTIFICATE_CATEGORY_SUCCESS',
  SINGLE_ENABLE_CERTIFICATE_CATEGORY_FINISH: 'SINGLE_ENABLE_CERTIFICATE_CATEGORY_FINISH',

  // Disable CERTIFICATE_CATEGORY
  SINGLE_DISABLE_CERTIFICATE_CATEGORY_REQUEST: 'SINGLE_DISABLE_CERTIFICATE_CATEGORY_REQUEST',
  SINGLE_DISABLE_CERTIFICATE_CATEGORY_SUCCESS: 'SINGLE_DISABLE_CERTIFICATE_CATEGORY_SUCCESS',
  SINGLE_DISABLE_CERTIFICATE_CATEGORY_FINISH: 'SINGLE_DISABLE_CERTIFICATE_CATEGORY_FINISH',

  // sort
  SORT_CERTIFICATE_CATEGORY_START: 'SORT_CERTIFICATE_CATEGORY_START',
  SORT_CERTIFICATE_CATEGORY_SUCCESS: 'SORT_CERTIFICATE_CATEGORY_SUCCESS',
  SORT_CERTIFICATE_CATEGORY_FINISH: 'SORT_CERTIFICATE_CATEGORY_FINISH',
  SORT_CERTIFICATE_CATEGORY_RESET: 'SORT_CERTIFICATE_CATEGORY_RESET',
}
