import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import TrashIcon from '@rsuite/icons/Trash'
import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiEye} from 'react-icons/fi'
import {toast} from 'react-toastify'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as nicInternship from '../redux'
import ViewCandidateDetails from './ViewCandidateDetails'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const NicInternshipAppliedList = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {data, loading, exportSuccess, success, deleteSuccess} = useSelector(
    (state: any) => state.nicInternshipApplication
  )
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    fromDate: '',
    toDate: '',
  })

  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [municipalityData, setMunicipalityData] = useState([])
  const [zoneData, setZoneData] = useState([])
  const [functionalAreaData, setFunctionalAreaData] = useState([])
  const [branchData, setBranchData] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [vacancyApplicantStatusData, setVacancyApplicantStatusData] = useState<any>([])
  const [educationData, setEducationData] = useState([])
  const ftpStorage = checkBackendSetting('ftpStorage')

  useEffect(() => {
    axios.get(API_URL + '/province').then((res) => {
      setProvinceData(res.data.data.province)
    })
    axios.get(API_URL + '/district').then((res) => {
      setDistrictData(res.data.data.district)
    })
    axios.get(API_URL + '/zone').then((res) => {
      setZoneData(res.data.data.zone)
    })
    axios.get(API_URL + '/municipality').then((res) => {
      setMunicipalityData(res.data.data.municipality)
    })
    axios.get(API_URL + '/functionalarea').then((res) => {
      setFunctionalAreaData(res.data.data.functionalArea)
    })
    axios.get(API_URL + '/branch/list').then((res) => {
      setBranchData(res.data.data.branch)
    })
    axios.get(API_URL + '/department').then((res) => {
      setDepartmentData(res.data.data.department)
    })
    axios.get(API_URL + '/general-applicant-status').then((res) => {
      setVacancyApplicantStatusData(res.data.data.generalApplicantStatus)
    })
    axios.get(API_URL + '/qualification').then((res) => {
      setEducationData(res.data.data.qualification)
    })
  }, [])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [showViewCandidateDetailsModal, setShowViewCandidatesModal] = useState(false)
  const [selectedCandidateId, setSelectedCandidateId] = useState('')

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group' data-cy='career-intern-link-group'>
        <CheckPermissions type='Edit'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='career-intern-edit-button'
            speaker={<Tooltip>View Details</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setShowViewCandidatesModal(true)
                setSelectedCandidateId(rowData?.id)
              }}
              data-cy='edit-button'
              appearance='subtle'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='career-intern-link-group'
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let atmId = [rowData.id]
                setCheckedValues(atmId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }
  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Unique Code',
      dataKey: 'uniqueCode',
      flexGrow: 1,
      cell: <Cell dataKey='uniqueCode' />,
      sortable: true,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
      sortable: false,
    },
    {
      label: 'Applied Date',
      dataKey: 'appliedDate',
      flexGrow: 1,
      cell: <Cell dataKey='appliedDate' />,
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(nicInternship.actions.getInternshipLists(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Application deleted successfully')
    }

    if (exportSuccess) {
      toast.success('Application exported successfully')
    }
    handleChecked([])
    dispatch(nicInternship?.actions.getInternshipLists(params))
  }, [deleteSuccess, success, exportSuccess])

  const internshipApplication = data?.internshipAppliedList
    ? data?.internshipAppliedList?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        jobCode: item.jobCode,
        slug: item.slug,
        name: item.name,
        uniqueCode: item.uniqueCode,
        appliedDate: item.appliedDate,
        email: item.email,
        status: item.status,
      }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleExport = () => {
    axios
      .get(
        API_URL +
          `/apply-internship-clipping/export?statusId=d6e52829-56d2-40e2-921a-43a174aa9737&fromDate=${params.fromDate}&toDate=${params.toDate}`
      )
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'internship-applied.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  const handleDelete = () => {
    let ids = checkedValues?.map((value) => ({id: value}))
    dispatch(nicInternship?.actions?.deleteInternshipLists(ids))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(nicInternship?.actions.getInternshipLists(params))
  }

  const shortList = (applicantId: string, actionType: string) => {
    let selectedStatusId = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id
    axios
      .put(API_URL + `/apply-vacancy/${applicantId}`, {
        vacancyApplicantStatusId: selectedStatusId,
      })
      .then((res) => {
        toast.success('Success.')
        dispatch(nicInternship.actions.getInternshipLists(params))
      })
  }

  const handleDownloadResume = () => {
    let applicantIds = ''
    if (checkedValues.length > 0) {
      for (let e in checkedValues) {
        //creating comma seperated Ids
        applicantIds = applicantIds ? applicantIds + ',' + checkedValues[e] : checkedValues[e]
      }
    }

    !isEmpty(checkedValues)
      ? axios
          .get(
            API_URL + `/apply-internship-clipping/get-resume?internshipApplicantId=${applicantIds}`
          )
          .then((res) => {
            const link = document.createElement('a')
            link.href = res.data?.data?.fileName
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
            handleChecked([])
          })
          .catch((err) => toast.error(err.message))
      : toast.error('No data selected')
  }

  const hideBankNameInCareer = checkBackendSetting('hideBankNameInCareer')

  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='career-intern-shadow-comp'>
        <DesignComponent
          moduleName={`${hideBankNameInCareer ? '' : 'Nic'} Internship Applications`}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleExport={handleExport}
          addShow={false}
          importShow={false}
          toggleMultipleShow={false}
          deleteShow={false}
          filterData={true}
          downloadResumeShow={ftpStorage ? false : true}
          handleDownloadResume={handleDownloadResume}
          data-cy='career-intern-design '
        />

        <div className='datatable' data-cy='career-intern-datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={internshipApplication}
            checkedValues={checkedValues}
            showLoading={loading}
            data-cy='career-intern-r-suite'
          />

          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='career-intern-pagination'
          />

          {showViewCandidateDetailsModal && (
            <ViewCandidateDetails
              handleClose={() => setShowViewCandidatesModal(false)}
              data={
                data?.internshipAppliedList?.filter((d: any) => d.id === selectedCandidateId)[0]
              }
              provinceList={provinceData}
              districtList={districtData}
              zoneList={zoneData}
              functionalAreaList={functionalAreaData}
              municipalityList={municipalityData}
              departmentList={departmentData}
              branchList={branchData}
              educationList={educationData}
            />
          )}

          <>
            {alertOpen &&
              (!isEmpty(checkedValues) ? (
                <DeleteModal
                  handleClick={() => handleDelete()}
                  isOpen={alertOpen}
                  handleClose={() => handleAlertClose()}
                />
              ) : (
                toast.error('No data selected') && setAlertOpen(false)
              ))}
          </>
        </div>
      </div>
    </>
  )
}

export default NicInternshipAppliedList
