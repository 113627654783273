import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as galleryCategoryRedux from '../../gallery/galleryCategory/redux'
import {IGalleryCategoryTypeState} from '../../gallery/galleryCategory/redux'
import * as galleryTypeRedux from '../../gallery/gallerytype/redux'
import {IGalleryFileTypeState} from '../../gallery/gallerytype/redux'
import * as galleryRedux from '../../gallery/redux'
import {GalleryCategoryTypeModel} from '../galleryCategory/Model'
import {GalleryFileTypeModel} from '../gallerytype/Model'
import {convertToSlug} from 'src/cms/helpers/helperFunction'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

let FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  date: Yup.string().required('Date is required'),
})

const AddGalleryModal = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [thumbImage, setThumbImage] = useState('')
  const [coverImage, setCoverImage] = useState('')
  const [filterData, setFilterData] = useState([])
  const [linkData, setLinkData] = useState('')
  const {loading, success} = useSelector((state: any) => state.gallery)
  const galleryFileTypeData: IGalleryFileTypeState = useSelector((state: any) => state.gallerytype)

  const galleryCategoryTypeData: IGalleryCategoryTypeState = useSelector(
    (state: any) => state.galleryCategory
  )
  useEffect(() => {
    dispatch(galleryTypeRedux?.actions.getGalleryFileType())
    dispatch(galleryCategoryRedux?.actions.getGalleryCategoryType())
  }, [])

  const galleryFileOption = galleryFileTypeData?.data?.galleryFileOption?.map(
    (items: GalleryFileTypeModel | any) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  const galleryCategoryOption = galleryCategoryTypeData?.data?.galleryCategory?.map(
    (items: GalleryCategoryTypeModel | any) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  useEffect(() => {
    if (success) {
      dispatch(galleryRedux?.actions.getGallery(params))
      isEmpty(editSelectedData)
        ? toast.success('Gallery added successfully')
        : toast.success('Gallery edited successfully')
      dispatch(galleryRedux?.actions?.resetGallery())
      handleClose()
    }
  }, [success])

  const isAlbumThumbImageEnRequired = checkBackendSetting('isAlbumThumbImageEnRequired')
  if (isAlbumThumbImageEnRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      thumbImage: Yup.string().required('Thumb Image is required'),
    })
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Photos & Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                date: '',
                description: '',
                thumbImage: '',
                // coverImage: '',
                slug: '',
                galleryoptionid: '',
                photos: [
                  {
                    photoTitle: '',
                    filetype: '',
                    link: '',
                    iframe: '',
                    description: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  date: moment(values?.date).format('YYYY-MM-DD'),
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                  photos: values?.photos?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(galleryRedux.actions.updateGallery(formData, editSelectedData?.id))
                } else {
                  dispatch(galleryRedux.actions.addGallery(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'title',
                      'date',
                      'thumbImage',
                      'galleryoptionid',
                      'description',
                      'photos',
                      'slug',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Album Title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label=' Date'
                            name='date'
                            placeholderText='Please select a date'
                            setFieldValue={setFieldValue}
                            value={values.date || false}
                            touched={touched}
                            showIcon={true}
                            errors={{}}
                            required={true}
                          />
                        </div>
                        <div className='col-lg-6'>
                          <div className='row'>
                            <div className='col-md-12 col-xs-12'>
                              <FormInputMediaManager
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Album Thumb Image'
                                name='thumbImage'
                                setFieldValue={setFieldValue}
                                setImageUrl={setThumbImage}
                                value={values?.thumbImage}
                                required={isAlbumThumbImageEnRequired}
                              />

                              {!isEmpty(values?.thumbImage) ? (
                                <>
                                  <li className='listing'>
                                    <div className='thumbImageBlock'>
                                      <button
                                        type='button'
                                        title='Remove'
                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                        onClick={() => {
                                          setThumbImage('')
                                          setFieldValue('thumbImage', '')
                                        }}
                                        data-cy='modal-thumbnail-remove'
                                      >
                                        X
                                      </button>

                                      <img
                                        className='thumbImage w-100 h-100'
                                        src={`${imageBaseUrl}/${values?.thumbImage}`}
                                        alt=''
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            name='description'
                            handleChange={handleChange}
                            label='Album Description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.title)
                            }
                            disabled={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select a Category type'
                            label='Gallery Category'
                            name='galleryoptionid'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={galleryCategoryOption}
                            values={values}
                            setFieldValue={setFieldValue}
                            // required
                          />
                        </div>
                      </div>
                    </div>

                    <div className='card-body border-top pt-5'>
                      <h5>Photo Videos on Album</h5>
                      <div className=''>
                        <div className=''>
                          <FieldArray
                            name='photos'
                            render={(arrayHelpers) => (
                              <div>
                                {values.photos && values.photos.length > 0
                                  ? values.photos.map((friend: any, index: any) => (
                                      <div key={index} className='row'>
                                        <div className='col-12 pt-5 border-top'>
                                          {index > 0 && (
                                            <div className=' d-flex justify-content-md-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                                data-cy='modal-field-remove'
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        <>
                                          {galleryFileTypeData?.data?.galleryFileOption
                                            ?.filter(
                                              (item: any) =>
                                                item.systemName === values.photos[index].filetype
                                            )
                                            .map((filter: any) => setFilterData(filter))}
                                        </>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Title in English'
                                            label='Title'
                                            name={`photos.${index}.photoTitle`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTinyMce
                                            containerClassName='col-md-12'
                                            labelClassName='col-md-12'
                                            label='Description '
                                            name={`photos.${index}.description`}
                                            initialValue={
                                              !isEmpty(editSelectedData)
                                                ? editSelectedData?.photos?.[index]?.description
                                                : ''
                                            }
                                            handleChange={handleChange}
                                          />
                                        </div>

                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                          <FormSelect
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Please select a file type'
                                            label='File Type'
                                            name={`photos.${index}.filetype`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            options={galleryFileOption}
                                            values={values}
                                            arrValue={values.photos[index].filetype}
                                            // values={galleryFileOption.find(
                                            //   (option) =>
                                            //     option.value === values.photos[index].filetype
                                            // )}
                                            setFieldValue={setFieldValue}
                                            // required
                                          />
                                        </div>
                                        {galleryFileOption.find(
                                          (option) => option.value === values.photos[index].filetype
                                        )?.label == 'Link' && (
                                          <div className='col-md-6 col-xs-12'>
                                            <FormInputMediaManager
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              label='Upload Image'
                                              name={`photos.${index}.link`}
                                              setFieldValue={setFieldValue}
                                              setImageUrl={setLinkData}
                                              value={values.photos[index].link}
                                            />

                                            {!isEmpty(values.photos[index].link) ? (
                                              <>
                                                <li className='listing'>
                                                  <div className='thumbImageBlock'>
                                                    <button
                                                      type='button'
                                                      title='Remove'
                                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                      onClick={() => {
                                                        setLinkData('')
                                                        setFieldValue(`photos.${index}.link`, '')
                                                      }}
                                                      data-cy='modal-thumbnail-remover'
                                                    >
                                                      X
                                                    </button>

                                                    <img
                                                      className='thumbImage w-100 h-100'
                                                      src={`${imageBaseUrl}/${values.photos[index].link}`}
                                                      alt=''
                                                    />
                                                  </div>
                                                </li>
                                              </>
                                            ) : null}
                                          </div>
                                        )}
                                        {galleryFileOption.find(
                                          (option) => option.value === values.photos[index].filetype
                                        )?.label == 'Iframe' && (
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              as='textarea'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              placeholder='Iframe '
                                              label='Iframe'
                                              name={`photos.${index}.iframe`}
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}

                                <div className='d-flex justify-content-end'>
                                  <button
                                    className=' p-2 ps-3 pe-3 btn btn-primary w-100'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        id: null,
                                        photoTitle: '',
                                        filetype: '',
                                        link: '',
                                        iframe: '',
                                        description: '',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 mt-2'>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddGalleryModal
