import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortAuctionModel, SortAuctionActionModel} from '../Model/Model'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getAuctionSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getAuction, params)
    yield put(actions.getAuctionSuccess(response?.data?.data))
    yield put(actions.getAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAuctionFinish())
  }
}

function* addAuctionSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAuction, body)
    yield put(actions.addAuctionSuccess(response.data?.data))
    yield put(actions.addAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.addAuctionFinish())
  }
}

function* enableAuctionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableAuction, selectedUsers)
    yield put(actions.enableAuctionSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableAuctionFinish())
  }
}

function* disableAuctionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableAuction, selectedUsers)
    yield put(actions.disableAuctionSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableAuctionFinish())
  }
}

function* singleEnableAuctionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableAuction, selectedUsers)
    yield put(actions.singleEnableAuctionSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleEnableAuctionFinish())
  }
}

function* singleDisableAuctionSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableAuction, selectedUsers)
    yield put(actions.singleDisableAuctionSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleDisableAuctionFinish())
  }
}

function* updateAuctionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(service.updateAuction, body, action.payload?.id)
    yield put(actions.updateAuctionSuccess(response.data?.data))
    yield put(actions.updateAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.updateAuctionFinish())
  }
}

function* deleteAuctionSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAuction, body)
    yield put(actions.deleteAuctionSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteAuctionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.deleteAuctionFinish())
  }
}

function* sortAuction(action: SortAuctionActionModel) {
  try {
    const body: SortAuctionModel = action.payload
    const response: ResponseModel = yield call(service.sortAuction, body)

    yield put({
      type: actionTypes.SORT_AUCTION_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_AUCTION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put({type: actionTypes.SORT_AUCTION_FINISH})
  }
}

function* getAuctionTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAuctionTypeList)
    yield put(actions.getAuctionTypeSuccess(response?.data?.data))
    yield put(actions.getAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAuctionTypeFinish())
  }
}

function* getPropertyTypeListSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getPropertyTypeList)
    yield put(actions.getPropertyTypeListSuccess(response?.data?.data))
    yield put(actions.getPropertyTypeListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getPropertyTypeListFinish())
  }
}

function* getAuctionImageTypeListSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAuctionImageTypeList)
    yield put(actions.getAuctionImageTypeListSuccess(response?.data?.data))
    yield put(actions.getAuctionImageTypeListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAuctionImageTypeListFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_AUCTION_START, getAuctionSaga)
  yield takeLatest(actionTypes.ADD_AUCTION_START, addAuctionSaga)
  yield takeLatest(actionTypes.UPDATE_AUCTION_START, updateAuctionSaga)
  yield takeLatest(actionTypes.DELETE_AUCTION_START, deleteAuctionSaga)
  yield takeLatest(actionTypes.ENABLE_AUCTION_REQUEST, enableAuctionSaga)
  yield takeLatest(actionTypes.DISABLE_AUCTION_REQUEST, disableAuctionSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_AUCTION_REQUEST, singleEnableAuctionSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_AUCTION_REQUEST, singleDisableAuctionSaga)
  yield takeLatest(actionTypes.SORT_AUCTION_START, sortAuction)
  yield takeLatest(actionTypes.GET_AUCTION_TYPE_LIST_START, getAuctionTypeSaga)
  yield takeLatest(actionTypes.GET_PROPERTY_TYPE_LIST_START, getPropertyTypeListSaga)
  yield takeLatest(actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_START, getAuctionImageTypeListSaga)
}
