import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {CertificateCategoryModel} from '../Model/CertificateCategory'
import {SortCertificateCategoryActionModel, SortCertificateCategoryModel} from '../Model'

function* getCertificateCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCertificateCategory, params)
    yield put(actions.getCertificateCategorySuccess(response?.data?.data))
    yield put(actions.getCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCertificateCategoryFinish())
  }
}

function* getAllCertificateCategorySaga() {
  try {
    const response: ResponseModel = yield call(service.getAllCertificateCategory)
    yield put(actions.getAllCertificateCategorySuccess(response?.data?.data))
    yield put(actions.getAllCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllCertificateCategoryFinish())
  }
}

function* addCertificateCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCertificateCategory, body)
    yield put(actions.addCertificateCategorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCertificateCategoryFinish())
  }
}

function* enableCertificateCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableCertificateCategory, selectedUsers)
    yield put(actions.enableCertificateCategorySuccess(response?.data))
    yield delay(100)
    yield put(actions.enableCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCertificateCategoryFinish())
  }
}

function* disableCertificateCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableCertificateCategory, selectedUsers)
    yield put(actions.disableCertificateCategorySuccess(response?.data))
    yield delay(100)
    yield put(actions.disableCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCertificateCategoryFinish())
  }
}

function* singleEnableCertificateCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleEnableCertificateCategory,
      selectedUsers
    )
    yield put(actions.singleEnableCertificateCategorySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableCertificateCategoryFinish())
  }
}

function* singleDisableCertificateCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableCertificateCategory,
      selectedUsers
    )
    yield put(actions.singleDisableCertificateCategorySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableCertificateCategoryFinish())
  }
}

function* updateCertificateCategorySaga(action: ActionModel) {
  try {
    const body: CertificateCategoryModel = action.payload
    const response: ResponseModel = yield call(
      service.updateCertificateCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateCertificateCategorySuccess(response.data?.data))
    yield put(actions.updateCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCertificateCategoryFinish())
  }
}

function* deleteCertificateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCertificateCategory, body)
    yield put(actions.deleteCertificateCategorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteCertificateCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCertificateCategoryFinish())
  }
}

function* sortCertificateCategory(action: SortCertificateCategoryActionModel) {
  try {
    const body: SortCertificateCategoryModel = action.payload
    const response: ResponseModel = yield call(service.sortCertificateCategory, body)

    yield put({
      type: actionTypes.SORT_CERTIFICATE_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_CERTIFICATE_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CERTIFICATE_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CERTIFICATE_CATEGORY_START, getCertificateCategorySaga)
  yield takeLatest(actionTypes.GET_ALL_CERTIFICATE_CATEGORY_START, getAllCertificateCategorySaga)
  yield takeLatest(actionTypes.ADD_CERTIFICATE_CATEGORY_START, addCertificateCategorySaga)
  yield takeLatest(actionTypes.UPDATE_CERTIFICATE_CATEGORY_START, updateCertificateCategorySaga)
  yield takeLatest(actionTypes.DELETE_CERTIFICATE_CATEGORY_START, deleteCertificateCategorySaga)
  yield takeLatest(actionTypes.ENABLE_CERTIFICATE_CATEGORY_REQUEST, enableCertificateCategorySaga)
  yield takeLatest(actionTypes.DISABLE_CERTIFICATE_CATEGORY_REQUEST, disableCertificateCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_CERTIFICATE_CATEGORY_REQUEST,
    singleEnableCertificateCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_CERTIFICATE_CATEGORY_REQUEST,
    singleDisableCertificateCategorySaga
  )

  yield takeLatest(actionTypes.SORT_CERTIFICATE_CATEGORY_START, sortCertificateCategory)
}
