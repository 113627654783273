import moment from 'moment'
import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String
    firstName: String
    middleName: String
    lastName: String
    email: String

    contactNumber: String
    query: String

    type: [
      {
        id: String
        name: String
      }
    ]

    status: [
      {
        id: String
        name: String
      }
    ]
  }
  handleClose: () => void
}

const ViewGrievanceDetails = ({data, handleClose}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Inquiry Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={4}>First Name : {data?.firstName}</Col>
              <Col md={4}>Middle Name : {data?.middleName}</Col>
              <Col md={4}>Last Name : {data?.lastName}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={4}>Email : {data?.email}</Col>
              <Col md={4}>Contact Number: {data?.contactNumber}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={4}>Message : {data?.query}</Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewGrievanceDetails
