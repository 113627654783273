import {SortDocumentCategoryModel} from '../Model'
import {DocumentCategoryModel} from '../Model/DocumentCategoryModel'

export const actionTypes: any = {
  // get
  GET_ALL_DOCUMENT_CATEGORY_START: 'GET_ALL_DOCUMENT_CATEGORY_START',
  GET_ALL_DOCUMENT_CATEGORY_SUCCESS: 'GET_ALL_DOCUMENT_CATEGORY_SUCCESS',
  GET_ALL_DOCUMENT_CATEGORY_FINISH: 'GET_ALL_DOCUMENT_CATEGORY_FINISH',

  // extended hours
  GET_DOCUMENT_CATEGORY_START: 'GET_DOCUMENT_CATEGORY_START',
  GET_DOCUMENT_CATEGORY_SUCCESS: 'GET_DOCUMENT_CATEGORY_SUCCESS',
  GET_DOCUMENT_CATEGORY_FINISH: 'GET_DOCUMENT_CATEGORY_FINISH',

  // add branch
  ADD_DOCUMENT_CATEGORY_START: 'ADD_DOCUMENT_CATEGORY_START',
  ADD_DOCUMENT_CATEGORY_SUCCESS: 'ADD_DOCUMENT_CATEGORY_SUCCESS',
  ADD_DOCUMENT_CATEGORY_FINISH: 'ADD_DOCUMENT_CATEGORY_FINISH',
  RESET_DOCUMENT_CATEGORY: 'RESET_DOCUMENT_CATEGORY',

  // update DOCUMENT_CATEGORY
  UPDATE_DOCUMENT_CATEGORY_START: 'UPDATE_DOCUMENT_CATEGORY_START',
  UPDATE_DOCUMENT_CATEGORY_SUCCESS: 'UPDATE_DOCUMENT_CATEGORY_SUCCESS',
  UPDATE_DOCUMENT_CATEGORY_FINISH: 'UPDATE_DOCUMENT_CATEGORY_FINISH',

  // delete DOCUMENT_CATEGORY
  DELETE_DOCUMENT_CATEGORY_START: 'DELETE_DOCUMENT_CATEGORY_START',
  DELETE_DOCUMENT_CATEGORY_SUCCESS: 'DELETE_DOCUMENT_CATEGORY_SUCCESS',
  DELETE_DOCUMENT_CATEGORY_FINISH: 'DELETE_DOCUMENT_CATEGORY_FINISH',

  // Enable DOCUMENT_CATEGORY
  ENABLE_DOCUMENT_CATEGORY_REQUEST: 'ENABLE_DOCUMENT_CATEGORY_REQUEST',
  ENABLE_DOCUMENT_CATEGORY_SUCCESS: 'ENABLE_DOCUMENT_CATEGORY_SUCCESS',
  ENABLE_DOCUMENT_CATEGORY_FINISH: 'ENABLE_DOCUMENT_CATEGORY_FINISH',

  // Disable DOCUMENT_CATEGORY
  DISABLE_DOCUMENT_CATEGORY_REQUEST: 'DISABLE_DOCUMENT_CATEGORY_REQUEST',
  DISABLE_DOCUMENT_CATEGORY_SUCCESS: 'DISABLE_DOCUMENT_CATEGORY_SUCCESS',
  DISABLE_DOCUMENT_CATEGORY_FINISH: 'DISABLE_DOCUMENT_CATEGORY_FINISH',

  // Enable DOCUMENT_CATEGORY
  SINGLE_ENABLE_DOCUMENT_CATEGORY_REQUEST: 'SINGLE_ENABLE_DOCUMENT_CATEGORY_REQUEST',
  SINGLE_ENABLE_DOCUMENT_CATEGORY_SUCCESS: 'SINGLE_ENABLE_DOCUMENT_CATEGORY_SUCCESS',
  SINGLE_ENABLE_DOCUMENT_CATEGORY_FINISH: 'SINGLE_ENABLE_DOCUMENT_CATEGORY_FINISH',

  // Disable DOCUMENT_CATEGORY
  SINGLE_DISABLE_DOCUMENT_CATEGORY_REQUEST: 'SINGLE_DISABLE_DOCUMENT_CATEGORY_REQUEST',
  SINGLE_DISABLE_DOCUMENT_CATEGORY_SUCCESS: 'SINGLE_DISABLE_DOCUMENT_CATEGORY_SUCCESS',
  SINGLE_DISABLE_DOCUMENT_CATEGORY_FINISH: 'SINGLE_DISABLE_DOCUMENT_CATEGORY_FINISH',

  // sort
  SORT_DOCUMENT_CATEGORY_START: 'SORT_DOCUMENT_CATEGORY_START',
  SORT_DOCUMENT_CATEGORY_SUCCESS: 'SORT_DOCUMENT_CATEGORY_SUCCESS',
  SORT_DOCUMENT_CATEGORY_FINISH: 'SORT_DOCUMENT_CATEGORY_FINISH',
  SORT_DOCUMENT_CATEGORY_RESET: 'SORT_DOCUMENT_CATEGORY_RESET',
}
