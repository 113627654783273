import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'

type Props = {
  data: {
    auction: any
    fullname: string
    email: string
    mobileNumber: string
    address: string
    message: string
    submitted_date: string
    submitted_time: string
  }
  handleClose: () => void
}
const ViewAuctionEnquiryDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Auction Enquiry Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Auction Name</b>
              </Col>
              <Col md={6}>
                <p>{data?.auction?.name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Full Name</b>
              </Col>
              <Col md={6}>
                <p>{data?.fullname}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email</b>
              </Col>
              <Col md={6}>
                <p>{data?.email || ' - '}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Mobile Number</b>
              </Col>
              <Col md={6}>
                <p>{data?.mobileNumber || ' - '}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Address</b>
              </Col>
              <Col md={6}>
                <p>{data?.address || ' - '}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Message</b>
              </Col>
              <Col md={6}>
                <p>{data?.message || ' - '}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Submitted Date</b>
              </Col>
              <Col md={6}>
                <p>{data?.submitted_date || ' - '}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewAuctionEnquiryDetailsModal
