import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ProgramModel, SortProgramActionModel, SortProgramModel} from '../Model/ProgramModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getProgramSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getProgram, params)
    yield put(actions.getProgramSuccess(response?.data?.data))
    yield put(actions.getProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getProgramFinish())
  }
}

function* getAllProgramSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllProgram)
    yield put(actions.getAllProgramSuccess(response?.data?.data))
    yield put(actions.getAllProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllProgramFinish())
  }
}

function* addProgramSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addProgram, body)
    yield put(actions.addProgramSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addProgramFinish())
  }
}

function* enableProgramSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableProgram, selectedUsers)
    yield put(actions.enableProgramSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProgramFinish())
  }
}

function* disableProgramSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableProgram, selectedUsers)
    yield put(actions.disableProgramSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProgramFinish())
  }
}

function* singleEnableProgramSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableProgram, selectedUsers)
    yield put(actions.singleEnableProgramSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableProgramFinish())
  }
}

function* singleDisableProgramSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableProgram, selectedUsers)
    yield put(actions.singleDisableProgramSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableProgramFinish())
  }
}

function* updateProgramSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.updateProgram, body, action.payload?.id)
    yield put(actions.updateProgramSuccess(response.data?.data))
    yield put(actions.updateProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateProgramFinish())
  }
}

function* deleteProgramSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteProgram, body)
    yield put(actions.deleteProgramSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteProgramFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteProgramFinish())
  }
}

function* sortProgram(action: SortProgramActionModel) {
  try {
    const body: SortProgramModel = action.payload
    const response: ResponseModel = yield call(service.sortProgram, body)

    yield put({
      type: actionTypes.SORT_PROGRAM_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_PROGRAM_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_PROGRAM_FINISH})
  }
}

function* getBannerOptionSaga() {
  //GET Banner OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getBannerOption)
    yield put(actions.getBannerOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getBannerOptionError(err))
  }
}

function* getAlbumOptionSaga() {
  //GET Album OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getAlbumOption)
    yield put(actions.getAlbumOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getAlbumOptionError(err))
  }
}

function* getDownloadOptionSaga() {
  //GET FAQ OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getDownloadOption)
    yield put(actions.getDownloadOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getDownloadOptionError(err))
  }
}

function* getLeadFormSaga() {
  //GET LEAD FORM SAGA
  try {
    const response: ResponseModel = yield call(service.getLeadForm)
    yield put(actions.getLeadFormSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getLeadFormError(err))
  }
}

function* getPageHeaderSaga() {
  //GET PAGE HEADER SAGA
  try {
    const response: ResponseModel = yield call(service.getPageHeader)
    yield put(actions.getPageHeaderSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getPageHeaderError(err))
  }
}

function* getProgramOptionSaga() {
  //GET Program OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getProgramOption)
    yield put(actions.getProgramOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getProgramOptionError(err))
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PROGRAM_START, getProgramSaga)
  yield takeLatest(actionTypes.GET_ALL_PROGRAM_START, getAllProgramSaga)
  yield takeLatest(actionTypes.ADD_PROGRAM_START, addProgramSaga)
  yield takeLatest(actionTypes.UPDATE_PROGRAM_START, updateProgramSaga)
  yield takeLatest(actionTypes.DELETE_PROGRAM_START, deleteProgramSaga)
  yield takeLatest(actionTypes.ENABLE_PROGRAM_REQUEST, enableProgramSaga)
  yield takeLatest(actionTypes.DISABLE_PROGRAM_REQUEST, disableProgramSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_PROGRAM_REQUEST, singleEnableProgramSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_PROGRAM_REQUEST, singleDisableProgramSaga)
  yield takeLatest(actionTypes.SORT_PROGRAM_START, sortProgram)
  yield takeLatest(actionTypes.GET_PROGRAM_BANNER_OPTION_START, getBannerOptionSaga)
  yield takeLatest(actionTypes.GET_PROGRAM_ALBUM_OPTION_START, getAlbumOptionSaga)
  yield takeLatest(actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_START, getDownloadOptionSaga)
  yield takeLatest(actionTypes.GET_PROGRAM_LEAD_FORM_START, getLeadFormSaga)
  yield takeLatest(actionTypes.GET_PROGRAM_PAGE_HEADER_START, getPageHeaderSaga)
  yield takeLatest(actionTypes.GET_PROGRAM_OPTION_START, getProgramOptionSaga)
}
