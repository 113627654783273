import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
// rsuite
import IconButton from 'rsuite/IconButton'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'

import RemindOutlineIcon from '@rsuite/icons/RemindOutline'
import TrashIcon from '@rsuite/icons/Trash'
import {groupBy, isEmpty, mapValues} from 'lodash'
import {AiOutlineEdit} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import Modal from 'rsuite/Modal'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as servicePartnerRedux from '../../servicePartnerManagement'
import {ExchangeRateModel} from '../Model'
import * as exchangeRateRedux from '../index'
import AddExchangeRate from './AddExchangeRate'
import ImportExchangeRate from './ImportExchangeRate'

const Cell = Table.Cell
const ExchangeRate = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [actionType1, setActionType1] = useState('Add')
  const [open1, setOpen1] = useState(false)
  const [addNotice, setAddNotice] = useState(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<ExchangeRateModel | any>({})
  const [alertOpen, setAlertOpen] = useState(false)
  const [importModalOpen, setImportModalOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [alertOpenFile, setAlertOpenFile] = useState(false)
  const handleAlertOpenFile = () => setAlertOpenFile(true)
  const handleAlertCloseFile = () => setAlertOpenFile(false)

  //Getting States
  const exchangeRateData = useSelector((state: any) => state.exchangeRate)

  const {
    enableSuccess,
    disableSuccess,
    success,
    singleActivateSuccess,
    deleteSuccess,
    importSuccess,
  } = exchangeRateData

  const handleToggleAction = (data: {[key: string]: string}) => {
    const formData = [
      {
        id: data.id,
      },
    ]

    data?.status
      ? dispatch(exchangeRateRedux.actions.disableExchangeRate(formData))
      : dispatch(exchangeRateRedux.actions.enableExchangeRate(formData))
  }

  useEffect(() => {
    dispatch(servicePartnerRedux.actions.servicePartnerCurrencyList())
  }, [])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData({
                  servicePartnerRateCategories: [rowData.exchangeRateCate],
                  date: rowData.date,
                  rateType: rowData.rateType,
                })
                setActionType1('Edit')
                setOpen1(true)
              }}
              icon={<AiOutlineEdit />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let forexSettingId = [rowData.id]
                setSelectedData(forexSettingId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const handleClose = () => setOpen(false)
  const handleClose1 = () => setOpen1(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Date',
      flexGrow: 1,
      dataKey: 'date',
      cell: <Cell dataKey='date' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const data = exchangeRateData?.data?.servicePartnerRate
    ? exchangeRateData?.data?.servicePartnerRate?.map((rate: any, i: number) => {
        const exchangeRateCate = mapValues(
          groupBy(rate?.serviceProviderCategory, 'item.name'),
          (obj) =>
            obj?.map((item: any) => {
              return {
                ...item,
                exchangeRateId: rate?.id,
                name: item?.item?.name,
              }
            })
        )
        return {
          sn: (params?.page - 1) * params?.limit + (i + 1),
          id: rate?.id,
          date: rate?.date,
          rateType: rate?.rateType,
          status: rate?.status,
          exchangeRateCate,
        }
      })
    : []

  // get forexRate data when page referesh
  useEffect(() => {
    dispatch(exchangeRateRedux.actions.getExchangeRate(params))
  }, [params])

  useEffect(() => {
    if (enableSuccess) {
      toast.success('Exchange Rate activated successfully')
    }
    if (disableSuccess) {
      toast.success('Exchange Rate deactivated successfully')
    }
    if (deleteSuccess) {
      toast.success('Exchange Rate deleted successfully')
    }
    if (importSuccess) {
      toast.success('File Imported successfully')
    }
    handleCheck([])
    dispatch(exchangeRateRedux?.actions.getExchangeRate(params))
  }, [enableSuccess, disableSuccess, deleteSuccess, success, importSuccess])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const activateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(exchangeRateRedux?.actions.enableExchangeRate(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(exchangeRateRedux?.actions.disableExchangeRate(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleImportModalOpen = () => {
    setImportModalOpen(true)
  }
  const handleImportModalClose = () => {
    setImportModalOpen(false)
  }

  function fileImport(event: any) {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(exchangeRateRedux.actions.importExchangeRate(formData))
      event.target.value = null
    }
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen1(true)
    setActionType1('Add')
  }

  const handleDelete = () => {
    let exchangeRateId = selectedData?.map((value) => ({id: value}))
    dispatch(exchangeRateRedux?.actions.deleteExchangeRate(exchangeRateId))

    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(exchangeRateRedux.actions.getExchangeRate(params))
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Exchange rates'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleExport={handleAlertOpenFile}
          handleClickImport={handleImportModalOpen}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          exportButtonName='Download sample'
          importShow1
          importShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={exchangeRateData?.loading}
            onChecked={handleCheck}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={exchangeRateData?.data?.meta?.total}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open1 && (
          <AddExchangeRate
            open={open1}
            handleClose={handleClose1}
            actionType={actionType1}
            editSelectedData={editSelectedData}
          />
        )}
        <>
          {alertOpen &&
            (!isEmpty(selectedData) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </>

        {/* File Download */}
        <div className='modal-container'>
          <Modal
            backdrop='static'
            role='alertdialog'
            open={alertOpenFile}
            onClose={handleAlertCloseFile}
            enforceFocus={false}
            size='xs'
          >
            <Modal.Body>
              <div className='px-4'>
                <div className='d-flex'>
                  <RemindOutlineIcon
                    style={{
                      color: '#ffb300',
                      fontSize: 24,
                    }}
                  />
                  <h4>
                    <strong className='ms-3'>Choose file format and download!</strong>
                  </h4>
                </div>

                {/* Template download */}

                <div className='row mt-5'>
                  <div className='col-lg-6'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                      onClick={() => {
                        dispatch(exchangeRateRedux.actions.downloadExchangeRateSample('csv'))
                        handleAlertCloseFile()
                      }}
                    >
                      CSV file
                    </button>
                  </div>
                  <div className='col-lg-6'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                      onClick={() => {
                        dispatch(exchangeRateRedux.actions.downloadExchangeRateSample('xlsx'))
                        handleAlertCloseFile()
                      }}
                    >
                      {' '}
                      XLSX file
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={handleAlertCloseFile} className='btn btn-secondary btn-sm ms-3'>
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* End of file Download */}
        {/* File Import */}
        <div className='modal-container'>
          <Modal
            backdrop='static'
            open={importModalOpen}
            onClose={handleImportModalClose}
            enforceFocus={false}
            size='xs'
          >
            <Modal.Body>
              <div className='px-4'>
                <div className='d-flex'>
                  <h4>
                    <strong className=''>Import Exchange Rate</strong>
                  </h4>
                </div>

                {/* Template download */}

                <ImportExchangeRate handleImportModalClose={handleImportModalClose} />
              </div>
            </Modal.Body>
          </Modal>
        </div>
        {/* End of file Import */}
      </div>
    </div>
  )
}
export default ExchangeRate
