import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

//Get Stock Rate
function* getStockRateSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getStock, params)
    const data: any = response?.data
    yield put(actions.getStockRateSuccess(data))
    yield put(actions.getStockRateError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getStockRateError())
  }
}

//Get Stock rate file
function* getStockRateFileSaga(action: ActionModel) {
  try {
    const data: any = action.payload.params
    const fileType: any = action.payload.fileType

    yield delay(500)
    const response: ResponseModel = yield call(service.getStockFile, fileType, data)
    const responseData: any = response?.data
    yield put(actions.getStockRateFileSuccess(responseData))
    yield put(actions.getStockRateFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getStockRateFileError())
  }
}

//Create Stock Rate
function* createStockRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.postStockRate, body)
    yield put({type: actionTypes.CREATE_STOCK_RATE_SUCCESS, payload: response.data})
    yield delay(100)
    yield put({type: actionTypes.CREATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.CREATE_STOCK_RATE_FINISH})
  }
}

//Activate Deactivate Stock Rate
function* activateStockRates(action: ActionModel) {
  try {
    const selectedGoldRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateStockRate, selectedGoldRate)
    yield put({type: actionTypes.ACTIVATE_STOCK_RATE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.ACTIVATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_STOCK_RATE_FINISH})
  }
}

//Latest two
function* singleActivateStockRate(action: ActionModel) {
  try {
    const selectedGoldRate: any = action.payload
    const response: ResponseModel = yield call(service.singleActivateStockRate, selectedGoldRate)
    yield put({type: actionTypes.SINGLE_ACTIVATE_STOCK_RATE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_STOCK_RATE_FINISH})
  }
}

function* singleDeactivateStock(action: ActionModel) {
  try {
    const selectedStockRate: any = action.payload
    const response: ResponseModel = yield call(service.singleDeactivateStockRate, selectedStockRate)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_FINISH})
  }
}

function* deactivateStockRate(action: ActionModel) {
  try {
    const selectedStockRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateStockRate, selectedStockRate)
    yield put({type: actionTypes.DEACTIVATE_STOCK_RATE_SUCCESS, payload: response?.data})
    yield delay(100)
    yield put({type: actionTypes.DEACTIVATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_STOCK_RATE_FINISH})
  }
}

//Import Stock Rate File
function* importStockRateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importStockRate, body)
    yield put(actions.importStockRateSuccess(response.data?.data))
    yield put(actions.importStockRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importStockRateFinish())
  }
}

//Update Stock Rate
function* updateStockRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(service.updateStockRate, body, action.payload?.id)
    yield put({type: actionTypes.UPDATE_STOCK_RATE_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_STOCK_RATE_FINISH})
  }
}

function* deleteStockRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteStockRate, body)
    yield put({type: actionTypes.DELETE_STOCK_RATE_SUCCESS, payload: response.data})
    yield delay(0.1)
    yield put({type: actionTypes.DELETE_STOCK_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_STOCK_RATE_FINISH})
  }
}
function* exportTemplateFileSaga() {
  try {
    const response: ResponseModel = yield call(service.exportTemplateFile)
    const responseData: any = response?.data
    yield put(actions.exportTemplateFileSuccess(responseData))
    yield put(actions.exportTemplateFileFinish())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportTemplateFileFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_STOCK_RATE_START, getStockRateSaga)
  yield takeLatest(actionTypes.GET_STOCK_RATE_FILE_START, getStockRateFileSaga)
  yield takeLatest(actionTypes.CREATE_STOCK_RATE_START, createStockRateSaga)
  yield takeLatest(actionTypes.ACTIVATE_STOCK_RATE_REQUEST, activateStockRates)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_STOCK_RATE_REQUEST, singleActivateStockRate)
  yield takeLatest(actionTypes.DEACTIVATE_STOCK_RATE_REQUEST, deactivateStockRate)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_REQUEST, singleDeactivateStock)
  yield takeLatest(actionTypes.UPDATE_STOCK_RATE_START, updateStockRateSaga)
  yield takeLatest(actionTypes.DELETE_STOCK_RATE_START, deleteStockRateSaga)
  yield takeLatest(actionTypes.IMPORT_STOCK_RATE_START, importStockRateSaga)
  yield takeLatest(actionTypes.EXPORT_SHARES_TEMPLATE_FILE_START, exportTemplateFileSaga)
}
