import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import * as place from 'src/app/modules/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import * as agency from '../index'

import Modal from 'rsuite/Modal'
import {OptionModel, PlaceModel} from '../Model'

import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import moment from 'moment'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import MapView from '../../../../common/components/Map/MapView'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddAgency = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [provinceValue, setProvinceValue] = useState('')
  const [agencyCategoryValue, setAgencyCategoryValue] = useState('')
  const [valleyProvinceOptions, setValleyProvinceOptions] = useState([])
  const [valleyDistrictOptions, setValleyDistrictOptions] = useState([])
  const [location, setLocation] = useState({lat: '', lng: ''})
  const {category, agencyExtendedHourStatus, loading, success} = useSelector(
    (state: any) => state.agency
  )

  const {district, province} = useSelector((state: any) => state.place)

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  // Check backend Setting
  const agencyVisibility = checkBackendSetting('showAgencyInWebsite')
  const showIframe = checkBackendSetting('showAtmAgencyLocationInIframe')
  const agencyExtendedHourRequired = checkBackendSetting('branchExtendedHourRequired')
  const agencyStatusRequired = checkBackendSetting('branchStatusRequired')
  const showQOS = checkBackendSetting('showQos')
  const showCashCounterStatus = checkBackendSetting('showCounterStatus')
  const agencyExtendFromDes = checkBackendSetting('branchExtendFrom')

  const FORM_VALIDATION = Yup.object().shape({
    // agencyExtendedId: Yup.string().when('agencyExtendedHourRequired', {
    //   is: (value: any) => !value && agencyExtendedHourRequired,
    //   then: Yup.string().required('Agency extended hour is required'),
    //   otherwise: Yup.string(),
    // }),
    title: Yup.string().required('Agency title is required'),
    code: Yup.string()
      .required('Agency Code is required')
      .matches(/^[A-Za-z0-9\s]*$/, 'Must not contain symbol'),
    email: Yup.string()
      .email('Invalid Email. Eg: example@xyz.com')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please provide valid email')
      .nullable(),
    telephone: Yup.string()
      .matches(/^[0-9#$%^&*()@!-/., ]*$/, 'Must contain only numbers')
      .nullable(),
    fax: Yup.string()
      .matches(/^[0-9#$%^&*()@!-/., ]*$/, 'Must contain only numbers')
      .nullable(),
    status: Yup.string().when('agencyStatusRequired', {
      is: (value: any) => !value && agencyStatusRequired,
      then: Yup.string().required('Brach Status is required'),
      otherwise: Yup.string(),
    }),
  })

  const persistSettings = JSON.parse(localStorage.getItem('persist:settings') as string)
  const backendData = JSON.parse(persistSettings.backendData)
  const agencyData = backendData['Branch Settings']?.find(
    (item: {[key: string]: string | []}) => item.name === 'branchExtendFrom'
  )

  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      setLocation({lat: editSelectedData?.latitude, lng: editSelectedData?.longitude})
    }
  }, [editSelectedData])

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(agency.actions.getAgencyCategory())
    dispatch(agency.actions.getExtendedHour())
    dispatch(place.action.getProvince())
  }, [])

  useEffect(() => {
    if (provinceValue) {
      dispatch(place.action.getDistrict(provinceValue))
    }
  }, [provinceValue])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const categoryOptions = category?.map((items: OptionModel) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const extendedHours = agencyExtendedHourStatus?.map((items: OptionModel) => ({
    label: items.display_name,
    value: items.id,
    systemName: items.system_name,
  }))

  const districtOptions = district?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  const provinceOptions = province?.map((items: PlaceModel) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (agencyCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
      const provinceOptions = province
        ?.filter((d: any) => d.title === 'Bagmati Pradesh')
        .map((items: PlaceModel) => ({
          label: items.title,
          value: items.id,
        }))
      setValleyProvinceOptions(provinceOptions)
    } else {
      const provinceOptions = province?.map((items: PlaceModel) => ({
        label: items.title,
        value: items.id,
      }))
      setValleyProvinceOptions(provinceOptions)
    }
  }, [agencyCategoryValue])

  useEffect(() => {
    if (!isEmpty(agencyCategoryValue) && !isEmpty(provinceValue)) {
      if (agencyCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId === 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      } else {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId !== 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      }
    }
  }, [agencyCategoryValue, provinceValue, district])

  useEffect(() => {
    if (success) {
      dispatch(agency?.actions?.getAgencyData(params))
      isEmpty(editSelectedData)
        ? toast.success('Agency added successfully')
        : toast.success('Agency edited successfully')
      dispatch(agency?.actions?.resetAgency())
      handleClose()
    }
  }, [success])
  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
      setAgencyCategoryValue(editSelectedData?.agency_category?.id)
    }
  })

  const hideMultiLanguage = checkMultiLanguage() // check multi language and hide np filed
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>{actionType} Agency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                agencyCategoryId: '',
                code: '',
                title: '',
                provinceId: '',
                status: '',
                agencyExtendedId: '',
                latitude: '',
                longitude: '',
                upcomingAgencyStatus: false,
                cashCounterStatus: false,
                hide_in_website: false,
                openingTime: null,
                closingTime: null,
                qos: false,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  longitude: values?.longitude ? values?.longitude.toString() : '',
                  latitude: values?.latitude ? values?.latitude.toString() : '',
                  status: values?.status === 'Active' ? true : false,
                  openingTime: values?.openingTime
                    ? moment(values?.openingTime).format('HH:mm')
                    : null,
                  closingTime: values?.closingTime
                    ? moment(values?.closingTime).format('HH:mm')
                    : null,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(agency.actions.updateAgency(formData, editSelectedData?.id))
                } else {
                  dispatch(agency.actions.addAgency(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'address',
                      'address_np',
                      'closingTime',
                      'code',
                      'description',
                      'description_np',
                      'email',
                      'fax',
                      'id',
                      'qos',
                      'openingTime',
                      'telephone',
                      'title',
                      'title_np',
                      'latitude',
                      'longitude',
                      'iframe',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('agencyCategoryId', editSelectedData?.agency_category?.id, false)
                    setFieldValue('provinceId', editSelectedData?.province?.id, false)
                    setFieldValue('districtId', editSelectedData?.district?.id, false)
                    setFieldValue('agencyExtendedId', editSelectedData?.extended_hour?.id, false)
                    setFieldValue('upcomingAgencyStatus', editSelectedData?.upcoming_agency, false)
                    setFieldValue('cashCounterStatus', editSelectedData?.cash_counter, false)
                    setFieldValue('qos', editSelectedData?.qos, false)

                    setFieldValue('hide_in_website', editSelectedData?.hide_in_website, false)
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }

                  if (actionType === 'Add') {
                    setFieldValue(
                      'agencyExtendedId',
                      extendedHours
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Category'
                            name='agencyCategoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Agency Code ID'
                            label='Agency Code ID'
                            name='code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <>
                          {!isEmpty(values?.agencyCategoryId)
                            ? setAgencyCategoryValue(values?.agencyCategoryId)
                            : ''}
                        </>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder={`Agency Title ${hideMultiLanguage ? '' : '(EN)'} `}
                            label={`Agency Title ${hideMultiLanguage ? '' : '(EN)'} `}
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        {hideMultiLanguage ? null : (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Agency Title (NP)'
                              label='Agency Title (NP)'
                              name='title_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select province'
                            label='Province'
                            name='provinceId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(agencyCategoryValue)
                                ? valleyProvinceOptions
                                : provinceOptions
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <>
                          {!isEmpty(values?.provinceId) ? setProvinceValue(values?.provinceId) : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select district'
                            label='District'
                            name='districtId'
                            disabled={
                              !isEmpty(editSelectedData) || values?.provinceId ? false : true
                            }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(agencyCategoryValue) && !isEmpty(valleyProvinceOptions)
                                ? valleyDistrictOptions
                                : districtOptions
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Agency Address'
                            label={`Agency Address ${hideMultiLanguage ? '' : '(EN)'}`}
                            name='address'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {hideMultiLanguage ? null : (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter Agency Address (NP)'
                              label='Agency Address (NP)'
                              name='address_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}

                        <MapView
                          latName='latitude'
                          lngName='longitude'
                          location={{
                            lat: values.latitude,
                            lng: values.longitude,
                          }}
                          setFieldValue={setFieldValue}
                        />

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Longitude'
                            label='Longitude'
                            name='longitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Latitude'
                            label='Latitude'
                            name='latitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Telephone'
                            label='Telephone'
                            name='telephone'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {showIframe && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Enter Iframe'
                              label='Iframe'
                              name='iframe'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Fax Number'
                            label='Fax'
                            name='fax'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter E-mail'
                            label='E-mail'
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Extended Hours'
                            name='agencyExtendedId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={extendedHours}
                            values={values}
                            setFieldValue={setFieldValue}
                            required={agencyExtendedHourRequired ? true : false}
                          />
                        </div>

                        {values?.agencyExtendedId === '9eec06ad-7d7e-4174-9bc8-fda58213a8f5' ? (
                          <>
                            {agencyExtendFromDes ? (
                              <>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTinyMce
                                    containerClassName='col-md-12'
                                    label='Agency Description (EN)'
                                    name='description'
                                    initialValue={
                                      !isEmpty(editSelectedData)
                                        ? editSelectedData?.description
                                        : ''
                                    }
                                    handleChange={handleChange}
                                  />
                                </div>
                                <div className='col-md-6 col-xs-12'>
                                  <FormTinyMce
                                    containerClassName='col-md-12'
                                    label='Agency Description (NP)'
                                    name='description_np'
                                    initialValue={
                                      !isEmpty(editSelectedData)
                                        ? editSelectedData?.description_np
                                        : ''
                                    }
                                    handleChange={handleChange}
                                  />
                                </div>
                              </>
                            ) : null}
                            <div className='col-md-3 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                dateFormat='hh:mm aa'
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                name='openingTime'
                                label='Opening Time'
                                placeholderText='--:-- --'
                                setFieldValue={setFieldValue}
                                value={values.openingTime || false}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                              />
                            </div>
                            <div className='col-md-3 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                dateFormat='hh:mm aa'
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                name='closingTime'
                                label='Closing Time'
                                placeholderText='--:-- --'
                                setFieldValue={setFieldValue}
                                value={values.closingTime || false}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                              />
                            </div>
                          </>
                        ) : null}

                        <div className='col-md-4 col-xs-12'>
                          <label
                            className={`form-label fw-bolder text-dark fs-6 ${
                              agencyStatusRequired && 'required'
                            } `}
                          >
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        {agencyVisibility && (
                          <div className='col-lg-3'>
                            <label htmlFor=''></label>
                            <FormCheckbox
                              labelClassName='col-md-12'
                              containerClassName='col-md-12 col-lg-12'
                              name='hide_in_website'
                              touched={touched}
                              errors={errors}
                              onChange={handleChange}
                              checkBoxText='Hide in website?'
                              checked={values.hide_in_website}
                            />
                          </div>
                        )}
                        <div className='col-md-3 col-sm-12 col-xs-12'>
                          <label htmlFor=''></label>
                          <div className='row '>
                            {showQOS && (
                              <FormCheckbox
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                name='qos'
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                checkBoxText='Cash Deposit Machine?'
                                checked={values.qos}
                              />
                            )}

                            <FormCheckbox
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              name='upcomingAgencyStatus'
                              touched={touched}
                              errors={errors}
                              onChange={handleChange}
                              checkBoxText='Upcoming Agency'
                              checked={values.upcomingAgencyStatus}
                            />

                            {showCashCounterStatus && (
                              <FormCheckbox
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                name='cashCounterStatus'
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                checkBoxText='Cash Counter Status'
                                checked={values.cashCounterStatus}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAgency
