import {FieldArray, Form, Formik, useFormikContext} from 'formik'
import {cloneDeep, groupBy, isEmpty, mapValues} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
//Manual import
import moment from 'moment'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as forexRateCategoryRedux from '../../../forexRateCategory/redux/index'
import * as forexRateAdd from '../index'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddForexRates = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, editSuccess} = useSelector((state: any) => state.forexRate)

  const forexCategory = useSelector((state: any) => state.forexRateCategory)

  useEffect(() => {
    dispatch(forexRateCategoryRedux?.actions.getForexRateCategory(params))
  }, [params])

  useEffect(() => {
    if (editSuccess) {
      dispatch(forexRateAdd?.actions.getForexRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Forex Rate added successfully')
        : toast.success('Forex Rate edited successfully')
      handleClose()
      dispatch(forexRateAdd?.actions.updateForexRateReset())
    }
  }, [editSuccess])

  const AddEditForm = () => {
    // Grab values and submitForm from context
    const {resetForm, setFieldValue} = useFormikContext()
    useEffect(() => {
      if (!isEmpty(editSelectedData)) {
        const fields = ['forexRateCategories']
        fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
      } else {
        resetForm({
          values: {
            forexRateCategories: [
              {
                ...categoryData,
              },
            ],
          },
        })
      }
    }, [editSelectedData])
    return null
  }

  //To ignore the dot in name field
  const formatCategoryData = forexCategory?.data?.forexRateCategory?.map((obj: any) => ({
    ...obj,
    name: obj?.name?.replace(/[.,]/g, ''),
  }))

  const categoryData = mapValues(groupBy(formatCategoryData, 'name'), (obj2: any) =>
    obj2.map((item: any) => ({
      ...item,
      unit: 0,
      cash_buy: 0,
      non_cash_buy: 0,
      sell: 0,
      feature_order: 0,
    }))
  )
  const today = new Date()

  const showForexDateTime = checkBackendSetting(`showForexDateTime`)

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Forex Rates </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                date: null,
                time: null,
                forexRateCategories: [
                  {
                    ...categoryData,
                  },
                ],
              }}
              onSubmit={(values: any) => {
                let formData: any = []

                let forexRateData = cloneDeep(values?.forexRateCategories[0] || 0)
                Object.keys(forexRateData).map((key, index) => {
                  formData.push(forexRateData[key][0] || 0)
                })

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    forexRateAdd?.actions.updateForexRate(
                      {
                        date: moment(values?.date).format('YYYY-MM-DD'),
                        time: moment(values?.date)
                          .set({
                            hour: moment(values?.time).hour(),
                            minute: moment(values?.time).minute(),
                            second: moment(values?.time).second(),
                          })
                          .format('HH:mm:ss'),
                        forexRateCategories: formData,
                      },
                      formData[0].forexRateId
                    )
                  )
                } else {
                  dispatch(
                    forexRateAdd?.actions.CreateForexRate({
                      date: moment(values?.date).format('YYYY-MM-DD'),
                      time: moment(values?.date)
                        .set({
                          hour: moment(values?.time).hour(),
                          minute: moment(values?.time).minute(),
                          second: moment(values?.time).second(),
                        })
                        .format('HH:mm:ss'),
                      forexRateCategories: formData,
                    })
                  )
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleSubmit,
                errors,
                handleBlur,
                setFieldValue,
                values,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    setFieldValue('date', moment(editSelectedData?.date).toDate(), false)
                    setFieldValue('time', moment(`${editSelectedData?.time}`).toDate(), false)
                  }
                }, [])

                return (
                  <Form>
                    <div>
                      {showForexDateTime && (
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              label='Date'
                              name='date'
                              placeholderText='Please select a  Date'
                              setFieldValue={setFieldValue}
                              value={values?.date ? values?.date : moment(today).toDate()}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                              data-cy='publish-date-drop-down'
                              maxDate={new Date()}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              dateFormat='hh:mm aa'
                              showTimeSelect={true}
                              showTimeSelectOnly={true}
                              name='time'
                              label='Time'
                              placeholderText='--:-- --'
                              setFieldValue={setFieldValue}
                              value={values?.time ? values?.time : moment(today).toDate()}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                              data-cy='publish-time-drop-down'
                            />
                          </div>
                        </div>
                      )}
                      <table className='table'>
                        <thead className='thead-dark'>
                          <tr>
                            <th scope='col'>Currency</th>
                            <th scope='col'>Unit</th>
                            <th scope='col'>Cash Buy</th>
                            <th scope='col'>Non Cash Buy</th>
                            <th scope='col'>Sell</th>
                            <th scope='col'>Feature Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='forexRateCategories'
                            render={(arrayHelpers) =>
                              values.forexRateCategories.map((item: any) =>
                                Object.keys(item).map((key, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{key}</td>
                                      <td>
                                        <div className='form-group'>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].unit`}
                                            defaultValue={0}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.unit || 0
                                            }
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].cash_buy`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.cash_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].non_cash_buy`}
                                            value={
                                              values?.forexRateCategories[0][key][0]
                                                ?.non_cash_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].sell`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.sell || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].feature_order`}
                                            value={
                                              values?.forexRateCategories[0][key][0]
                                                ?.feature_order || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              )
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                    <AddEditForm />
                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddForexRates
