import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getLoanSubCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getLoanSubCategory, params)
    yield put(actions.getLoanSubCategorySuccess(response?.data?.data))
    yield put(actions.getLoanSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getLoanSubCategoryFinish())
  }
}

function* addLoanSubCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addLoanSubCategory, body)
    yield put(actions.addLoanSubCategorySuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addLoanSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addLoanSubCategoryFinish())
  }
}

function* updateLoanSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateLoanSubCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateLoanSubCategorySuccess(response.data?.data))
    yield put(actions.updateLoanSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateLoanSubCategoryFinish())
  }
}

function* deleteLoanSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteLoanSubCategory, body)
    yield put(actions.deleteLoanSubCategorySuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteLoanSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteLoanSubCategoryFinish())
  }
}

function* singleActivateLoanSubCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateLoanSubCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_FINISH})
  }
}

function* singleDeactivateLoanSubCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateLoanSubCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_FINISH})
  }
}

function* sortLoanSubCategory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortLoanSubCategory, body)

    yield put({
      type: actionTypes.SORT_LOAN_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_LOAN_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_LOAN_SUB_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_LOAN_SUB_CATEGORY_START, getLoanSubCategorySaga)
  yield takeLatest(actionTypes.ADD_LOAN_SUB_CATEGORY_START, addLoanSubCategorySaga)
  yield takeLatest(actionTypes.UPDATE_LOAN_SUB_CATEGORY_START, updateLoanSubCategorySaga)
  yield takeLatest(actionTypes.DELETE_LOAN_SUB_CATEGORY_START, deleteLoanSubCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    singleActivateLoanSubCategory
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    singleDeactivateLoanSubCategory
  )
  yield takeLatest(actionTypes.SORT_LOAN_SUB_CATEGORY_START, sortLoanSubCategory)
}
