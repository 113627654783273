import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
// branch
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import * as galleryRedux from 'src/app/modules/cms/components/gallery/redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import moment from 'moment'
import {GalleryModel} from 'src/app/modules/cms/components/gallery/Model'
import {FormOptionModal, StateParamsModel} from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {BannerModel} from '../../cms/components/banner/Model'
import * as events from '../index'
import {convertToSlug} from 'src/cms/helpers/helperFunction'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required').nullable(),
  published_date: Yup.string().required('Published date is required').nullable(),
  start_date: Yup.string().required('Start date is required').nullable(),
  end_date: Yup.string().required('End date is required').nullable(),
  status: Yup.string().required('Status is required'),
  is_featured: Yup.string().required('Featured Events is required'),
})

const AddEventModal = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const [attachedFile, setAttachedFile] = useState('')
  const [imageEng, setImageEng] = useState('')
  const [imageNp, setImageNp] = useState('')
  const [attachfile, setAttachfile] = useState('')
  const [applicationName, setApplicationName] = useState('')

  const {bannerOption, albumOption, loading, success} = useSelector((state: any) => state.events)
  const {
    data: {banner},
  } = useSelector((state: any) => state.banner)

  const {
    data: {album},
  } = useSelector((state: any) => state.gallery)

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(bannerRedux.actions.getAllBanner())
    dispatch(galleryRedux.actions.getAllGallery())
    dispatch(events.actions.getBannerOption())
    dispatch(events.actions.getAlbumOption())
  }, [])
  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setApplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const bannerOptions = bannerOption?.map((items: any) => ({
    label: items?.displayName,
    systemName: items?.systemName,
    value: items?.id,
  }))

  const bannerData = banner?.map((items: BannerModel) => ({
    label: items.name,
    value: items.id,
  }))

  const albumShowOptions =
    albumOption?.map((items: any) => ({
      label: items?.displayName,
      systemName: items?.systemName,
      value: items?.id,
    })) || []

  const albumOptions = album?.map((items: GalleryModel) => ({
    label: items.title,
    value: items.id,
  }))

  const featuredEventsOptions = [
    {label: 'Yes', value: 'Active'},
    {label: 'No', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Event added successfully')
        : toast.success('Event edited successfully')
      dispatch(events?.actions.getEvent(params))
      dispatch(events?.actions?.addEventReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                attached_file_title: '',
                attached_file: '',
                slug: '',
                long_description: '',
                short_description: '',
                published_date: null,
                published_time: null,
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
                image: '',
                albumId: '',
                bannerId: '',
                showAlbumId: '',
                showBannerId: '',
                cta_title: '',
                cta_link: '',
                status: '',
                is_featured: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                  published_date: moment(values?.published_date).format('YYYY-MM-DD'),
                  published_time: values?.published_time
                    ? moment(values?.published_time).format('hh:mm A')
                    : null,
                  start_date: values?.start_date
                    ? moment(values?.start_date).format('YYYY-MM-DD')
                    : null,
                  start_time: values?.start_time
                    ? moment(values?.start_time).format('hh:mm A')
                    : null,
                  end_date: values?.end_date ? moment(values?.end_date).format('YYYY-MM-DD') : null,
                  end_time: values?.end_time ? moment(values?.end_time).format('hh:mm A') : null,
                  is_featured: values?.is_featured === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(events.actions.updateEvent(formData, editSelectedData?.id))
                } else {
                  dispatch(events.actions.addEvent(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'attached_file_title',
                      'attached_file',
                      'slug',
                      'long_description',
                      'short_description',
                      'published_date',
                      'published_time',
                      'start_date',
                      'start_time',
                      'end_date',
                      'end_time',
                      'image',
                      'showBannerId',
                      'showAlbumId',
                      'albumId',
                      'bannerId',
                      'cta_title',
                      'cta_link',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'published_date',
                      moment(editSelectedData?.published_date, 'YYYY-MM-DD').toDate(),
                      false
                    )
                    setFieldValue(
                      'start_date',
                      editSelectedData?.start_date
                        ? moment(editSelectedData?.start_date, 'YYYY-MM-DD').toDate()
                        : null,
                      false
                    )

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )

                    setFieldValue(
                      'is_featured',
                      editSelectedData?.is_featured === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                useEffect(() => {
                  if (actionType === 'Add') {
                    setFieldValue(
                      'showBannerId',
                      bannerOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'showAlbumId',
                      albumShowOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                  }
                }, [bannerOption?.length, albumOption?.length])

                const showBannerId = bannerOptions?.find(
                  (item: FormOptionModal) => item.value === values.showBannerId
                )
                const showAlbumId = albumShowOptions?.find(
                  (item: FormOptionModal) => item.value === values.showAlbumId
                )
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter title'
                            label='Title '
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Attached file title'
                            label='Attached file title '
                            name='attached_file_title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Attached File'
                            name='attached_file'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.attached_file}
                            buttonName='Add Attached File'
                          />
                          {!isEmpty(values?.attached_file) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('attached_file', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.attached_file.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.attached_file}`
                                    }
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.title)
                            }
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Short Description'
                            name='short_description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.short_description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Long Description'
                            name='long_description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.long_description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='Published date'
                            name='published_date'
                            placeholderText='Please select a Published date'
                            setFieldValue={setFieldValue}
                            value={values.published_date || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='published_time'
                            label='Published time'
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values?.published_time || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='Start date'
                            name='start_date'
                            placeholderText='Please select a start date'
                            setFieldValue={setFieldValue}
                            value={values.start_date || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='start_time'
                            label='Start time'
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values?.start_time || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='End date'
                            name='end_date'
                            placeholderText='Please select a End date'
                            setFieldValue={setFieldValue}
                            value={values.end_date || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            dateFormat='hh:mm aa'
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            name='end_time'
                            label='End time'
                            placeholderText='--:-- --'
                            setFieldValue={setFieldValue}
                            value={values?.end_time || false}
                            errors={errors}
                            touched={touched}
                            showIcon={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image '
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageEng}
                            value={values?.image}
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageEng('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='modal-thumbnail-remover'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Show Album'
                            name='showAlbumId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={albumShowOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        {showAlbumId?.systemName === 'yes' ? (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select album'
                              label='Album'
                              name='albumId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={albumOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        ) : null}
                        {/* 
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Show Banner'
                            name='showBannerId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={bannerOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        {showBannerId?.systemName === 'yes' ? (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select banner'
                              label='Banner'
                              name='bannerId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={bannerData}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        ) : null} */}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter title'
                            label='Button Title'
                            name='cta_title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter link'
                            label='Button Link '
                            name='cta_link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Featured Events
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {featuredEventsOptions?.map(
                              (featuredOption: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Featured Events'
                                  name='is_featured'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  checkBoxText={featuredOption?.label}
                                  value={featuredOption?.value}
                                  required
                                />
                              )
                            )}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='is_featured'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddEventModal
