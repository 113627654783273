export const actionTypes: any = {
  // get
  GET_ALL_ALGOLIA_SEARCH_START: 'GET_ALL_ALGOLIA_SEARCH_START',
  GET_ALL_ALGOLIA_SEARCH_SUCCESS: 'GET_ALL_ALGOLIA_SEARCH_SUCCESS',
  GET_ALL_ALGOLIA_SEARCH_FINISH: 'GET_ALL_ALGOLIA_SEARCH_FINISH',

  // extended hours
  GET_ALGOLIA_SEARCH_START: 'GET_ALGOLIA_SEARCH_START',
  GET_ALGOLIA_SEARCH_SUCCESS: 'GET_ALGOLIA_SEARCH_SUCCESS',
  GET_ALGOLIA_SEARCH_FINISH: 'GET_ALGOLIA_SEARCH_FINISH',

  // add branch
  ADD_ALGOLIA_SEARCH_START: 'ADD_ALGOLIA_SEARCH_START',
  ADD_ALGOLIA_SEARCH_SUCCESS: 'ADD_ALGOLIA_SEARCH_SUCCESS',
  ADD_ALGOLIA_SEARCH_FINISH: 'ADD_ALGOLIA_SEARCH_FINISH',
  RESET_SEARCH_TAG: 'RESET_SEARCH_TAG',

  // update SEARCH_TAG
  UPDATE_ALGOLIA_SEARCH_START: 'UPDATE_ALGOLIA_SEARCH_START',
  UPDATE_ALGOLIA_SEARCH_SUCCESS: 'UPDATE_ALGOLIA_SEARCH_SUCCESS',
  UPDATE_ALGOLIA_SEARCH_FINISH: 'UPDATE_ALGOLIA_SEARCH_FINISH',

  // delete SEARCH_TAG
  DELETE_ALGOLIA_SEARCH_START: 'DELETE_ALGOLIA_SEARCH_START',
  DELETE_ALGOLIA_SEARCH_SUCCESS: 'DELETE_ALGOLIA_SEARCH_SUCCESS',
  DELETE_ALGOLIA_SEARCH_FINISH: 'DELETE_ALGOLIA_SEARCH_FINISH',

  // Enable SEARCH_TAG
  ENABLE_ALGOLIA_SEARCH_REQUEST: 'ENABLE_ALGOLIA_SEARCH_REQUEST',
  ENABLE_ALGOLIA_SEARCH_SUCCESS: 'ENABLE_ALGOLIA_SEARCH_SUCCESS',
  ENABLE_ALGOLIA_SEARCH_FINISH: 'ENABLE_ALGOLIA_SEARCH_FINISH',

  // Disable SEARCH_TAG
  DISABLE_ALGOLIA_SEARCH_REQUEST: 'DISABLE_ALGOLIA_SEARCH_REQUEST',
  DISABLE_ALGOLIA_SEARCH_SUCCESS: 'DISABLE_ALGOLIA_SEARCH_SUCCESS',
  DISABLE_ALGOLIA_SEARCH_FINISH: 'DISABLE_ALGOLIA_SEARCH_FINISH',

  // Enable SEARCH_TAG
  SINGLE_ENABLE_ALGOLIA_SEARCH_REQUEST: 'SINGLE_ENABLE_ALGOLIA_SEARCH_REQUEST',
  SINGLE_ENABLE_ALGOLIA_SEARCH_SUCCESS: 'SINGLE_ENABLE_ALGOLIA_SEARCH_SUCCESS',
  SINGLE_ENABLE_ALGOLIA_SEARCH_FINISH: 'SINGLE_ENABLE_ALGOLIA_SEARCH_FINISH',

  // Disable SEARCH_TAG
  SINGLE_DISABLE_ALGOLIA_SEARCH_REQUEST: 'SINGLE_DISABLE_ALGOLIA_SEARCH_REQUEST',
  SINGLE_DISABLE_ALGOLIA_SEARCH_SUCCESS: 'SINGLE_DISABLE_ALGOLIA_SEARCH_SUCCESS',
  SINGLE_DISABLE_ALGOLIA_SEARCH_FINISH: 'SINGLE_DISABLE_ALGOLIA_SEARCH_FINISH',

  // sort
  SORT_ALGOLIA_SEARCH_START: 'SORT_ALGOLIA_SEARCH_START',
  SORT_ALGOLIA_SEARCH_SUCCESS: 'SORT_ALGOLIA_SEARCH_SUCCESS',
  SORT_ALGOLIA_SEARCH_FINISH: 'SORT_ALGOLIA_SEARCH_FINISH',
  SORT_ALGOLIA_SEARCH_RESET: 'SORT_ALGOLIA_SEARCH_RESET',
}
