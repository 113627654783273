import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {SuccessStoryResponseModel} from '../Model'

function* getSuccessStoryDataSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSuccessStoryData, params)
    yield put(actions.getSuccessStoryDataSuccess(response?.data?.data))
    yield put(actions.getSuccessStoryDataFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSuccessStoryDataFinish())
  }
}

function* getSuccessStoryFileTypeSaga() {
  try {
    const response: SuccessStoryResponseModel = yield call(service.getSuccessStoryFileType)
    yield put(actions.getSuccessStoryFileTypeSuccess(response?.data?.data?.successstoryFileOption))
    yield put(actions.getSuccessStoryFileTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSuccessStoryFileTypeFinish())
  }
}

function* getSuccessStoryListSaga() {
  try {
    const response: ResponseModel = yield call(service.getSuccessStoryList)
    yield put(actions.getSuccessStoryListSuccess(response?.data?.data))
    yield put(actions.getSuccessStoryListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSuccessStoryListFinish())
  }
}

function* addSuccessStoryItemSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addSuccessStoryItem, body)
    yield put(actions.addSuccessStoryItemSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addSuccessStoryItemFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addSuccessStoryItemFinish())
  }
}

function* updateScrItemSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateSuccessStoryItem,
      body,
      action.payload?.id
    )
    yield put(actions.updateSuccessStoryItemSuccess(response.data?.data))
    yield put(actions.updateSuccessStoryItemFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateSuccessStoryItemFinish())
  }
}

function* deleteSuccessStoryItemSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBulkSuccessStory, body)
    yield put(actions.deleteSuccessStoryItemSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteSuccessStoryItemFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteSuccessStoryItemFinish())
  }
}

function* activateSuccessStorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateSuccessStory, selectedUsers)
    yield put(actions.activateSuccessStorySuccess(response?.data))
    yield delay(100)
    yield put(actions.activateSuccessStoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.activateSuccessStoryFinish())
  }
}

function* deactivateSuccessStorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateSuccessStory, selectedUsers)
    yield put(actions.deactivateSuccessStorySuccess(response?.data))
    yield delay(100)
    yield put(actions.deactivateSuccessStoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deactivateSuccessStoryFinish())
  }
}

function* singleActivateSuccessStorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleActivateSuccessStory, selectedUsers)
    yield put(actions.singleActivateSuccessStorySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleActivateSuccessStoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleActivateSuccessStoryFinish())
  }
}

function* singleDeactivateSuccessStorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDeactivateSuccessStory, selectedUsers)
    yield put(actions.singleDeactivateSuccessStorySuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDeactivateSuccessStoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDeactivateSuccessStoryFinish())
  }
}

function* sortSuccessStory(action: any) {
  try {
    const data: any = action.payload?.data
    const response: ResponseModel = yield call(service.sortCategories, data)

    yield put({
      type: actionTypes.SORT_SUCCESS_STORY_SUCCESS,
      payload: response?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_SUCCESS_STORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_SUCCESS_STORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SUCCESS_STORY_DATA_START, getSuccessStoryDataSaga)
  yield takeLatest(actionTypes.GET_SUCCESS_STORY_LIST_START, getSuccessStoryListSaga)
  yield takeLatest(actionTypes.GET_SUCCESS_STORY_FILE_TYPE_START, getSuccessStoryFileTypeSaga)
  yield takeLatest(actionTypes.ADD_SUCCESS_STORY_ITEM_START, addSuccessStoryItemSaga)
  yield takeLatest(actionTypes.UPDATE_SUCCESS_STORY_ITEM_START, updateScrItemSaga)
  yield takeLatest(actionTypes.DELETE_BULK_SUCCESS_STORY_START, deleteSuccessStoryItemSaga)
  yield takeLatest(actionTypes.ACTIVATE_SUCCESS_STORY_START, activateSuccessStorySaga)
  yield takeLatest(actionTypes.DEACTIVATE_SUCCESS_STORY_START, deactivateSuccessStorySaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_START, singleActivateSuccessStorySaga)
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_START,
    singleDeactivateSuccessStorySaga
  )
  yield takeLatest(actionTypes.SORT_SUCCESS_STORY_START, sortSuccessStory)
}
