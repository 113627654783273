import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {BankDetailModel} from '../Model/BankDetailModel'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortBankDetailActionModel, SortBankDetailModel} from '../Model'

function* getBankDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBankDetail, params)
    yield put(actions.getBankDetailSuccess(response?.data?.data))
    yield put(actions.getBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBankDetailFinish())
  }
}

function* addBankDetailSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBankDetail, body)
    yield put(actions.addBankDetailSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBankDetailFinish())
  }
}

function* enableBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableBankDetail, selectedUsers)
    yield put(actions.enableBankDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableBankDetailFinish())
  }
}

function* disableBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableBankDetail, selectedUsers)
    yield put(actions.disableBankDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableBankDetailFinish())
  }
}

function* singleEnableBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableBankDetail, selectedUsers)
    yield put(actions.singleEnableBankDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableBankDetailFinish())
  }
}

function* singleDisableBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableBankDetail, selectedUsers)
    yield put(actions.singleDisableBankDetailSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableBankDetailFinish())
  }
}

function* updateBankDetailSaga(action: ActionModel) {
  try {
    const body: BankDetailModel = action.payload
    const response: ResponseModel = yield call(service.updateBankDetail, body, action.payload?.id)
    yield put(actions.updateBankDetailSuccess(response.data?.data))
    yield put(actions.updateBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBankDetailFinish())
  }
}

function* deleteBankDetailSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBankDetail, body)
    yield put(actions.deleteBankDetailSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.deleteBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBankDetailFinish())
  }
}

function* sortBankDetail(action: SortBankDetailActionModel) {
  try {
    const body: SortBankDetailModel = action.payload
    const response: ResponseModel = yield call(service.sortBankDetail, body)

    yield put({
      type: actionTypes.SORT_BANK_DETAIL_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_BANK_DETAIL_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BANK_DETAIL_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_BANK_DETAIL_START, getBankDetailSaga)
  yield takeLatest(actionTypes.ADD_BANK_DETAIL_START, addBankDetailSaga)
  yield takeLatest(actionTypes.UPDATE_BANK_DETAIL_START, updateBankDetailSaga)
  yield takeLatest(actionTypes.DELETE_BANK_DETAIL_START, deleteBankDetailSaga)
  yield takeLatest(actionTypes.ENABLE_BANK_DETAIL_REQUEST, enableBankDetailSaga)
  yield takeLatest(actionTypes.DISABLE_BANK_DETAIL_REQUEST, disableBankDetailSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_BANK_DETAIL_REQUEST, singleEnableBankDetailSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_BANK_DETAIL_REQUEST, singleDisableBankDetailSaga)
  yield takeLatest(actionTypes.SORT_BANK_DETAIL_START, sortBankDetail)
}
