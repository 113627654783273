import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {PayoutLocationModel} from '../Model/PayoutLocationModel'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortPayoutLocationActionModel, SortPayoutLocationModel} from '../Model'

function* getPayoutLocationSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getPayoutLocation, params)
    yield put(actions.getPayoutLocationSuccess(response?.data?.data))
    yield put(actions.getPayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getPayoutLocationFinish())
  }
}

function* addPayoutLocationSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addPayoutLocation, body)
    yield put(actions.addPayoutLocationSuccess(response.data?.data))
    yield delay(100)
    yield put(actions.addPayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addPayoutLocationFinish())
  }
}

function* enablePayoutLocationSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enablePayoutLocation, selectedUsers)
    yield put(actions.enablePayoutLocationSuccess(response?.data))
    yield delay(100)
    yield put(actions.enablePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enablePayoutLocationFinish())
  }
}

function* disablePayoutLocationSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disablePayoutLocation, selectedUsers)
    yield put(actions.disablePayoutLocationSuccess(response?.data))
    yield delay(100)
    yield put(actions.disablePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enablePayoutLocationFinish())
  }
}

function* singleEnablePayoutLocationSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnablePayoutLocation, selectedUsers)
    yield put(actions.singleEnablePayoutLocationSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnablePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnablePayoutLocationFinish())
  }
}

function* singleDisablePayoutLocationSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisablePayoutLocation, selectedUsers)
    yield put(actions.singleDisablePayoutLocationSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisablePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisablePayoutLocationFinish())
  }
}

function* updatePayoutLocationSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updatePayoutLocation,
      body,
      action.payload?.id
    )
    yield put(actions.updatePayoutLocationSuccess(response.data?.data))
    yield put(actions.updatePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updatePayoutLocationFinish())
  }
}

function* deletePayoutLocationSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deletePayoutLocation, body)
    yield put(actions.deletePayoutLocationSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deletePayoutLocationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deletePayoutLocationFinish())
  }
}

function* sortPayoutLocation(action: SortPayoutLocationActionModel) {
  try {
    const body: SortPayoutLocationModel = action.payload
    const response: ResponseModel = yield call(service.sortPayoutLocation, body)

    yield put({
      type: actionTypes.SORT_PAYOUT_LOCATION_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_PAYOUT_LOCATION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_PAYOUT_LOCATION_FINISH})
  }
}

function* importPayoutLocationsSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importPayoutLocation, body)
    yield put(actions.importPayoutLocationsSuccess(response.data?.data))
    yield put(actions.importPayoutLocationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importPayoutLocationsFinish())
  }
}

//Get paylout locations file
function* exportFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    yield delay(500)
    const data: any = action.payload.params
    const response: ResponseModel = yield call(service.exportFile, fileName)
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
  } catch (error: any) {
    yield put(actions.exportFileError())
  }
}

function* exportTemplateFileSaga() {
  try {
    const response: ResponseModel = yield call(service.exportTemplateFile)
    const responseData: any = response?.data
    yield put(actions.exportTemplateFileSuccess(responseData))
    yield put(actions.exportTemplateFileFinish())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportTemplateFileFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PAYOUT_LOCATION_START, getPayoutLocationSaga)
  yield takeLatest(actionTypes.ADD_PAYOUT_LOCATION_START, addPayoutLocationSaga)
  yield takeLatest(actionTypes.UPDATE_PAYOUT_LOCATION_START, updatePayoutLocationSaga)
  yield takeLatest(actionTypes.DELETE_PAYOUT_LOCATION_START, deletePayoutLocationSaga)
  yield takeLatest(actionTypes.ENABLE_PAYOUT_LOCATION_REQUEST, enablePayoutLocationSaga)
  yield takeLatest(actionTypes.DISABLE_PAYOUT_LOCATION_REQUEST, disablePayoutLocationSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_PAYOUT_LOCATION_REQUEST,
    singleEnablePayoutLocationSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_PAYOUT_LOCATION_REQUEST,
    singleDisablePayoutLocationSaga
  )
  yield takeLatest(actionTypes.SORT_PAYOUT_LOCATION_START, sortPayoutLocation)
  yield takeLatest(actionTypes.EXPORT_PAYOUT_LOCATION_TEMPLATE_FILE_START, exportTemplateFileSaga)
  yield takeLatest(actionTypes.EXPORT_PAYOUT_LOCATION_FILE_START, exportFileSaga)
  yield takeLatest(actionTypes.IMPORT_PAYOUT_LOCATION_START, importPayoutLocationsSaga)
}
