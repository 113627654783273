import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import {PageTitle} from 'src/cms/layout/core'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty} from 'lodash'
import * as key from 'src/app/modules/siteSettings/components/keySetting'
import {AnalyticsDashboard} from 'react-analytics-charts'
// Over ten different commonly used charts are available
import {SessionsByDateChart, SessionsGeoChart} from 'react-analytics-charts'
import {Analytics} from './Analytics'
import {Chart} from 'chart.js'
import PieChart from './component.tsx/Pie'
import {Line} from 'react-chartjs-2'
import {DateRangePicker} from 'materialui-daterange-picker'
import moment from 'moment'
import * as dashboard from 'src/app/pages/dashboard/GoogleAnalyticsRedux'
import {IAnalyticDataState} from 'src/app/pages/dashboard/GoogleAnalyticsRedux'
import {Loader} from 'rsuite'

interface Props {
  applicationName: string | null
  gTrackCode: any
}

const DashboardPage = ({applicationName, gTrackCode}: Props) => {
  const [gaStatus, setGaStatus] = useState(false)
  const [callApi, setCallApi] = useState(false)
  const [totalUserData, setTotalUserata] = useState<any>('')
  const [userCountryData, setUserCountryData] = useState<any>('')
  const [userUniqueData, setUserUniqueData] = useState<any>('')

  //permission management
  const {permissions} = useSelector((state: any) => {
    return state?.auth?.rolePermissions ?? []
  })

  const hasViewTotalUsersAccess =
    permissions?.findIndex((d: {name: string}) => d?.name === 'View Total Users') >= 0
  const hasViewUniqueUsersAccess =
    permissions?.findIndex((d: {name: string}) => d?.name === 'View Total Unique Users') >= 0
  const hasViewTotalUsersAroundTheWorldAccess =
    permissions?.findIndex(
      (d: {name: string}) => d?.name === 'View Total Users All Around World'
    ) >= 0

  const dispatch = useDispatch()
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  //primary color for charts
  const primaryColor = settingTypeData?.data?.setting?.filter(
    (d: any) => d?.name === 'primaryColor'
  )[0]?.value

  var curr = new Date()
  curr.setDate(curr.getDate())
  var todayDate = curr.toISOString().substr(0, 10)
  var curr1 = new Date()
  curr1.setDate(curr.getDate() - 7)
  var sevenDayBefore = curr1.toISOString().substr(0, 10)

  const [payload, setPayload] = useState({
    fromDate: sevenDayBefore,
    toDate: todayDate,
  })

  const userDeviceData: any = useSelector((state: any) => state.devices)
  const countryData: any = useSelector((state: any) => state.country)
  const uniqueData: any = useSelector((state: any) => state.unique)

  useEffect(() => {
    if (gaStatus) {
      fetchData1()
    }
  }, [gaStatus])

  const fetchData1 = () => {
    dispatch(dashboard.actions.getUserDetail(payload))
    dispatch(dashboard.actions.getCountryDetail(payload))
    dispatch(dashboard.actions.getUniqueDetail(payload))
  }
  const fetchData = () => {
    dispatch(dashboard.actions.getUserDetail(payload))
    dispatch(dashboard.actions.getCountryDetail(payload))
    dispatch(dashboard.actions.getUniqueDetail(payload))
    toggle()
  }

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Detail Settings']?.map((item: any) => {
          if (item?.name === 'googleAnalytics') {
            setGaStatus(item?.value === '1' ? true : false)
          }
        })
    }
  }, [settingTypeData])
  // For Pie chart
  useEffect(() => {
    const UserData = [
      {
        id: 1,
        device: 'Mobile',
        userGain: userDeviceData?.data.totalmobileusers,
      },
      {
        id: 2,
        device: 'Desktop',
        userGain: userDeviceData?.data.totaldesktopusers,
      },
      {
        id: 3,
        device: 'Tablet',
        userGain: userDeviceData?.data.totaltabletusers,
      },
    ]
    setUserData({
      labels: UserData?.map((data) => data.device),
      datasets: [
        {
          label: 'Users Gained',
          data: UserData?.map((data) => data.userGain),
          backgroundColor: ['#0083ba', primaryColor, '#d8f0fe'],
          borderWidth: 2,
        },
      ],
    })
    // setChartData(UserData)
  }, [userDeviceData?.data])

  const [userData, setUserData] = useState<any>()

  // For line Chart
  const filterDay =
    uniqueData && uniqueData?.data?.date?.map((items: any) => moment(items).format('dddd'))
  useEffect(() => {
    setData({
      labels: uniqueData?.data?.time?.length === 0 ? filterDay : uniqueData?.data?.time,

      datasets: [
        {
          label: 'No. of pageViews',
          data: uniqueData?.data?.pageViews,
          backgroundColor: 'transparent',
          borderColor: '#0083ba',
          tension: 0.4,
          fill: true,
          pointStyle: 'rect',
          pointBorderColor: '#0083ba',
          pointBackgroundColor: '#fff',
          showLine: true,
        },
        {
          label: 'No. of Unique Users',
          data: uniqueData?.data?.users,
          borderColor: primaryColor,
          backgroundColor: 'transparent',

          tension: 0.4,
          fill: true,
          pointStyle: 'rect',
          pointBorderColor: '#005b31',
          pointBackgroundColor: '#fff',
          showLine: true,
        },
      ],
    })
  }, [uniqueData?.data])

  const [data, setData] = useState<any>()
  const [open, setOpen] = useState(false)
  // const [dateRange, setDateRange] = useState<any>({})

  const [startDate, setStartDate] = useState<any>(sevenDayBefore)
  const [endDate, setEndDate] = useState<any>(todayDate)

  const toggle = () => setOpen(!open)
  const setDateRange = (dateRange: any) => {
    setStartDate(moment(dateRange.startDate).subtract(10, 'days').calendar())
    setEndDate(moment(dateRange.startDate).subtract(10, 'days').calendar())

    setPayload({
      ...payload,
      fromDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
      toDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
    })
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <span className='d-flex text-gray-800 text-hover-primary justify-content-center fs-2 fw-bolder me-1'>
            <h1> Welcome to {applicationName}</h1>
          </span>

          {/* show or hide analytics based on backend settings */}
          {gaStatus && (
            <div>
              <div>
                {/* <DateRangeFilter /> */}{' '}
                {(hasViewTotalUsersAccess ||
                  hasViewUniqueUsersAccess ||
                  hasViewTotalUsersAroundTheWorldAccess) && (
                  <>
                    <div className='d-flex border border-dark cursor-pointer ' onClick={toggle}>
                      <div className='d-flex ms-5  p-3'>
                        <div>
                          {' '}
                          <h3>{payload.fromDate}</h3>{' '}
                        </div>
                        <span className='mx-4'>-</span>
                        <div>
                          {' '}
                          <h3> {payload.toDate}</h3>{' '}
                        </div>
                      </div>
                    </div>

                    <DateRangePicker
                      open={open}
                      toggle={toggle}
                      onChange={(range: any) => setDateRange(range)}
                    />
                  </>
                )}
                <div className='d-flex align-items-center  justify-content-center mt-2'>
                  {open && (
                    <div
                      className='btn btn-success position-relative top-50'
                      onClick={() => fetchData()}
                    >
                      ok
                    </div>
                  )}
                </div>
              </div>
              {!userDeviceData?.loading ? (
                <>
                  {hasViewTotalUsersAccess && (
                    <div className=' py-4'>
                      <div className='d-flex flex-column '>
                        <div className='me-5'>
                          <h1 className='heading__component__title text-center my-3'>
                            Total Website Users
                          </h1>{' '}
                        </div>
                        <div className='row  '>
                          <div className=' col-lg-3  '>
                            <div className='border border-dark p-5 text-center'>
                              <span className='text-center '>
                                <h2>Total Users</h2>
                              </span>
                              <span>
                                ({moment(payload.fromDate).format('ll')} -{' '}
                                {moment(payload.toDate).format('ll')})
                              </span>

                              <span className='text-center'>
                                <h1>{userDeviceData?.data.totalusers}</h1>
                              </span>
                            </div>
                          </div>
                          <div className=' col-lg-3'>
                            <div className='border border-dark p-5 text-center'>
                              <span className='text-center'>
                                <h2>Mobile Users</h2>
                              </span>
                              <span>
                                ({moment(payload.fromDate).format('ll')} -{' '}
                                {moment(payload.toDate).format('ll')})
                              </span>
                              <span className='text-center'>
                                <h1>{userDeviceData?.data.totalmobileusers}</h1>
                              </span>
                            </div>
                          </div>
                          <div className='col-lg-3 '>
                            <div className='border border-dark p-5 text-center'>
                              <span className='text-center'>
                                <h2>Desktop Users</h2>
                              </span>
                              <span>
                                ({moment(payload.fromDate).format('ll')} -{' '}
                                {moment(payload.toDate).format('ll')})
                              </span>

                              <span className='text-center'>
                                <h1>{userDeviceData?.data.totaldesktopusers}</h1>
                              </span>
                            </div>
                          </div>
                          <div className='col-lg-3'>
                            <div className='border border-dark p-5 text-center'>
                              <span className='text-center'>
                                <h2>Tablet Users</h2>
                              </span>
                              <span>
                                ({moment(payload.fromDate).format('ll')} -{' '}
                                {moment(payload.toDate).format('ll')})
                              </span>
                              <span className='text-center'>
                                <h1>{userDeviceData?.data.totaltabletusers}</h1>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {hasViewUniqueUsersAccess && (
                    <div className='row py-3 my-3'>
                      {' '}
                      <div className='col-lg-6 '>
                        <h1 className='ms-3 text-center heading__component__title'>
                          Active Users by Devices
                        </h1>
                        <div
                          className='border border-dark p-4 '
                          style={{height: '500px', width: '500px'}}
                        >
                          {userData !== undefined && <PieChart chartData={userData} />}
                        </div>
                      </div>
                      <div className='col-lg-6 ' style={{height: '500px'}}>
                        <h1 className='heading__component__title text-center'>Users on Website</h1>
                        {data !== undefined && <Line data={data} height={'250px'} />}
                      </div>
                    </div>
                  )}

                  {hasViewTotalUsersAroundTheWorldAccess && (
                    <div className='mt-5'>
                      <h1 className='mt-5 heading__component__title'>Viewers over the world</h1>
                      <div className='overflow-auto' style={{height: '500px'}}>
                        <table className='table table-bordered '>
                          <thead className=' sticky-top'>
                            <tr>
                              <th scope='col'>SN</th>
                              <th scope='col'>COUNTRY</th>
                              <th scope='col'>VIEWS</th>
                              <th scope='col'>PERCENTAGE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {countryData &&
                              countryData?.data?.viewers_around_world?.map((item: any) => (
                                <tr>
                                  <th scope='row'>1</th>
                                  <td>{item.country_name}</td>
                                  <td>{item.views}</td>
                                  <td>{item.percentage}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className='text-center mt-5'>
                  <Loader size='md' content='Loading...' />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl: any = useIntl()
  const dispatch = useDispatch()
  const [color, setColor] = useState({r: 255, g: 255, b: 255, a: 1})
  const [applicationName, setAplicationName] = useState('')
  const keyData: any = useSelector((state: any) => state.key)

  const gaTrackId = keyData?.data?.keySetting.filter(
    (item: any) => item?.name === 'GA-Tracking-ID'
  )[0]?.key

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const onChange = (color: any) => {
    setColor(color.rgb)
  }
  useEffect(() => {
    dispatch(key.actions.getKey())
  }, [])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setAplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl?.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage applicationName={applicationName} gTrackCode={gaTrackId} />
    </>
  )
}

export {DashboardWrapper}
