import {Col, Row} from 'react-bootstrap'
import {Modal} from 'rsuite'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {GrAttachment} from 'react-icons/gr'

type Props = {
  data: {
    ref_number: string
    customer_name: string
    email: string
    phone: string
    address: string
    product: string
    subject: string
    subject_details: string
    subject_file_path: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Help Desk Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Reference Number</b>
              </Col>
              <Col md={6}>
                <p>{data.ref_number}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Customer Name</b>
              </Col>
              <Col md={6}>
                <p>{data.customer_name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email Address</b>
              </Col>
              <Col md={6}>
                <p>{data.email}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Phone Number</b>
              </Col>
              <Col md={6}>
                <p>{data.phone}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Address</b>
              </Col>
              <Col md={6}>
                <p>{data.address}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Product</b>
              </Col>
              <Col md={6}>
                <p>{data.product}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Subject</b>
              </Col>
              <Col md={6}>
                <p>{data.subject}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Subject Details</b>
              </Col>
              <Col md={6}>
                <p>{data.subject_details}</p>
              </Col>
            </Row>
            {data?.subject_file_path && (
              <Row>
                <Col md={6}>
                  <span>Attachments:</span>
                </Col>
                <Col>
                  <a target='_blank' href={imageBaseUrl + '/' + data?.subject_file_path}>
                    <GrAttachment />
                    View
                  </a>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
