export const actionTypes: any = {
  // product BREADCRUMB OPTION
  GET_CONTENT_BREADCRUMB_OPTION_START: 'GET_CONTENT_BREADCRUMB_OPTION_START',
  GET_CONTENT_BREADCRUMB_OPTION_SUCCESS: 'GET_CONTENT_BREADCRUMB_OPTION_SUCCESS',
  GET_CONTENT_BREADCRUMB_OPTION_FINISH: 'GET_CONTENT_BREADCRUMB_OPTION_FINISH',

  //GET APPLY NOW SECTION CONSTANTS
  GET_SLIDER_SECTION_START: 'GET_SLIDER_SECTION_START',
  GET_SLIDER_SECTION_SUCCESS: 'GET_SLIDER_SECTION_SUCCESS',
  GET_SLIDER_SECTION_FINISH: 'GET_SLIDER_SECTION_FINISH',

  //GET LANDING OPTION CONSTANTS
  GET_LANDING_PAGE_START: 'GET_LANDING_PAGE_START',
  GET_LANDING_PAGE_SUCCESS: 'GET_LANDING_PAGE_SUCCESS',
  GET_LANDING_PAGE_FINISH: 'GET_LANDING_PAGE_FINISH',

  //GET APPLY NOW SECTION CONSTANTS
  GET_APPLY_NOW_SECTION_START: 'GET_APPLY_NOW_SECTION_START',
  GET_APPLY_NOW_SECTION_SUCCESS: 'GET_APPLY_NOW_SECTION_SUCCESS',
  GET_APPLY_NOW_SECTION_FINISH: 'GET_APPLY_NOW_SECTION_FINISH',

  //GET BANNER CONSTANTS
  GET_CONSTANTBANNER_START: 'GET_CONSTANTBANNER_START',
  GET_CONSTANTBANNER_SUCCESS: 'GET_CONSTANTBANNER_SUCCESS',
  GET_CONSTANTBANNER_FINISH: 'GET_CONSTANTBANNER_FINISH',

  //GET COLLAPSIBLE SECTION CONSTANTS
  GET_COLLAPSIBLE_SECTION_START: 'GET_COLLAPSIBLE_SECTION_START',
  GET_COLLAPSIBLE_SECTION_SUCCESS: 'GET_COLLAPSIBLE_SECTION_SUCCESS',
  GET_COLLAPSIBLE_SECTION_FINISH: 'GET_COLLAPSIBLE_SECTION_FINISH',

  //GET FAQ OPTION CONSTANTS
  GET_FAQ_OPTION_START: 'GET_FAQ_OPTION_START',
  GET_FAQ_OPTION_SUCCESS: 'GET_FAQ_OPTION_SUCCESS',
  GET_FAQ_OPTION_FINISH: 'GET_FAQ_OPTION_FINISH',

  //GET Download OPTION CONSTANTS
  GET_DOWNLOAD_OPTION_START: 'GET_DOWNLOAD_OPTION_START',
  GET_DOWNLOAD_OPTION_SUCCESS: 'GET_DOWNLOAD_OPTION_SUCCESS',
  GET_DOWNLOAD_OPTION_FINISH: 'GET_DOWNLOAD_OPTION_FINISH',

  //GET HELP SECTION CONSTANTS
  GET_HELP_SECTION_START: 'GET_HELP_SECTION_START',
  GET_HELP_SECTION_SUCCESS: 'GET_HELP_SECTION_SUCCESS',
  GET_HELP_SECTION_FINISH: 'GET_HELP_SECTION_FINISH',

  //GET LEAD FORM CONSTANTS
  GET_LEAD_FORM_START: 'GET_LEAD_FORM_START',
  GET_LEAD_FORM_SUCCESS: 'GET_LEAD_FORM_SUCCESS',
  GET_LEAD_FORM_FINISH: 'GET_LEAD_FORM_FINISH',

  //GET PAGE HEADER CONSTANTS
  GET_PAGE_HEADER_START: 'GET_PAGE_HEADER_START',
  GET_PAGE_HEADER_SUCCESS: 'GET_PAGE_HEADER_SUCCESS',
  GET_PAGE_HEADER_FINISH: 'GET_PAGE_HEADER_FINISH',

  //GET PRODUCT OPTION CONSTANTS
  GET_PRODUCT_OPTION_START: 'GET_PRODUCT_OPTION_START',
  GET_PRODUCT_OPTION_SUCCESS: 'GET_PRODUCT_OPTION_SUCCESS',
  GET_PRODUCT_OPTION_FINISH: 'GET_PRODUCT_OPTION_FINISH',

  //GET REVIEW AND RATING CONSTANTS
  GET_REVIEW_AND_RATING_START: 'GET_REVIEW_AND_RATING_START',
  GET_REVIEW_AND_RATING_SUCCESS: 'GET_REVIEW_AND_RATING_SUCCESS',
  GET_REVIEW_AND_RATING_FINISH: 'GET_REVIEW_AND_RATING_FINISH',

  //GET REVIEW AND RATING CONSTANTS
  GET_CONTENT_DATA_START: 'GET_CONTENT_DATA_START',
  GET_CONTENT_DATA_SUCCESS: 'GET_CONTENT_DATA_SUCCESS',
  GET_CONTENT_DATA_FINISH: 'GET_CONTENT_DATA_FINISH',

  //GET REVIEW AND RATING CONSTANTS
  GET_CONTENT_DATA_BY_CATEGORY_ID_START: 'GET_CONTENT_DATA_BY_CATEGORY_ID_START',
  GET_CONTENT_DATA_BY_CATEGORY_ID_SUCCESS: 'GET_CONTENT_DATA_BY_CATEGORY_ID_SUCCESS',
  GET_CONTENT_DATA_BY_CATEGORY_ID_FINISH: 'GET_CONTENT_DATA_BY_CATEGORY_ID_FINISH',

  //ADD CONTENT CONSTANTS
  ADD_CONTENT_START: 'ADD_CONTENT_START',
  ADD_CONTENT_SUCCESS: 'ADD_CONTENT_SUCCESS',
  ADD_CONTENT_FINISH: 'ADD_CONTENT_FINISH',
  RESET_CONTENT: 'RESET_CONTENT',

  // update PRODUCT_TAG
  UPDATE_CONTENT_DATA_START: 'UPDATE_CONTENT_DATA_START',
  UPDATE_CONTENT_DATA_SUCCESS: 'UPDATE_CONTENT_DATA_SUCCESS',
  UPDATE_CONTENT_DATA_FINISH: 'UPDATE_CONTENT_DATA_FINISH',

  // delete PRODUCT_TAG
  DELETE_CONTENT_DATA_START: 'DELETE_CONTENT_DATA_START',
  DELETE_CONTENT_DATA_SUCCESS: 'DELETE_CONTENT_DATA_SUCCESS',
  DELETE_CONTENT_DATA_FINISH: 'DELETE_CONTENT_DATA_FINISH',

  // Enable PRODUCT_TAG
  ENABLE_CONTENT_DATA_REQUEST: 'ENABLE_CONTENT_DATA_REQUEST',
  ENABLE_CONTENT_DATA_SUCCESS: 'ENABLE_CONTENT_DATA_SUCCESS',
  ENABLE_CONTENT_DATA_FINISH: 'ENABLE_CONTENT_DATA_FINISH',

  // Disable PRODUCT_TAG
  DISABLE_CONTENT_DATA_REQUEST: 'DISABLE_CONTENT_DATA_REQUEST',
  DISABLE_CONTENT_DATA_SUCCESS: 'DISABLE_CONTENT_DATA_SUCCESS',
  DISABLE_CONTENT_DATA_FINISH: 'DISABLE_CONTENT_DATA_FINISH',

  // Enable PRODUCT_TAG
  SINGLE_ENABLE_CONTENT_DATA_REQUEST: 'SINGLE_ENABLE_CONTENT_DATA_REQUEST',
  SINGLE_ENABLE_CONTENT_DATA_SUCCESS: 'SINGLE_ENABLE_CONTENT_DATA_SUCCESS',
  SINGLE_ENABLE_CONTENT_DATA_FINISH: 'SINGLE_ENABLE_CONTENT_DATA_FINISH',

  // Disable PRODUCT_TAG
  SINGLE_DISABLE_CONTENT_DATA_REQUEST: 'SINGLE_DISABLE_CONTENT_DATA_REQUEST',
  SINGLE_DISABLE_CONTENT_DATA_SUCCESS: 'SINGLE_DISABLE_CONTENT_DATA_SUCCESS',
  SINGLE_DISABLE_CONTENT_DATA_FINISH: 'SINGLE_DISABLE_CONTENT_DATA_FINISH',
}
