import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {CATEGORY_TYPES, COMMON_TYPES} from './constant'
import {
  createCategoryAPI,
  deleteCategoryAPI,
  getAllCategoryAPI,
  singleActivateCategoryAPI,
  singleDeactivateCategoryAPI,
  sortCategoryAPI,
  updateCategoryAPI,
} from './service'

function* getAllCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    const response: ResponseModel = yield call(getAllCategoryAPI, params)
    yield put({
      type: CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.SUCCESS,
      payload: response?.data,
    })
    yield put({
      type: CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}
function* createCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(createCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}
function* updateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(updateCategoryAPI, body, action.payload?.id)
    yield put({
      type: CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}
function* deleteCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(deleteCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(0.1)
    yield put({
      type: CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}
function* singleActivateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(singleActivateCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield {
      type: CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER,
    }
  }
}
function* singleDeactivateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(singleDeactivateCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}
function* activateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(singleActivateCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield {
      type: CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    }
  }
}
function* deactivateCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(singleDeactivateCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.SUCCESS,
    })
    yield delay(100)
    yield put({
      type: CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put({
      type: CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER,
    })
  }
}

function* sortCategory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(sortCategoryAPI, body)
    yield put({
      type: CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.CALL_OVER})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.CALL_OVER})
  }
}

export function* saga() {
  yield takeLatest(CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.REQUEST, getAllCategorySaga)
  yield takeLatest(CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.REQUEST, createCategorySaga)
  yield takeLatest(CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.REQUEST, updateCategorySaga)
  yield takeLatest(CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.REQUEST, deleteCategorySaga)
  yield takeLatest(
    CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST,
    singleActivateCategorySaga
  )
  yield takeLatest(
    CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST,
    singleDeactivateCategorySaga
  )
  yield takeLatest(CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.REQUEST, activateCategorySaga)
  yield takeLatest(
    CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.REQUEST,
    deactivateCategorySaga
  )
  yield takeLatest(CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.REQUEST, sortCategory)
}
