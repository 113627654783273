export const actionTypes: any = {
  // ADD dybnamic component
  ADD_DYNAMIC_COMPONENT_START: 'ADD_DYNAMIC_COMPONENT_START',
  ADD_DYNAMIC_COMPONENT_SUCCESS: 'ADD_DYNAMIC_COMPONENT_SUCCESS',
  ADD_DYNAMIC_COMPONENT_FINISH: 'ADD_DYNAMIC_COMPONENT_FINISH',
  RESET_DYNAMIC_COMPONENT: 'RESET_DYNAMIC_COMPONENT',

  // UPDATE component

  UPDATE_DYNAMIC_COMPONENT_START: 'UPDATE_DYNAMIC_COMPONENT_START',
  UPDATE_DYNAMIC_COMPONENT_SUCCESS: 'UPDATE_DYNAMIC_COMPONENT_SUCCESS',
  UPDATE_DYNAMIC_COMPONENT_FINISH: 'UPDATE_DYNAMIC_COMPONENT_FINISH',

  // Delete component
  DELETE_DYNAMIC_COMPONENT_START: 'DELETE_DYNAMIC_COMPONENT_START',
  DELETE_DYNAMIC_COMPONENT_SUCCESS: 'DELETE_DYNAMIC_COMPONENT_SUCCESS',
  DELETE_DYNAMIC_COMPONENT_FINISH: 'DELETE_DYNAMIC_COMPONENT_FINISH',

  // GET Dynamic component
  GET_DYNAMIC_COMPONENT_START: 'GET_DYNAMIC_COMPONENT_START',
  GET_DYNAMIC_COMPONENT_SUCCESS: 'GET_DYNAMIC_COMPONENT_SUCCESS',
  GET_DYNAMIC_COMPONENT_FINISH: 'GET_DYNAMIC_COMPONENT_FINISH',
}
