export const actionTypes: any = {
  // get
  GET_ALL_MEMBER_TYPE_START: 'GET_ALL_MEMBER_TYPE_START',
  GET_ALL_MEMBER_TYPE_SUCCESS: 'GET_ALL_MEMBER_TYPE_SUCCESS',
  GET_ALL_MEMBER_TYPE_FINISH: 'GET_ALL_MEMBER_TYPE_FINISH',

  // extended hours
  GET_MEMBER_TYPE_START: 'GET_MEMBER_TYPE_START',
  GET_MEMBER_TYPE_SUCCESS: 'GET_MEMBER_TYPE_SUCCESS',
  GET_MEMBER_TYPE_FINISH: 'GET_MEMBER_TYPE_FINISH',

  // add branch
  ADD_MEMBER_TYPE_START: 'ADD_MEMBER_TYPE_START',
  ADD_MEMBER_TYPE_SUCCESS: 'ADD_MEMBER_TYPE_SUCCESS',
  ADD_MEMBER_TYPE_FINISH: 'ADD_MEMBER_TYPE_FINISH',
  RESET_MEMBER_TYPE: 'RESET_MEMBER_TYPE',

  // update MEMBER_TYPE
  UPDATE_MEMBER_TYPE_START: 'UPDATE_MEMBER_TYPE_START',
  UPDATE_MEMBER_TYPE_SUCCESS: 'UPDATE_MEMBER_TYPE_SUCCESS',
  UPDATE_MEMBER_TYPE_FINISH: 'UPDATE_MEMBER_TYPE_FINISH',

  // delete MEMBER_TYPE
  DELETE_MEMBER_TYPE_START: 'DELETE_MEMBER_TYPE_START',
  DELETE_MEMBER_TYPE_SUCCESS: 'DELETE_MEMBER_TYPE_SUCCESS',
  DELETE_MEMBER_TYPE_FINISH: 'DELETE_MEMBER_TYPE_FINISH',

  // Enable MEMBER_TYPE
  ENABLE_MEMBER_TYPE_REQUEST: 'ENABLE_MEMBER_TYPE_REQUEST',
  ENABLE_MEMBER_TYPE_SUCCESS: 'ENABLE_MEMBER_TYPE_SUCCESS',
  ENABLE_MEMBER_TYPE_FINISH: 'ENABLE_MEMBER_TYPE_FINISH',

  // Disable MEMBER_TYPE
  DISABLE_MEMBER_TYPE_REQUEST: 'DISABLE_MEMBER_TYPE_REQUEST',
  DISABLE_MEMBER_TYPE_SUCCESS: 'DISABLE_MEMBER_TYPE_SUCCESS',
  DISABLE_MEMBER_TYPE_FINISH: 'DISABLE_MEMBER_TYPE_FINISH',

  // Enable MEMBER_TYPE
  SINGLE_ENABLE_MEMBER_TYPE_REQUEST: 'SINGLE_ENABLE_MEMBER_TYPE_REQUEST',
  SINGLE_ENABLE_MEMBER_TYPE_SUCCESS: 'SINGLE_ENABLE_MEMBER_TYPE_SUCCESS',
  SINGLE_ENABLE_MEMBER_TYPE_FINISH: 'SINGLE_ENABLE_MEMBER_TYPE_FINISH',

  // Disable MEMBER_TYPE
  SINGLE_DISABLE_MEMBER_TYPE_REQUEST: 'SINGLE_DISABLE_MEMBER_TYPE_REQUEST',
  SINGLE_DISABLE_MEMBER_TYPE_SUCCESS: 'SINGLE_DISABLE_MEMBER_TYPE_SUCCESS',
  SINGLE_DISABLE_MEMBER_TYPE_FINISH: 'SINGLE_DISABLE_MEMBER_TYPE_FINISH',

  // sort
  SORT_MEMBER_TYPE_START: 'SORT_MEMBER_TYPE_START',
  SORT_MEMBER_TYPE_SUCCESS: 'SORT_MEMBER_TYPE_SUCCESS',
  SORT_MEMBER_TYPE_FINISH: 'SORT_MEMBER_TYPE_FINISH',
  SORT_MEMBER_TYPE_RESET: 'SORT_MEMBER_TYPE_RESET',
}
