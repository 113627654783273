import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import * as enquiryManager from '../index'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {SortType} from 'rsuite-table/lib/@types/common'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {isEmpty} from 'lodash'
import {FiEye, FiPenTool} from 'react-icons/fi'
import GrievanceActionsModal from './grievanceActionsModal'
import axios from 'axios'
import * as departments from '../../../../userMgmt/components/departments/index'
import {toast} from 'react-toastify'
import ViewGrievanceDetails from './ViewDetails'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const EnquiryComponent = () => {
  const dispatch = useDispatch()
  const {
    data: {query, meta},
    deleteSuccess,
    success,
    loading,
  } = useSelector((state: any) => state.enquiryManager)

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  useEffect(() => {
    dispatch(enquiryManager.actions.getEnquiry(params))
  }, [params])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const [showGrievanceActionModal, setShowGrievanceActionModal] = useState(false)
  const [selectedGrievanceId, setSelectedGrievanceId] = useState(false)
  const [grievanceStatusData, setGrievanceStatusData]: any = useState([])
  const [status, setStatus] = useState('')
  const [department, setDepartment] = useState('')
  const [remarks, setRemarks] = useState('')
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const [showGrievanceDetailsModal, setShowGrievanceDetailsModal] = useState(false)

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
            <IconButton
              onClick={() => {
                setShowGrievanceDetailsModal(true)
                setSelectedGrievanceId(rowData?.id)
              }}
              appearance='subtle'
              icon={<FiEye />}
              data-cy='view-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let branchId = [rowData.id]
                setCheckedValues(branchId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              onClick={() => {
                setRemarks('')
                setStatus(rowData?.status || 'Select Status')
                setDisableSubmitButton(false)
                setShowGrievanceActionModal(true)
                setSelectedGrievanceId(rowData?.id)
              }}
              appearance='subtle'
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'First Name',
      dataKey: 'firstName',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.firstName) ? rowData?.firstName : '-')}</Cell>,
      sortable: false,
    },
    {
      label: 'Last Name',
      dataKey: 'lastName',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.lastName) ? rowData?.lastName : '-')}</Cell>,
      sortable: false,
    },
    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.email) ? rowData?.email : '-')}</Cell>,
      sortable: false,
    },
    {
      label: 'Contact Number',
      dataKey: 'contactNumber',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) => (!isEmpty(rowData?.contactNumber) ? rowData?.contactNumber : '-')}
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: <Cell>{(rowData) => rowData?.status}</Cell>,
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Inquiry deleted successfully')
    }

    handleChecked([])
    dispatch(enquiryManager?.actions.getEnquiry())
  }, [deleteSuccess, success])
  const grievanceData = !isEmpty(query)
    ? query?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        firstName: item.firstName,
        middleName: item.middleName,
        lastName: item.lastName,
        type: item.type.name,
        email: item.email,
        query: item.query,
        contactNumber: item.contactNumber,
        status: item.status?.display_name,
      }))
    : []
  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }
  const updateGrievanceStatus = () => {
    setDisableSubmitButton(true)

    const statusId = grievanceStatusData.filter(
      (g: {display_name: string; id: string}) => g.display_name === status
    )[0]?.id

    axios
      .put(API_URL + `/query/${selectedGrievanceId}`, {
        statusId: statusId,
      })
      .then((res) => {
        toast.success('Successfully edited the Inquiry.')
        handleRefresh()
        setShowGrievanceActionModal(false)
      })
      .catch((err) => {
        toast.error('Something went wrong.')
        setDisableSubmitButton(false)
      })
  }

  const handleDelete = () => {
    let grievanceId = checkedValues?.map((value) => ({id: value}))
    dispatch(enquiryManager?.actions?.deleteEnquiry(grievanceId))
    handleAlertClose()
  }

  useEffect(() => {
    handleChecked([])
    dispatch(enquiryManager?.actions.getEnquiry())
  }, [success])

  const handleExport = () => {
    dispatch(enquiryManager.actions.exportFile('Inquiry Data', params))
  }
  const handleRefresh = () => {
    dispatch(enquiryManager.actions.getEnquiry())
  }

  useEffect(() => {
    axios.get(API_URL + '/query-status').then((res) => {
      setGrievanceStatusData(res.data?.data?.queryStatus)
    })
  }, [])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Inquiry Manager'
          params={params}
          setParams={setParams}
          filterData={true}
          handleExport={handleExport}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          addShow={false}
          deleteShow={true}
          importShow={false}
          toggleMultipleShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            showLoading={loading}
            data={grievanceData}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {showGrievanceActionModal && (
            <GrievanceActionsModal
              remarks={remarks}
              handleClick={updateGrievanceStatus}
              handleClose={() => {
                setShowGrievanceActionModal(false)
              }}
              setRemarks={setRemarks}
              department={department}
              statusList={grievanceStatusData}
              isOpen={showGrievanceActionModal}
              disableSubmitButton={disableSubmitButton}
              status={status}
              setStatus={setStatus}
            />
          )}
          {showGrievanceDetailsModal && (
            <ViewGrievanceDetails
              data={query?.filter((d: any) => d.id === selectedGrievanceId)[0]}
              handleClose={() => setShowGrievanceDetailsModal(false)}
            />
          )}

          <>
            {alertOpen &&
              (!isEmpty(checkedValues) ? (
                <DeleteModal
                  handleClick={() => handleDelete()}
                  isOpen={alertOpen}
                  handleClose={() => handleAlertClose()}
                />
              ) : (
                toast.error('No data selected') && setAlertOpen(false)
              ))}
          </>
        </div>
      </div>
    </>
  )
}

export default EnquiryComponent
